import Vue from 'vue'
import Router from 'vue-router'
import BeforeEach from '@/router/methods/before-each'
import AfterEach from '@/router/methods/after-each'

// Public Views
import Checkout from '@/Pages/Checkout.vue'
import SeriesPages from './series-routes'
import ReoccuringEventPages from './reoccuring-event-routes'

import PublicPages from './public-routes'
import UserPages from './user-routes'
import AdminPages from './_OrgUsername'
import VblAdminPages from './admin-routes'
import ManagementPages from './managers'
import TournamentPages from './tournament-routes'
import LeaguePages from './league-routes'
import RosterPages from './rostered-team-routes'
import ClubPages from './clubs-routes'
import SessionPages from './session-routes'

Vue.use(Router)

const router = new Router({
  routes: [
    ...PublicPages,
    ...ClubPages,
    ...UserPages,
    ...ManagementPages,
    {
      path: '/checkout',
      name: 'checkout',
      component: Checkout
    },
    ...SeriesPages,
    ...SessionPages,
    ...ReoccuringEventPages,
    ...TournamentPages,
    ...LeaguePages,
    ...RosterPages,
    ...AdminPages,
    ...VblAdminPages
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (!(to.name === from.name)) {
      return { x: 0, y: 0 }
    }
  },
  mode: 'history'
})

router.beforeEach(BeforeEach)
router.afterEach(AfterEach)

export default router
