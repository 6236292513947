import loadTournament from '@/router/methods/loadTournament'
import DivisionRoutes from './session-division-routes'

const TournamentHome = () => import('@/Pages/Tournament/Home.vue')
const TeamHome = () => import('@/Pages/Tournament/Team/Home.vue')
const TeamList = () => import('@/Pages/Tournament/Teams.vue')
const PlayerList = () => import('@/Pages/Tournament/Players.vue')

export default [
  {
    path: '/session/:tournamentId',
    name: 'session-brochure',
    component: TournamentHome,
    beforeEnter: loadTournament,
    props: true
  },
  {
    path: '/session/:tournamentId/admin',
    name: 'session-admin',
    component: TournamentHome,
    beforeEnter: loadTournament,
    props: true
  },
  {
    path: '/session/:tournamentId/teams',
    name: 'session-teams',
    component: TeamList,
    beforeEnter: loadTournament,
    props: (route) => { return { tournamentId: route.params.tournamentId, tab: 'teams' } }
  },
  {
    path: '/session/:tournamentId/players',
    name: 'session-players',
    component: PlayerList,
    beforeEnter: loadTournament,
    props: (route) => { return { tournamentId: route.params.tournamentId, tab: 'players' } }
  },
  {
    path: '/session/:tournamentId/team/:teamId',
    name: 'session-division-team',
    component: TeamHome,
    beforeEnter: loadTournament,
    props: true
  },
  ...DivisionRoutes
]
