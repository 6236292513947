const options = []

for (let f = 3; f <= 7; f++) {
  options.push(`${f}'`)
  options.push(`${f}' 0"`)
  for (let i = 1; i <= 11; i++) {
    options.push(`${f}' ${i}"`)
  }
}

export default options
