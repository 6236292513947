export default class PointsSDK {
  constructor (http) {
    this.http = http
  }

  get (username) {
    return this.http.get(`/PointSystem/${username}`)
  }

  post (dto) {
    return this.http.post('/PointSystem', dto)
  }

  systemOrganizations = {
    get: (id) => {
      return this.http.get(`/PointSystem/${id}/MemberOrganizations`)
    },
    post: (id, organizationId) => {
      return this.http.post(`/PointSystem/${id}/MemberOrganizations?organizationId=${organizationId}`)
    },
    tournaments: {
      get: (id, username) => {
        return this.http.get(`/PointSystem/${id}/${username}/tournaments`)
      },
      post: (id, username, dto) => {
        return this.http.post(`/PointSystem/${id}/${username}/tournaments`, dto)
      }
    }
  }

  rerun (id) {
    return this.http.post(`/PointSystem/Rerun/${id}?clearOld=true`)
  }
}
