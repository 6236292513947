<template>
  <div>
    <v-dialog
      v-model="dupDialog"
      max-width="300px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          <div>
            <h3 class="headline mb-0">Oops</h3>
            <div>The player you selected is already on this registration</div>
          </div>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="color3" @click.stop="dupDialog = false">Got it</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="inactiveDialog"
      max-width="300px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-text>
          <div>
            <h3 class="headline">Oops</h3>
            <div>{{inactiveMsg.text}}</div>
            <div class="pt-3">
              <v-btn color="color3 color3Text--text" small :href="joinNow">{{inactiveMsg.btn}}</v-btn>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="color3" @click.stop="inactiveDialog = false">Got it</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-autocomplete
      label="Player Lookup"
      v-model="selectedResult"
      :hint="searchHint"
      placeholder="Start typing to search"
      persistent-hint
      color="color3"
      append-icon="fas fa-search"
      autocomplete="off"
      :items="items"
      item-value="id"
      item-text="fullName"
      return-object
      :search-input.sync="term"
      :loading="searching"
      :filter="filter"
      solo
      clearable
      :hide-no-data="!searched"
      attach
    >
      <template slot="no-data" v-if="!membersOnly">
        <v-list-item @click.stop="$emit('manual-click', term)">
          <v-list-item-content>
            <v-list-item-title>
              Can't find the player?
            </v-list-item-title>
            <v-list-item-subtitle>Click here to manually enter the player</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template slot="item" slot-scope="data">
        <template v-if="data.item.header">
          <v-subheader>{{data.item.header}}</v-subheader>
          <v-divider></v-divider>
        </template>
        <template v-else-if="isObject(data.item)">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else-if="newResults">
          <search-result-item
            :player="data.item"
            :showId="false"
            :noEmit="true"
          ></search-result-item>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title>
              {{data.item.fullName}} {{data.item.ageGroup | ageDivisionYouth}}
              <span class="grey--text text--lighten-1 caption">
                {{data.item.email ? ` - ${data.item.email}` : ''}}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle>{{data.item.cityState}}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapGetters } from 'vuex'
import SearchResultItem from '@/components/Player/Search/SearchResultItem'
import { formatArrayOr } from '@/Filters'

export default {
  props: ['sbid', 'partners', 'registration', 'allowTbd', 'membersOnly', 'newResults', 'xPlayers', 'acceptedLevels'],
  data () {
    return {
      selectedResult: null,
      searchResults: [],
      term: null,
      searching: false,
      dupDialog: false,
      inactiveDialog: false,
      inactiveMsg: {
        text: 'The member you selected has an expired or invalid membership',
        btn: 'Renew Now'
      },
      prevTerm: null,
      searched: false
    }
  },
  computed: {
    ...mapGetters(['liveUpdates', 'tournament']),
    searchHint () {
      return this.sbid ? `Enter ${this.sbid.startsWith('p') ? 'a p1440' : this.sbid === 'AVPA' ? 'an AVP America' : `an ${this.sbid}`} number, name, or email` : 'Enter a name or email'
    },
    items () {
      return this.term && this.term.length >= 3 ? this.searchResults : this.partnerList
    },
    partnerList () {
      return this.partners && this.partners.length ? [{ header: 'Past Partners' }, ...this.partners] : []
    },
    joinNow () {
      const t = this.tournament
      if (t) {
        if (t.isBVNE) return 'https://bvne.volleyballlife.com/join'
        if (t.isP1440) return 'https://play.p1440.org/join'
        return `https://${t.organization.username}.volleyballlife.com/join`
      }
      return null
    },
    asOf () {
      return this.tournament && this.tournament.strict ? this.tournament.startDate.format('MM-DD-YYYY') : null
    }
  },
  methods: {
    isObject (v) {
      return typeof v !== 'object'
    },
    search: debounce(function () {
      this.doSearch()
    }, 500),
    doSearch () {
      if (!this.term || typeof this.term !== 'string' || this.term.length < 3 || (this.selectedResult && this.selectedResult.name === this.term)) return
      this.searching = true
      const dto = {
        term: this.term,
        allowTbd: this.allowTbd,
        membersOnly: this.membersOnly,
        tournamentId: this.registration ? this.registration.tournamentId : 0,
        playerId: 0,
        asOf: this.asOf
      }
      this.$VBL.player.lookup(dto)
        .then(r => {
          this.searchResults = r.data || []
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.searching = false
          this.searched = true
        })
    },
    filter () {
      return true
    },
    onSelect (val, event) {
      const tbd = val && val.id && val.id === 2764 && this.allowTbd
      if (val && !tbd) {
        var x = this.registration.players.map(p => p.playerProfileId)
        if (x.includes(val.id)) {
          if (event) event.preventDefault()
          this.dupDialog = true
        }
      }
    }
  },
  watch: {
    term: 'search',
    selectedResult: function (val) {
      if (val) {
        const tbd = val.id && val.id === 2764 && this.allowTbd
        if (this.registration) {
          var x = this.registration.players.map(p => p.playerProfileId)
          if (this.xPlayers) x.push(...this.xPlayers)
          if (x.includes(val.id) && !tbd) {
            this.$nextTick(() => {
              this.selectedResult = null
              this.dupDialog = true
              this.prevTerm = this.term
            })
            return
          }
          if (this.membersOnly) {
            var levelIsGood = true
            if (this.acceptedLevels && this.acceptedLevels.length) {
              var al = this.acceptedLevels.map(m => m.toLowerCase())
              var l = val.levels.map(f => f.toLowerCase())
              var good = l.find(f => al.includes(f))
              if (!good) levelIsGood = false
            }

            if (!val.active || !levelIsGood) {
              this.inactiveMsg = !val.active ? {
                text: 'The member you selected has an expired or invalid membership',
                btn: 'Renew Now'
              } : {
                text: `This event requires a ${formatArrayOr(this.acceptedLevels)} membership`,
                btn: 'Join Now'
              }

              this.$nextTick(() => {
                this.selectedResult = null
                this.inactiveDialog = true
                this.prevTerm = this.term
              })
              return
            }
          }
        }
        this.$emit('player-selected', val)
        if (this.clearOnSelect) {
          this.term = null
        }
      } else if (this.prevTerm) {
        this.term = this.prevTerm
      }
    }
  },
  components: {
    SearchResultItem
  }
}
</script>

<style>

</style>
