<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" class="title">
        {{title}}
        <div class="subheading mt-1">Please enter your bid number to continue.</div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-text-field
          name="bidId"
          label="Bid Number"
          v-model="bidId"
          autocomplete="off"
          :loading="loading"
          @input="clearError"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-row dense v-if="bidNotFound">
        <v-col cols="12" class="error--text title">
          We were unable to locate your bid.
          <div class="subheading">Please check the number and try again</div>
        </v-col>
      </v-row>
    </v-expand-transition>
    <v-row dense>
      <v-col class="text-center" cols="12" >
        <v-btn
          color="color3 color3Text--text"
          @click.stop="getBid"
          :loading="loading"
          :disabled="!dirty"
        >Validate</v-btn>
        <v-btn
          color="color3"
          text
          @click.stop="clearBid"
          v-if="registration.bid"
        >clear</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['tournament', 'registration', 'divisional', 'optional', 'active'],
  data () {
    return {
      loading: false,
      bidId: null,
      bidNotFound: false,
      bidError: null
    }
  },
  computed: {
    ...mapGetters(['liveUpdates']),
    title () {
      return this.optional ? 'Do you have a bid?' : `This ${this.divisional ? 'division' : 'tournament'} requires a bid.`
    },
    dto () {
      return { bidId: this.bidId, tournamentId: this.tournament.id }
    },
    dirty () {
      return this.bidId && (!this.registration.bid || this.registration.bid.bidId !== this.bidId)
    }
  },
  methods: {
    getBid () {
      if (!this.bidId) return
      this.loading = true
      this.$VBL.http.get(`bid/check?b=${this.bidId}&t=${this.tournament.id}`)
        .then(r => {
          if (!r.data) {
            this.bidNotFound = true
          } else {
            this.registration.setBid(r.data, this.tournament)
            this.$emit('bid-found')
          }
        })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    clearError () {
      this.bidNotFound = false
    },
    clearBid () {
      this.registration.bid = null
      this.bidId = null
    }
  },
  watch: {
    active: function (val) {
      if (this.registration.bid) {
        this.bidId = this.registration.bid.bidId
      } else {
        this.bidId = null
      }
    }
  },
  mounted () {
    if (this.registration.bid) {
      this.bidId = this.registration.bid.bidId
    }
  }
}
</script>

<style>

</style>
