import { store } from '@/store'
import * as actions from '@/store/ActionTypes'
import * as mutations from '@/store/MutationTypes'

export default (to, from, next) => {
  if (!from || to.name !== from.name) store.commit(mutations.LOADING, to.name)
  let t = null
  if (to.meta.y) {
    t = `v-window-y-${to.meta.y > (from.meta.y || 0) ? '' : 'reverse-'}transition`
  } else if (to.params.divisionId && from.params.divisionId && to.params.divisionId !== from.params.divisionId) {
    const a = store.getters.tournament
    const b = a.divisions.findIndex(f => f.id === to.params.divisionId)
    const c = a.divisions.findIndex(f => f.id === from.params.divisionId)
    t = `v-window-y-${(b || 0) > (c || 0) ? '' : 'reverse-'}transition`
  }
  store.commit(mutations.SET_TRANSITION, t)

  if (to.params && to.params.tournamentId) {
    store.dispatch(actions.LOAD_TOURNAMENT, to.params)
      .then(() => {
        next()
      })
  } else {
    next()
  }

  // const ids = ['5902', '4458', '4457', '4456', '4455']
  // if (to.params && to.params.tournamentId && ids.includes(to.params.tournamentId)) {
  //   const url = `/court/${to.params.court}`
  //   next(url)
  // } else {
  //   if (to.params && to.params.tournamentId) {
  //     store.dispatch(actions.LOAD_TOURNAMENT, to.params)
  //       .then(() => {
  //         next()
  //       })
  //   } else {
  //     next()
  //   }
  // }

  // const a = store.getters.oidcIsAuthenticated
  // const t = store.getters.oidcAccessToken
  // if (a && !store.getters.user) {
  //   store.dispatch(actions.LOAD_USER).then(() => {
  //     next()
  //   })
  // } else if (t && !a) {
  //   store.dispatch(actions.LOGOUT).then(() => {
  //     next()
  //   })
  // } else {
  //   next()
  // }
}
