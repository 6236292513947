<template>
    <div
      v-if="bidStream"
      :key="$vuetify.breakpoint.name"
      style="width: 100%; height: 100%"
      class="d-flex align-center justify-center"
    >
      <div v-if="divId === 1240" data-ad-tagid="gjzALsZFAj4cuveoSvc3" data-zid="1240" data-name="volleyballlife.com 160x600"></div>
      <div v-if="divId === 1237" data-ad-tagid="KMciZVoMfwF6WIsOooBW" data-zid="1237" data-name="volleyballlife.com 300x250"></div>
      <div v-if="divId === 1238" data-ad-tagid="n8XeCFZ0NcwsP3Kg03ER" data-zid="1238" data-name="volleyballlife.com 300x600"></div>
      <div v-if="divId === 1239" data-ad-tagid="aUXoiMKwKjknNEZUOX1b" data-zid="1239" data-name="volleyballlife.com 728x90"></div>
      <div v-if="divId === 1241" data-ad-tagid="48ocrXJf9ddMdc6lK799" data-zid="1241" data-name="volleyballlife.com MOB 300x250"></div>
      <div v-if="divId === 1242" data-ad-tagid="DopNcsx3sJatEYQKTC2l" data-zid="1242" data-name="volleyballlife.com MOB 320x50"></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['lg', 'size'],
  data () {
    return {
      sizeIds: {
        '160x600': 1240,
        '300x250': 1237,
        '300x600': 1238,
        '728x90': 1239,
        '300x250M': 1241,
        '320x50': 1242
      }
    }
  },
  computed: {
    ...mapGetters(['bidStream']),
    mobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    calcDivId () {
      return this.lg ? (this.mobile ? 1241 : 1238) : (this.mobile ? 1242 : 1239)
    },
    divId () {
      return (this.size && this.sizeIds[this.size]) || this.calcDivId
    }
  }
}
</script>

<style scoped>
</style>
