import uniq from 'lodash.uniq'

export default class Finish {
  roundOf = 0
  value = 0
  maxTeams = 0
  teams = []
  validOnlyWhenEmpty = false

  constructor (value, roundOf, max) {
    this.value = value || 9999999
    this.roundOf = roundOf || 9999999
    this.maxTeams = max || 9999999
  }

  // gettters
  get valid () {
    if (this.validOnlyWhenEmpty) return this.teams.length === 0
    if (this.teams.length === 0) return null
    return this.teams.length <= this.maxTeams
  }

  get distinctPoints () {
    return uniq(this.teams.map((team) => {
      return team.finishPoints
    }))
  }

  get points () {
    return this.distinctPoints.length === 1 ? this.distinctPoints[0] : null
  }
}
