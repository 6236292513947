// Loading
export const LOADING = 'LOADING'
export const LOADING_COMPLETE = 'LOADING_COMPLETE'
export const LOADING_CLEAR = 'LOADING_CLEAR'

export const SET_TRANSITION = 'SET_TRANSITION'

// Shared
export const SET_LOADING = 'SET_LOADING'
export const SET_ERROR = 'SET_ERROR'
export const SET_NAV = 'SET_NAV'
export const TOGGLE_VIEW = 'TOGGLE_VIEW'
export const STORE_THEME = 'STORE_THEME'
export const APPLY_THEME = 'APPLY_THEME'
export const SET_THEME_DARK = 'SET_THEME_DARK'
export const SET_THEME_DRAWER = 'SET_THEME_DRAWER'
export const SET_THEME_PREVIEW = 'SET_THEME_PREVIEW'
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR'
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR'
export const PRINT_POOLS = 'PRINT_POOLS'
export const SET_PRINTABLE = 'SET_PRINTABLE'
export const SET_IFRAME = 'SET_IFRAME'

// Options
export const SET_COUNTRIES = 'SET_COUNTRIES'
export const SET_COUNTRIES_STATUS = 'SET_COUNTRIES_STATUS'
export const SET_COLLEGES = 'SET_COLLEGES'
export const SET_COLLEGES_STATUS = 'SET_COLLEGES_STATUS'
export const SET_COLLEGE_LIST = 'SET_COLLEGES'
export const SET_COLLEGE_LIST_STATUS = 'SET_COLLEGES_STATUS'
export const SET_AGETYPEOPTIONS = 'SET_AGETYPEOPTIONS'
export const SET_GENDEROPTIONS = 'SET_GENDEROPTIONS'
export const SET_DIVISIONOPTIONS = 'SET_DIVISIONOPTIONS'
export const SET_LOCATIONOPTIONS = 'SET_LOCATIONOPTIONS'
export const SET_OPTIONS_LOADED = 'SET_OPTIONS_LOADED'

// User
export const SET_USER = 'SET_USER'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_LASTLOGINTYPE = 'SET_LASTLOGINTYPE'
export const SET_MAGICLINKTIME = 'SET_MAGICLINKTIME'
export const SET_USER_IMAGES = 'SET_USER_IMAGES'
export const SET_NO_HOWTO = 'SET_NO_HOWTO'
export const SET_NO_HOWTO_COLLEGE = 'SET_NO_HOWTO_COLLEGE'
export const SET_USER_PREFERENCE = 'SET_USER_PREFERENCE'
export const SET_USER_BIDPROGRAMS = 'SET_USER_BIDPROGRAMS'
export const SET_USER_BOOKMARKS = 'SET_USER_BOOKMARKS'
export const SET_PASSWORDSET_DT = 'SET_PASSWORDSET_DT'
export const SET_UNREAD = 'SET_UNREAD'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const UPDATE_TOKEN = 'UPDATE_TOKEN'
export const CLEAN_TOKENS = 'CLEAN_TOKENS'
export const SET_MY_TOURNAMENT_TEAMS = 'SET_MY_TOURNAMENT_TEAMS'
export const SET_COURT_MANAGER_SETTINGS = 'SET_COURT_MANAGER_SETTINGS'

// Tournament
export const SET_TOURNAMENT = 'SET_TOURNAMENT'
export const SET_TOURNAMENT_REGDATA = 'SET_TOURNAMENT_REGDATA'

export const UPDATE_TOURNAMENT = 'UPDATE_TOURNAMENT'
export const UPDATE_DIVISION = 'UPDATE_DIVISION'
export const UPDATE_TEAM = 'UPDATE_TEAM'
export const UPDATE_DAY = 'UPDATE_DAY'
export const UPDATE_FLIGHT = 'UPDATE_FLIGHT'
export const UPDATE_BRACKET = 'UPDATE_BRACKET'
export const UPDATE_POOL = 'UPDATE_POOL'
export const UPDATE_MATCH = 'UPDATE_MATCH'
export const UPDATE_GAME = 'UPDATE_GAME'

export const DELETE_TEAM = 'DELETE_TEAM'
export const DELETE_SELECTOR = 'DELETE_SELECTOR'

export const DELETE_MATCH = 'DELETE_MATCH'

export const DELETE_ROUND = 'DELETE_ROUND'
export const RESET_ROUND = 'RESET_ROUND'

export const MOVE_TEAM = 'MOVE_TEAM'
export const SET_DIVISION_JPROP = 'SET_DIVISION_JPROP'
export const SET_ROUND_JPROP = 'SET_ROUND_JPROP'
export const SET_TOURNAMENT_ALLOWADD = 'SET_TOURNAMENT_ALLOWADD'
export const SET_TEAM_TO_MSG = 'SET_TEAM_TO_MSG'
// Cart
export const ADD_CART_ITEM = 'ADD_CART_ITEM'
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM'
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM'
export const CLEAR_CART = 'CLEAR_CART'
export const CLEAN_CARTS = 'CLEAN_CARTS'
export const SET_CART_STATUS = 'SET_CART_STATUS'
export const SET_CART_ORDER_ID = 'SET_CART_ORDER_ID'
export const APPLY_PROMO = 'APPLY_PROMO'
export const CLEAR_PROMO = 'CLEAR_PROMO'

// Order
export const SET_ORDER = 'SET_ORDER'

// Organization
export const SET_ORGANIZATION = 'SET_ORGANIZATION'
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'

// Live Updates
export const SUBSCRIBTION_ADD = 'SUBSCRIBTION_ADD'

// Live Scoring
export const SET_MATCH_PROPS = 'SET_MATCH_PROPS'
export const CLEAR_MATCH_PROPS = 'CLEAR_MATCH_PROPS'
