<template>
  <v-btn icon :color="my.color" :loading="my.loading">
    <v-icon>{{my.icon}}</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: ['type'],
  computed: {
    my () {
      switch (this.type) {
        case 'good':
          return {
            color: 'success white--text',
            icon: 'fas fa-check-circle'
          }
        case 'warning':
          return {
            color: 'warning white--text',
            icon: 'fas fa-question-circle'
          }
        case 'error':
          return {
            color: 'error white--text',
            icon: 'fas fa-exclamation-circle'
          }
        case 'loading':
          return {
            color: 'color1 color1Text--text',
            loading: true
          }
        case 'meh':
          return {
            color: 'color1 color1Text--text',
            icon: 'fas fa-minus-circle'
          }
        case 'open':
          return {
            color: 'color1 color1Text--text',
            icon: 'fas fa-circle'
          }
      }
      return null
    }
  }
}
</script>

<style>

</style>
