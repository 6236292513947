import endpoints from './endpoints'

export default class TournamentSDK {
  constructor (http) {
    this.http = http
  }

  getAllSummaries (query) {
    return this.http.get(endpoints.tournament.getAllSummaries(query))
  }

  getById (id) {
    return this.http.get(endpoints.tournament.getById(id))
  }

  getTournamentCopyById (id) {
    return this.http.get(endpoints.tournament.getCopyById(id))
  }

  getRawTournamentById (id) {
    return this.http.get(endpoints.tournament.getRawById(id))
  }

  save (tournament) {
    if (tournament.dto) {
      tournament = tournament.dto
    }
    tournament.divisions.forEach(d => {
      d.days.forEach(r => {
        r.teamSelectors = []
        r.flights = []
        r.brackets = []
      })
    })
    return this.http.post(endpoints.tournament.put, tournament)
  }

  saveQuick (tournament) {
    return this.http.post(`${endpoints.tournament.put}?quick=true`, tournament)
  }

  publish (id, isPublic) {
    return this.http.post(endpoints.tournament.publish(id, isPublic))
  }

  getSeededTeams (id) {
    return this.http.get(endpoints.tournament.getSeededTeams(id))
  }

  getRegistrationData (id) {
    return this.http.get(endpoints.tournament.getRegistrationData(id))
  }

  getFinancialData (id) {
    return this.http.post(`/tournament/${id}/FinanceReport`)
  }

  getRawRegistrationData (id) {
    return this.http.get(endpoints.tournament.getRawRegistrationData(id))
  }

  getWithdrawDates (id) {
    return this.http.get(`/tournament/${id}/withdraw-dates`)
  }

  putBulkRegister (overwrite, registrations, finish) {
    return this.http.put(`/tournament/register/upload?o=${overwrite}&f=${finish}`, registrations)
  }

  lockResults (id) {
    return this.http.post(endpoints.tournament.lockResults(id))
  }

  getLeads (id) {
    return this.http.get(endpoints.tournament.getLeads(id))
  }

  checkBids (id) {
    return this.http.get(`/Bid/TournamentBids/${id}`)
  }

  postBids (id, dto) {
    return this.http.post(`/Bid/TournamentBids/${id}`, dto)
  }

  trickleCheck (id, bidProgramid) {
    return this.http.get(`/Bid/TrickleCheck/${id}/${bidProgramid}`)
  }

  checkin = {
    getWindows: (id) => {
      return this.http.get(`/tournament/${id}/OnlineCheckin`)
    },
    postWindows: (id, dto) => {
      return this.http.post(`/tournament/${id}/OnlineCheckin`, dto)
    },
    sendSms: (id) => {
      return this.http.post(`/tournament/${id}/SmsCheckIn`)
    }
  }

  streams = {
    getAll: (tournamentId) => {
      return this.http.get(`/livestream/${tournamentId}`)
    },
    post: (tournamentId, dto) => {
      return this.http.post(`/livestream/${tournamentId}`, dto)
    },
    delete: (tournamentId, streamId) => {
      return this.http.delete(`/livestream/${tournamentId}/${streamId}`)
    }
  }

  logos = {
    get: (tournamentId) => {
      return this.http.get(`/tournament/${tournamentId}/Summary`)
    },
    post: (tournamentId, dto) => {
      return this.http.post(`/tournament/${tournamentId}/Logos`, dto)
    }
  }

  teams = {
    refresh: (divisionId) => {
      return this.http.get(`division/${divisionId}/teams/refresh`)
    }
  }

  league = {
    generate: (dto) => {
      return this.http.post('league/generate', dto)
    },
    fill: (dto) => {
      return this.http.post('league/fill', dto)
    },
    fillPools: (dto) => {
      return this.http.post('league/fill-pools', dto)
    },
    fillLadders: (dto) => {
      return this.http.post('league/fill-ladders', dto)
    },
    clear: (id) => {
      return this.http.post(`league/clear?roundId=${id}`)
    },
    addRefs: (tournamentId, divisionId, poolId) => {
      return this.http.post(`league/addRefs?tournamentId=${tournamentId}&divisionId=${divisionId}&poolId=${poolId}`)
    },
    gameReminders: {
      get: (leagueId) => {
        return this.http.get(`league/gameReminders?leagueId=${leagueId}`)
      },
      post: (leagueId, dto) => {
        dto.tournamentId = leagueId
        return this.http.post('league/gameReminders', dto)
      },
      resend: (leagueId, jobId) => {
        return this.http.post(`league/gameReminders/resend?tournamentId=${leagueId}&jobId=${jobId}`)
      }
    }
  }

  multiplier = {
    get: (id) => {
      return this.http.get(`tournament/${id}/PointMultiplier`)
    },
    set: (id, pm) => {
      return this.http.post(`tournament/${id}/PointMultiplier?pm=${pm}`)
    }
  }

  patch (dto) {
    return this.http.patch('/tournament', dto)
  }

  getFieldAvail (id) {
    return this.http.post(`/tournament/${id}/fieldSummary`)
  }

  getQrPackage (docs) {
    return this.http.post('/qr', docs)
  }

  getPoolMatchTemplates (teams, kob) {
    return this.http.get(`/matches/template?teams=${teams}&kob=${kob}`)
  }

  clearCourts (flightId) {
    return this.http.post(`/flight/${flightId}/clearCourts`)
  }

  checklists = {
    get: (id) => {
      return this.http.get(`/checklist/${id}`)
    },
    getAll: (tournamentId) => {
      return this.http.get(`/checklist/tournament/${tournamentId}`)
    },
    post: (dto) => {
      return this.http.post(`/checklist/tournament/${dto.tournamentId}`, dto)
    },
    delete: (tournamentId, checklistId) => {
      return this.http.delete(`/checklist/tournament/${checklistId}`)
    },
    putItem: (id, dto) => {
      return this.http.post(`/checklist/${id}/item`, dto)
    }
  }

  matches = {
    getSettings: (id, pool) => {
      return this.http.get(`/matches/settings?matchId=${id}&pool=${pool}`)
    },
    postSettings: (id, pool, dto) => {
      return this.http.post(`/matches/settings?matchId=${id}&pool=${pool}`, dto)
    }
  }
}
