import Vue from 'vue'
import * as mutations from '../MutationTypes'
import * as actions from '../ActionTypes'
import User from '../../classes/User'
import moment from 'moment'
import SDK from '../../VBL'
import { firstBy } from 'thenby'
// import uniqBy from 'lodash.uniqby'

export default {
  state: {
    user: null,
    lastLoginType: null,
    lastPwSet: null,
    magicLinkDt: null,
    noHowTo: false,
    noCollegeHowTo: false,
    preferences: {
      tournamentView: null,
      courtManager: {
        day: null,
        divisions: [],
        courts: [],
        iMinutes: 30,
        iHeight: 32,
        iWidth: 200,
        showNames: false,
        showPlayers: false,
        color2: false,
        autoS: 10
      }
    },
    unread: 0,
    notifications: [],
    tokens: [],
    teams: []
  },
  mutations: {
    [mutations.SET_USER] (state, payload) {
      if (payload) {
        state.user = new User(payload)
      } else {
        state.user = null
        state.unread = 0
        state.notifications = []
      }
    },
    [mutations.SET_LASTLOGINTYPE] (state, payload) {
      state.lastLoginType = payload
    },
    [mutations.SET_PASSWORDSET_DT] (state, payload) {
      state.user.lastPwSet = new Date()
    },
    [mutations.SET_MAGICLINKTIME] (state, payload) {
      state.magicLinkDt = payload
    },
    [mutations.SET_USER_IMAGES] (state, payload) {
      state.user.images = payload
    },
    [mutations.SET_NO_HOWTO] (state, payload) {
      state.noHowTo = true
    },
    [mutations.SET_NO_HOWTO_COLLEGE] (state, payload) {
      state.noCollegeHowTo = true
    },
    [mutations.SET_USER_PREFERENCE] (state, payload) {
      Object.assign(state.preferences, payload)
    },
    [mutations.SET_USER_BIDPROGRAMS] (state, payload) {
      state.user.bidPrograms = payload
    },
    [mutations.SET_USER_BOOKMARKS] (state, payload) {
      state.user.bookmarks = payload
    },
    [mutations.SET_UNREAD] (state, payload) {
      state.unread = payload
    },
    [mutations.SET_NOTIFICATIONS] (state, payload) {
      if (payload.reset) state.notifications = []
      const notes = Array.isArray(payload) ? payload : (payload.notes || [])
      notes.forEach(n => {
        const i = state.notifications.findIndex(f => f.id === n.id)
        n.unix = moment(n.dtCreated).unix()
        if (i === -1) {
          state.notifications.push(n)
        } else {
          state.notifications.splice(i, 1, n)
        }
      })
      state.notifications.sort(firstBy('unix', -1))
    },
    [mutations.UPDATE_TOKEN] (state, payload) {
      if (!payload.valid) {
        state.tokens = state.tokens.filter(f => !(f.teamId === payload.tournamentTeamId && f.token === payload.id.toLowerCase()))
        return
      }
      const i = state.tokens.findIndex(f => f.teamId === payload.tournamentTeamId && f.token === payload.id.toLowerCase())
      if (i === -1) {
        state.tokens.push(payload.storeDto)
      }
    },
    [mutations.CLEAN_TOKENS] (state, payload) {
      state.tokens = state.tokens.filter(f => moment().isBefore(moment(f.validTo)))
    },
    [mutations.SET_MY_TOURNAMENT_TEAMS] (state, payload) {
      state.teams = payload
    },
    [mutations.SET_COURT_MANAGER_SETTINGS] (state, payload) {
      state.preferences.courtManager = Object.assign(state.preferences.courtManager, payload)
    },
    ENSURE_ORG_PROP (state, payload) {
      var page = state.user && state.user.pages && state.user.pages.find(page => {
        return page.username.toLowerCase() === payload.username.toLowerCase()
      })

      if (page) {
        if (page.props) {
          if (!page.props.includes(payload.prop)) {
            page.props.push(payload.prop)
          }
        } else {
          page.props = [payload.prop]
        }
      }
    },
    addPage (state, payload) {
      console.log('here')
      payload.forEach(pl => {
        var x = state.user.pages.find(f => f.id === pl.id)
        if (!x) state.user.pages.push(pl)
      })
      // state.users.pages = uniqBy(...state.user.pages, 'username')
    }
  },
  actions: {
    [actions.LOAD_USER] ({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        commit(mutations.LOADING, actions.LOAD_USER)
        commit(mutations.SET_ERROR, null)
        const sdk = new SDK(Vue.prototype.axios)
        return sdk.user.getMe()
          .then((response) => {
            // dispatch(actions.LOAD_USER_BOOKMARKS)
            dispatch(actions.LOAD_USER_BIDPROGRAMS)
            commit(mutations.SET_USER, response.data)
            if (state.user.isCollegeCoach) {
              dispatch('loadCollegeTeams')
            }
            resolve()
          })
          .catch((response) => {
          // Error
            console.log(response)
            commit(mutations.SET_ERROR, response.message)
            resolve()
          })
          .finally(() => {
            commit(mutations.LOADING_COMPLETE, actions.LOAD_USER)
          })
      })
    },
    loadCollegeTeams ({ state }) {
      const sdk = new SDK(Vue.prototype.axios)
      sdk.user.getMyCollegeTeams()
        .then(r => {
          state.user.collegeTeams = r.data
        })
    },
    [actions.UNLOAD_USER] ({ commit, state, rootGetters, dispatch }) {
      if (state.user) {
        commit(mutations.SET_USER, null)
        commit(mutations.SET_ORDER, null)
      }
    },
    [actions.LOAD_USER_IMAGES] ({ commit, state }) {
      const sdk = new SDK(Vue.prototype.axios)

      return sdk.user.getMyImages()
        .then((response) => {
          commit(mutations.SET_USER_IMAGES, response.data)
        })
        .catch((error) => {
          console.log(error.response)
        })
    },
    [actions.LOAD_USER_BIDPROGRAMS] ({ commit, state }) {
      const user = state.user
      if (user && user.pages && user.pages.length) {
        const sdk = new SDK(Vue.prototype.axios)
        sdk.user.getBidPrograms()
          .then(r => {
            commit(mutations.SET_USER_BIDPROGRAMS, r.data)
          })
          .catch((response) => {
            console.log(response)
          })
      }
    },
    [actions.LOAD_USER_BOOKMARKS] ({ commit, state }) {
      const sdk = new SDK(Vue.prototype.axios)
      sdk.user.getBookmark()
        .then(r => {
          commit(mutations.SET_USER_BOOKMARKS, r.data)
        })
        .catch((response) => {
          console.log(response)
        })
    }
  },
  getters: {
    user (state) {
      return state.user && new User(state.user)
    },
    preferences (state) {
      return state.preferences
    },
    admin (state, getters) {
      return getters.user && getters.user.isTournamentAdmin(getters.tournament)
    },
    isRef (state, getters) {
      return getters.user && getters.tournament && getters.tournament.refIds.includes(getters.user.id)
    },
    userImages (state) {
      return state.user && state.user.images
    },
    getPageInfo: (state) => (username) => {
      if (typeof username === 'string') {
        return state.user && state.user.pages && state.user.pages.find(page => {
          return page.username.toLowerCase() === username.toLowerCase()
        })
      }
      return state.user && state.user.pages && state.user.pages.find(page => {
        return page.id === username
      })
    },
    dtMagicLinkExpires (state) {
      return state.magicLinkDt ? moment(state.magicLinkDt) : null
    },
    full (state) {
      return true
    },
    noHowTo (state) {
      return state.noHowTo
    },
    noCollegeHowTo (state) {
      return state.noCollegeHowTo
    },
    unread (state) {
      return state.user ? state.unread : 0
    },
    notifications (state) {
      return state.user ? state.notifications : []
    },
    tokens (state) {
      // state.tokens.filter(f => moment().isBefore(moment(f.validTo)))
      return state.tokens
    },
    userTeams (state) {
      const a = []
      // state.tokens.filter(f => moment().isBefore(moment(f.validTo)))
      if (state.user && state.teams && state.teams.length) {
        a.push(...state.teams)
      }
      if (state.tokens && state.tokens.length) {
        a.push(...state.tokens.map(m => m.teamId))
      }
      return a
    },
    getTeamToken: (state) => (teamId, route) => {
      if (route.query.token) return route.query.token
      // state.tokens.filter(f => moment().isBefore(moment(f.validTo)))
      const t = state.tokens && state.tokens.find(f => f.teamId === teamId)
      return t ? t.token : null
    },
    courtManagerSettings (state) {
      return state.preferences.courtManager
    }
  }
}
