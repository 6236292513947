const all = [...new Set([
  'Loyola',
  'Mira Costa',
  'Notre Dame',
  'Palisades Charter',
  'Redondo Union',
  'Arroyo Grande',
  'Dos Pueblos',
  'San Luis Obispo',
  'San Marcos',
  'Santa Barbara',
  'Bishop Montgomery',
  'Palos Verdes',
  'Saddleback Valley Christian',
  'Santa Monica',
  'Windward',
  'Crossroads',
  'Oaks Christian',
  'Viewpoint',
  'Wildwood',
  'Malibu',
  'Saint Francis',
  'Peninsula HS',
  'Downey',
  'California HS',
  'Wiseburn Da Vinci',
  // SAN DIEGO DISTRICT
  'Canyon Crest Academy',
  'Carlsbad',
  'Cathedral Catholic',
  'Classical Academy',
  'Coronado',
  'Francis Parker',
  'Helix',
  'Horizon Prep',
  'La Costa Canyon',
  'La Jolla',
  'La Jolla Country Day School',
  'Mission Bay',
  'Mission Vista',
  'Our Lady of Peace',
  'Pacific Ridge',
  'Point Loma',
  'Poway',
  'Rancho Bernardo',
  'Sage Creek',
  'San Marcos',
  'Santa Fe Christian',
  'Scripps Ranch',
  'Serra',
  'University City',
  'Valhalla',
  'Westview',
  // SOUTHERN PACIFIC DISTRICT
  'Archer School for Girls',
  'Agoura',
  'Bishop Montgomery',
  'Downey',
  'El Toro',
  'Chaminade',
  'Laguna Beach',
  'Marymount',
  'Mira Costa',
  'Newbury Park',
  'North Torrance',
  'Notre Dame (Sherman Oaks)',
  'Notre Dame Academy',
  'Oaks Christian',
  'Pacifica Christian',
  'Palisades Charter',
  'Palos Verdes',
  'Redondo Union',
  'Saddleback Valley Christian',
  'Saint Joseph',
  'Santa Margarita',
  'Santa Monica',
  'Sierra Canyon',
  'South Torrance',
  'Thousand Oaks',
  'Viewpoint',
  'Village Christian',
  'Wildwood',
  'Windward',
  'Westlake',
  'El Segundo',
  'J Serra',
  // NORTHERN CALIFORNIA (PACIFIC) DISTRICT SCHOOLS
  'Acalanes',
  'Aptos',
  'Aragon',
  'Branson',
  'Burlingame',
  'Campolindo',
  'Carlmont',
  'Carondelet',
  'Convent & Stuart Hall',
  'Encinal',
  'Granada',
  'Gunn',
  'Half Moon Bay',
  'Harbor',
  'Homestead',
  'Los Gatos',
  'Menlo Atherton',
  'Miramonte',
  'Notre Dame Belmont',
  'Palo Alto',
  'Piedmont',
  'Redwood',
  'Sacred Heart Cathedral',
  'Sacred Heart Prep',
  'Saint Francis',
  'Saint Ignatius',
  'San Francisco University High School',
  'San Leandro',
  'Santa Catalina',
  'Santa Cruz',
  'Scotts Valley',
  'Soquel',
  'Woodside',
  // AZ PREP D-I
  'Agua Fria',
  'Desert Edge',
  'Dysart',
  'Estrella Foothills',
  'Millennium',
  'Shadow Ridge',
  'Valley Vista',
  'Verrado',
  'Willow Canyon',
  'Youngker',
  'Arcadia',
  'Barry Goldwater',
  'Boulder Creek',
  'Chaparral',
  'Deer Valley',
  'Mountain Ridge',
  'Notre Dame Prep',
  'Saguaro',
  'Sandra Day O\'Connor',
  'Sunnyslope',
  'Basha',
  'Casteel',
  'Chandler',
  'Hamilton',
  'Perry',
  'Seton Catholic Prep',
  'Xavier Prep',
  'Amphitheater',
  'Canyon Del Oro',
  'Ironwood Ridge',
  'Salpointe Catholic',
  'Coconino',
  'Flagstaff',
  'Mohave',
  'Combs',
  'Corona del Sol',
  'Desert Vista',
  'Highland',
  'Marcos de Niza',
  'Maricopa',
  'Mesquite',
  'Mountain Pointe',
  'Queen Creek',
  'Williams Field',
  // AZ PREP D-II
  'Arete Prep',
  'AZ College Prep',
  'Chandler Prep',
  'Fountain Hills',
  'Horizon Honors',
  'North Pointe Prep',
  'Rancho Solano Prep',
  'River Valley',
  'Scottsdale Prep',
  'Trivium Prep',
  'Valley Christian',
  'Veritas Prep',
  // SOUTHERN PACIFIC DISTRICT
  'Mira Costa',
  'Redondo Union',
  'Palos Verdes',
  'Palisades Charter',
  'Santa Monica',
  'Bishop Montgomery',
  'Chaminade',
  'St. Anthony',
  'Notre Dame, Sherman Oaks',
  'St. Joseph',
  'El Segundo',
  'Gahr',
  'Malibu',
  'Viewpoint',
  'Wildwood',
  'Archer School for Girls',
  'Notre Dame Academy',
  'Crossroads',
  'Pacifica Christian',
  'Sierra Canyon',
  // NORTHERN CALIFORNIA DISTRICT
  'Pleasant Valley High School, Chico',
  'Brentwood'])
].sort()

export default {
  boys: all,
  girls: all
}
