<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
    transition="dialog-transition"
    scrollable
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="color3"
        text
        v-on="on"
      >{{playerIName}} not playing?</v-btn>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Bid Restrictions</v-toolbar-title>
      </v-toolbar>
      <v-card-title class="title">
        This bid was awarded to:<br>{{playerNames | formatArray}}
        <div class="subheading">The bid substition policy states that {{playerCount}} of these players must remain on the team.</div>
      </v-card-title>
      <v-card-text class="subheading pt-0">
        Which player(s) will remain on the team?<br>
        <v-checkbox
          v-for="(p, i) in registration.bid.players"
          :key="p.id"
          :label="`${p.firstName} ${p.lastName}`"
          v-model="keepers"
          :value="p.id"
          hide-details
          :class="{'mt-0': i > 0}"
        ></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-row dense>
          <v-col class="text-center" cols="12" >
            <v-btn
              color="color3 color3Text--text"
              fab
              :disabled="!saveable || !dirty"
              @click.stop="save"
            >
              <v-icon>fas fa-save</v-icon>
            </v-btn>
            <v-btn
              color="red white--text"
              @click.stop="dialog = false"
              fab
            >
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import isEqual from 'lodash.isequal'
export default {
  props: ['tournament', 'registration', 'playerI', 'adminQuickAdd'],
  data () {
    return {
      dialog: false,
      keepers: []
    }
  },
  computed: {
    playerCount () {
      return this.registration.bid.subPolicy
    },
    playerIName () {
      return this.registration.players[this.playerI - 1].firstName
    },
    playerNames () {
      return this.registration.bid.players.map(m => `${m.firstName} ${m.lastName}`)
    },
    dirty () {
      const a = this.keepers
      const b = this.registration.bidPlayers
      return !isEqual(a.sort(), b.sort())
    },
    saveable () {
      return this.keepers.length >= this.playerCount
    }
  },
  methods: {
    save () {
      if (!this.saveable) return
      this.registration.bidKeep(this.keepers)
      this.dialog = false
    },
    initKeepers () {
      this.keepers = this.registration.bidPlayers
    }
  },
  watch: {
    dialog: function (val) {
      this.initKeepers()
    }
  }
}
</script>

<style>

</style>
