<template>
  <v-app-bar
    color="color1 color1Text--text"
    app
    clipped-left
    fixed
  >
    <v-app-bar-nav-icon>
      <v-btn color="color1Text" icon @click.stop="$emit('toggle-drawer')">
        <v-icon>far fa-bars</v-icon>
      </v-btn>
    </v-app-bar-nav-icon>
    <router-link
      v-if="logoUrl"
      to="/"
      tag="img"
      :src="logoUrl"
      style="height: 100%; max-height: 100%; position: relative"
    >{{logoUrl}}</router-link>
    <v-toolbar-title v-else
      :style="$vuetify.breakpoint.smAndUp ? 'width: 300px; min-width: 250px' : 'min-width: 72px'"
      class="ml-0 pl-4"
    >
      <router-link
        to="/"
        tag="span"
        class="hidden-xs-only"
        style="cursor: pointer"
      >{{organizationName}}</router-link>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <!-- CART FAB  -->
    <cart-fab btn-class="mr-2"></cart-fab>
    <!-- TOURNAMENT QUICK SETTINGS  -->
    <v-fab-transition>
      <quick-settings-dialog v-if="view.admin && tournament"></quick-settings-dialog>
    </v-fab-transition>
    <v-fab-transition>
      <bid-assign-dialog v-if="user && user.bidPrograms && user.bidPrograms.length && tournament"></bid-assign-dialog>
    </v-fab-transition>
    <!-- USER -->
    <template v-if="user">
      <!-- NOTIFICATIONS -->
      <v-fab-transition>
        <notifications v-if="notifications && notifications.length"/>
      </v-fab-transition>
      <!-- USER NAME  -->
      <user-menu></user-menu>
    </template>
    <template v-else>
      <v-btn text color=color1Text @click.stop="$emit('login-click')">
        <v-icon class="mr-2">fas fa-unlock-alt</v-icon>
        Sign In
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import * as actions from '@/store/ActionTypes'
const QuickSettingsDialog = () => import('@/components/Tournament/QuickSettings/Dialog')
const BidAssignDialog = () => import('@/components/Tournament/Bids/BidAssignDialog')
const CartFab = () => import('@/components/Utils/CartFab')
const UserMenu = () => import('@/Nav/Toolbars/UserMenu')
const Notifications = () => import('@/Nav/Toolbars/Notifications')

export default {
  data () {
    return {
      drawer: null,
      signInDialog: false
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'organizationName',
      'logoUrl',
      'tournament',
      'view',
      'notifications'
    ]),
    userMenu () {
      return [
        { icon: 'fas fa-user-circle', text: 'My Account', to: { name: 'me' } },
        ...this.user.profiles.map(p => {
          return {
            icon: 'fas fa-id-card',
            text: p.relation === 'Self' ? 'My Player Profile' : `${p.profile.firstName}'s Player Profile`,
            to: { name: 'player-profile', params: { playerProfileId: p.profile.id } }
          }
        }),
        { icon: 'fas fa-history', text: 'My Orders', to: { name: 'my-orders' } },
        { icon: 'fas fa-heart', text: 'Favorites & Notes', to: { name: 'my-bookmarks' } },
        // { icon: 'fas fa-bell', text: 'Notifications', to: { name: 'my-notifications' } },
        { icon: 'fas fa-sign-out-alt', text: 'Sign Out', click: () => { this.$emit('logout-click') } }
      ]
    }
  },
  methods: {
    goToCart () {
      this.$router.push({ name: 'checkout' })
    },
    checkMyTeams () {
      console.log('checking')
      if (this.user && this.tournament && this.tournament.checkUserTeams) {
        this.$store.dispatch(actions.LOAD_MY_TOURNAMENT_TEAMS, { tournamentId: this.tournament.id })
      }
    }
  },
  watch: {
    user: 'checkMyTeams',
    tournament: 'checkMyTeams',
    'tournament.teams.length': 'checkMyTeams'
  },
  mounted () {
    this.checkMyTeams()
  },
  components: {
    CartFab,
    QuickSettingsDialog,
    BidAssignDialog,
    UserMenu,
    Notifications
  }
}
</script>
