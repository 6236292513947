// import BracketTeam from './BracketTeam'
import { BracketTypes } from './BracketTypes'
// import BracketBase from './BracketBase'
import DtoUpdate from './DtoUpdate'
import flatten from '../helpers/ArrayFlatten'
import Match from './Match'
import MatchSettings from './MatchSettings'
import uniq from 'lodash.uniq'
import { firstBy } from 'thenby'
import uniqBy from 'lodash.uniqby'
import Dual from './Dual'
import max from 'lodash.max'

export default class Bracket {
  id = 0
  name = 'Playoffs'
  type = 'SINGLE_ELIM'
  champions = 1
  winnersMatchSettings = null
  losersMatchSettings = null
  teamCount = 0
  _dto = null
  _matches = null
  matches = []
  placeMatches = []
  props = []
  updates = 0
  duals = []
  placeDuals = []
  manualRefs = false
  prevLoser = false

  seedDisplayMap = null

  constructor (sdk, dto) {
    this.sdk = sdk
    if (dto) {
      this.update(dto)
    }
  }

  // methods
  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = ['teams', 'winnersMatchSettings', 'matches', 'placeMatches']
    DtoUpdate(dto, this, exclude)

    if (dto.winnersMatchSettings) {
      this.winnersMatchSettings = new MatchSettings(dto.winnersMatchSettings)
    }
    if (!this.winnersMatchSettings) {
      this.winnersMatchSettings = new MatchSettings({
        matchTypeId: 1
      }, true)
    }
    if (dto.losersMatchSettings) {
      this.losersMatchSettings = new MatchSettings(dto.losersMatchSettings)
    }
    if (dto.matches) {
      dto.matches.forEach(m => this.matchUpdate(m))
      // this.matches = dto.matches.map(m => new Match(this.sdk, m, 'bracket'))
      if (this.isDuals) {
        var numbers = uniq(this.matches.filter(f => f.number > 1000).map(m => m.dualN)).sort()
        if (numbers.length) {
          this.duals = numbers.map(n => {
            const parent = this.matches.find(f => f.number === n)
            return new Dual(n, this.matches.filter(f => f.dualN === n).sort(firstBy('dualMatchN')), parent.displayNumber)
          })
        }
      }
    }
    if (dto.placeMatches) {
      this.placeMatches = dto.placeMatches.map(m => new Match(this.sdk, m, 'bracket'))
      if (this.isDuals) {
        var numbers2 = uniq(this.placeMatches.filter(f => f.number < -1000).map(m => m.dualN)).sort()
        if (numbers2.length) {
          this.placeDuals = numbers2.map(n => {
            const parent = this.placeMatches.find(f => f.number === n)
            return new Dual(n, this.placeMatches.filter(f => f.dualN === n).sort(firstBy('dualMatchN', -1)), parent.displayNumber)
          })
        }
      }
    }
    this.popQualifiers()
    this.updates++
  }

  matchUpdate (dto) {
    const match = this.matches.find(m => (dto.id !== 0 && m.id === dto.id) || (m.number === dto.number))
    if (match) {
      match.update(dto)
    } else {
      this.matches.push(new Match(this.sdk, dto, 'bracket'))
    }
  }

  popQualifiers () {
    if (this.champions > 1) {
      const matches = this.matches.filter(f => f.isWinners && !f.isDual)
      var rounds = [...new Set(matches.map(m => m.round))]
      this.champions >= 2 && this.popRoundMatches(matches, rounds.pop())
      this.champions >= 3 && this.popRoundMatches(matches, rounds.pop())
      this.champions >= 6 && this.popRoundMatches(matches, rounds.pop())
      this.champions >= 16 && this.popRoundMatches(matches, rounds.pop())
    }
  }

  popRoundMatches (matches, round) {
    matches.filter(f => f.round === round).forEach(m => { m.isBye = true })
  }

  addLosers () {
    this.losersMatchSettings = new MatchSettings({
      matchTypeId: 1
    }, true)
  }

  findMatch (dto) {
    return dto.id > 0 ? this.matches.find(f => f.id === dto.id) : this.matches.find(m => m.round === dto.round && m.number === dto.number)
  }

  edit () {
    this._dto = JSON.stringify(this.settingsDto)
  }

  restore () {
    this.update(this._dto)
  }

  updateMatches (dto) {
    this.matches = []
    let i = 0
    this.winnersRounds.forEach(round => {
      round.matches.forEach(m => {
        const match = this.mapMatch(m, this.winnersMatchSettings, dto)
        match.roundNumber = i
        this.matches.push(match)
      })
      i++
    })
  }

  findNextMatches (forNumber, winners) {
    return this.matches.filter(f => (f.homeFeeder === forNumber || f.awayFeeder === forNumber) && f.isWinners === winners)
  }

  nextMatches (forNumber, winners) {
    return this.matches.filter(f => (f.homeFeeder === forNumber || f.awayFeeder === forNumber) && f.isWinners === winners).map(m => {
      return {
        match: m,
        winners: (m.homeFeeder === forNumber && m.homeMap.includes('Win')) || (m.awayFeeder === forNumber && m.awayMap.includes('Win'))
      }
    })
  }

  updateMatchMeta (tournament, division, day) {
    this.matches.forEach(m => m.updateMatchMetaB(tournament, division, day, this))
    this.placeMatches.forEach(m => m.updateMatchMetaB(tournament, division, day, this))
  }

  getMatchMeta (tournament, division, day) {
    const a = []
    a.push(...flatten(this.matches.filter(f => !f.isBye).map(m => m.getMatchMetaB(tournament, division, day, this))))
    a.push(...flatten(this.placeMatches.map(m => m.getMatchMetaB(tournament, division, day, this))))
    return a
  }

  get winners () {
    const matches = this.matches.filter(f => f.isWinners && !f.isDual)
    var rounds = [...new Set(matches.map(m => m.round))]
    return rounds.map(r => {
      return {
        number: r,
        matches: matches.filter(f => f.round === r),
        get desc () {
          return [...new Set(this.matches.filter(f => !f.isBye).map(m => m.matchDescription))]
        },
        getDesc (x) {
          return [...new Set(this.matches.filter(f => !f.isBye && f.number !== x).map(m => m.matchDescription))]
        }
      }
    })
  }

  get lastWinnersRoundN () {
    return max(this.matches.filter(f => f.isWinners && !f.isDual).map(m => m.round))
  }

  get losers () {
    const matches = this.placeMatches.length > 0 ? this.placeMatches.filter(f => !f.isDual) : this.matches.filter(f => !f.isWinners && !f.isDual)
    var rounds = [...new Set(matches.map(m => m.round))]
    return rounds.map(r => {
      return {
        number: r,
        matches: matches.filter(f => f.round === r),
        get desc () {
          return [...new Set(this.matches.filter(f => !f.isBye).map(m => m.matchDescription))]
        },
        getDesc (x) {
          return [...new Set(this.matches.filter(f => !f.isBye && f.number !== x).map(m => m.matchDescription))]
        }
      }
    })
  }

  get teams () {
    const a = this.matches.map(m => [m.homeTeam, m.awayTeam].filter(f => f))
    const b = flatten(a)
    return uniqBy(b, 'teamId')
  }

  get teamsPlayed () {
    const a = this.matches.filter(f => f.status && !f.isBye).map(m => [m.homeTeam, m.awayTeam].filter(f => f))
    const b = flatten(a)
    return uniqBy(b, 'teamId')
  }

  get swappableTeams () {
    const nogo = this.teamsPlayed.map(m => m.teamId)
    return this.teams.filter(f => !nogo.includes(f.teamId))
  }

  mapMatch (template, settings, dto) {
    const existing = (dto.matches || []).find(d => d.number === template.number)
    if (existing) {
      Object.assign(existing, { homeFeeder: template.homeFeeder, awayFeeder: template.awayFeeder })
      return new Match(this.sdk, existing, 'bracket')
    } else {
      const home = this.getTeam(template.homeFeeder, template.homeTeamSeed)
      const away = this.getTeam(template.awayFeeder, template.awayTeamSeed)

      // const ref = this.teams.find(f => f.slot === m.ref)
      const games = JSON.parse(JSON.stringify(settings.gameSettings))
      return new Match(this.sdk, {
        bracketId: this.id,
        number: template.number,
        homeTeam: home,
        awayTeam: away,
        // refTeam: ref,
        isMatch: settings.isMatch,
        games: games,
        homeFeeder: template.homeFeeder,
        awayFeeder: template.awayFeeder,
        homeMap: this.seedDisplayMap[template.homeTeamSeed - 1],
        awayMap: this.seedDisplayMap[template.awayTeamSeed - 1]
      }, 'bracket')
    }
  }

  getTeam (feederN, seed) {
    if (feederN) {
      const feeder = this.matches.find(f => f.number === feederN)
      if (feeder && feeder.complete) {
        return feeder.winningTeam
      } else {
        return {
          name: `Match ${feederN} Winner`
        }
      }
    } else {
      return this.teams.find(f => f.seed === seed) || { name: this.seedDisplayMap[seed - 1] }
    }
  }

  save (dayId) {
    return this.sdk.post.brackets(this.settingsDto, dayId)
  }

  // getter/setters
  get pools () {
    return flatten(this.flights.map(f => f.pools))
  }

  // getters
  get dirty () {
    return this._dto && JSON.stringify(this.settingsDto) !== this._dto
  }

  get settingsDto () {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      champions: this.champions,
      winnersMatchSettings: this.winnersMatchSettings ? this.winnersMatchSettings.dto : null,
      losersMatchSettings: this.losersMatchSettings ? this.losersMatchSettings.dto : null,
      props: this.props,
      manualRefs: this.manualRefs,
      prevLoser: this.prevLoser
    }
  }

  get winnersRounds () {
    const rounds = []
    const roundI = uniq(this.matches.map(m => m.round)).sort()
    roundI.forEach(r => {
      rounds.push({
        number: r,
        matches: this.matches.filter(f => f.round === r).sort(firstBy('number'))
      })
    })

    return rounds
  }

  hiddenRounds (isWinners) {
    const rounds = isWinners ? this.winners : this.losers
    const round = rounds.find(r => {
      const real = r.matches.find(f => !f.isBye)
      if (real) return true
    })
    return round ? round.number : 0
  }

  get winnersStatus () {
    const matches = this.matches.filter(f => f.isWinners)
    const rounds = []
    matches.forEach(m => {
      let r = rounds.find(f => f.n === m.round)
      if (!r) {
        r = {
          n: m.round,
          started: false,
          matches: 0,
          played: 0,
          get complete () { return this.matches === this.played }
        }
        rounds.push(r)
      }
      r.matches++
      m.complete && r.played++
      if (!m.isBye && m.status) r.started = true
    })
    rounds.sort(firstBy('n'))
    if (this.champions > 1) {
      this.champions >= 2 && rounds.pop()
      this.champions >= 3 && rounds.pop()
      this.champions >= 8 && rounds.pop()
      this.champions >= 16 && rounds.pop()
    }

    const started = rounds.filter(f => f.started).length
    return {
      type: this.type,
      started: started,
      rounds: rounds,
      complete: started && !rounds.filter(f => !f.complete).length,
      champions: this.champions,
      get current () {
        const a = rounds.find(f => !f.complete)
        if (a) {
          if (a.matches === this.champions) return 'Final Round'
          switch (a.matches) {
            case 1:
              return this.type && this.type.includes('4') ? 'Medal Matches' : 'Finals'
            case 2:
              return this.type && this.type === 'DOUBLE_ELIM_CROSSOVER' ? 'Crossover' : 'Semis'
            case 4:
              return 'Quarters'
            default:
              return `Round ${a.n + 1}`
          }
        }
      }
    }
  }

  get losersStatus () {
    const matches = this.placeMatches.length > 0 ? this.placeMatches : this.matches.filter(f => !f.isWinners)
    if (!matches.length) return null
    const rounds = []
    var adj = this.hiddenRounds(false)
    matches.forEach(m => {
      let r = rounds.find(f => f.n === m.round)
      if (!r) {
        r = {
          n: m.round - adj,
          started: false,
          matches: 0,
          played: 0,
          get complete () { return this.matches === this.played }
        }
        rounds.push(r)
      }
      r.matches++
      m.complete && r.played++
      if (!m.isBye && m.status) r.started = true
    })
    rounds.sort(firstBy('n'))
    const started = rounds.filter(f => f.started).length
    return {
      started: started,
      rounds: rounds,
      complete: started && !rounds.filter(f => !f.complete).length,
      get current () {
        const a = rounds.find(f => !f.complete)
        return `Round ${a.n + 1}`
      }
    }
  }

  get isDouble () {
    return this.type.startsWith('DOUBLE')
  }

  get allMatches () {
    return [...this.matches, ...this.placeMatches]
  }

  get isChallenge () {
    return this.type === 'SINGLE_ELIM_CHALLENGE'
  }

  get isQualifier () {
    return this.champions > 1
  }

  get isComplete () {
    var incomplete = this.matches.find(f => !f.complete) || this.placeMatches.find(f => !f.complete)
    return !incomplete
  }

  get losersName () {
    if (this.placeMatches.length > 0) return 'Consolation Matches'
    return (this.bracketTypeObj && this.bracketTypeObj.placeBracketName) || 'Contenders Bracket'
  }

  get bracketTypeObj () {
    return BracketTypes.find(f => f.value === this.type)
  }

  get hasPlaceBracket () {
    return (this.bracketTypeObj && this.bracketTypeObj.hasPlaceBracket) || this.placeMatches.length > 0
  }

  get isDuals () {
    return this.props.filter(f => f.startsWith('duals-')).length > 0
  }

  get allDuals () {
    return [...this.duals, ...this.placeDuals]
  }

  get dualsOG () {
    var numbers = uniq(this.matches.filter(f => f.number > 1000).map(m => m.dualN)).sort()
    return numbers.map(n => {
      const parent = this.matches.find(f => f.number === n)
      return new Dual(n, this.matches.filter(f => f.dualN === n).sort(firstBy('dualMatchN')), parent.displayNumber)
    })
  }

  getMatchTitle (match) {
    if (match.isWinners) {
      const rounds = uniq(this.matches.map(m => m.round)).sort().reverse()
      if (rounds.length && match.round === rounds[0]) return 'Finals'
      if (rounds.length > 1 && match.round === rounds[1]) return this.type && this.type === 'DOUBLE_ELIM_CROSSOVER' ? 'Crossover' : this.type && this.type.includes('4') ? 'Bronze Match' : 'Semi Finals'
      if (rounds.length > 2 && match.round === rounds[2]) return this.type && this.type.includes('4') ? 'Semi Finals' : 'Quarters'
      if (rounds.length > 2 && this.type && this.type.includes('4') && match.round === rounds[3]) return 'Quarters'
      return `Round ${match.round + 1}`
    }
    var adj = this.hiddenRounds(false)
    return `Round ${match.round + 1 - adj}`
  }

  get qText () {
    const a = this.props.find(f => f.startsWith('Q-'))
    if (!a) return this.isChallenge ? 'C' : this.isQualifier ? 'Q' : null
    return a.replace('Q-', '')
  }
}
