import { store } from '../store'
import * as mutations from '../store/MutationTypes'

import OrderPages from './orders'
import MemberPages from '@/router/Member'
import PlayerPages from '@/router/Player'

// Public Views
// import Home from '@/Pages/Home'
const Home = () => import('@/Pages/Home.vue')
const Rankings = () => import('@/Pages/Rankings')
const RankingsId = () => import('@/Pages/Rankings/_Id')
const Tournaments = () => import('@/Pages/Tournaments')
const Organizations = () => import('@/Pages/Organizations')
const Privacy = () => import('@/Pages/Privacy.vue')
const Terms = () => import('@/Pages/Terms.vue')
const Feedback = () => import('@/Pages/Feedback.vue')
const FeedbackThanks = () => import('@/Pages/FeedbackThanks.vue')
const EmailConfirm = () => import('@/Pages/Confirm.vue')
const Points = () => import('@/Pages/Points.vue')
const AAUPoints = () => import('@/Pages/Points/AAUPoints.vue')
const BVNEPoints = () => import('@/Pages/Points/BVNEPoints.vue')
const p1440Points = () => import('@/Pages/Points/p1440Points.vue')
const PointsTab = () => import('@/Pages/Points/CustomPoints.vue')
const Magic = () => import('@/Pages/Magic.vue')
const TourneyList = () => import('@/components/Tournament/TournamentList.vue')
const MustSignIn = () => import('@/Pages/MustSignIn.vue')
const BracketBuilder = () => import('@/Pages/BracketBuilder.vue')
const Auth = () => import('@/components/Account/Auth.vue')
const ProductDisplay = () => import('@/components/Store/ProductDisplay.vue')
const StoreFront = () => import('@/components/Store/StoreFront.vue')
const Agreement = () => import('@/Pages/Agreement.vue')
const NotFound = () => import('@/Pages/404.vue')
const Heather = () => import('@/Pages/Heather.vue')
const VideoLibrary = () => import('@/Pages/Videos.vue')
const Court = () => import('@/Pages/Court.vue')
const VolleyAge = () => import('@/Pages/VolleyAge.vue')
const HelpLinks = () => import('@/Pages/Links.vue')
const FacebookDdr = () => import('@/Pages/FacebookDdr.vue')
// const Clubs = () => import('@/Pages/Club/Index.vue')
// const ClubHome = () => import('@/Pages/Club/Home.vue')
// const ClubTeams = () => import('@/Pages/Club/Teams.vue')
const RatingsExplained = () => import('@/Pages/RatingsExplained.vue')
const HolidayAds = () => import('@/components/Ads/HolidayAds.vue')

const devRoutes = process.env.NODE_ENV === 'production' ? [] : [
  {
    path: '/heather',
    name: 'heather',
    component: Heather,
    meta: { y: 100 }
  }
]

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { y: 0 }
  },
  {
    path: '/auth',
    name: 'auth',
    component: Auth
  },
  {
    path: '/signin',
    name: 'signin',
    component: MustSignIn
  },
  {
    path: '/notfound',
    name: 'not-found',
    component: NotFound
  },
  {
    path: '/signup',
    name: 'signup',
    alias: '/',
    component: Home
  },
  {
    path: '/rankings',
    name: 'rankings-home',
    component: Rankings,
    props: true,
    beforeEnter: (to, from, next) => {
      const rankings = store.getters.rankings
      if (rankings.length === 1) {
        store.commit(mutations.LOADING_COMPLETE, to.name)
        next(rankings[0].route)
      } else {
        next()
      }
    },
    meta: { y: 1 }
  },
  {
    path: '/rankings/:id',
    name: 'rankings',
    component: RankingsId,
    props: true
  },
  {
    path: '/tournaments',
    component: Tournaments,
    meta: { y: 2 },
    children: [
      {
        path: ':filter',
        name: 'filtered-tournaments',
        component: TourneyList,
        meta: { y: 2 },
        props: true
      },
      {
        path: '',
        name: 'tournaments',
        redirect: '/tournaments/upcoming',
        meta: { y: 2 },
        props: true
      }
    ],
    props: true
  },
  {
    path: '/points',
    name: 'points',
    component: Points,
    meta: { y: 3 },
    children: [
      {
        path: 'aau',
        name: 'points-aau-tab',
        component: AAUPoints,
        props: (route) => { return { isTab: true } },
        meta: { y: 3 }
      },
      {
        path: 'usavp',
        name: 'points-usavp-tab',
        component: AAUPoints,
        props: (route) => { return { isTab: true } },
        meta: { y: 3 }
      },
      {
        path: 'bvne',
        name: 'points-bvne-tab',
        component: BVNEPoints,
        props: (route) => { return { isTab: true } },
        meta: { y: 3 }
      },
      {
        path: 'p1440',
        name: 'points-p1440-tab',
        component: p1440Points,
        props: (route) => { return { isTab: true } },
        meta: { y: 3 }
      },
      {
        path: ':pointSystemId',
        name: 'custom-points-tab',
        component: PointsTab,
        props: true,
        meta: { y: 3 }
      }
    ]
  },
  {
    path: '/aaupoints',
    name: 'aaupoints',
    meta: { y: 3 },
    component: AAUPoints
  },
  {
    path: '/bvnepoints',
    name: 'bvnepoints',
    meta: { y: 3 },
    component: BVNEPoints
  },
  {
    path: '/p1440points',
    name: 'p1440points',
    meta: { y: 3 },
    component: p1440Points
  },
  {
    path: '/pointSystem/:pointSystemId',
    name: 'soloPoints',
    props: true,
    meta: { y: 3 },
    component: PointsTab
  },
  {
    path: '/organizations',
    name: 'organizations',
    meta: { y: 4 },
    component: Organizations
  },
  {
    path: '/privacy',
    name: 'privacy',
    meta: { y: 100 },
    component: Privacy
  },
  {
    path: '/terms',
    name: 'terms',
    meta: { y: 100 },
    component: Terms
  },
  {
    path: '/feedback',
    name: 'feedback',
    meta: { y: 100 },
    component: Feedback
  },
  {
    path: '/feedback/thanks',
    name: 'feedback-thanks',
    meta: { y: 100 },
    component: FeedbackThanks
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: EmailConfirm,
    props: true
  },
  {
    path: '/magic/:linkId',
    name: 'magic',
    component: Magic,
    props: true
  },
  {
    path: '/bracketbuilder',
    name: 'bracketbuilder',
    component: BracketBuilder
  },
  {
    path: '/store',
    name: 'store-front',
    component: StoreFront,
    props: true
  },
  {
    path: '/store/:tag',
    name: 'product-details',
    component: ProductDisplay,
    props: true
  },
  {
    path: '/agreement/:id',
    name: 'agreement-sign',
    component: Agreement,
    props: true
  },
  {
    path: '/videos',
    name: 'video-library',
    component: VideoLibrary,
    props: true
  },
  {
    path: '/links',
    name: 'link-library',
    component: HelpLinks,
    props: true
  },
  {
    path: '/court/:court',
    name: 'court-locator',
    component: Court,
    props: true
  },
  {
    path: '/whats-your-volley-age',
    name: 'volley-age',
    component: VolleyAge,
    props: true
  },
  {
    path: '/ratings/explained',
    name: 'ratings-explained',
    component: RatingsExplained,
    props: true
  },
  {
    path: '/holidays',
    name: 'holiday-ads',
    component: HolidayAds,
    props: true
  },
  {
    path: '/deletion',
    name: 'facebook-ddr',
    component: FacebookDdr,
    props: true
  },
  // {
  //   path: '/clubs',
  //   name: 'clubs',
  //   component: Clubs,
  //   children: [
  //     {
  //       path: '/',
  //       name: 'club-home',
  //       component: ClubHome,
  //       props: true
  //     },
  //     {
  //       path: '/clubs/teams',
  //       name: 'club-teams',
  //       component: ClubTeams,
  //       props: true
  //     }
  //   ],
  //   props: true
  // },
  ...devRoutes,
  ...MemberPages,
  ...OrderPages,
  ...PlayerPages
]
