// import * as mutations from '../store/MutationTypes'

export default {
  computed: {
    release () {
      return false
    },
    admin () {
      return this.user && this.user.isTournamentAdmin(this.tournament)
    },
    bracket () {
      return this.day && this.day.brackets[0]
    },
    day () {
      if (!this.division) return null
      if (this.$route.name === 'league-play' && this.$route.query.round) {
        return this.division.days[this.$route.query.round - 1]
      }
      return this.division.days.find(f => f.id === this.dayId)
    },
    dayId () {
      return +this.$route.params.dayId
    },
    division () {
      if (!this.tournament) return null
      if (this.divisionId) return this.tournament.divisions.find(f => f.id === +this.divisionId)

      if (this.teamId) {
        return this.tournament.divisions.find((d) => {
          return d.teams.find((t) => {
            return t.id === this.teamId
          })
        })
      }
      return null
    },
    divisionId () {
      return +this.$route.params.divisionId
    },
    divisions () {
      return this.tournament.divisions
    },
    divisionTabs () {
      return this.division ? this.createDivisionTabs(this.division) : []
    },
    mode () {
      return this.admin && !this.publicView ? 'admin' : 'public'
    },
    pool () {
      if (this.$route.name === 'league-play' && this.$route.query.round && this.day) {
        return this.day.leaguePool
      }
      return this.poolId && this.pools ? this.pools.find(p => p.id === this.poolId) : null
    },
    poolId () {
      return +this.$route.params.poolId
    },
    pools () {
      return this.day ? [].concat.apply([], this.day.flights.map(flight => flight.pools)) : []
    },
    poolsCreated () {
      return this.division.poolCount > 0
    },
    publicView () {
      return this.$store.getters.publicView
    },
    round () {
      if (!this.division) return null
      return this.division.days.find(f => f.id === this.dayId)
    },
    team () {
      return this.teamId ? this.division.teams.find(f => f.id === +this.teamId) : null
    },
    teamColors () {
      return [
        'red--text text--darken-4',
        'purple--text',
        'indigo--text text--darken-4',
        'cyan--text',
        'light-green--text text--accent-4',
        'yellow--text text--accent-4',
        'deep-orange--text'
      ]
    },
    teamId () {
      return +this.$route.params.teamId
    },
    tournament () {
      return this.$store.getters.tournament
    },
    tournamentId () {
      return +this.$route.params.tournamentId
    },
    user () {
      return this.$store.getters.user
    }
  },
  methods: {
    createDivisionTabs (division) {
      const tabs = []

      if (this.mode === 'admin' && !this.publicView) {
        tabs.push(
          {
            text: 'Settings',
            to: { name: 'division-home', params: { tournamentId: this.tournamentId, divisionId: division.id } },
            key: `settings_${division.id}`
          }
        )
      }
      tabs.push(
        {
          text: 'Teams',
          to: { name: 'division-teams', params: { tournamentId: this.tournamentId, divisionId: division.id } },
          key: `teams_${division.id}`,
          count: division.teamCount
        }
      )
      division.days.forEach(day => {
        if (day.poolPlay) {
          tabs.push({
            text: day.name,
            to: { name: 'pools-home', params: { tournamentId: this.tournamentId, divisionId: division.id, dayId: day.id } },
            key: `pools_${division.id}_${day.id}`,
            count: day.pools.length
          })
        } else {
          tabs.push({
            text: day.name,
            to: { name: 'bracket-home', params: { tournamentId: this.tournamentId, divisionId: division.id, dayId: day.id } },
            key: `bracket_${division.id}_${day.id}`
          })
        }
      })
      return tabs
    }
  }
}
