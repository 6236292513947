<template>
  <v-dialog
    v-if="type === 'time'"
    ref="time"
    persistent
    v-model="timeDialog"
    width="290px"
    :return-value.sync="time"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        :label="label + ' Time'"
        :key="label + ' Time'"
        prepend-icon="far fa-clock"
        readonly
        v-model="timeF"
        :disabled="disabled"
        hide-details
      >
        <v-btn color="warning" slot="append-outer" v-if="warning && warning.time" fab x-small @click.stop="$emit('warning-click', `${label} Time`)">
          <v-icon>fas fa-exclamation</v-icon>
        </v-btn>
      </v-text-field>
    </template>
    <v-time-picker
      actions
      v-model="time"
      color="color3"
      :header-color="headerColor"
      :min="minimum"
    >
      <v-row dense justify="center">
        <v-btn text color="error white--text" class="mr-1" @click="timeDialog = false">Cancel</v-btn>
        <v-btn color="success white--text" @click="$refs.time.save(time); sync()">OK</v-btn>
      </v-row>
    </v-time-picker>
  </v-dialog>
  <v-dialog
    v-else-if="type === 'date'"
    ref="date"
    persistent
    v-model="dateDialog"
    width="290px"
    :return-value.sync="date"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        :label="label + ' Date'"
        :key="label + ' Date'"
        prepend-icon="far fa-calendar-alt"
        readonly
        v-model="dateF"
        hide-details
        :disabled="disabled"
      >
        <v-btn color="warning" slot="append" v-if="warning && warning.date" fab x-small @click.stop="$emit('warning-click', `${label} Date`)">
          <v-icon>fas fa-exclamation</v-icon>
        </v-btn>
      </v-text-field>
    </template>
    <v-date-picker
      scrollable
      actions
      v-model="date"
      color="color3"
      :header-color="headerColor"
      :min="minimum"
      :max="max"
    >
      <v-row dense justify="center">
        <v-btn text color="error white--text" class="mr-1" @click="dateDialog = false">Cancel</v-btn>
        <v-btn color="success white--text" @click="$refs.date.save(date); sync()">OK</v-btn>
      </v-row>
    </v-date-picker>
  </v-dialog>
  <v-row dense v-else>
    <v-col cols="6" sm="6">
      <v-dialog
        ref="date"
        persistent
        v-model="dateDialog"
        width="290px"
        :return-value.sync="date"
        :disabled="disabled"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-on="on"
            :label="label + ' Date'"
            :key="label + ' Date'"
            prepend-icon="far fa-calendar-alt"
            readonly
            v-model="dateF"
            hide-details
            :disabled="disabled"
          >
            <v-btn color="warning" slot="append" v-if="warning && warning.date" fab x-small @click.stop="$emit('warning-click', `${label} Date`)">
              <v-icon>fas fa-exclamation</v-icon>
            </v-btn>
          </v-text-field>
        </template>
        <v-date-picker
          scrollable
          actions
          v-model="date"
          color="color3"
          :header-color="headerColor"
          :min="minimum"
          :max="max"
        >
          <v-row dense justify="center">
            <v-btn text color="error white--text" class="mr-1" @click="dateDialog = false">Cancel</v-btn>
            <v-btn color="success white--text" @click="$refs.date.save(date); sync()">OK</v-btn>
          </v-row>
        </v-date-picker>
      </v-dialog>
    </v-col>
    <v-col cols="6" sm="6">
      <v-dialog
        ref="time"
        persistent
        v-model="timeDialog"
        width="290px"
        :return-value.sync="time"
        :disabled="disabled"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-on="on"
            :label="label + ' Time'"
            :key="label + ' Time'"
            prepend-icon="far fa-clock"
            readonly
            v-model="timeF"
            :disabled="disabled"
            hide-details
          >
            <v-btn color="warning" slot="append" v-if="warning && warning.time" fab x-small @click.stop="$emit('warning-click', `${label} Time`)">
              <v-icon>fas fa-exclamation</v-icon>
            </v-btn>
          </v-text-field>
        </template>
        <v-time-picker
          v-model="time"
          color="color3"
          :header-color="headerColor"
          :min="minimum"
          :max="max"
        >
          <v-row dense justify="center">
            <v-btn text color="error white--text" class="mr-1" @click="timeDialog = false">Cancel</v-btn>
            <v-btn color="success white--text" @click="$refs.time.save(time); sync()">OK</v-btn>
          </v-row>
        </v-time-picker>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import isDark from '@/classes/IsDark'
import { mapGetters } from 'vuex'

export default {
  props: {
    datetime: { type: [String, Object] },
    label: { type: String },
    type: { type: String },
    disabled: {
      type: Boolean,
      default: false
    },
    inputIsTime: {
      type: Boolean,
      default: false
    },
    warning: { type: Object },
    min: { type: [String, Object] },
    past: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dateDialog: false,
      timeDialog: false,
      date: null,
      time: null
    }
  },
  computed: {
    ...mapGetters(['theme']),
    headerColor () {
      return isDark(this.theme.color2) ? 'color2' : isDark(this.theme.color1) ? 'color1' : 'black'
    },
    dateF () {
      return this.date ? moment(this.date).format('MM/DD/YYYY') : ''
    },
    timeF () {
      return this.time ? (this.time.toLowerCase().includes('am') || this.time.toLowerCase().includes('pm'))
        ? moment(this.time, ['h:mmA']).format('h:mm A') : moment(this.time, ['HH:mm']).format('h:mm A') : ''
    },
    _datetime () {
      return `${this.date}T${this.time || '00'}:00`
    },
    minimum () {
      return this.min ? this.min === 'now' ? moment().format() : moment(this.min).format() : null
    },
    max () {
      return this.past ? moment().subtract(1, 'days').format() : null
    }
  },
  methods: {
    initDateTime () {
      if (!this.datetime) {
        this.date = null
        this.time = null
        return
      }
      this.date = moment(this.inputIsTime ? null : this.datetime).format('YYYY-MM-DD')
      this.time = this.inputIsTime ? this.datetime : moment(this.datetime).format('HH:mm')
    },
    sync () {
      this.type === 'date' ? this.$emit('update:datetime', this.date)
        : this.type === 'time' ? this.$emit('update:datetime', this.time)
          : this.$emit('update:datetime', this._datetime)
    }
  },
  watch: {
    datetime: function () {
      if (this.dateDialog || this.timeDialog) return
      this.initDateTime()
    }
  },
  mounted () {
    this.initDateTime()
  }
}
</script>

<style>

</style>
