import flatten from '../helpers/ArrayFlatten'
import uniq from 'lodash.uniq'
import { firstBy } from 'thenby'
import PlayerProfile from './PlayerProfile'

export default class Leaderboard {
  leaders = []

  constructor (dto) {
    this.leaders = dto.map(m => {
      m.players = m.playerProfiles.map(p => {
        return new PlayerProfile(null, p)
      })
      return m
    })
  }

  get divisions () {
    return uniq(flatten(this.leaders.map(m => m.divisions)))
  }

  get genders () {
    return uniq(flatten(this.leaders.map(m => m.genders)))
  }

  get overall () {
    return {
      title: 'Overall',
      leaders: this.leaders.map(m => {
        const x = Object.assign({}, m)
        x.rank = x.rankings.Overall
        x.points = x.points.Overall
        x.records = x.records.filter(f => f.tags.includes('Overall'))
        x.events = x.records.length
        return x
      })
    }
  }

  get divisional () {
    return this.divisions.map(division => {
      return {
        title: division,
        leaders: this.leaders.filter(f => f.divisions.includes(division)).map(m => {
          const x = Object.assign({}, m)
          x.rank = x.rankings[division]
          x.points = x.points[division]
          x.records = x.records.filter(f => f.tags.includes(division))
          x.events = x.records.length
          return x
        }).sort(firstBy('rank'))
      }
    })
  }

  get gender () {
    return this.genders.map(g => {
      return {
        title: g,
        leaders: this.leaders.filter(f => f.genders.includes(g)).map(m => {
          const x = Object.assign({}, m)
          x.rank = x.rankings[g]
          x.points = x.points[g]
          x.records = x.records.filter(f => f.tags.includes(g))
          x.events = x.records.length
          return x
        }).sort(firstBy('rank'))
      }
    })
  }
}
