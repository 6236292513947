import DtoUpdate from './DtoUpdate'

export default class Organization {
  constructor (dto) {
    this.id = 0
    this.name = null
    this.username = null
    this.logoUrl = null
    this.description = null
    this.isActive = false
    this.isPublic = false
    this.email = null
    this.phone = null
    this.websiteUrl = null
    this.contact = null
    this.facebook = null
    this.twitter = null
    this.instagram = null
    this.snapchat = null
    this.organizationMembers = []
    this.photos = []
    this.stripeAccounts = []
    this.notes = null
    this.theme = {}
    this.connectUrl = null
    this.connected = false

    if (dto) {
      this.update(dto)
    }
  }

  // methods
  update (dto) {
    dto = dto || {}
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = ['theme']
    DtoUpdate(dto, this, exclude)

    if (dto.theme) {
      DtoUpdate(dto.theme, this.theme, [])
    }
  }

  // Getters
  get dto () {
    return {
      id: this.id,
      name: this.name,
      username: this.username,
      logoUrl: this.logoUrl,
      description: this.description,
      isActive: this.isActive,
      isPublic: this.isPublic,
      websiteUrl: this.websiteUrl,
      email: this.email,
      phone: this.phone,
      facebook: this.facebook,
      twitter: this.twitter,
      instagram: this.instagram,
      snapchat: this.snapchat,
      notes: this.notes
    }
  }
}
