import loadTournament from '@/router/methods/loadTournament'

const DivisionHome = () => import('@/Pages/Tournament/Division/Home.vue')
const DivisionSettings = () => import('@/Pages/Tournament/Division/Settings/Index.vue')
const TeamList = () => import('@/Pages/Tournament/Division/Teams/Home.vue')

export default [
  {
    path: '/session/:tournamentId/division/:divisionId',
    component: DivisionHome,
    beforeEnter: loadTournament,
    props: true,
    children: [
      {
        path: '',
        name: 'session-division-home',
        component: DivisionSettings,
        props: true,
        meta: { displayName: 'Settings' }
      },
      // {
      //   path: 'schedule',
      //   name: 'session-rounds',
      //   component: LeagueRounds,
      //   props: true,
      //   meta: { displayName: 'Schedule' }
      // },
      {
        path: 'teams',
        name: 'session-division-teams',
        component: TeamList,
        props: true,
        meta: { displayName: 'Teams' }
      },
      {
        path: 'waitlist',
        name: 'session-division-waitlist',
        component: TeamList,
        props: (route) => { return { waitlist: true } },
        meta: { displayName: 'Waitlist' }
      }
    ]
  }
]
