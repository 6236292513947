<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    transition="dialog-transition"
  >
  <v-card>
    <v-card-text class="pa-0">
      <v-btn color="error" absolute text icon style="right: 0px" @click.stop="dialog = false">
        <v-icon>fas fa-times</v-icon>
      </v-btn>
      <v-container class="fill-height">
        <v-row class="text-center"   align="center" justify="center" >
          <v-col cols="12" class="text-h3">
            You've been blocked!
          </v-col>
          <v-col cols="12">
            <v-img src="https://vblimages.blob.core.windows.net/images/Blocking2.gif" contain></v-img>
          </v-col>
          <v-col cols="12">
            <slot></slot>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn color="color3 color3Text--text" @click.stop="signIn">Sign In</v-btn>
    </v-card-actions>
  </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['message'],
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapGetters(['auth'])
  },
  methods: {
    open () {
      this.dialog = true
    },
    signIn () {
      this.auth.login()
      this.dialog = false
    }
  }
}
</script>

<style scoped>

</style>
