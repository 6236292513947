import Finish from './Finish'

const createMatches = (teamCount, champions) => {
  let i = champions * 2
  const roundOfs = []
  while (i < roundOfs) {
    roundOfs.push(i)
    i *= 2
  }

  const rounds = roundOfs.map(roundOf => {
    const matchCount = roundOf / 2
    const matches = Array(matchCount)
    let direction = 'd'
    let i = 0

    for (let seed = 1; seed <= roundOf; seed++) {
      matches[i].push(seed++)
      if (direction === 'd') {
        if (i < this.round.length - 1) {
          i++
        } else {
          direction = 'u'
        }
      } else {
        if (i === 0) {
          direction = 'd'
        } else {
          i--
        }
      }
    }
  })
  return rounds
}

export const BracketTypes = [
  {
    text: 'Single elimination',
    value: 'SINGLE_ELIM',
    getMatches: createMatches,
    hasPlaceBracket: false
  },
  {
    text: 'Single elimination with 3rd and 4th',
    value: 'SINGLE_ELIM_WITH_4TH',
    hasPlaceBracket: false
  },
  {
    text: 'Double elimination with crossover',
    value: 'DOUBLE_ELIM_CROSSOVER',
    placeBracketName: 'Contenders',
    hasPlaceBracket: true
  },
  {
    text: 'Double elimination',
    value: 'DOUBLE_ELIM',
    hasPlaceBracket: true,
    placeBracketName: 'Contenders'
  },
  {
    text: 'Challenge bracket',
    value: 'SINGLE_ELIM_CHALLENGE',
    hasPlaceBracket: false
  },
  {
    text: 'Challenge bracket top down',
    value: 'SINGLE_ROUND_CHALLENGE_TOP_DOWN',
    hasPlaceBracket: false
  },
  {
    text: 'Single elimination w/ 3rd, 4th, 5th & 6th',
    value: 'SINGLE_ELIM_WITH_5TH',
    hasPlaceBracket: true,
    placeBracketName: '5th Place'
  },
  {
    text: 'Single elimination w/ 5th & 6th NO 4th',
    value: 'SINGLE_ELIM_WITH_5TH_NO_4TH',
    hasPlaceBracket: true,
    placeBracketName: '5th Place'
  },
  {
    text: 'Single elimination w/ 5th & 7th',
    value: 'SINGLE_ELIM_5TH_AND_7TH',
    hasPlaceBracket: true,
    placeBracketName: 'Consolation'
  },
  {
    text: 'Single elimination w/ 5th - 8th',
    value: 'SINGLE_ELIM_5TH_THRU_8TH',
    hasPlaceBracket: true,
    placeBracketName: 'Consolation'
  },
  {
    text: 'Double elimination crossover with 3rd and 4th',
    value: 'DOUBLE_ELIM_CROSSOVER_WITH_4TH',
    placeBracketName: 'Contenders',
    hasPlaceBracket: true
  },
  {
    text: 'Double elimination crossover with 3rd and 4th',
    value: 'DOUBLE_ELIM_CROSSOVER_WITH_4TH',
    placeBracketName: 'Contenders',
    hasPlaceBracket: true
  }
]

export const SINGLE_ELIM = {
  displayName: 'Single elimination',
  type: 'SINGLE_ELIM',
  finishes: [
    new Finish(1, 1, 1),
    new Finish(2, 2, 1),
    new Finish(3, 4, 2),
    new Finish(5, 8, 4),
    new Finish(9, 16, 8),
    new Finish(17, 32, 16),
    new Finish(33, 64, 32),
    new Finish(65, 128, 64),
    new Finish(129, 256, 128),
    new Finish(257, 512, 256),
    new Finish(513, 1024, 512)
  ]
}
export const SINGLE_ELIM_WITH_4TH = {
  displayName: 'Single elimination with 3rd and 4th',
  type: 'SINGLE_ELIM_WITH_4TH',
  finishes: [
    new Finish(1, 1, 1),
    new Finish(2, 2, 1),
    new Finish(3, 4, 1),
    new Finish(4, 4, 1),
    new Finish(5, 8, 4),
    new Finish(9, 16, 8),
    new Finish(17, 32, 16),
    new Finish(33, 64, 32),
    new Finish(65, 128, 64),
    new Finish(129, 256, 128),
    new Finish(257, 512, 256),
    new Finish(513, 1024, 512)
  ]
}
export const DOUBLE_ELIM_CROSSOVER = {
  displayName: 'Double elimination with crossover',
  type: 'DOUBLE_ELIM_CROSSOVER',
  finishes: [
    new Finish(1, 1, 1),
    new Finish(2, 2, 1),
    new Finish(3, 4, 2),
    new Finish(5, 6, 2),
    new Finish(7, 8, 2),
    new Finish(9, 12, 4),
    new Finish(13, 16, 4),
    new Finish(17, 24, 8),
    new Finish(25, 32, 8),
    new Finish(33, 48, 16),
    new Finish(49, 64, 16),
    new Finish(65, 96, 32),
    new Finish(97, 128, 32),
    new Finish(129, 192, 64),
    new Finish(193, 256, 64),
    new Finish(257, 384, 128)
  ]
}
export const DOUBLE_ELIM = {
  displayName: 'Double elimination',
  type: 'DOUBLE_ELIM',
  finishes: [
    new Finish(1, 1, 1),
    new Finish(2, 2, 1),
    new Finish(3, 3, 1),
    new Finish(4, 4, 1),
    new Finish(5, 6, 2),
    new Finish(7, 8, 2),
    new Finish(9, 12, 4),
    new Finish(13, 16, 4),
    new Finish(17, 24, 8),
    new Finish(25, 32, 8),
    new Finish(33, 48, 16),
    new Finish(49, 64, 16),
    new Finish(65, 96, 32),
    new Finish(97, 128, 32),
    new Finish(129, 192, 64),
    new Finish(193, 256, 64),
    new Finish(257, 384, 128)
  ]
}

// qualifier style
// single to roundOf = # of qualifiers
// single value = value + # of auto entry to main
