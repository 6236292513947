import DtoUpdate from './DtoUpdate'
import { minuteDiff } from '@/classes/HelperFunctions'
import moment from 'moment'
import flatten from '../helpers/ArrayFlatten'

export default class LeagueSettings {
  weeks = null
  courts = null
  slots = []
  dows = []
  _courtNames = []
  courtsUsed = []
  kob = false

  constructor (dto, courtsUsed) {
    if (dto) {
      this.update(dto)
    }
    this.courtsUsed = courtsUsed || []
  }

  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = []
    DtoUpdate(dto, this, exclude)
  }

  get dto () {
    return {
      weeks: this.weeks,
      courts: this.courts,
      slots: this.slots,
      dows: this.dows,
      _courtNames: this._courtNames
    }
  }

  get courtNames () {
    var a = []
    for (let i = 0; i < this.courts; i++) {
      a.push(this._courtNames.length > i ? this._courtNames[i] : `${i + 1}`)
    }
    const outliers = this.courtsUsed.filter(f => !a.includes(f))
    return [...a, ...outliers]
  }

  get slotObjects () {
    return this.slots.map((m, i) => {
      return {
        start: m,
        end: i < this.slots.length - 1 ? this.slots[i + 1] : moment(this.slots[i], 'h:mm A').add(minuteDiff(this.slots[i], this.slots[i - 1]), 'm').format('h:mm A')
      }
    })
  }

  get courtSlots () {
    return flatten(this.courtNames.map(c => {
      return this.slotObjects.map(s => {
        return {
          court: c,
          start: s.start,
          end: s.end
        }
      })
    }))
  }

  getAllSlots (days) {
    const d = [...new Set(days.map(m => m.date ? m.date.format('YYYY-MM-DD') : null))]
    return flatten(d.map(d => {
      return this.courtSlots.map(c => {
        return {
          court: c.court,
          day: d,
          start: c.start,
          end: c.end,
          dt: moment(`${d} ${c.start}`, 'YYYY-MM-DD h:mm A')
        }
      })
    }))
  }
}
