export default [
  {
    name: 'DUAL_RECORD',
    text: 'Dual record',
    short: 'DR',
    default: true
  },
  {
    name: 'MATCH_WIN_RATIO',
    text: 'Match win ratio',
    short: 'MWR',
    default: true
  },
  {
    name: 'HEAD_2_HEAD',
    text: 'Head to head',
    short: 'H2H',
    default: true
  },
  {
    name: 'SET_WIN_RATIO',
    text: 'Set win ratio',
    short: 'SWR',
    default: true
  },
  {
    name: 'POINTS_BETWEEN_3',
    text: 'Points among tied teams (three or more only)',
    short: 'PATT 3+',
    default: false
  },
  {
    name: 'POINTS_BETWEEN',
    text: 'Points among tied teams',
    short: 'PATT',
    default: true
  },
  {
    name: 'MATCHES_BETWEEN',
    text: 'Matches among tied teams',
    short: 'MATT',
    default: false
  },
  {
    name: 'OVERALL_POINTS',
    text: 'Overall points',
    short: 'OP',
    default: true
  },
  {
    name: 'ORIGINAL_SEED',
    text: 'Original seed',
    short: 'OS',
    default: true
  },
  {
    name: 'POINTS_FOR',
    text: 'Total Points For',
    short: 'PF',
    default: false
  }
]
