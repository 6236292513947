<template>
  <v-card>
    <v-card-text>
      <v-row dense v-if="false">
        <v-col cols="12" class="text-left title mt-n3">
          Credit or Debit Card
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <nmi-text-field divId="ccnumber" ref="ccnumber" label="Card Number" icon="fas fa-credit-card">
          </nmi-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="5">
          <nmi-text-field divId="ccexp" ref="ccexp" label="Card Expiration" icon="far fa-calendar-alt">
          </nmi-text-field>
        </v-col>
        <v-col cols="3">
          <nmi-text-field divId="cvv" ref="cvv" label="CVV">
          </nmi-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="Postal Code"
            placeholder="Zip"
            color="color3"
            solo
            single-line
            hide-details
            v-model="zip"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="text-center">
          <v-checkbox label="Save for future use" v-model="saveCard" hide-details color="success"></v-checkbox>
        </v-col>
      </v-row>
      <v-row dense v-if="false">
        <v-col cols="12" class="text-center">
          <v-btn
            @click.stop="getToken"
            color="success"
            :loading="loading"
          >Pay Now</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import NmiTextField from './NmiTextField'
export default {
  props: [],
  data () {
    return {
      loading: false,
      token: null,
      validFields: [],
      zip: null,
      saveCard: false
    }
  },
  computed: {
    valid () {
      const base = new Set(this.validFields)
      return base.has('ccnumber') && base.has('ccexp') && base.has('cvv') && this.zip && this.zip.length >= 5
    }
  },
  methods: {
    getToken () {
      this.loading = true
      window.CollectJS.startPaymentRequest()
    },
    finishSubmit (token) {
      this.token = token
      this.$emit('token-created', token)
      this.loading = false
    },
    onFocus (e) {
      const parent = e.srcElement.parentElement
      const field = this.$refs[parent.id]
      e.type === 'focus' ? field.focus() : field.blur()
    },
    focusBlur () {
      var frames = document.querySelectorAll('iframe.CollectJSInlineIframe')
      for (var i = 0; i < frames.length; i++) {
        frames[i].addEventListener('focus', this.onFocus)
        frames[i].addEventListener('blur', this.onFocus)
      }
    },
    onValidate (fieldName, valid, message) {
      const field = this.$refs[fieldName]
      if (!field) return
      if (valid) {
        this.validFields.push(fieldName)
        field.setError()
      } else {
        this.validFields = this.validFields.filter(f => f !== fieldName)
        if (message === 'Field is empty') {
          // field.resetLabel()
        } else {
          field.setError(message)
        }
      }
    },
    config () {
      this.loading = true
      window.CollectJS.configure({
        variant: 'inline',
        styleSniffer: true,
        callback: (token) => {
          this.finishSubmit(token)
        },
        fieldsAvailableCallback: this.focusBlur,
        validationCallback: this.onValidate,
        fields: {
          ccnumber: {
            selector: '#ccnumber',
            placeholder: 'Card Number'
          },
          ccexp: {
            selector: '#ccexp',
            placeholder: 'MM/YY'
          },
          cvv: {
            selector: '#cvv',
            placeholder: 'CVV'
          }
        }
      })
      this.loading = false
    },
    addScript () {
      this.loading = true
      const script = document.createElement('script')
      script.src = 'https://secure.networkmerchants.com/token/Collect.js'
      // dev key
      // script.setAttribute('data-tokenization-key', '8Q6c86-hnmhKc-mG4sAy-95QfcP')

      script.setAttribute('data-tokenization-key', 'f33hVA-Rhps76-mU5Xr7-H484HK')
      script.setAttribute('data-google-font', 'Roboto:300,400,500,700')
      script.onload = this.config
      document.head.appendChild(script)
    }
  },
  watch: {
    valid: function (v) {
      this.$emit('valid-change', v)
    },
    loading: function (v) {
      this.$emit('loading', !!v)
    },
    zip: function (v) {
      this.$emit('zip-change', v)
    }
  },
  beforeMount () {
    if (window.CollectJS) {
      this.config()
    } else {
      this.addScript()
    }
  },
  components: {
    NmiTextField
  }
}
</script>

<style scoped>
>>> .v-input__icon {
  margin-right: 6px !important;
}
</style>
