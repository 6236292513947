import endpoints from './endpoints'

export default class OrganizationSDK {
  constructor (http) {
    this.http = http
  }

  search (term) {
    return this.http.get(`/organizations?search=${term}`)
  }

  get (username) {
    return this.http.get(endpoints.organization.base(username))
  }

  post (dto) {
    const url = endpoints.organization.base(dto.username)
    return this.http.post(url, dto)
  }

  permit (username) {
    return this.http.get(endpoints.organization.permit(username))
  }

  getConnectURL (id) {
    return this.http.get(endpoints.organization.getConnectURLById(id))
  }

  getUsernameAvailability (username) {
    return this.http.get(endpoints.organization.getUsernameAvailability(username))
  }

  registrationFields (ids) {
    return this.http.post('/Data/RegistrationFields', ids)
  }

  stripe (id) {
    return this.http.get(endpoints.organization.stripe(id))
  }

  todaysEvents (username) {
    return this.http.get(`/organization/today?username=${username}`)
  }

  vblAdmin = {
    get: (username) => {
      return this.http.get(endpoints.organization.vblAdmin(username))
    },
    post: (username) => {
      return this.http.post(endpoints.organization.vblAdmin(username))
    }
  }

  templates = {
    get: (username, type) => {
      const t = type ? `?type=${type}` : ''
      return this.http.get(`${endpoints.organization.templates(username)}${t}`)
    },
    post: (username, dto) => {
      return this.http.post(endpoints.organization.templates(username), dto)
    }
  }

  members = {
    invite: {
      check: (dto) => {
        return this.http.post(`${endpoints.membership.invite}/check`, dto)
      },
      import: (dto) => {
        return this.http.post(`${endpoints.membership.invite}/import`, dto)
      },
      post: (dto) => {
        return this.http.post(endpoints.membership.invite, dto)
      },
      getAll: (username) => {
        return this.http.get(endpoints.membership.invites(username))
      },
      delete: (dto) => {
        if (typeof dto === 'string') dto = [dto]
        return this.http.delete(endpoints.membership.invite, { data: { ids: dto } })
      },
      send: (dto) => {
        return this.http.post(`${endpoints.membership.invite}/send`, dto)
      }
    },
    code: {
      send: (username, dto) => {
        return this.http.post(`${endpoints.membership.access(username)}/send`, dto)
      },
      verify: (username, dto) => {
        return this.http.post(`${endpoints.membership.access(username)}/verify`, dto)
      }
    },
    post: (username, profile, image) => {
      const dto = image || new FormData()
      dto.append('profile', JSON.stringify(profile))
      return this.http.post(endpoints.membership.form(username), dto)
    },
    up: (username, dto) => {
      return this.http.post(`${endpoints.membership.base(username)}/up`, dto)
    },
    getHistory: (memberId, username) => {
      return this.http.get(`/Membership/${username}/${memberId}/history`)
    },
    pathcLevel: (username, dto) => {
      return this.http.post(`/Membership/${username}/memberLevel`, dto)
    }
  }

  pointSystems = {
    getOptions: (username) => {
      return this.http.get(`/organization/${username}/pointsystems/options`)
    }
  }

  affiliates = {
    getAll: (username) => {
      return this.http.get(`/organization/${username}/affiliates`)
    },
    post: (username, form) => {
      return this.http.post(`/organization/${username}/affiliates`, form)
    },
    check: (username, of) => {
      return this.http.get(`/organization/${username}/IsAffiliate?of=${of}`)
    }
  }

  notifications = {
    get: (username) => {
      return this.http.get(`/notification/organization/${username}`)
    },
    post: (dto) => {
      return this.http.post('/notification/organization', dto)
    }
  }

  fees = {
    get: (username, windowId) => {
      var q = windowId ? `?windowId=${windowId}` : ''
      return this.http.get(`/organization/${username}/fees${q}`)
    }
  }

  registrations = {
    search: (username, term) => {
      return this.http.post('/organization/RegistrationSearch', { username: username, term: term })
    }
  }

  calanders = {
    get: (username) => {
      return this.http.get(`/organization/Calendars?o=${username}`)
    }
  }
}
