import DivisionRoutes from './division-routes'

// const TournamentList = () => import('@/Pages/Public/Tournaments')
const TournamentHome = () => import('@/Pages/Tournament/Home.vue')
const TeamHome = () => import('@/Pages/Tournament/Team/Home.vue')
const Live = () => import('@/Pages/Tournament/Live/Match.vue')
const TeamList = () => import('@/Pages/Tournament/Teams.vue')
const CourtList = () => import('@/Pages/Tournament/Courts/CourtManager.vue')
const AdvancedCourtManager = () => import('@/Pages/Tournament/Courts/AdvancedCourtManager.vue')
const PlayerList = () => import('@/Pages/Tournament/Players.vue')
const StreamList = () => import('@/Pages/Tournament/LiveStream.vue')
const CourtTimeline = () => import('@/Pages/Tournament/Court.vue')
const AllPools = () => import('@/Pages/Tournament/AllPools.vue')
const LiveMatches = () => import('@/Pages/Tournament/LiveMatches.vue')
const Checklist = () => import('@/Pages/Tournament/Checklist.vue')
const ScoreBoard = () => import('@/Pages/ScoreBoard.vue')
// function fudsCourtRedirect (to, from, next) {
//   const ids = ['5902', '4458', '4457', '4456', '4455']
//   if (to.params.tournamentId && ids.includes(to.params.tournamentId)) {
//     const url = `/court/${to.params.court}`
//     next(url)
//   } else {
//     next()
//   }
// }

export default [
  {
    path: '/WMS',
    redirect: '/tournament/227'
  },
  {
    path: '/tournament/:tournamentId',
    name: 'tournament-brochure',
    component: TournamentHome,
    props: true
  },
  {
    path: '/tournament/:tournamentId/admin',
    name: 'tournament-admin',
    component: TournamentHome,
    props: true
  },
  {
    path: '/tournament/:tournamentId/register',
    name: 'tournament-register',
    component: TournamentHome,
    props: (route) => { return { tournamentId: route.params.tournamentId, tab: 'register' } }
  },
  {
    path: '/tournament/:tournamentId/location',
    name: 'tournament-location',
    component: TournamentHome,
    props: (route) => { return { tournamentId: route.params.tournamentId, tab: 'location' } }
  },
  {
    path: '/tournament/:tournamentId/teams',
    name: 'tournament-teams',
    component: TeamList,
    props: (route) => { return { tournamentId: route.params.tournamentId, tab: 'teams' } }
  },
  {
    path: '/tournament/:tournamentId/players',
    name: 'tournament-players',
    component: PlayerList,
    props: (route) => { return { tournamentId: route.params.tournamentId, tab: 'players' } }
  },
  {
    path: '/tournament/:tournamentId/courts-advanced',
    name: 'tournament-courts-advanced',
    component: AdvancedCourtManager,
    props: (route) => { return { tournamentId: route.params.tournamentId } }
  },
  {
    path: '/tournament/:tournamentId/courts',
    name: 'tournament-courts',
    component: CourtList,
    props: (route) => { return { tournamentId: route.params.tournamentId } }
  },
  {
    path: '/tournament/:tournamentId/manage',
    name: 'tournament-manager',
    component: TournamentHome,
    props: (route) => { return { tournamentId: route.params.tournamentId, tab: 'manage' } }
  },
  {
    path: '/tournament/:tournamentId/team/:teamId',
    name: 'division-team',
    component: TeamHome,
    props: true
  },
  {
    path: '/tournament/:tournamentId/live/:matchId',
    name: 'tournament-live',
    component: Live,
    props: true
  },
  {
    path: '/tournament/:tournamentId/livestreams',
    name: 'tournament-streams',
    component: StreamList,
    props: true
  },
  {
    path: '/tournament/:tournamentId/court/:court',
    name: 'court-timeline',
    component: CourtTimeline,
    props: true
  },
  {
    path: '/tournament/:tournamentId/poolMonitor',
    name: 'tournament-pool-monitor',
    component: AllPools,
    props: true
  },
  {
    path: '/tournament/:tournamentId/live',
    name: 'tournament-live-matches',
    component: LiveMatches,
    props: true
  },
  {
    path: '/tournament/:tournamentId/checklist/:checklistId',
    name: 'tournament-checklist',
    component: Checklist,
    props: true
  },
  {
    path: '/tournament/:tournamentId/scoreboard',
    name: 'scoreboard',
    component: ScoreBoard,
    props: true
  },
  ...DivisionRoutes
]
