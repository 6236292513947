import endpoints from './endpoints'

export default class ThemeSDK {
  constructor (http) {
    this.http = http
  }

  get (username) {
    let url = endpoints.theme.base + '?v=2'
    if (username) url += `&username=${username}`
    return this.http.get(url)
  }

  post (theme) {
    return this.http.post(endpoints.theme.base, theme)
  }

  getCarousel (username) {
    let url = endpoints.theme.base
    if (username) url += `/carousel?username=${username}`
    return this.http.get(url)
  }

  postCarousel (username, dto) {
    let url = endpoints.theme.base
    if (username) url += `/carousel/${username}`
    return this.http.post(url, dto)
  }
}
