import { firstBy } from 'thenby'
import DtoUpdate from './DtoUpdate'
import { fixedX } from '@/Filters'

export default class TeamPlayer {
  id = 0
  jersey= null
  points = []
  ratings = []
  playerPoints = []

  constructor (dto, teamTags) {
    if (dto) {
      this.update(dto)
    }
    const t = teamTags
    if (t && t.length > 0) {
      const a = `${this.ppId}-jersey-`
      const tag = t.find(f => f.startsWith(a))
      if (tag) {
        this.jersey = tag.replace(a, '')
      }
    }
  }

  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = [] // ['points']
    DtoUpdate(dto, this, exclude)
    if (this.playerPoints.length) {
      this.playerPoints.sort(firstBy(function (pp) { return pp.system.toLowerCase() })).reverse()
    }
  }

  getSeedPoints (id) {
    const p = this.playerPoints.find(f => f.systemId === id)
    return p ? p.total : 'NA'
  }

  getSeedPointsObj (id) {
    const p = this.playerPoints.find(f => f.systemId === id)
    return p
  }

  isBookmarked (user) {
    return user && user.bookmarks && !!user.bookmarks.find(f => f.id === this.ppId)
  }

  get aauPoints () {
    return this.getSeedPoints(3)
  }

  get p1440Points () {
    return this.getSeedPoints(4)
  }

  get avpaPoints () {
    return this.getSeedPoints(-9)
  }

  get usavPoints () {
    return this.getSeedPoints(-9)
  }

  get movr () {
    const a = this.getSeedPoints(-33)
    return fixedX(a, 2)
  }

  get movrG () {
    const a = this.getSeedPoints(-34)
    return fixedX(a, 2)
  }

  get movrObj () {
    return this.getSeedPointsObj(-33)
  }

  get movrGObj () {
    return this.getSeedPointsObj(-34)
  }

  get fullname () {
    return `${this.firstName} ${this.lastName}`
  }

  get fullnameCommit () {
    return `${this.firstName} ${this.lastName}${this.commitForName}`
  }

  get commitForName () {
    return this.commitAbbr ? ` (${this.commitAbbr})` : ''
  }

  get ppId () {
    return this.playerProfileId || this.id
  }

  getMemberId (program) {
    if (!this.memberIds) return 'NA'
    var id = this.memberIds.find(f => f.toLowerCase().startsWith(program))
    if (id) {
      id = id.split(' ')[1]
    }

    return id
  }

  updateRatings (r) {
    const i = this.ratings.findIndex(f => f.systemId === r.systemId)
    if (i === -1) {
      this.ratings.push(r)
    } else {
      this.ratings.splice(i, 1, r)
    }
  }

  getRating (id) {
    const p = this.ratings.find(f => f.systemId === id)
    return p ? p.rating : { currentRating: '-', currentValue: 0 }
  }
}
