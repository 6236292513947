var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c('loading-bar',{attrs:{"value":_vm.loading}}),(_vm.count > 3)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":"fas fa-search","label":"Search","hide-details":"","color":"color3"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1):_vm._e(),_c('v-window',{attrs:{"touchless":""},model:{value:(_vm.window),callback:function ($$v) {_vm.window=$$v},expression:"window"}},[_c('v-window-item',{attrs:{"value":0}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"title"},[_vm._v("Contributing Organizations")]),_c('p',{staticClass:"subheading mt-2"},[_vm._v(" These are the organizations that have the ability to add tournaments to this series. To view and/or edit the tournaments that are included in the series, click the organization. ")])])],1),_c('v-data-iterator',{attrs:{"items":_vm.organizations,"footer-props":{itemsPerPageOptions: _vm.rowsPerPageItems},"options":_vm.pagination,"search":_vm.search,"hide-default-footer":_vm.count < 12},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('v-row',_vm._l((items),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"6","sm":"6","md":"4"}},[_c('organization-card',{attrs:{"organization":item,"selector":true},on:{"selected":_vm.onSelected}})],1)}),1)]}}])},[_c('template',{slot:"no-data"},[_c('v-card',{staticClass:"my-12",attrs:{"flat":""}},[_c('v-card-text',[_c('h1',[_vm._v("There are no organizations participating yet.")]),_c('v-btn',{attrs:{"color":"color3 color3Text--text"},on:{"click":function($event){$event.stopPropagation();_vm.dialog=true}}},[_vm._v("Add an Organizaiton")])],1)],1)],1)],2)],1),_c('v-window-item',{attrs:{"value":1}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.selectedProfile && _vm.selectedProfile.name))]),_c('p',{staticClass:"subheading mt-2"},[_vm._v(" Select edit the tournaments that will contibute to the point system, then click save (on the bottom right of the screen). ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"color":"color3","append-icon":"fas fa-search","label":"Search","hide-details":"","solo-inverted":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rows,"options":_vm.listPage,"footer-props":{itemsPerPageOptions: [25, 50, -1]},"loading":_vm.loading,"loading-text":("Loading " + (_vm.selectedProfile && _vm.selectedProfile.name) + "'s tournaments..."),"search":_vm.search,"show-select":""},on:{"update:options":function($event){_vm.listPage=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"color3","indeterminate":""}})]},proxy:true},{key:"item.sanctionedBy",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"95px"}},[_c('v-carousel',{staticClass:"elevation-0",attrs:{"show-arrows":false,"hide-delimiters":"","height":"40","max":"95","cycle":"","continuous":"","interval":"3000"}},_vm._l((item.logos),function(logo,i){return _c('v-carousel-item',{key:((logo.url) + "_" + i),class:logo.class,attrs:{"height":"40px","src":logo.url,"contain":""}})}),1)],1)]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.dateDisplay))]),_c('div',[_vm._v(_vm._s(item.year))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"font-weight-thin"},[_vm._v(_vm._s(_vm._f("formatArray")(item.divisions)))])]}},{key:"item.public",fn:function(ref){
var item = ref.item;
return [(!item.public)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("fal fa-low-vision")])]}}],null,true)},[_c('span',[_vm._v("Only you can see this")])]):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('v-fab-transition',[(_vm.window === 0 && _vm.series.id)?_c('organization-add-dialog',{attrs:{"exclude":_vm.exclude,"seriesId":_vm.series.id,"btnShift":true},on:{"added":_vm.onOrgAdded}}):_vm._e()],1),_c('v-fab-transition',[(_vm.window > 0)?_c('v-btn',{staticStyle:{"margin-right":"60px"},attrs:{"id":_vm.activeFab.id,"color":_vm.activeFab.color,"fab":"","bottom":"","right":"","fixed":"","disabled":_vm.loading,"loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.activeFab.click($event)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.activeFab.icon))])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }