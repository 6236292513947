export default [
  {
    style: 'PRE',
    display: 'Capture Seed og',
    description: [
      'Ideal for well seeded tournaments, in this seeding style the resulting seeds are predetermined by the pools seed/order and an algrithm that minimizes the chances of two teams from the same pool meeting in the first round.',
      'Because the seeds are predetermined, the teams are placed in to the bracket as soon as their pool is finished. As a result, you can often start some of the playoff matches without waiting for that 1 pool that is always behind 😉.'
    ]
  },
  {
    style: 'CAPTURE',
    display: 'Capture Seed I',
    description: [
      'Ideal for well seeded tournaments, in this seeding style the resulting seeds are predetermined by the pools seed/order and an algrithm that minimizes the chances of two teams from the same pool meeting in the first round.',
      'Because the seeds are predetermined, the teams are placed in to the bracket as soon as their pool is finished. As a result, you can often start some of the playoff matches without waiting for that 1 pool that is always behind 😉.'
    ]
  },
  {
    style: 'CAPTURE_NEW',
    display: 'Capture Seed',
    description: [
      'Ideal for well seeded tournaments, in this seeding style the resulting seeds are predetermined by the pools seed/order and an algrithm that minimizes the chances of two teams from the same pool meeting in the first round.',
      'Because the seeds are predetermined, the teams are placed in to the bracket as soon as their pool is finished. As a result, you can often start some of the playoff matches without waiting for that 1 pool that is always behind 😉.'
    ]
  },
  {
    style: 'GROUP',
    display: 'Performance',
    description: ['In this seeding style, we will seed the teams based on performance, grouped by their finish.', 'For example, in a 4 pool tournament, playoff seeds 1-4 would be the teams that finished 1st in pool, ordered by performance using the same tie break system used for pools. Seeds 5-8 would be the 2nd place pool finishers and so on.']
  },
  {
    style: 'RESEED',
    display: 'Pure Performance',
    description: ['In this seeding style, we will completely re-seed all teams based on pure performance.', 'As a word of caution, this means that it is possible for a team to finish 2nd in their pool and end up seeded higher than a team placing first in another pool… and we all know how fun it would be to explain that to the lower seeded team 😉']
  },
  {
    style: 'ORIGINAL_SEED',
    display: 'Use original seed',
    description: ['This seeding style is pretty self-explanatory. We will use the original seeds assigned to the team and pool play results will not affect the seeding.']
  },
  {
    style: 'MAPPED',
    display: 'Custom Mapping',
    description: ['This seeding style is for advanced use only. The tournament director will decide what pool finishes equal what seeds']
  },
  {
    style: 'OLD_SCHOOL',
    display: 'Old School 1',
    description: ['This seeding style is just like the old days (before we had computers to calculate stuff) It will only look at win/loss record and point differntial. It will treat pools of different sizes the same (so teams in a 5 team pool will have an advantage because 1 loss will give a 75% win percentage, where 1 loss in a 4 team pool will be a 66.7%) Likewise, it will treate all matches the same with point differential, regardless of the point played.']
  },
  {
    style: 'OLD_SCHOOL2',
    display: 'Old School',
    description: ['This seeding style is just like the old days (before we had computers to calculate stuff) It will only look at win/loss record and point differntial. It will treat pools of different sizes the same (so teams in a 5 team pool will have an advantage because 1 loss will give a 75% win percentage, where 1 loss in a 4 team pool will be a 66.7%) Likewise, it will treate all matches the same with point differential, regardless of the point played.']
  }
]
