import endpoints from './endpoints'

export default class CartSDK {
  constructor (http) {
    this.http = http
  }

  getStatus (id) {
    return this.http.get(endpoints.cart.cartStatus(id))
  }

  getStatus2 (bag) {
    return this.http.post(endpoints.cart.cartStatus2, bag)
  }

  process (bag) {
    return this.http.post(endpoints.cart.process, bag)
  }

  getRegistrationPaymentStatus (teamId) {
    // return this.http.get(`/cart/RegistrationStatus/${teamId}`)
    return this.http.get(`/cart/RegistrationStatuses/${teamId}`)
  }

  refund (dto) {
    return this.http.post(endpoints.cart.refund, dto)
  }

  getBag (dto) {
    return this.http.post(endpoints.cart.getBag, dto)
  }

  getBagByItemId (id) {
    return this.http.post(endpoints.cart.getBagByItemId(id))
  }

  getBagByItem (id, dto) {
    return this.http.post(`/cart/BagItem/${id}`, dto)
  }

  checkItemsStatus (items, oId, ap) {
    const urlParam = oId ? `?organizationId=${oId}&ap=${!!ap}` : ''
    return this.http.post(endpoints.cart.checkItemStatus + urlParam, items)
  }

  applyPromo (dto) {
    return this.http.post(endpoints.cart.promo, dto)
  }

  validateBag (dto) {
    return this.http.post(endpoints.cart.validateBag, dto)
  }

  postExternalPayment (dto) {
    return this.http.post(`/cart/BagItem/${dto.shoppingBagItemId}/ExternalPayment`, dto)
  }

  makePayable (dto) {
    return this.http.post('/cart/BagItem/MakePayable', dto)
  }

  getAuthDotNetToken (bag) {
    // return this.http.post(endpoints.cart.process, bag)
    return this.http.get('AuthDotNet')
  }

  refundPolicy = {
    getAll: (username, skinny) => {
      return this.http.get(`Organization/${username}/refundPolicies?skinny=${!!skinny}`)
    },
    get: (id) => {
      return this.http.get(`Cart/RefundPolicy/${id}`)
    },
    post: (username, policy) => {
      return this.http.post(`Organization/${username}/refundPolicies`, policy)
    }
  }

  promos = {
    getAll: (username) => {
      return this.http.get(`/organization/${username}/promos`)
    },
    post: (dto) => {
      return this.http.post(`/organization/${dto.organizationId}/promos`, dto)
    },
    disable: (username, ids) => {
      return this.http.post(`/organization/${username}/promos/disable`, { ids: ids })
    },
    delete: (username, ids) => {
      return this.http.post(`/organization/${username}/promos/delete`, { ids: ids })
    },
    getUsage: (username, id) => {
      return this.http.get(`/organization/${username}/promo/${id}/usage`)
    }
  }
}
