<template>
  <div v-if="series">
    <series-manager
      v-if="manage"
      :series="series"
      @changed="onChange"
      @tab-change="onTabChanged"
      :view="view"
    ></series-manager>
    <series-brochure
      v-else
      :series="series"
      :view="view"
      :startTab="series && series.iLeaderboard.leaders.length ? 'leaderboard' : null"
    ></series-brochure>
    <v-fab-transition>
      <v-tooltip top v-if="view.admin">
        <span v-if="manage" class="text-center">Series Brochure</span>
        <span v-else class="text-center">Series Admin</span>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            color="color3 color3Text--text"
            fab
            bottom
            right
            fixed
            @click.stop="toggleManage"
            :disabled="isNew"
          >
            <v-icon v-if="manage">fas fa-file-image</v-icon>
            <v-icon v-else>fas fa-user-shield</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </v-fab-transition>
    <div class="text-right pr-3">
      <v-btn color="color3" text icon class="xsmall" @click="load">
        <v-icon>fas fa-redo-alt</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import SeriesBrochure from '@/components/Events/Series/SeriesBrochure.vue'
import SeriesManager from '@/components/Events/Series/SeriesManager.vue'
import Series from '@/classes/Series'
import { mapGetters } from 'vuex'
import * as mutations from '@/store/MutationTypes'
export default {
  props: ['seriesTag'],
  data () {
    return {
      series: null,
      activeTab: 'edit'
    }
  },
  computed: {
    ...mapGetters(['user', 'publicView', 'getPageInfo']),
    view () {
      return {
        admin: this.series && this.user && (this.series.adminId === this.user.id || this.series.id === 0) && !this.publicView,
        public: !(this.series && this.user && (this.series.adminId === this.user.id || this.series.id === 0) && !this.publicView)
      }
    },
    manage () {
      return this.$route.name.startsWith('series-admin')
    },
    isNew () {
      return !!this.$route.query.new
    },
    username () {
      return this.$route.query.username
    },
    page () {
      return this.getPageInfo(this.username)
    }
  },
  methods: {
    onTabChanged (val) {
      this.activeTab = val
    },
    load () {
      if (this.isNew && this.user && this.user.pages.length) {
        this.createNew()
        return
      }
      this.$store.commit(mutations.LOADING, this.seriesTag)
      this.$VBL.events.series.get(this.seriesTag)
        .then(r => {
          if (!r.data) this.$router.push(`/signin?returnUrl=${this.$route.fullPath}`)
          this.series = new Series(r.data)
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.$store.commit(mutations.LOADING_COMPLETE, this.seriesTag)
        })
    },
    onChange (dto) {
      // this.series = new Series(dto)
      this.load()
    },
    toggleManage () {
      const to = {
        name: this.$route.name === 'series-home' ? 'series-admin' : 'series-home',
        params: this.$route.params
      }
      this.$router.push(to)
    },
    createNew () {
      if (this.isNew && this.user && this.user.pages.length) {
        this.series = new Series({
          adminId: this.user.id,
          titleTag: this.seriesTag,
          title: this.seriesTag.substr(0, this.seriesTag.length - 11).replace(/_/g, ' '),
          dtStart: this.$route.query.start,
          organizers: [{
            organization: this.page
          }]
        })
      }
    }
  },
  watch: {
    user: function (n, o) {
      this.load()
    }
  },
  mounted () {
    this.load()
  },
  components: {
    SeriesBrochure,
    SeriesManager
  }
}
</script>

<style>

</style>
