<template>
  <!-- committed -->
  <v-row>
    <v-col cols="12" sm="6" md="3" lg="2">
      <v-select
        :items="[{value: true, text: 'Yes'}, {value: false, text: 'No'}]"
        v-model="player.committed"
        :label="required ? 'Committed*' : 'Committed'"
        :hint="commitHint || `Are you already committed to a school?`"
        persistent-hint
        @blur="$emit('blur-commit')"
        :error-messages="errors._committed"
        :disabled="disable"
        color="color3"
        item-color="color3"
        :hide-details="disable"
        :placeholder="commitPlaceholder"
        :solo="commitSolo"
      ></v-select>
    </v-col>
    <v-col cols="12" sm="6" md="3" lg="2" v-if="!noCommittedAs">
      <v-select
        :items="['Beach', 'Indoor', 'Both']"
        v-model="player.committedAs"
        :label="'Committed To Play'"
        :hint="`Did you commit as a beach or indoor player?`"
        :persistent-hint="player.committed"
        @blur="$emit('blur')"
        :disabled="disable || !player.committed"
        :hide-details="disable"
        color="color3"
        item-color="color3"
      ></v-select>
    </v-col>
    <v-col cols="12" md="6" lg="8">
      <v-autocomplete
        :items="colleges"
        name="committedSchool"
        :label="required && player.committed ? 'School Committed To*' : 'School Committed To'"
        v-model="player.committedSchool"
        :hint="user && user.vbl ? player.committedSchool : (committedSchoolHint || `What school are you committed to?`)"
        :persistent-hint="player.committed"
        @blur="emitBlur"
        :error-messages="errors.committedSchool"
        color="color3"
        item-color="color3"
        :item-text="item => item.name === item.abbr || !item.abbr ? item.name : `${item.name} - ${item.abbr}`"
        item-value="name"
        :disabled="disable || !player.committed"
        :hide-details="disable"
        :placeholder="committedSchoolPlaceholder"
        :solo="committedSchoolSolo"
        clearable
        @update:search-input="search = $event"
      >
        <template v-slot:no-data>
          <v-list-item two-line @click.stop="dialog=true">
            <v-list-item-content>
              <v-list-item-title>Can't find your college?</v-list-item-title>
              <v-list-item-subtitle>
                <v-btn color="color3 color3Text--text" @click.stop="dialog = true" text class="ml-3">Click here to Add Your college</v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" md="6" lg="8" v-if="user && user.vbl">
      <v-text-field
        color="color3"
        label="School Abbreviation"
        v-model="player.commitAbbr"
        :disabled="disable"
      ></v-text-field>
    </v-col>
    <v-dialog
      v-model="dialog"
      scrollable persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar color="color1 color1Text--text">
          <v-toolbar-title>Add Your College</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="color2 color2Text--text" @click.stop="dialog=false" small fab>
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            label="College"
            color="color3"
            v-model="manualCollege"
            hint="Enter the full name - E.g. University of Denver"
            persistent-hint
            autofocus
          ></v-text-field>
          <v-text-field
            label="Abbreviation or nickname"
            color="color3"
            v-model="manualCollegeAbbr"
            hint="Enter the abbreviation - E.g. DU"
            persistent-hint
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn color="color3 color3Text--text" @click.stop="add">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { firstBy } from 'thenby'
import { mapGetters } from 'vuex'

export default {
  props: [
    'player', 'disable', 'collegeList',
    'required', 'errors',
    'solo',
    'commitPlaceholder', 'committedSchoolPlaceholder',
    'commitHint', 'committedSchoolHint',
    'commitSolo', 'committedSchoolSolo',
    'noCommittedAs'
  ],
  data () {
    return {
      dialog: false,
      search: null,
      manualCollege: null,
      manualCollegeAbbr: null,
      manualObj: null,
      currentObj: null
    }
  },
  computed: {
    ...mapGetters(['user']),
    colleges () {
      const base = [...this.collegeList]
      if (this.currentObj) base.push(this.currentObj)
      if (this.manualObj) base.push(this.manualObj)

      return base.sort(firstBy('name'))
    }
  },
  methods: {
    setCollegeAbbr () {
      const college = this.colleges && this.player.committedSchool && this.colleges.find(f => f.name === this.player.committedSchool)
      this.player.commitAbbr = college ? college.abbr : null
    },
    emitBlur () {
      setTimeout(() => {
        this.$emit('blur-commit-school')
      }, 333)
    },
    add () {
      this.manualObj = {
        abbr: this.manualCollegeAbbr,
        name: this.manualCollege
      }
      this.player.committedSchool = this.manualCollege
      this.dialog = false
    },
    addCurrent () {
      if (!this.player.committedSchool) return
      const college = this.colleges && this.player.committedSchool && this.colleges.find(f => f.name === this.player.committedSchool)
      if (!college) {
        this.currentObj = {
          abbr: this.player.commitAbbr,
          name: this.player.committedSchool
        }
      }
    }
  },
  watch: {
    'player.committedSchool': 'setCollegeAbbr',
    dialog: function (val) {
      if (val) this.manualCollege = this.search
    }
  },
  mounted () {
    this.addCurrent()
  }
}
</script>
