<template>
  <image-library
    ref="library"
    :images="images"
    :username="username"
    :oId="oId"
    :vertical="vertical"
    :noPreview="noPreview"
    :singleRow="singleRow"
    :imgHeight="imgHeight"
    :imgWidth="imgWidth"
    :disabled="disabled"
    :cover="cover"
    :noUpload="noUpload"
    :center="center"
    :logo="logo"
    :uploadOnly="uploadOnly"
    :exclude="exclude"
    :loading="loading"
    :uploading="uploading"
    :deleteing="deleteing"
    @upload-image="uploadImage"
    @delete-image="deleteImage"
    @preview-click="previewImage"
    @has-unsaved-image="setUnsavedImage"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import ImageLibrary from '@/components/Utils/ImageLibrary.vue'
import * as actions from '@/store/ActionTypes'

export default {
  props: ['username', 'oId', 'sb', 'vertical', 'noPreview', 'singleRow', 'imgHeight', 'imgWidth', 'disabled', 'cover', 'noUpload', 'center', 'logo', 'uploadOnly', 'exclude'],
  data () {
    return {
      images: [],
      loading: false,
      uploading: false,
      deleteing: false,
      hasUnsavedImage: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    organizationId () {
      if (this.oId) return this.oId
      const page = this.user.pages.find(f => f.username.toLowerCase() === this.username.toLowerCase())
      return page && page.id
    },
    isVertical () {
      return this.vertical !== undefined && this.vertical !== false
    },
    isLogo () {
      return this.logo !== undefined && this.logo !== false
    },
    hasNoPreview () {
      return this.noPreview !== undefined && this.noPreview !== false
    },
    hasNoUpload () {
      return this.noUpload !== undefined && this.noUpload !== false
    },
    isSingleRow () {
      return this.singleRow !== undefined && this.singleRow !== false
    },
    isCentered () {
      return this.center !== undefined && this.center !== false
    },
    isUploadOnly () {
      return this.uploadOnly !== undefined && this.uploadOnly !== false
    },
    ratio () {
      return this.width && this.height ? null : 1
    },
    width () {
      return this.imgWidth ? this.imgWidth : this.isSingleRow ? 192 : null
    },
    height () {
      return this.imgHeight ? this.imgHeight : this.isSingleRow ? 192 : null
    },
    displayImages () {
      if (!this.exclude) return this.images
      const x = typeof this.exclude === 'string' ? [this.exclude] : this.exclude
      return this.images.filter(f => !x.includes(f.url))
    }
  },
  methods: {
    loadImages () {
      if (!this.organizationId) return
      this.loading = true
      this.images = []
      this.$VBL.get.images('organization', this.organizationId, this.sb)
        .then(r => {
          this.images = r.data
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    },
    uploadImage (img) {
      if (this.uploading) return

      this.uploading = true
      const type = `organization${this.isLogo ? '-logo' : ''}`
      this.$VBL.post.images(type, this.organizationId, img.formData, this.sb)
        .then(r => {
          if (this.isLogo) {
            this.$store.dispatch(actions.LOAD_THEME, { username: this.username, force: true })
            this.$emit('logo-saved', r.data)
          } else {
            this.images.unshift(...r.data)
            this.$emit('image-saved', r.data[0])
          }
          this.$refs.library.uploaded()
        })
        .catch(err => console.log(err.response))
        .finally(() => {
          this.uploading = false
        })
    },
    deleteImage (id) {
      if (this.deleteing) return

      this.deleteing = true
      this.$VBL.delete.image('organization', this.organizationId, id)
        .then(r => {
          var i = this.images.findIndex(f => f.id === id)
          this.images.splice(i, 1)
          this.$refs.library.dialog = false
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.deleteing = false
        })
    },
    previewImage (image) {
      this.$emit('preview-click', image)
    },
    setUnsavedImage (val) {
      this.hasUnsavedImage = val
      this.$emit('has-unsaved-image', !!val)
    }
  },
  watch: {
    username: function (val) {
      if (val) {
        this.loadImages()
      }
    }
  },
  mounted () {
    this.loadImages()
  },
  components: {
    ImageLibrary
  }
}
</script>
