<template>
  <v-card
    v-if="ad"
    height="100%"
    max-height="100%"
    @click.native="actionClick"
    :class="`flexcard clickable ${ad.class || 'white--text'}`"
    :color="color || '#0c0c0c'"
    :flat="flat"
  >
    <v-fab-transition>
      <v-img
        :key="img"
        height="100%"
        max-height="150px"
        :src="img"
        :contain="ad.contain"
        :class="ad.class || 'white--text'"
        :position="ad.position"
      >
      </v-img>
    </v-fab-transition>
    <div style="position: absolute; bottom: 0; width: 100%;">
        <v-container fluid>
          <v-row dense>
            <v-col cols="10" :class="ad.textClass" :style="ad.textStyle">
                {{ad.tagline}}
            </v-col>
            <v-col class="text-right" cols="2" v-if="!ad.noBtn">
              <v-btn color="color3 color3Text--text" fab small @click.native="actionClick">
                <v-icon>fas fa-caret-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
    </div>
  </v-card>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapGetters } from 'vuex'
import Shuffle from '@/helpers/Shuffle'
// import FitText from '@/components/Utils/FitText'

export default {
  props: ['ad', 'flat', 'rowHeader'],
  data () {
    return {
      count: 0,
      interval: null,
      img: null
    }
  },
  computed: {
    ...mapGetters(['liveUpdates']),
    color () {
      if (this.img && this.img.includes('color=')) {
        const q = (this.img.split('?'))[1]
        var query = {}
        var pairs = (q[0] === '?' ? q.substr(1) : q).split('&')
        for (var i = 0; i < pairs.length; i++) {
          var pair = pairs[i].split('=')
          query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
        }
        return query.color || this.ad.color
      }
      return this.ad.color
    }
  },
  methods: {
    actionClick: debounce(function () {
      this.doActionClick()
    }, 333),
    doActionClick () {
      const dto = {
        id: this.ad.id,
        page: this.$route.fullPath
      }
      this.liveUpdates.invoke('AdClick', dto)
      this.ad.relative ? this.$router.push(this.ad.actionUrl) : window.open(this.ad.actionUrl, '_blank')
    },
    shuffleMe () {
      const base = (this.rowHeader && this.ad.rowHeadImages) || this.ad.images
      const images = Shuffle(base)
      this.img = images[0]
    },
    onStart (v) {
      if (this.interval) clearInterval(this.interval)
      if (!v) return
      this.interval = setInterval(() => {
        this.count++
      }, 13000)
    }
  },
  watch: {
    count: 'shuffleMe'
  },
  mounted () {
    this.shuffleMe()
    this.onStart(true)
  },
  destroyed () {
    this.onStart(false)
  }
}
</script>

<style>

</style>
