import * as signalR from '@microsoft/signalr'
// import { Promise } from 'es6-promise'
import { store } from '@/store'
import * as actions from '@/store/ActionTypes'
import * as endpoints from '@/classes/_URL'

// const ConnectionState = [
//   'Connecting',
//   'Connected',
//   'Disconnected'
// ]

export default class LiveUpdates {
  url = endpoints.signalR
  token = null
  connection = null
  stopped = false
  retry = null
  NOGO = false
  subscriptions = []

  constructor (token) {
    this.token = token
    this._init()
  }

  // METHODS
  async connect (token) {
    if (token !== this.token) {
      // user logged in or out change connection to pass token in URL
      if (this.connectionStatus === 'Connected') {
        await this._stop()
      }
      this.token = token
      this._init()
    }
    return this._connect()
  }

  subscribe (subscription) {
    console.log('subscribe')
    // if (this.connectionStatus !== 'Connected') {
    //  console.log('subscribe: queueing')
    //  setTimeout(this.subscribe(subscription), 2500)
    //  return
    // }
    var existing = this.subscriptions.find(f => JSON.stringify(f) === JSON.stringify(subscription))
    if (existing) return

    if (subscription.args.length === 1) subscription.args.push(null)

    this.subscriptions.push(subscription)
    if (this.connectionStatus === 'Connected') {
      this.connection.invoke(subscription.methodName, ...subscription.args)
        .then(response => {
          console.log('subscribe: complete')
        })
    }
  }

  unsubscribe (subscription) {
    var existingIndex = this.subscriptions.findIndex(f => JSON.stringify(f) === JSON.stringify(subscription))
    if (existingIndex !== -1) {
      this.connection.invoke('UnsubsribeToTournament', ...subscription.args)
        .then(response => {
          this.subscriptions.splice(existingIndex, 1)
        })
    }
  }

  _init () {
    // const url = this.token ? `${this.url}?token=${this.token}` : this.url

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(this.url, { accessTokenFactory: () => this.token })
      .configureLogging(signalR.LogLevel.Error)
      // .withAutomaticReconnect()
      .build()
    this.connection.on('consoleLog', this.log)
    this.connection.on('StoreMutation', this.storeMutation)
    this.connection.on('StoreAction', this.storeAction)
    this.connection.on('NoUser', this.noUser)

    this.connection.onclose(() => {
      if (!this.stopped) {
        this._connect()
      }
    })
  }

  _stop () {
    this.stopped = true
    return this.connection.stop()
  }

  _connect () {
    return new Promise((resolve, reject) => {
      console.log('_connet: ' + this.connectionStatus)
      this.stopped = false
      if (this.retry) {
        clearTimeout(this.retry)
      }
      if (this.connectionStatus !== 'Disconnected') {
        return resolve()
      }
      this.connection.start()
        .then(() => {
          this._resubscribe().then(() => {
            console.log('Connected')
            return resolve()
          })
        })
        .catch(err => {
          console.log(err)
          if (this.connectionStatus !== 'Connected' && !this.NOGO) {
            this.retry = setTimeout(() => {
              this._connect()
            }, 500)
          }
        })
    })
  }

  _resubscribe () {
    const promises = []
    this.subscriptions.forEach(subscription => {
      promises.push(this.connection.invoke(subscription.methodName, ...subscription.args))
    })
    return Promise.all(promises)
  }

  log (data) {
    console.log(data)
  }

  storeMutation (name, payload) {
    store.commit(name, payload)
  }

  storeAction (name, payload) {
    store.dispatch(name, payload)
  }

  noUser (name, payload) {
    // if (store.getters.oidcIsAuthenticated) {
    //   store.dispatch(actions.LOGOUT)
    // }
    console.log('noUser called')
    store.dispatch(actions.UNLOAD_USER)
  }

  // GETTERS
  get connectionStatus () {
    return this.connection.connection.connectionState
  }

  get connectionId () {
    return this.connection.connectionId
  }
}
