import loadTournament from '@/router/methods/loadTournament'
import DivisionRoutes from './league-division-routes'

// const TournamentList = () => import('@/Pages/Public/Tournaments')
const TournamentHome = () => import('@/Pages/Tournament/Home.vue')
const TeamHome = () => import('@/Pages/Tournament/Team/Home.vue')
const Live = () => import('@/Pages/Tournament/Live/Match.vue')
const TeamList = () => import('@/Pages/Tournament/Teams.vue')
// const LeagueCourtList = () => import('@/Pages/Tournament/Courts/LeagueCourtManager.vue')
const LeagueCourtList = () => import('@/Pages/Tournament/Courts/CourtManager.vue')
const PlayerList = () => import('@/Pages/Tournament/Players.vue')
const StreamList = () => import('@/Pages/Tournament/LiveStream.vue')
const ScheduleManager = () => import('@/components/Leagues/ScheduleManager.vue')

export default [
  {
    path: '/league/:tournamentId',
    name: 'league-brochure',
    component: TournamentHome,
    beforeEnter: loadTournament,
    props: true
  },
  {
    path: '/league/:tournamentId/admin',
    name: 'league-admin',
    component: TournamentHome,
    beforeEnter: loadTournament,
    props: true
  },
  {
    path: '/league/:tournamentId/teams',
    name: 'league-teams',
    component: TeamList,
    beforeEnter: loadTournament,
    props: (route) => { return { tournamentId: route.params.tournamentId, tab: 'teams' } }
  },
  {
    path: '/league/:tournamentId/players',
    name: 'league-players',
    component: PlayerList,
    beforeEnter: loadTournament,
    props: (route) => { return { tournamentId: route.params.tournamentId, tab: 'players' } }
  },
  {
    path: '/league/:tournamentId/courts',
    name: 'league-courts',
    component: LeagueCourtList,
    beforeEnter: loadTournament,
    props: (route) => { return { tournamentId: route.params.tournamentId } }
  },
  {
    path: '/league/:tournamentId/schedule',
    name: 'league-schedule',
    component: ScheduleManager,
    beforeEnter: loadTournament,
    props: (route) => { return { tournamentId: route.params.tournamentId } }
  },
  {
    path: '/league/:tournamentId/team/:teamId',
    name: 'league-division-team',
    component: TeamHome,
    beforeEnter: loadTournament,
    props: true
  },
  {
    path: '/league/:tournamentId/live/:matchId',
    name: 'league-live',
    component: Live,
    beforeEnter: loadTournament,
    props: true
  },
  {
    path: '/league/:tournamentId/livestreams',
    name: 'league-streams',
    component: StreamList,
    beforeEnter: loadTournament,
    props: true
  },
  ...DivisionRoutes
]
