import endpoints from './endpoints'

export default class UserSDK {
  constructor (http) {
    this.http = http
  }

  changePassword (dto) {
    return this.http.post(endpoints.user.changePassword, dto)
  }

  getMe (uid) {
    const url = `${endpoints.user.getMe}${uid ? '?uid=' + uid : ''}`
    return this.http.get(url)
  }

  getMyPlayers (asOf) {
    const x = asOf ? `?asOf=${asOf}` : ''
    return this.http.get(`/Me/Players${x}`)
  }

  getSignIns () {
    return this.http.get(endpoints.user.getSignIns)
  }

  getBidPrograms () {
    return this.http.get('/Me/BidPrograms')
  }

  linkFacebook (authResponse) {
    return this.http.post(endpoints.facebook.link, authResponse)
  }

  unlinkFacebook (id) {
    return this.http.post(endpoints.facebook.unlink(id))
  }

  getFacebookDDR (id) {
    return this.http.get(`/facebook/deletion?id=${id}`)
  }

  postEmail (email) {
    return this.http.post(endpoints.user.email, email)
  }

  deleteEmail (emailId) {
    return this.http.delete(endpoints.email.delete(emailId))
  }

  deletePhone (phoneId) {
    return this.http.delete(`phone/${phoneId}`)
  }

  register (user) {
    return this.http.post(endpoints.user.register, user)
  }

  sendMagicLink (dto) {
    return this.http.post(endpoints.magicLink.send, dto)
  }

  sendCode (dto) {
    return this.http.post(`${endpoints.magicLink.send}?code=true`, dto)
  }

  loginViaMagicLink (id) {
    return this.http.post(endpoints.magicLink.login(id))
  }

  getMyImages () {
    return this.http.get(endpoints.images.get)
  }

  resendEmailConfirm (emailId) {
    return this.http.post(endpoints.email.resend(emailId))
  }

  getOrderHistory (uid) {
    let url = endpoints.user.orderHistory
    if (uid) url = `${url}?uid=${uid}`
    return this.http.get(url)
  }

  getBookmark (id) {
    return this.http.get('/me/bookmarks')
  }

  toggleBookmark (type, id) {
    return this.http.post(`/me/bookmarks/${id}?type=${type}`)
  }

  getPlayerNotes (id) {
    return this.http.get(`/notes/player/${id}`)
  }

  postPlayerNotes (id, dto) {
    return this.http.post(`/notes/player/${id}`, dto)
  }

  deleteNote (id) {
    return this.http.delete(`/notes/player/${id}`)
  }

  twoFactor (challenge) {
    return this.http.post('/me/TwoFactorChallenege', challenge)
  }

  getMyTeams (tournamentId) {
    return this.http.get(`me/Teams?tournamentId=${tournamentId}`)
  }

  getMyCollegeTeams () {
    return this.http.get('roster/myTeams')
  }

  search (term, id, email) {
    let url = `users/search?term=${term}`
    if (id) url = `${url}&id=${id}`
    if (email) url = `${url}&email=true`
    return this.http.get(url)
  }

  contact = {
    add: (dto) => {
      return this.http.post('/me/contact/add', dto)
    },
    confirm: (code) => {
      return this.http.post(`/me/contact/confirm?code=${code}`)
    }
  }

  notification = {
    get: (pp) => {
      return this.http.get(`/me/notifications?page=${pp | 0}`)
    },
    markAs: (action, ids) => {
      return this.http.post(`/me/notifications?action=${action}`, ids)
    }
  }

  tags = {
    get: (ppId, list, report) => {
      return this.http.get(`/me/tags?id=${ppId || 0}&list=${list || false}&report=${report || false}`)
    },
    post: (dto) => {
      return this.http.post('/me/tags', dto)
    }
  }

  ntdp = {
    get: (ppId, list, report) => {
      return this.http.get(`/me/NtdpRatings?id=${ppId || 0}&list=${list || false}&report=${report || false}`)
    },
    post: (dto) => {
      return this.http.post('/me/NtdpRatings', dto)
    },
    delete: (id) => {
      return this.http.delete(`/me/NtdpRatings?id=${id}`)
    }
  }
}
