import endpoints from './endpoints'
import TournamentSDK from './tournament'
import OrganizationSDK from './organization'
import CartSDK from './Cart'
import UserSDK from './user'
import PlayerSDK from './player'
import TeamSDK from './team'
import ThemeSDK from './theme'
import DivisionSDK from './DivisionSDK'
import RoundSDK from './RoundSDK'
import PostSDK from './PostSDK'
import PatchSDK from './PatchSDK'
import DeleteSDK from './DeleteSDK'
import GetSDK from './GetSDK'
import StoreSDK from './StoreSDK'
import PointsSDK from './PointsSDK'
import LiveScoringSDK from './LiveScoringSDK'
import AgreementsSDK from './AgreementsSDK'
import EventSDK from './EventSDK'
import CollegeSDK from './CollegeSDK'
import RosterSDK from './RosterSDK'
import RatingsSDK from './RatingsSDK'
import ReportsSDK from './ReportsSDK'
import ClubSDK from './ClubSDK'

export default class VBL {
  constructor (http) {
    this.http = http
    this.tournament = new TournamentSDK(http)
    this.division = new DivisionSDK(http)
    this.round = new RoundSDK(http)
    this.organization = new OrganizationSDK(http)
    this.cart = new CartSDK(http)
    this.user = new UserSDK(http)
    this.player = new PlayerSDK(http)
    this.team = new TeamSDK(http)
    this.theme = new ThemeSDK(http)
    this.post = new PostSDK(http)
    this.patch = new PatchSDK(http)
    this.delete = new DeleteSDK(http)
    this.get = new GetSDK(http)
    this.store = new StoreSDK(http)
    this.points = new PointsSDK(http)
    this.liveScoring = new LiveScoringSDK(http)
    this.agreements = new AgreementsSDK(http)
    this.events = new EventSDK(http)
    this.college = new CollegeSDK(http)
    this.roster = new RosterSDK(http)
    this.ratings = new RatingsSDK(http)
    this.reports = new ReportsSDK(http)
    this.club = new ClubSDK(http)
  }

  facebookLogin (authResponse) {
    return this.http.post(endpoints.facebook.login, authResponse)
  }

  facebookJoin (profile) {
    return this.http.post(endpoints.facebook.create, profile)
  }

  putFeedback (dto) {
    return this.http.put(endpoints.feedback, dto)
  }

  postImages (dto, config) {
    // {
    //   images: [],
    //   type: string,
    //   id: int (user or organization)
    // }
    return this.http.post(endpoints.images.post, dto, config)
  }

  verifyAAU (dto) {
    // {
    //   "id": "string",
    //   "lastname": "string",
    //   "zipcode": "string",
    //   "dob": "string",
    //   "by": "string"
    // }
    return this.http.post(endpoints.aau.verify, dto)
  }

  verifyMe (dto) {
    return this.http.post('SanctioningBody/Verify', dto)
  }

  getLevel (id) {
    return this.http.get(`/AVPA/level?id=${id}`)
  }

  getRumble (id) {
    return this.http.get(`/AVPA/RumbleInfo?ppid=${id}`)
  }

  verifyAVPA (number, asOf, create) {
    var a = asOf ? `&asOf=${asOf}` : ''
    var b = create === true ? '&create=true' : ''
    var url = `/AVPA/Verify?id=${number}${a}${b}`
    return this.http.post(url)
  }

  verifyP1440 (number) {
    return this.http.post(endpoints.p1440.verify(number))
  }

  p1440Unlimited (number) {
    return this.http.post(endpoints.p1440.unlimited(number))
  }

  getPointScale (sbid, grouped) {
    var q = grouped ? '?grouped=true' : ''
    return this.http.get(endpoints.rankings.pointScale(sbid) + q)
  }

  getMultipliers (sbid) {
    return this.http.get(endpoints.rankings.teamMultiplier(sbid))
  }

  waitlist (dto) {
    return this.http.post(endpoints.waitlist, dto)
  }

  waitlistLogs (tournamentId) {
    return this.http.get(`/waitlist/logs/${tournamentId}`)
  }

  sms = {
    check: (number) => {
      return this.http.post(`/Phone/SmsCheck?phone=${number}`)
    },
    checkPlayer: (id) => {
      return this.http.post(`/Phone/PlayerSmsCheck?id=${id}`)
    },
    sendCode: (number) => {
      return this.http.post(`/Phone/SmsSendCode?phone=${number}`)
    },
    verify: (number, code) => {
      return this.http.post(`/Phone/SmsOptIn?phone=${number}&code=${code}`)
    }
  }

  getLocationCityState (locationId) {
    return this.http.get(`/Locations/GetCityState?id=${locationId}`)
  }
}
