import moment from 'moment'

export default class Bid {
  _dtCreated = null
  awardedTo = null
  awardedToId = 0
  bidId = null
  bidProgramId = null
  createdBy = null
  discountAmount = null
  discountPercentage = null
  divisionList = []
  _dtEarned = null
  earnedAt = null
  earnedFor = null
  gender = null
  genderId = null
  id = 0
  notes = null
  players = []
  subPolicy = 0
  tournament = null
  tournamentDivisionList = []
  tournamentId = null
  usedById = null

  constructor (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    Object.assign(this, dto)
  }

  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    Object.assign(this, dto)
  }

  get divisionRestrict () {
    return !!(this.divisionList.length || this.genderId || this.tournamentDivisionList.length)
  }

  getAllowedDivisions (tournament) {
    if (!tournament || tournament.id !== this.tournamentId) return []

    return this.divisionRestrict ? tournament.publicDivisions.filter(f => {
      return (!this.divisionList.length || this.divisionList.includes(f.division.id)) &&
        (!this.genderId || f.gender.id === this.genderId) &&
        (!this.tournamentDivisionList.length || this.tournamentDivisionList.includes(f.id))
    }) : tournament.publicDivisions
  }

  get dtCreated () {
    return moment(this._dtCreated)
  }

  set dtCreated (val) {
    this._dtCreated = val
  }

  set dtEarned (val) {
    this._dtEarned = val ? moment.utc(val).format('YYYY-MM-DD[T]HH:mm[Z]') : null
  }

  get dtEarned () {
    return this._dtEarned ? moment.utc(this._dtEarned) : null
  }

  get playerNames () {
    return this.players.map(m => m.fullName)
  }

  get used () {
    return this.usedById > 0
  }

  get discountValue () {
    return this.discountAmount || (this.discountPercentage * 100)
  }

  get discountType () {
    return this.discountPercentage > 0 ? 'Percent' : 'Dollars'
  }

  get dto () {
    return {
      id: this.id,
      bidId: this.bidId,
      bidProgramId: this.bidProgramId,
      tournamentId: this.tournamentId,
      subPolicy: this.subPolicy,
      discountAmount: this.discountAmount,
      discountPercentage: this.discountPercentage,
      divisionList: this.divisionList,
      tournamentDivisionList: this.tournamentDivisionList,
      genderId: this.genderId,
      awardedToId: this.awardedToId,
      notes: this.notes,
      players: this.players.map(m => {
        return {
          id: m.id || 0,
          aauNumber: m.aauNumber,
          email: m.email,
          fullName: m.fullName
        }
      }),
      dtEarned: this.dtEarned,
      earnedAt: this.earnedAt,
      earnedFor: this.earnedFor
    }
  }

  get teamId () {
    return this.players.map(m => m.id).sort().join('-')
  }
}
