<template>
  <v-breadcrumbs class="py-0" :class="{'pl-0': $vuetify.breakpoint.smAndUp}" :items="crumbs">
    <template v-slot:divider>
      <v-icon class="xsmall">fas fa-chevron-right</v-icon>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  props: ['crumbs']
}
</script>

<style>

</style>
