<template>
  <div>
    <event-brochure :vblEvent="series" :view="view" :startTab="startTab || (series.brochureHTML ? 'information' : 'tournaments')">
      <template slot="tabs">
        <v-tab href="#tournaments">
          <span class="hidden-xs-only color2Text--text">Tournaments</span>
          <v-icon color="color2Text">far fa-calendar-alt</v-icon>
        </v-tab>
        <v-tab href="#leaderboard">
          <span class="hidden-xs-only color2Text--text">Leaderboard</span>
          <v-icon color="color2Text">fas fa-trophy</v-icon>
        </v-tab>
      </template>

      <template slot="tab-items">
        <v-tab-item key="tournaments" value="tournaments">
          <v-card flat color="grey lighten-4">
            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  <v-expansion-panels popout v-model="panel" hover multiple>
                    <v-expansion-panel v-if="series.upcoming.length">
                      <v-expansion-panel-header color="color2 color2Text--text" class="title">
                        Upcoming Tournaments
                        <template v-slot:actions>
                          <v-icon color="color1Text">$expand</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <tournament-list
                          :tournaments="series.upcoming"
                          :sortDesc="false"
                          :listView="listView"
                        ></tournament-list>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel
                      :style="series.upcoming.length > 0 ? 'border-top: 1px solid white !important' : null"
                    >
                      <v-expansion-panel-header color="color2 color2Text--text" class="title">
                        Previous Tournaments
                        <template v-slot:actions>
                          <v-icon color="color1Text">$expand</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="px-0">
                        <tournament-list
                          :tournaments="series.previous"
                          :sortDesc="true"
                          :listView="listView"
                        ></tournament-list>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-card-text>
            <v-fab-transition>
              <v-tooltip left>
                <span>{{listView ? 'Card View' : 'List View'}}</span>
                <template v-slot:activator="{ on }">
                  <v-btn color="color3 color3Text--text"
                    v-on="on"
                    fab
                    fixed
                    bottom
                    right
                    @click.stop="listView = !listView"
                    :class="{'shift-left': view.admin}"
                  >
                    <v-icon v-if="listView">fas fa-table</v-icon>
                    <v-icon v-else>fas fa-list</v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </v-fab-transition>

          </v-card>

        </v-tab-item>
        <v-tab-item key="leaderboard" value="leaderboard">
          <v-card flat color="grey lighten-4">
            <v-card-text>
              <v-row dense v-if="series.iLeaderboard.leaders.length">
                <v-col cols="12" md="6" :offset-md="series.leaderboards.length === 1 ? 3 : null" v-for="(board, i) in series.leaderboards" :key="i">
                  <leaderboard :leaderboard="board" :byClub="series.settings.byClub" :byTeam="series.settings.byTeam" :titleTag="series.titleTag" :view="view"></leaderboard>
                </v-col>
              </v-row>
              <v-row dense v-else>
                <v-col cols="12" class="title my-12 text-center">
                  You can't have leaders until the first event is played, silly 😉
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </template>

    </event-brochure>
  </div>
</template>

<script>
import EventBrochure from '@/components/Events/EventBrochure'
import TournamentList from '@/components/Tournament/TournamentList'
import Leaderboard from '@/components/Leaderboard/Leaderboard'
import { mapGetters } from 'vuex'

export default {
  props: ['series', 'view', 'startTab'],
  data () {
    return {
      listView: true,
      panel: [0]
    }
  },
  computed: {
    ...mapGetters(['user', 'publicView']),
    up () {
      return this.series.upcoming
    }
  },
  watch: {
    '$vuetify.breakpoint.xs': function (val) {
      if (val) {
        this.listView = false
      }
    }
  },
  mounted () {
    this.listView = !this.$vuetify.breakpoint.xs
  },
  components: {
    EventBrochure,
    TournamentList,
    Leaderboard
  }
}
</script>

<style scoped>
.shift-left {
  margin-right: 60px;
}
</style>
