<template>
  <v-container fluid class="pt-0">
    <loading-bar :value="loading"></loading-bar>
    <v-row dense v-if="count > 3">
      <v-col cols="12">
        <v-text-field
          append-icon="fas fa-search"
          label="Search"
          hide-details
          v-model="search"
          color="color3"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-window v-model="window" touchless>
      <!-- ORGANIZATIONS -->
      <v-window-item :value="0">
        <v-row dense>
          <v-col cols="12">
            <p class="title">Contributing Organizations</p>
            <p class="subheading mt-2">
              These are the organizations that have the ability to add tournaments to this series.
              To view and/or edit the tournaments that are included in the series, click the organization.
            </p>
          </v-col>
        </v-row>
        <v-data-iterator
          :items="organizations"
          :footer-props="{itemsPerPageOptions: rowsPerPageItems}"
          :options.sync="pagination"
          :search="search"
          :hide-default-footer="count < 12"
        >
          <template v-slot:default="{items}">
            <v-row>
              <v-col
                v-for="item in items"
                :key="item.id"
                cols="6" sm="6" md="4"
              >
                <organization-card
                  :organization="item"
                  :selector="true"
                  @selected="onSelected"
                ></organization-card>
              </v-col>
            </v-row>
          </template>
          <template slot="no-data">
            <v-card flat class="my-12">
              <v-card-text>
                <h1>There are no organizations participating yet.</h1>
                <v-btn color="color3 color3Text--text" @click.stop="dialog=true">Add an Organizaiton</v-btn>
              </v-card-text>
            </v-card>
          </template>
        </v-data-iterator>
      </v-window-item>
      <!-- ORGANIZATION TOURNAMENTS -->
      <v-window-item :value="1">
        <v-row dense>
          <v-col cols="12">
            <p class="title">{{selectedProfile && selectedProfile.name}}</p>
            <p class="subheading mt-2">
              Select edit the tournaments that will contibute to the point system, then click save (on the bottom right of the screen).
            </p>
          </v-col>
          <v-col cols="12">
            <v-text-field
              color="color3"
              append-icon="fas fa-search"
              label="Search"
              hide-details
              v-model="search"
              solo-inverted
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="rows"
          :options.sync="listPage"
          :footer-props="{itemsPerPageOptions: [25, 50, -1]}"
          :loading="loading"
          :loading-text="`Loading ${selectedProfile && selectedProfile.name}'s tournaments...`"
          :search="search"
          show-select
          v-model="selected"
        >
          <template v-slot:progress>
            <v-progress-linear color="color3" indeterminate></v-progress-linear>
          </template>
          <template v-slot:[`item.sanctionedBy`]="{item}">
              <div style="width:95px">
                <v-carousel
                  :show-arrows="false"
                  hide-delimiters
                  height="40"
                  max="95"
                  class="elevation-0"
                  cycle continuous
                  interval="3000"
                >
                  <v-carousel-item
                    height="40px"
                    v-for="(logo, i) in item.logos"
                    :key="`${logo.url}_${i}`"
                    :src="logo.url"
                    :class="logo.class"
                    contain
                  ></v-carousel-item>
                </v-carousel>
              </div>
          </template>
          <template v-slot:[`item.startDate`]="{item}">
            <div>{{ item.dateDisplay }}</div>
            <div>{{ item.year }}</div>
          </template>
          <template v-slot:[`item.name`]="{item}">
            <div class="font-weight-bold">{{ item.name }}</div>
            <div class="font-weight-thin">{{ item.divisions | formatArray }}</div>
          </template>
          <template v-slot:[`item.public`]="{item}">
            <v-tooltip right v-if="!item.public">
              <span>Only you can see this</span>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">fal fa-low-vision</v-icon>
              </template>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-window-item>
    </v-window>
    <v-fab-transition>
      <organization-add-dialog
        v-if="window === 0 && series.id"
        @added="onOrgAdded"
        :exclude="exclude"
        :seriesId="series.id"
        :btnShift="true"
      ></organization-add-dialog>
    </v-fab-transition>
    <v-fab-transition>
      <v-btn
        v-if="window > 0"
        :id="activeFab.id"
        :color="activeFab.color"
        fab bottom right fixed
        @click.stop="activeFab.click"
        :disabled="loading"
        :loading="loading"
        style="margin-right: 60px"
      >
        <v-icon>{{activeFab.icon}}</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
import OrganizationCard from '@/components/Organization/FlashCard.vue'
import OrganizationAddDialog from './OrganizationAddDialog.vue'
import { mapGetters } from 'vuex'
import Summary from '@/classes/TournamentSummary'

export default {
  props: ['series'],
  data () {
    return {
      loading: false,
      search: null,
      pagination: { itemssPerPage: 12 },
      rowsPerPageItems: [12, 24, 36],
      window: 0,
      selectedProfile: null,
      summaries: [],
      listPage: { sortBy: ['startDate'], itemsPerPage: 25, sortDesc: [true] },
      selected: []
    }
  },
  computed: {
    ...mapGetters(['user']),
    organizations () {
      return this.series.organizers.map(m => m.organization)
    },
    activeFab () {
      return this.saveable ? {
        id: 'save',
        color: 'success white--text',
        click: this.saveTournaments,
        icon: 'fas fa-save'
      } : {
        id: 'cancel',
        color: 'error white--text',
        click: this.cancel,
        icon: 'fas fa-times'
      }
    },
    count () {
      return this.organizations.length
    },
    exclude () {
      return this.organizations.map(m => m.username)
    },
    headers () {
      return [
        { value: 'sanctionedBy', align: 'left', sortable: true },
        { text: 'Date', value: 'startDate', align: 'left', sortable: true },
        { text: 'Name', value: 'name', align: 'left', sortable: true },
        { text: 'Location', value: 'locations', align: 'left', sortable: true },
        { value: 'public', align: 'left', sortable: 'false' }
      ]
    },
    rows () {
      if (!this.summaries) return []
      var base = this.summaries
      var result = base.map((t) => {
        return {
          id: t.id,
          startDate: t.timestamp,
          dateDisplay: t.startDate.isSame(t.endDate) ? t.startDate.format('MMM Do') : `${t.startDate.format('MMM Do')} - ${t.endDate.format('MMM Do')}`,
          dayDisplay: t.startDate.isSame(t.endDate) ? t.startDate.format('dddd') : `${t.startDate.format('ddd')} - ${t.endDate.format('ddd')}`,
          name: t.name,
          locations: t.locationsString,
          public: t.isPublic,
          regOpen: t.regOpen,
          sanctionedBy: t.sanctionedBy || '',
          admin: this.user && this.user.isTournamentAdmin(t),
          ibvl: t.ibvl,
          logos: t.logos.filter(f => f && f.url) || [],
          divisions: (t.divisionNames || []).sort(),
          tournament: t,
          tags: t.tags,
          year: t.startDate.format('YYYY')
        }
      })
      if (!result.length) return [{ x: 'filler' }]
      return result
    },
    selectedIds () {
      return new Set(this.selected.map(m => m.id))
    },
    existing () {
      return this.series.tournaments.map(m => m.id)
    },
    summaryIds () {
      return new Set(this.summaries.map(m => m.id))
    },
    addMe () {
      return [...this.selectedIds].filter(f => !this.existing.includes(f))
    },
    dropMe () {
      return this.existing.filter(f => !this.selectedIds.has(f) && this.summaryIds.has(f))
    },
    dto () {
      return {
        add: this.addMe,
        remove: this.dropMe
      }
    },
    saveable () {
      return this.dto.add.length || this.dto.remove.length
    }
  },
  methods: {
    onOrgAdded (org) {
      this.series.organizers.push({ organization: org, order: this.series.organizers.length })
    },
    onSelected (org) {
      this.selectedProfile = org
      this.loadTournaments()
      this.window = 1
    },
    loadTournaments () {
      this.loading = true
      // this.$VBL.tournament.getAllSummaries(`organizationUserName=${this.selectedProfile.username}&coOp="false`)
      this.$VBL.events.series.organizers.tournaments.get(this.series.id, this.selectedProfile.username)
        .then(r => {
          this.loadSummaries(r.data)
        })
        .catch((error) => { console.log(error) })
        .finally(() => { this.loading = false })
    },
    cancel () {
      this.window = 0
      setTimeout(() => {
        this.summaries = []
      }, 500)
    },
    saveTournaments () {
      this.loading = true
      this.$VBL.events.series.organizers.tournaments.post(this.series.id, this.selectedProfile.username, this.dto)
        .then(r => {
          this.$emit('changed')
          this.window = 0
        })
        .catch((error) => { console.log(error) })
        .finally(() => { this.loading = false })
    },
    loadSummaries (data) {
      this.summaries = data.map(s => new Summary(s))
      const ids = this.series.tournaments.map(m => m.id)
      this.selected = this.summaries.filter(f => ids.includes(f.id))
    }
  },
  components: {
    OrganizationCard,
    OrganizationAddDialog
  },
  mounted () {
    // this.loadOrganizations()
  },
  watch: {
    window: function (val) {
      if (!val) {
        this.selected = []
      }
    }
  }
}
</script>

<style scoped>
.flexcard {
  cursor: pointer;
}
.social {
  border-radius: 15px;
}
.social .v-icon {
  font-size: 30px !important;
}
.social .v-icon.fa-twitter,
.social .v-icon.fa-snapchat-ghost {
  font-size: 24px !important;
}
.instagram {
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
}
.fa-facebook-f:before {
  margin-left: 6px;
  margin-top: 6px;
}

</style>
