const warmUp = 0
const prod = 1
const dev = 0
const port = 44351
// const port = 60111

let api = process.env.NODE_ENV === 'production' || prod ? 'https://api.volleyballlife.com/api/v1.0' : warmUp ? 'https://volleyballlife-api-dev.azurewebsites.net/api/v1.0' : `https://localhost:${port}/api/v1.0`
let signalR = process.env.NODE_ENV === 'production' || prod ? 'https://api.volleyballlife.com/live' : warmUp ? 'https://volleyballlife-api-dev.azurewebsites.net/live' : `https://localhost:${port}/live`

// signalR = 'https://volleyballlife-api-dev.azurewebsites.net/live'

if (dev) {
  api = `https://localhost:${port}/api/v1.0`
  signalR = `https://localhost:${port}/live`
}

function getUrl (username, relativePath) {
  return process.env.NODE_ENV === 'production' || prod ? `https://${username}.volleyballlife.com${relativePath}` : warmUp ? `https://volleyballlife-api-dev.azurewebsites.net/${relativePath}` : `http://${username}.localhost:8080${relativePath}`
}

const qrCodeBase = 'https://vblfunctiions.azurewebsites.net/api/GetQRCode?code=0iBImdaF3cbRsE64Dbi7fhEoi6iyburfOKfZl481GPbrLREHdoUH/g=='

export { api, signalR, getUrl, qrCodeBase }
