import { formatArray } from '@/Filters/'

export default function (m) {
  var x = []
  var dh = m.dominantHand
  dh && (dh.startsWith('A') || dh.startsWith('L') || dh.startsWith('R')) && x.push(`${dh}${dh.startsWith('A') ? '' : '-handed'}`)
  var s = m.side
  var sp = m.sidePreferred
  var d = m.defense ? m.defense.charAt(0) : null
  var dp = m.defensePrferred
  var dp2 = dp ? dp.charAt(0) : null

  const a = s ? s.startsWith('B') ? 'Plays both sides' : `${s}-side` : null
  const b = sp && !sp.startsWith('N') && sp !== s ? `${sp.toLowerCase()}-side` : null
  const c = d ? d === 'D' ? 'Defender' : `${d === 'S' ? 'Split ' : ''}Blocker` : null
  const e = dp && !dp.startsWith('N') && dp2 !== d ? `${dp.toLowerCase()}` : null

  if (a && c) {
    if (s.startsWith('B')) {
      x.push(c, a)
    } else {
      x.push(`${a} ${c}`)
    }
  } else {
    a && x.push(a)
    c && x.push(c)
  }
  if (b || e) {
    x[x.length - 1] = x[x.length - 1] += ` (prefers ${formatArray([b, e])})`
  }
  return x
}
