<template>
  <v-list-item @click="onClick">
    <v-list-item-icon>
      <v-img
        :src="team.logoUrl"
        max-height="50px"
        max-width="50px"
        contain
      ></v-img>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title v-if="showId">{{team.id}}</v-list-item-title>
      <v-list-item-title>
        {{team.name}}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: ['team', 'showId', 'noEmit'],
  computed: {
  },
  methods: {
    onClick () {
      if (!this.noEmit) {
        this.$emit('click', this.team)
      }
    }
  }
}
</script>

<style scoped>

</style>
