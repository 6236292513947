import DtoUpdate from '../DtoUpdate'

export default class StandingsGroup {
  name = 'All'
  teamIds = []
  type = 'all'
  published = false
  availableTeams = null
  locked = false
  summary = null

  constructor (dto) {
    this.update(dto)
  }

  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = []
    DtoUpdate(dto, this, exclude)
  }

  get dto () {
    return {
      name: this.name,
      teamIds: this.teamIds,
      type: this.type,
      published: this.published,
      locked: this.locked,
      summary: this.summary
    }
  }
}
