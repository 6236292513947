const OrderIndex = () => import('@/Pages/Order/Index.vue')

export default [
  {
    path: '/Order/Lookup',
    name: 'order-lookup',
    meta: { y: 5, x: 0 },
    component: OrderIndex
  },
  {
    path: '/Order/Details/:orderId',
    name: 'order-details',
    meta: { y: 5, x: 1 },
    component: OrderIndex,
    props: true
  }
]
