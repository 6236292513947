import endpoints from './endpoints'

export default class PostSDK {
  constructor (http) {
    this.http = http
  }

  tournament (dto) {
    return this.http.post(endpoints.tournament.base, dto)
  }

  division (dto) {
    return this.http.post(endpoints.division.base, dto)
  }

  day (dto) {
    return this.http.post(endpoints.day.base, dto)
  }

  rounds (dto, divisionId, applyToAll) {
    const url = `${endpoints.division.rounds(divisionId)}${applyToAll ? '?applyToAll=true' : ''}`
    return this.http.post(url, dto)
  }

  flights (dto, dayId) {
    return this.http.post(endpoints.day.flights(dayId), dto)
  }

  pools (dto) {
    return this.http.post(endpoints.pools.base, dto)
  }

  brackets (dto, dayId) {
    return this.http.post(endpoints.day.brackets(dayId), dto)
  }

  teams (dto, dayId) {
    return this.http.post(endpoints.round.teams(dayId), dto)
  }

  matches (dto, ttids, key, applyTo) {
    if (!Array.isArray(dto)) dto = [dto]
    const p = key ? `&key=${key}` : ''
    const a = applyTo ? `&applyTo=${applyTo}` : ''
    return this.http.post(`${endpoints.matches.base}?ttids=${!!ttids}${p}${a}`, dto)
  }

  matcheReset (id, c, t) {
    return this.http.post(`${endpoints.matches.base}/clearBracket/${id}?c=${c}&t=${t}`)
  }

  unplay (matchId, type) {
    return this.http.post(endpoints.matches.unplay(matchId, type))
  }

  game (dto) {
    return this.http.post(endpoints.game.base, dto)
  }

  images (type, id, image, sb) {
    const p = sb ? `?sb=${sb}` : ''
    const url = `${endpoints.images(type, id)}${p}`
    return this.http.post(url, image)
  }

  location (dto) {
    return this.http.post(endpoints.locations, dto)
  }

  logo (imageId, organizationId) {
    return this.http.post(endpoints.logo(imageId, organizationId))
  }

  series (dto) {
    return this.http.post(endpoints.series.base, dto)
  }

  notification (dto) {
    return this.http.post(endpoints.notifications.tournament, dto)
  }

  bidProgram (dto) {
    return this.http.post(endpoints.bids.program, dto)
  }

  bid (dto) {
    return this.http.post(endpoints.bids.base, dto)
  }

  bidUpload (dto, id) {
    return this.http.post(endpoints.bids.upload(id), dto)
  }

  bidAward (dto) {
    return this.http.post(`${endpoints.bids.base}/assign`, dto)
  }

  membership (username, dto) {
    return this.http.post(endpoints.membership.base(username), dto)
  }

  membershipPermit (username, dto) {
    return this.http.post(endpoints.membership.permit(username), dto)
  }

  playerProfile (dto, organizationId) {
    const url = organizationId ? `${endpoints.players.base}/organization/${organizationId}` : endpoints.players.base
    return this.http.post(url, dto)
  }

  admins (username, dto) {
    return this.http.post(endpoints.organization.admins(username), dto)
  }

  adminInvite (username, id) {
    return this.http.post(`/organization/${username}/admins/resend/${id}`)
  }

  finishRound (dto) {
    return this.http.post(`${endpoints.round.byId(dto.roundId)}/finish`, dto)
  }
}
