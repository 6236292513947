<template>
  <v-app id="vbl" :style="cssProps">
    <vbl-nav class="not-printable" v-if="!isAuth && !inIFrame"></vbl-nav>
    <v-main class="not-printable" :dark="theme.dark">
      <v-container class="fill-height" v-if="error && false">
        <v-row dense align="center">
          <v-col cols="8" offset="2">
            <v-row class="text-center">
              <v-col cols="12">
                <v-alert text typeof="error" value="true">
                  Welp... we shanked that one <v-icon>mood_bad</v-icon><br>(Error: {{error}})
                </v-alert>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <transition :name="mainTransition" v-else>
        <router-view :key="myKey"></router-view>
      </transition>
    </v-main>
    <!-- p1440 FOOTER -->
    <p1440-footer
      v-if="subdomain === 'p1440' && !isAuth"
      :liveUpdateStatus="liveUpdateStatus"
      :altView="altView"
      :toggleView="toggleView"
      :inIFrame="inIFrame"
    ></p1440-footer>
    <!-- FOOTER -->
    <vbl-footer v-else-if="!isAuth"
      :liveUpdateStatus="liveUpdateStatus"
      :altView="altView"
      :toggleView="toggleView"
      :inIFrame="inIFrame"
    ></vbl-footer>
    <!-- LOADING -->
    <loading-dialog :showMe="isLoading" class="not-printable"></loading-dialog>
    <!-- PRINTABLES -->
    <div class="printable" v-if="printable && pool && view.adminAndRefs">
      <printable-pool
        :pool="pool"
        :teamColors="teamColors"></printable-pool>
    </div>
    <div class="printable" v-else-if="printable && pools && view.adminAndRefs">
      <printable-pool
        v-for="pool in pools"
        :key="`print-pool-${pool.id}`"
        :pool="pool"
        :teamColors="teamColors"></printable-pool>
    </div>
    <div v-if="regData" class="printable">
      <registration-summary
        :tournament="tournament"
        :forprint="true"
        :registrationData="regData"
      ></registration-summary>
    </div>
    <v-snackbar
      v-if="theme.isPreview && $route.name !== 'organization-theme'"
      v-model="snack"
      :timeout="0"
      vertical
    >
      You are currently previewing this theme
      <v-btn color="color3" text @click.stop="$router.push({name: 'organization-theme', params: {username: theme.username}})">Back to theme editor</v-btn>
    </v-snackbar>
    <v-overlay :value="overlay" color="white" opacity=".9">
      <v-container fluid>
        <v-row dense>
          <v-col cols="12" class="title text-center color3--text">
            <h2 class="color3--text">{{summaries.length > 1 ? `${summaries.length} Events ` : ''}}Happening Today:</h2>
            <div v-for="s in summaries" :key="s.id" class="my-3">
              <tournament-card
                :event="s"
                @event-clicked="overlay=false"
              ></tournament-card>
              <v-card width="375px" class="pa-2" light v-if="false">
                <fit-text>{{s.name}}</fit-text>
                <div class="caption">{{s.locations | formatArray}}</div>
                <v-card-text>
                  <v-btn :to="`/tournament/${s.id}/teams`" text>Jump to </v-btn>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-btn color="color3" text @click.stop="overlay=false">continue to site</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>
  </v-app>
</template>

<script>
import * as actions from './store/ActionTypes'
import * as mutations from './store/MutationTypes'
import { mapGetters, mapActions } from 'vuex'
import LiveUpdateConnectionManager from './Mixins/LiveUpdateConnectionManager'
import TournamentMixin from './Mixins/TournamentMixin'
import moment from 'moment'
import Nav from './Nav/Index.vue'
import Summary from '@/classes/TournamentSummary'
const LoadingDialog = () => import('@/components/Utils/LoadingDialog.vue')
// import IndoorClubWizard from './components/Organization/IndoorClubWizard.vue'
const PrintablePool = () => import('./components/Tournament/Pools/PoolSheet/Printable.vue')
const RegistrationSummary = () => import('./components/Tournament/Registration/RegistrationSummary.vue')
const p1440Footer = () => import('@/Footers/p1440.vue')
const VblFooter = () => import('@/Footers/VBL.vue')
const TournamentCard = () => import('@/components/Tournament/HappeningNowCard.vue')

export default {
  mixins: [LiveUpdateConnectionManager, TournamentMixin],
  data: () => ({
    buttons: false,
    snack: true,
    summaries: [],
    overlay: false,
    showNoCheck: false
  }),
  computed: {
    ...mapGetters([
      'isLoading',
      'error',
      'printPools',
      'theme',
      'view',
      'publicView',
      'printable',
      'subdomain',
      'inIFrame',
      'regData',
      'user',
      'mainTransition',
      'isLiveScoring',
      'matchProps',
      'tournament'
    ]),
    support () {
      return this.theme.email || 'Support@VolleyballLife.com'
    },
    altView () {
      return this.publicView ? 'admin' : 'public'
    },
    showViewAs () {
      return process.env.NODE_ENV !== 'production' && this.user && this.user.id === 1
    },
    break () {
      return this.$vuetify.breakpoint
    },
    cssProps () {
      return {
        '--color1': this.$vuetify.theme.color1,
        '--color1Text': this.$vuetify.theme.color1Text,
        '--color2': this.$vuetify.theme.color2,
        '--color2Text': this.$vuetify.theme.color2Text,
        '--color3': this.$vuetify.theme.color3,
        '--color3Text': this.$vuetify.theme.color3Text
      }
    },
    isInIFrame () {
      return this.$route.query.iframe // && this.$route.query.iframe === this.subdomain
    },
    isInIFrame2 () {
      return window.location !== window.parent.location
    },
    subdomainX () {
      return this.subdomain
    },
    isAuth () {
      return this.$route.name === 'auth'
    },
    authField () {
      const el = document.getElementById('returnUrl')
      return el && el.value
    },
    myKey () {
      return this.divisionId
    },
    currentRoute () {
      return this.$route.fullPath
    },
    printable () {
      return this.tournament && this.tournament.printable
    }
  },
  components: {
    'vbl-nav': Nav,
    LoadingDialog,
    // 'indoor-club-wizard': IndoorClubWizard,
    PrintablePool,
    RegistrationSummary,
    p1440Footer,
    TournamentCard,
    VblFooter
  },
  methods: {
    ...mapActions(['getOidcUser']),
    onEventClicked (e) {
      console.log(e)
    },
    initFB () {
      (function (d, s, id) {
        var js
        var fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s); js.id = id
        js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v5.0&appId=710675772455419&autoLogAppEvents=1'
        fjs.parentNode.insertBefore(js, fjs)
      }(document, 'script', 'facebook-jssdk'))
    },
    cleanStore () {
      const oldCart = this.$store.state.Cart.cart
      if (oldCart) {
        console.log('Cleaning Cart')
        if (oldCart.items.length) {
          oldCart.items.forEach(item => {
            console.log(' ...Moving Item')
            this.$store.commit(mutations.ADD_CART_ITEM, item)
          })
          this.$store.state.Cart.cart.items = []
        }
        // delete this.$store.state.cart
      }
    },
    toggleView () {
      this.$store.commit(mutations.TOGGLE_VIEW)
    },
    checkAuth () {
      if (this.authField && this.authField !== '{returnUrl}' && !this.isAuth) {
        this.$router.push({ name: 'auth' })
      }
    },
    checkScoring () {
      if (this.isLiveScoring) {
        if (this.$route.fullPath !== this.matchProps.path) {
          this.$store.dispatch(actions.KEY_CHECK)
            .then((r) => {
              if (r) this.$router.push(this.matchProps.path)
            })
        }
      } else if (!this.showNoCheck &&
        this.subdomain &&
        this.subdomain !== 'vbl' &&
          this.theme.props &&
          !this.theme.props.includes('noShowNow') &&
          !window.location.pathname.startsWith('/tournament') &&
          !window.location.pathname.startsWith('/league') &&
          !window.location.pathname.startsWith('/player') &&
          !window.location.pathname.startsWith('/court') &&
          !window.location.pathname.startsWith('/member') &&
          ['home', 'filtered-tournaments', null].includes(this.$route.name)) {
        this.checkNow()
      }
    },
    checkNow () {
      this.showNoCheck = true
      const query = `filter=now-${moment().local().format('YYYY-MM-DD')}`
      // const query = 'filter=now-2022-05-28'
      this.$VBL.tournament.getAllSummaries(query)
        .then((response) => {
          this.summaries = response.data.map(s => new Summary(s))
          console.log('here')
          this.overlay = this.summaries.length > 0 && this.summaries.length < 4
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    testClick () {
      window._mfq.push(function (mf) {
        console.log('Mouseflow is recording: ' + mf.isRecording())
      })
    },
    mfCheck () {
      if (this.user && this.user.vbl) {
        this.$mf.push('stop')
      }
    }
  },
  watch: {
    user: function (val) {
      if (!val && this.$route.meta.requiresUser) {
        this.$router.push({ name: 'home' })
      }
      // this.mfCheck()
    },
    currentRoute: 'checkScoring'
  },
  mounted () {
    // this.mfCheck()
    this.checkScoring()
  },
  async created () {
    this.cleanStore()
    this.checkAuth()
    if (this.subdomain && this.subdomain !== 'vbl' && this.theme.username === 'vbl') {
      window.location.href = window.location.href.replace(`//${this.subdomain}.`, '//')
    }
    // const iFrame = !!this.$route.query.iframe // && this.$route.query.iframe === this.subdomain
    const iFrame = window.location.href.includes('iframe=true')
    if (window.location.href.includes('killLiveScoring')) {
      this.$store.commit(mutations.CLEAR_MATCH_PROPS)
      const q = JSON.parse(JSON.stringify(this.$route.query))
      delete q.killLiveScoring
      this.$router.replace({ query: q })
    }
    this.$store.commit(mutations.SET_IFRAME, iFrame)
    this.$store.commit(mutations.LOADING_CLEAR)
    this.initFB()
    this.$store.commit(mutations.SET_ERROR, null)
    await this.$store.dispatch(actions.USER_CHECK, true)
    this.$store.dispatch(actions.LIVE_CONNECT)
    this.$store.commit(mutations.CLEAN_TOKENS)
    const that = this
    document.addEventListener('visibilitychange', function () {
      console.log(document.visibilityState)
      document.visibilityState === 'visible' && that.$store.dispatch(actions.USER_CHECK2, true)
    })
  }
}
</script>

<style>
a {
  color: var(--v-color3-base) !important;
}
.fab-container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100
}
.shift-left {
  margin-right: 60px
}
.grade {
  background: rgba(225,218,208,1);
  background: -moz-linear-gradient(top, rgba(225,218,208,1) 0%, rgba(245,242,237,1) 54%, rgba(255,255,255,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(225,218,208,1)), color-stop(54%, rgba(245,242,237,1)), color-stop(100%, rgba(255,255,255,1)));
  background: -webkit-linear-gradient(top, rgba(225,218,208,1) 0%, rgba(245,242,237,1) 54%, rgba(255,255,255,1) 100%);
  background: -o-linear-gradient(top, rgba(225,218,208,1) 0%, rgba(245,242,237,1) 54%, rgba(255,255,255,1) 100%);
  background: -ms-linear-gradient(top, rgba(225,218,208,1) 0%, rgba(245,242,237,1) 54%, rgba(255,255,255,1) 100%);
  background: linear-gradient(to bottom, rgba(225,218,208,1) 0%, rgba(245,242,237,1) 54%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e1dad0', endColorstr='#ffffff', GradientType=0 );
}
.border-right {
  border-right: 1px solid rgba(0,0,0,0.12);
}
@page {
  size:  auto;   /* auto is the initial value */
  margin: 0mm;  /* this affects the margin in the printer settings */
}
@media print {
  .not-printable,
  button.not-printable,
  .leader-line,
  .v-overlay {
    display: none !important;
  }
  .printable {
    width: 8.5in;
    /* font-size: 11pt; */
  }
}
@media screen {
  /*
  .fa, .fab, .fal, .far, .fas {
     display: inline-flex !important
  }
  */
  .printable {
    display: none;
  }
}
.clickable {
  cursor: pointer;
}
.flexcard {
  display: flex !important;
  flex-direction: column;
}
.table-logo {
  border: none !important;
  vertical-align: middle;
  max-height: 40px;
  max-width: 120px;
}
.avpNext {
  background-color: #fcd538 !important;
  border-color: #fcd538 !important;
  border-radius: 7px;
}
.xsmall {
  font-size: x-small !important;
}
.cap {
  font-size: x-small !important;
  line-height: initial;
}
.xsmall.v-btn {
  height: 24px !important;
  min-width: auto !important;
}
.xsmall.v-btn.v-btn--icon,
.xsmall.v-btn.v-btn--floating {
  width: 24px !important;
}
.xsmall .v-icon {
  font-size: small !important;
}
.block {
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover;
  content: ' ';
  opacity: 0.62;
  position: absolute;
  z-index: 1;
  background-color: white;
}
.v-btn__content .v-icon {
  transition: 0ms
}
.p1440Back {
    background-color: #ec4640;
    background-image: -webkit-linear-gradient( 0deg, #ec4640 0%, #510b32 100%);
    background-image: -moz-linear-gradient( 0deg, #ec4640 0%, #510b32 100%);
    background-image: -o-linear-gradient( 0deg, #ec4640 0%, #510b32 100%);
    background-image: -ms-linear-gradient( 0deg, #ec4640 0%, #510b32 100%);
    background-image: linear-gradient( 0deg, #ec4640 0%, #510b32 100%);
}
.p1440Back2 {
    background-color: #ec4640;
    background-image: -webkit-linear-gradient( 0deg, #510b32 0%, #c72c41 100%);
    background-image: -moz-linear-gradient( 0deg, #510b32 0%, #c72c41 100%);
    background-image: -o-linear-gradient( 0deg, #510b32 0%, #c72c41 100%);
    background-image: -ms-linear-gradient( 0deg, #510b32 0%, #c72c41 100%);
    background-image: linear-gradient( 0deg, #510b32 0%, #c72c41 100%);
}
.v-data-table--dense td,
.v-data-table--dense th {
  padding: 0 6px !important;
  white-space: nowrap !important;
}
.blm {
  color: rgb(255,255,255,0.4);
  background-color: black
}
.v-data-table__mobile-row{
  align-items: flex-start !important;
}
.v-slide-group .v-icon--disabled {
  opacity: 0 !important;
}
.f12 {
  font-size: 12px;
}
.v-application--is-ltr .v-data-footer__pagination {
    margin: 0 13px 0 13px;
}
.v-application--is-ltr .v-data-footer__select .v-select {
    margin: 13px 0 13px 13px;
}
</style>
