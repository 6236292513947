import Day from './TournamentDay'
import RegWindow from './TournamentRegistrationWindow'
import moment from 'moment'
import uniq from 'lodash.uniq'
import * as BracketTypes from './BracketTypes'
// import { Promise } from 'es6-promise'
import firstBy from 'thenby'
import flatten from '../helpers/ArrayFlatten'
import DtoUpdate from './DtoUpdate'
import Team from './Team'
import GroupedStandings from '@/classes/Standings/GroupedStandings'

export default class TournamentDivision {
  constructor (sdk, dto) {
    this.sdk = sdk
    this.ageType = null
    this.days = []
    this.division = null
    this._dtRefundCutoff = null
    this.emailNote = null
    this.gender = null
    this.id = 0
    this.location = null
    this.maxTeams = null
    this.minTeams = null
    this.numAllowedOnRoster = 2
    this.numOfPlayers = 2
    this.registrationFields = {
      id: 0,
      fields: ['cityState', 'gradYear', 'club'],
      requiredFields: ['phone', 'email', 'dob']
    }
    this.registrationWindows = [new RegWindow(this.sdk)]
    this.sanctioningBodyId = null
    this.teams = []
    this._teamCount = 0
    this.tournamentDirectorUserId = null

    this.playoffFormat = BracketTypes.SINGLE_ELIM.type
    this.validFinishes = null
    this.poolPlay = true
    this.consolationStyle = 'Participant'
    this.playoffTeams = null

    this.showTeams = false
    this.disableRegistration = false
    this.waitlist = true

    this.loading = false
    this._hydrated = false
    this.dtUpdated = null
    this.complete = false
    this.canceled = false
    this.i_repField = null
    this.repFieldOG = null
    this.bidProgram = null
    this._Name = null
    this.props = []
    this.info = null
    this.standings = []
    this.sportId = 1
    this.surfaceId = 1

    if (dto) {
      this.update(dto)
    }
  }

  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = ['days', 'registrationWindows', 'teams']
    DtoUpdate(dto, this, exclude)

    if (!this.sportId) this.sportId = 1
    if (!this.surfaceId) this.surfaceId = 1
    if (dto.teams) {
      dto.teams.forEach(team => {
        const t = this.teams.find(f => f.id === team.id)
        if (t) {
          t.update(team)
        } else {
          this.teams.push(new Team(this.sdk, team))
        }
      })
    }
    this.teams.forEach(t => { t.starters = this.numOfPlayers })

    if (dto.days) {
      this.days = this.days.filter(f => f.id)
      dto.days.forEach(day => {
        let d = this.days.find(f => f.id === day.id)
        if (!d) {
          d = new Day(this.sdk, day)
          this.days.push(d)
        }
        d.update(day)
      })
      this.days.sort(firstBy('number'))
    }

    this.days.forEach(d => {
      d._teams = this.activeTeams
    })

    if (dto.registrationWindows) this.registrationWindows = dto.registrationWindows.map(r => new RegWindow(this.sdk, r))
    this.registrationWindows.sort(firstBy('unix'))
    this.registrationFields && this.registrationFields.fields.sort()
    this.registrationFields && this.registrationFields.requiredFields.sort()
    this.initStandings()

    if (this.props.includes('fivbPerformaance')) {
      this.pools.forEach(p => {
        p.setFivbPerformaance(true)
      })
    }

    this.dtUpdated = Date.now()
  }

  initStandings () {
    const standings = this.info && JSON.parse(this.info)
    if (standings) {
      standings.forEach((s, i) => {
        if (this.standings.length > i) {
          this.standings[i].update(JSON.stringify(s))
        } else {
          this.standings.push(new GroupedStandings(JSON.stringify(s), this.rounds))
        }
      })
    } else {
      this.standings = []
    }
  }

  resetRound (dayId) {
    const d = this.days.find(day => {
      return day.id === dayId
    })
    if (d) {
      d.reset()
      d._teams = this.teams
    }
  }

  updateFromTemplate (template) {
    console.log('updateFromTemplate')
    this.location = template.location
    this.registrationFields.fields = template.registrationFields.fields
    this.registrationFields.requiredFields = template.registrationFields.requiredFields
    this.dtRefundCutoff = template.dtRefundCutoff
    template.days.forEach((d, i) => {
      this.days[i].updateFromTemplate(d)
    })
    template.registrationWindows.forEach((r, i) => {
      this.registrationWindows[i].updateFromTemplate(r)
    })
  }

  trackResultChanges () {
    this.currentResults = JSON.stringify(this.resultsDto)
  }

  hydrate (sdk) {
    if (this.hydrated) return
    return new Promise((resolve, reject) => {
      this.loading = true
      sdk.division.hydrate(this.id)
        .then(response => {
          this.update(response.data)
          this.hydrated = true
          this.loading = false
          resolve()
        })
        .catch(err => {
          this.loading = false
          reject(err)
        })
    })
  }

  patch (dto) {
    dto.id = this.id
    return this.sdk.division.patch(dto)
  }

  getTeams (sdk) {
    return new Promise((resolve, reject) => {
      this.loading = true
      sdk.division.getTeams(this.id)
        .then(response => {
          this.teams = response.data
          this.loading = false
          resolve()
        })
        .catch(err => {
          this.loading = false
          reject(err)
        })
    })
  }

  saveSeeds () {
    return this.sdk.division.patchTeams(this.id, this.seedsDto)
  }

  removeDay (i) {
    this.days.splice(i, 1)
  }

  addWindow () {
    this.registrationWindows.push(new RegWindow(this.sdk))
  }

  deleteWindow (i) {
    this.registrationWindows.splice(i, 1)
  }

  getTimeline (teamId, isLeague, crossCheck) {
    const team = this.activeTeams.find(t => t.id === teamId)
    if (!team && !crossCheck) return null
    return {
      team: team,
      division: {
        id: this.id,
        adults: this.ageType.id === 2,
        name: this.name,
        numOfPlayers: this.numOfPlayers,
        showTeams: this.showTeams,
        isLeague: isLeague
      },
      rounds: this.publicRounds.map(day => day.getTimeline(teamId, isLeague, this.location.offset, crossCheck)).filter(f => f && f.title)
    }
  }

  updateMatchMeta (tournament) {
    this.days.forEach(d => d.updateMatchMeta(tournament, this))
  }

  getMatchMeta (tournament) {
    return flatten(this.days.map(d => d.getMatchMeta(tournament, this)))
  }

  // getter-setters
  set hydrated (val) {
    if (val && !this._hydrated) this._hydrated = true
  }

  get hydrated () {
    return this._hydrated
  }

  set dtRefundCutoff (val) {
    this._dtRefundCutoff = val ? moment.utc(val).format('YYYY-MM-DD[T]HH:mm[Z]') : null
  }

  get dtRefundCutoff () {
    return this._dtRefundCutoff ? moment.utc(this._dtRefundCutoff) : null
  }

  get teamCount () {
    return this.teams.length > 0 ? this.activeTeams.length : this._teamCount
  }

  set teamCount (val) {
    this._teamCount = val
  }

  get poolCount () {
    return this.pools.length > 0 ? this.pools.length : this._poolCount
  }

  get poolTypes () {
    return this.pools.length > 0 && uniq(this.pools.map(m => m.teams.length)).sort().join('/')
  }

  set poolCount (val) {
    this._poolCount = val
  }

  get repField () {
    return this.isNcaa ? 'committedSchool' : this.i_repField || (this.isAdults ? 'cityState' : this.isHS ? 'hs' : 'club')
  }

  set repField (val) {
    this.i_repField = val
  }

  get waitlistSort () {
    const p = this.props.find(f => f.startsWith('waitlist-sort-'))
    return p ? p.replace('waitlist-sort-', '') : 'unix'
  }

  set waitlistSort (val) {
    this.props = this.props.filter(f => !f.startsWith('waitlist-sort-'))
    this.props.push(`waitlist-sort-${val}`)
  }

  // getters
  get isPublic () {
    return !this.canceled && !this.isXrefs && !this.hiddenDivision
  }

  get repFieldLabel () {
    return this.isAdults ? 'Representing' : this.repField === 'hs' ? 'School' : 'Club'
  }

  get activeTeams () {
    return this.teams.filter(f => !f.isDeleted && !f.waitlist)
  }

  get activeTeamsIds () {
    return this.activeTeams.map(m => m.id)
  }

  get waitlistTeams () {
    return this.teams.filter(f => !f.isDeleted && f.waitlist)
  }

  get checkinComplete () {
    return this.activeTeams.filter(f => !f.checkedIn).length === 0
  }

  get looksComplete () {
    if (this._hydrated) {
      const a = this.activeTeams.length > 0
      const b = !this.activeTeams.find(f => !f.finish)
      const first = this.activeTeams.filter(f => f.finish === 1)
      const c = this.bracketRounds.length > 0
      const d = this.bracketRounds.find(f => !f.bracket.isComplete)
      // const complete = (a && b && first.length === 1) || (c && !d)
      const complete = c ? !d : (a && b && first.length === 1)

      return !this.complete && complete
    }
    return false
  }

  get dto () {
    // this.registrationFields.fields
    // this.registrationFields.requiredFields
    return {
      ageType: this.ageType,
      days: this.days.map((d) => {
        return d.dto
      }),
      division: this.division,
      dtRefundCutoff: this._dtRefundCutoff,
      emailNote: this.emailNote,
      gender: this.gender,
      id: this.id > 0 ? this.id : 0,
      location: this.location,
      maxTeams: this.maxTeams,
      minTeams: this.minTeams,
      numAllowedOnRoster: this.numAllowedOnRoster,
      numOfPlayers: this.numOfPlayers,
      registrationFields: this.registrationFields,
      registrationWindows: this.registrationWindows.map((w) => {
        return w.dto
      }),
      sanctioningBodyId: this.sanctioningBodyId,
      tournamentDirectorUserId: this.tournamentDirectorUserId,
      canceled: this.canceled,
      complete: this.complete,
      disableRegistration: this.disableRegistration,
      showTeams: this.showTeams,
      bidProgram: this.bidProgram ? { id: this.bidProgram.id } : null,
      _Name: this._Name,
      repField: this.repField,
      waitlist: this.waitlist,
      props: this.props,
      info: this.info,
      sportId: this.sportId,
      surfaceId: this.surfaceId
    }
  }

  get resultsDto () {
    return this.teams.map((team) => {
      return {
        id: team.id,
        finish: team.finish || 999999999,
        iFinish: team.iFinish
      }
    })
  }

  get seedsDto () {
    return this.teams.filter(t => !t.isDeleted).map(team => {
      return {
        id: team.id,
        seed: team.seed,
        players: team.players
      }
    })
  }

  get registrationFieldsCompareString () {
    return JSON.stringify({
      fields: this.registrationFields.fields.sort(),
      requiredFields: this.registrationFields.requiredFields.sort()
    })
  }

  getFieldList (field) {
    return this.registrationFields.requiredFields.includes(field) ? 'requiredFields'
      : this.registrationFields.fields.includes(field) ? 'fields' : false
  }

  get allFields () {
    return this.registrationFields.fields.concat(this.registrationFields.requiredFields)
  }

  get teamList () {
    const list = []
    this.activeTeams.forEach((t) => {
      const team = {
        Division: this.name,
        Seed: t.seed,
        'Team Name': t.name,
        'AAU Points': t.aauSeedingPoints,
        'AVP Points': t.avpSeedingPoints
      }
      if (t.players) {
        for (let i = 0; i < t.players.length; i++) {
          team[`Player${i + 1} Name`] = t.players[i].name
          team[`Player${i + 1} AAU Points`] = t.players[i].aauSeedingPoints
          team[`Player${i + 1} AVP Points`] = t.players[i].avpSeedingPoints
          team[`Player${i + 1} ProfileId`] = t.players[i].playerProfileId
        }
      }
      list.push(team)
    })
    return list
  }

  get resultList () {
    return this.teams.map((team) => {
      return {
        finish: team.finish < 999 ? team.finish : '',
        _finish: team.finish ? team.finish : 999,
        name: team.name,
        xsName: team.name.replace('/', ' '),
        points: team.points
      }
    })
  }

  get formatN () {
    return this.props.includes('showFormat') ? ` ${this.numOfPlayers}'s` : ''
  }

  get format () {
    if (this.props.includes('showFormat')) {
      const map = {
        2: ' Doubles',
        3: ' Triples',
        4: ' Quads'
      }
      return map[this.numOfPlayers]
    }
    return ''
  }

  get name () {
    return this._Name || (
      this.isTrainingSession ? this.startDate.format('dddd, MMMM Do, YYYY')
        : this.isCollegeCoaches ? 'College Coaches'
          : this.isCoaches ? 'Coaches'
            : (this.gender && this.division ? `${this.gender.name} ${this.division.name}${this.format}` : null)
    )
  }

  get key () {
    return this.id > 0 ? this.id : this.name
  }

  get dates () {
    return this.days.map(d => d._date)
  }

  get uniqueDays () {
    return uniq(this.dates)
  }

  get dayCount () {
    return this.uniqueDays.length
  }

  get startDate () {
    if (this.days.length === 0) return null
    return moment.min(this.days.map(d => moment(d.date)))
  }

  get endDate () {
    if (this.days.length === 0) return null
    return moment.max(this.days.map(d => moment(d.date)))
  }

  get currentEntryFee () {
    return this.currentRegistrationWindow ? {
      amount: this.currentRegistrationWindow.fee,
      display: this.currentRegistrationWindow.feeString,
      perTeam: this.currentRegistrationWindow.feeIsPerTeam
    } : {
      amount: this.lastRegistrationWindow.fee,
      display: this.lastRegistrationWindow.feeString,
      perTeam: this.lastRegistrationWindow.feeIsPerTeam
    }
  }

  get dOrder () {
    return this.division ? this.division.order : 0
  }

  get propOrder () {
    const prop = this.props.find(f => f.startsWith('order-'))
    if (!prop) return 99
    return +prop.replace('order-', '')
  }

  get gName () {
    return this.gender.name
  }

  get divisionsString () {
    return this.regOpen && this.isFull && !this.acceptanceOnly ? `${this.name} - Waitlist` : this.name
  }

  get currentRegistrationWindow () {
    if (this.registrationWindows.length === 0) return null
    if (!(this.location && this.location.offset)) return null
    this.registrationWindows.forEach((r) => r.setOffset(this.location.offset, this.location.daylightOffset))

    return this.registrationWindows.find((w) => { return w.isCurrent })
  }

  get currentWindows () {
    if (this.registrationWindows.length === 0) return null
    if (this.location) {
      this.registrationWindows.forEach((r) => r.setOffset(this.location.offset, this.location.daylightOffset))
    }

    return this.registrationWindows.filter(f => f.isCurrent)
  }

  get lastRegistrationWindow () {
    if (this.registrationWindows.length === 0) return null
    return this.registrationWindows[this.registrationWindows.length - 1]
  }

  get addOn () {
    return this.currentRegistrationWindow && this.currentRegistrationWindow.addOn
  }

  get isAdults () {
    return this.division && this.division.ageTypeId === 2
  }

  get isCoed () {
    return this.gender && [2, 5].includes(this.gender.id)
  }

  get isDoubles () {
    return this.numOfPlayers === 2
  }

  get isSingles () {
    return this.numOfPlayers === 1
  }

  get isHS () {
    return (this.props && this.props.includes('scholastic')) || this.ibvl
  }

  get isShowcase () {
    return this.props && this.props.includes('isShowcase')
  }

  get isBeach () {
    return this.surfaceId === 1
  }

  get isGrass () {
    return this.surfaceId === 2
  }

  get isAVP () {
    return this.sanctioningBodyId && this.sanctioningBodyId.startsWith('AVP')
  }

  get isAAU () {
    return this.sanctioningBodyId && this.sanctioningBodyId === 'AAU'
  }

  get isUSAV () {
    return this.sanctioningBodyId && this.sanctioningBodyId === 'USAV'
  }

  get isP1440 () {
    return this.sanctioningBodyId && this.sanctioningBodyId.startsWith('p14')
  }

  get isBVNE () {
    return this.sanctioningBodyId && this.sanctioningBodyId.startsWith('BVNE')
  }

  get isBVCA () {
    return this.sanctioningBodyId && this.sanctioningBodyId.startsWith('BVCA')
  }

  get showCommit () {
    return this.isBVNE || this.props.includes('showCommit')
  }

  get isCoaches () {
    return this.props && this.props.includes('coach')
  }

  get isCollegeCoaches () {
    return this.props && this.props.includes('coach-college')
  }

  get isNcaa () {
    return this.props && this.props.includes('ncaa')
  }

  get isSpectators () {
    return this.props && this.props.includes('spectators')
  }

  get isXrefs () {
    return this.props && this.props.includes('externalRefs')
  }

  get hiddenDivision () {
    return this.props && this.props.includes('hidden')
  }

  get isCS () {
    return this.isSpectators || this.isCoaches || this.isCollegeCoaches || this.isXrefs || this.hiddenDivision
  }

  get isCompetitive () {
    return this.isLeague || this.isTournament
  }

  get coachProps () {
    var p = this.props && this.props.find(f => f.startsWith('coach-team'))
    if (p) {
      const a = p.split('-')
      const m = a.length === 3 ? +a.pop() : null
      return {
        team: true,
        max: m
      }
    }
    return null
  }

  get isTournament () {
    return !this.isCamp && !this.isClinic && !this.isTryout && !this.isYouthProgram && !this.isTrainingSession
  }

  get isCamp () {
    return this.props && this.props.includes('isCamp')
  }

  get isClinic () {
    return this.props && this.props.includes('isClinic')
  }

  get isTryout () {
    return this.props && this.props.includes('isTryout')
  }

  get isClubVClub () {
    return (this.props && this.props.includes('clubVClub')) || (this.isDuals && !this.isHS)
  }

  get teamReg () {
    return (this.props && this.props.includes('teamReg')) || this.lineups || this.isNcaa || this.orgRegFlow
  }

  get orgRegFlow () {
    return this.isNcaa || this.isClubVClub || this.is('clubBids') || this.isHS
  }

  get allowZeroPlayers () {
    return this.props && this.props.includes('allowZeroPlayers')
  }

  get allowTbd () {
    return this.props && this.props.includes('allowTbd')
  }

  get isLeague () {
    return this.props && this.props.includes('isLeague')
  }

  get isYouthProgram () {
    return this.props && this.props.includes('isYouthProgram')
  }

  get isTrainingSession () {
    return this.props && this.props.includes('isTrainingSession')
  }

  get divisionRequired () {
    return this.isTournament || this.isLeague || this.isTryout || this.isCS
  }

  get eventType () {
    return this.isTournament ? 'Tournament'
      : this.isLeague ? 'League'
        : this.isCamp ? 'Camp'
          : this.isClinic ? 'Clinic'
            : this.isTryout ? 'Tryout'
              : this.isTrainingSession ? 'Training Session'
                : this.isYouthProgram ? 'Youth Program'
                  : '???'
  }

  get teamLabel () {
    if (this.isCoaches) return 'Coaches'
    if (this.isXrefs) return 'Refs'
    if (this.isSingles) {
      var a = this.teams.find(f => f.players.length > 1)
      return a ? 'Teams' : 'Players'
    }
    if (this.isNcaa || this.isClubVClub) {
      return this.isDuals ? 'Squads' : 'Pairs'
    }
    return 'Teams'
  }

  get teamLabelSingle () {
    if (this.isCoaches) return 'Coach'
    if (this.isXrefs) return 'Ref'
    if (this.isSingles) {
      var a = this.teams.find(f => f.players.length > 1)
      return a ? 'Team' : 'Player'
    }
    if (this.isNcaa) {
      return this.isDuals ? 'Squad' : 'Pair'
    }
    return 'Team'
  }

  get participantType () {
    return (this.isTournament || this.isLeague) ? 'Team' : this.isCamp ? 'Participant' : this.isClinic ? 'Participant' : 'Player'
  }

  get personType () {
    return (this.isTournament || this.isLeague) ? 'Player' : this.isCamp ? 'Participant' : this.isClinic ? 'Participant' : 'Player'
  }

  get regOpen () {
    return !!this.currentRegistrationWindow && !this.disableRegistration
  }

  get regClosed () {
    return !this.regOpen
  }

  get isFull () {
    if (this.maxTeams === 0 || this.waitlistTeams.length > 0) return true
    return this.maxTeams && this.teamCount >= this.maxTeams
  }

  get hideWaitlist () {
    return this.props && this.props.includes('hideWaitlist')
  }

  get refundsOpen () {
    return this._dtRefundCutoff && this.location && moment(this._dtRefundCutoff.replace('Z', this.location.offset)).isAfter()
  }

  get teamSort () {
    return this.resultsLocked ? 'finish' : this.seeded ? 'seed' : this.isAVP ? 'avpSeedingPoints' : 'aauSeedingPoints'
  }

  get isSingleBracket () {
    return this.playoffFormat === BracketTypes.SINGLE_ELIM.type || this.playoffFormat === BracketTypes.SINGLE_ELIM_WITH_4TH.type
  }

  get unFinishedTeams () {
    return this.activeTeams.filter(team => !team.finish)
  }

  get finishedTeams () {
    return this.activeTeams.filter(team => team.finish)
  }

  get unSeededTeams () {
    return this.activeTeams.filter(team => !team.seed)
  }

  get seededTeams () {
    return this.activeTeams.filter(team => team.seed)
  }

  get isFullySeeded () {
    return this.unSeededTeams.length === 0
  }

  get sortedTeams () {
    return this.teams.sort(
      firstBy('finish')
        .thenBy('seed')
    )
  }

  get isAdvanced () {
    return this.days.length > 1
  }

  get flights () {
    return flatten(
      this.days.map(day => {
        return day.flights
      })
    )
  }

  get pools () {
    return flatten(
      this.days.map(day => {
        return day.flights.map(flight => {
          return flight.pools
        })
      })
    )
  }

  get rounds () {
    return this.days
  }

  get publicRounds () {
    return this.days.filter(d => d.published)
  }

  get leaguePlayRounds () {
    return this.rounds.filter(f => f.poolPlay)
  }

  get allBracketRounds () {
    return this.rounds.filter(f => f.bracketPlay && f.bracket && f.bracket.champions === 1)
  }

  getTournamentStyleRounds (props) {
    if (!props) return []
    var a = this.rounds.filter(f => props.rounds && props.rounds[f.id] && props.rounds[f.id].tournamentStyle)
    return a
  }

  get bracketRounds () {
    return this.publicRounds.filter(f => f.bracketPlay && f.bracket && f.bracket.champions === 1)
  }

  get bracketRoundsStarted () {
    return this.publicRounds.filter(f => f.bracketPlay && f.bracket && f.bracket.winnersStatus.started)
  }

  getBracketRound (teamId) {
    return this.bracketRounds.find(f => f.teamsIds.includes(teamId))
  }

  get hasMatches () {
    return this.days.findIndex(f => f.hasMatches) > -1
  }

  get matches () {
    return flatten(this.days.map(day => {
      return day.flights.map(flight => {
        return flight.pools.map(pool => {
          return pool.matches
        })
      })
    }))
  }

  get poolCourts () {
    return [...new Set(this.matches.map(m => m.court))].filter(f => !!f)
  }

  get completeMatches () {
    return this.matches.filter(m => m.complete)
  }

  get matchesSummary () {
    return {
      total: this.matches.length,
      complete: this.completeMatches.length,
      remaining: this.matches.length - this.completeMatches.length
    }
  }

  get games () {
    return flatten(this.days.map(day => {
      return day.flights.map(flight => {
        return flight.pools.map(pool => {
          return pool.matches.map(match => {
            return match.games
          })
        })
      })
    }))
  }

  get registrationCompareString () {
    return JSON.stringify(this.registrationWindows.map(r => r.compareString))
  }

  get windows () {
    this.registrationWindows.length && this.location && this.location.offset && this.registrationWindows.forEach((r) => r.setOffset(this.location.offset, this.location.daylightOffset))
    return this.registrationWindows
  }

  get resultsGrouped () {
    const groups = []
    let i = 0
    this.teamResults.forEach(r => {
      const multi = this.bracketRounds.length > 1
      const gName = multi ? r.elim : this.name
      const finish = multi ? r.finish : r.iFinish < 999999 ? r.iFinish : r.finish
      let group = groups.find(f => f.name === gName)
      if (!group) {
        group = { name: gName, places: [], i: i++, n: r.n, bracketSort: r.bracketSort }
        groups.push(group)
      }
      let place = group.places.find(f => f.place === finish)
      if (!place) {
        place = { place: finish, teams: [] }
        group.places.push(place)
      }
      place.teams.push(r.team)
    })

    return groups.sort(firstBy('bracketSort').thenBy('n'))
  }

  get resultsGrouped2 () {
    const groups = []
    let i = 0
    this.teamResults.forEach(r => {
      const gName = ''
      const finish = r.iFinish
      let group = groups.find(f => f.name === gName)
      if (!group) {
        group = { name: gName, places: [], i: i++, n: r.n, bracketSort: r.bracketSort }
        groups.push(group)
      }
      let place = group.places.find(f => f.place === finish)
      if (!place) {
        place = { place: finish, teams: [] }
        group.places.push(place)
      }
      place.teams.push(r.team)
    })

    return groups.sort(firstBy('iFinish'))
  }

  get teamResults () {
    return this.activeTeams.filter(f => !f.drop).map(m => {
      var rounds = this.publicRounds.filter(r => r.hasTeam(m.id))
      var lastR = rounds.sort(firstBy('number', -1))[0]
      var elim = `${this.name} - ${lastR ? lastR.name : m.iFinish || 999999}`
      var rName = lastR && this.getRoundName(lastR.id)
      if (rName) elim = rName

      return {
        iFinish: m.iFinish || 999999,
        finish: m.finish || 999999,
        team: m,
        elim: elim,
        roundId: lastR && lastR.id,
        n: lastR ? lastR.number : 0,
        bracketSort: lastR && lastR.bracketPlay ? 0 : 1
      }
    }).sort(firstBy('iFinish'))
  }

  getRoundName (roundId) {
    const rname = this.props.find(f => f.startsWith(`rName-${roundId}`))
    return rname && rname.replace(`rName-${roundId}-`, '').replaceAll('_', ' ')
  }

  get activePlayers () {
    return flatten(this.activeTeams.map(t => t.players))
  }

  get useTeamNames () {
    return this.props.includes('useTeamNames')
  }

  get noTeamNames () {
    return this.props.includes('noTeamNames')
  }

  get usePlayerNames () {
    return this.props.includes('usePlayerNames')
  }

  get noAvatar () {
    return this.props.includes('noAvatar')
  }

  get isKob () {
    return this.props.includes('kob')
  }

  get isLadder () {
    return this.props.includes('ladder')
  }

  get isDuals () {
    return this.dualPairCount > 1
  }

  get lineups () {
    return this.props.includes('lineups') || this.isNcaa
  }

  get dualPairCount () {
    const prop = this.props.find(f => f.startsWith('lineups-dual-'))
    if (prop) {
      return (+prop.replace('lineups-dual-', ''))
    }
    return 0
  }

  get lineupLock () {
    const prop = this.props.find(f => f.startsWith('lineup-lock-'))
    if (prop) {
      return (+prop.replace('lineup-lock-', ''))
    }
    return 0
  }

  lineupLockObj (matchStart, view, userTeams) {
    return {
      hasLock: this.lineupLock,
      dtLock: this.dtLineupLock(matchStart),
      isLocked: this.lineupIsLocked(matchStart),
      canDisplay: this.lineupCanDisplay(matchStart),
      view: view,
      userTeams: userTeams
    }
  }

  dtLineupLock (matchStart) {
    if (this.lineupLock === 0) return false
    if (!matchStart) return true
    const offset = this.location.offset
    const daylight = this.location.daylight
    if (moment.isMoment(matchStart)) matchStart = matchStart.format()

    const off = daylight && moment(matchStart.replace('Z', '')).isDST() ? daylight : offset
    const start = moment(matchStart.replace('Z', off))
    return start.subtract(this.lineupLock, 'm')
  }

  lineupIsLocked (matchStart) {
    return moment().isAfter(this.dtLineupLock(matchStart))
  }

  lineupCanDisplay (matchStart) {
    return this.lineupLock === 0 || this.lineupIsLocked(matchStart)
  }

  get dualProp () {
    return this.dualPairCount && `duals-${this.dualPairCount}`
  }

  get dualAdj () {
    const prop = this.props.find(f => f.startsWith('dual-adj-'))
    if (prop) {
      return (+prop.replace('dual-adj-', ''))
    }
    return 0
  }

  get leagueWeeks () {
    const prop = this.props.find(f => f.startsWith('league-weeks-'))
    if (prop) {
      return (+prop.replace('league-weeks-', ''))
    }
    return 0
  }

  set leagueWeeks (n) {
    if (n === this.leagueWeeks) return
    this.props = this.props.filter(f => f.startsWith('league-weeks-'))
    this.props.push(`league-weeks-${n}`)
  }

  setLeagueWeeks (n) {
    var cur = this.days.length
    while (++cur <= n) {
      this.days.push(new Day(this.sdk, { date: this.startDate.add((7 + cur), 'days') }))
    }
    this.days.forEach((d, i) => {
      if (i + 1 > n) {

      }
      const x = i + 1
      d.number = x
      d.date = this.startDate.add((7 * i), 'days')
      d.name = `Week ${x}`
      if (i === (n - 1)) {
        d.toBracketPlay()
      }
    })
  }

  get missingPoints () {
    return !!this.activeTeams.find(f => f.missingPoints)
  }

  get pointsLocked () {
    return this.activeTeams.filter(f => !f.pointsLocked).length === 0
  }

  get pointsIssue () {
    return !!this.activeTeams.find(f => f.pointsIssue)
  }

  get missingSomePoints () {
    return this.activeTeams.filter(f => f.missingSomePoints).length > 0
  }

  get poolTeams () {
    return flatten(this.rounds.map(m => m.poolTeams))
  }

  get poolTeamIds () {
    const pooled = new Set(this.poolTeams.map(m => m.teamId))
    return this.teamsSummary.teams.map(t => t.teamId).filter(f => !pooled.has(f))
  }

  get poollessTeamIds () {
    const pooled = new Set(this.poolTeams.map(m => m.teamId))
    return this.activeTeams.map(t => t.id).filter(f => !pooled.has(f))
  }

  get ibvl () {
    return this.registrationFields.requiredFields.includes('ibvl')
  }

  get refundPolicyId () {
    const prop = this.props.find(f => f.startsWith('refund-policy-'))
    if (prop) {
      return (+prop.replace('refund-policy-', ''))
    }
    return null
  }

  set refundPolicyId (n) {
    if (n === this.refundPolicyId) return
    this.props = this.props.filter(f => !f.startsWith('refund-policy-'))
    n && this.props.push(`refund-policy-${n}`)
  }

  get useLogos () {
    return !!this.activeTeams.find(f => f.logo)
  }

  get teamTypeProp () {
    return this.props.find(f => f.startsWith('teamType:'))
  }

  get teamType () {
    if (this.teamTypeProp) {
      return this.teamTypeProp.replace('teamType:', '')
    }
    return this.isNcaa ? 'ncaa' : this.isHS ? 'hs' : (this.isDuals || this.surfaceId === 3 || this.is('clubBids')) ? 'club' : false
  }

  get dualText () {
    return this.props.includes('nonDual') ? 'Match Up' : 'Dual'
  }

  get noResults () {
    return this.props.includes('noResults') || this.isCS
  }

  get acceptanceOnly () {
    return this.props.includes('acceptanceOnly')
  }

  is (prop) {
    return this.props.includes(prop)
  }
}
