const PlayerProfile = () => import('@/Pages/Player/Profile')
const PlayerSearch = () => import('@/Pages/Player/Search')
const Claim = () => import('@/Pages/Claim')

export default [
  {
    path: '/player/:playerProfileId',
    name: 'player-profile',
    component: PlayerProfile,
    props: true

  },
  {
    path: '/players',
    name: 'player-search',
    meta: { y: 0.9 },
    component: PlayerSearch,
    props: true
  },
  {
    path: '/claim-my-profile',
    name: 'claim-my-profile',
    meta: { y: 0.9 },
    component: Claim,
    props: true
  }
]
