import VblEvent from './VblEvent'
import TournamentSummary from './TournamentSummary'
import * as StatusEnum from './TournamentStatus'
import Leaderboard from './Leaderboard'
import { firstBy } from 'thenby'

export default class ReoccuringEvent extends VblEvent {
  tournaments = []
  iLeaderboard = null

  constructor (dto) {
    super(dto, 'series', ['tournaments', 'leaderboard', 'settings'])
    if (dto.tournaments) {
      this.tournaments = dto.tournaments.map(t => {
        t.summary = new TournamentSummary(t.summary)
        return t
      })
    }
    if (dto.leaderboard) {
      this.iLeaderboard = new Leaderboard(dto.leaderboard)
    }
    if (this.locations.length) {
      this.locations.sort(firstBy('name'))
    }

    const s = dto.settings
    this.settings = Object.assign({
      pointSystemId: null,
      byTeam: false,
      byTeamName: false,
      byClub: false,
      topN: null,
      LastN: null,
      points: [
        { finish: 1, pv: 60 },
        { finish: 2, pv: 45 },
        { finish: 3, pv: 40 },
        { finish: 4, pv: 35 },
        { finish: 5, pv: 30 },
        { finish: 6, pv: 25 },
        { finish: 7, pv: 20 },
        { finish: 8, pv: 15 },
        { finish: 9, pv: 10 },
        { finish: 9999, pv: 5 }
      ],
      ranking: ['overall']
    }, ((typeof s === 'string') ? JSON.parse(s) : s))
  }

  get dto () {
    const dto = super.dto
    dto.tournaments = this.tournaments
    return dto
  }

  get overall () {
    return this.settings && this.settings.ranking.includes('overall')
  }

  get divisional () {
    return this.settings && this.settings.ranking.includes('divisional')
  }

  get gender () {
    return this.settings && this.settings.ranking.includes('gender')
  }

  get summaries () {
    return this.tournaments.map(t => t.summary)
  }

  get upcoming () {
    return this.summaries.filter(f => f.dateStatus !== StatusEnum.PAST)
  }

  get previous () {
    return this.summaries.filter(f => f.dateStatus === StatusEnum.PAST)
  }

  get leaderboards () {
    var boards = []
    if (this.overall) boards.push(this.iLeaderboard.overall)
    if (this.divisional) boards.push(...this.iLeaderboard.divisional.sort(firstBy('title', -1)))
    if (this.gender) boards.push(...this.iLeaderboard.gender.sort(firstBy('title', -1)))
    return boards
  }
}
