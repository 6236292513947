import endpoints from './endpoints'

export default class TeamSDK {
  constructor (http) {
    this.http = http
  }

  getManageable (id) {
    return this.http.get(endpoints.team.getManageable(id))
  }

  changeDivisions (teamId, newDivisionId, credentials, token) {
    const t = token ? `?token=${token}` : ''
    return this.http.post(endpoints.team.changeDivisions(teamId, newDivisionId) + `${t}`, credentials || { orderId: null, email: null })
  }

  bulkMove (dto) {
    return this.http.post('/team/bulk-move', dto)
  }

  bulkCopy (dto) {
    return this.http.post('/team/bulk-copy', dto)
  }

  saveResults (results) {
    return this.http.post(endpoints.team.saveResults, results)
  }

  quickAdd (registration) {
    return this.http.put(endpoints.tournament.quickAdd, registration)
  }

  quickAdd2 (registrations) {
    return this.http.put(endpoints.tournament.quickAdd2, registrations)
  }

  unregister (id) {
    return this.http.post(endpoints.team.unregister(id))
  }

  reregister (id) {
    return this.http.post(endpoints.team.reregister(id))
  }

  changePartner (dto, token) {
    const t = token ? `?token=${token}` : ''
    return this.http.post(endpoints.team.changePartner + `${t}`, dto)
  }

  change (dto, token) {
    const t = token ? `?token=${token}` : ''
    return this.http.post(endpoints.team.change + `${t}`, dto)
  }

  getNotes (id) {
    return this.http.get(endpoints.team.notes(id))
  }

  withdrawAndRefund (dto, token) {
    const t = token ? `?token=${token}` : ''
    return this.http.post(endpoints.team.withdrawAndRefund + `${t}`, dto)
  }

  putChangeRequest (dto, token) {
    const t = token ? `?token=${token}` : ''
    return this.http.put(endpoints.team.putChangeRequest + `${t}`, dto)
  }

  getRegData (id) {
    return this.http.get(endpoints.team.regData(id))
  }

  getPartnerData (id) {
    return this.http.get(endpoints.team.partnerData(id))
  }

  checkIn (id, _in, token, playerId) {
    const url = endpoints.team.checkIn(id, _in)
    const q = []
    if (token) q.push(`token=${token}`)
    if (playerId) q.push(`playerId=${playerId}`)
    const query = q.length ? `?${q.join('&')}` : ''
    return this.http.post(`${url}${query}`)
  }

  updateName (dto) {
    return this.http.post(endpoints.team.updateName(dto.id), dto)
  }

  onlineCheckIn (dto) {
    return this.http.post(endpoints.team.onlineCheckin, dto)
  }

  tokenCheck (dto) {
    return this.http.post(endpoints.team.tokenCheck, dto)
  }

  createNewPlayer (id) {
    return this.http.post(endpoints.team.newProfile(id))
  }

  savePlayerField (rpId, dto) {
    return this.http.post(endpoints.team.savePlayerField(rpId), dto)
  }

  forfeit (dto, undo) {
    var url = `Team/Forfeit?undo=${!!undo}`
    return this.http.post(url, dto)
  }

  drop (id, undo) {
    var url = `Team/drop?id=${id}&undo=${!!undo}`
    return this.http.post(url)
  }

  merge (dto) {
    return this.http.post(endpoints.team.merge, dto)
  }

  undoMerge (ids) {
    return this.http.post('/team/merge-undo', ids)
  }

  getToken (teamId, token) {
    return this.http.get(`/team/tokenstatus/${teamId}?token=${token}`)
  }

  fieldChange (teamId, field) {
    return this.http.post(`/team/${teamId}/fieldChange/${field}`)
  }

  confirmationResend (teamId, dto) {
    return this.http.post(`/team/${teamId}/Confirmation/Send`, dto)
  }

  balance (teamId, token, credentials) {
    const t = token ? `?token=${token}` : ''
    return this.http.post(`/team/${teamId}/balance${t}`, credentials)
  }

  lineups = {
    get: (id) => {
      return this.http.get(`/team/${id}/lineups`)
    },
    post: (dto, token) => {
      const t = token ? `?token=${token}` : ''
      return this.http.post(`/team/lineup${t}`, dto)
    },
    delete: (dto, token) => {
      const t = token ? `?token=${token}` : ''
      return this.http.post(`/team/lineup/delete${t}`, dto)
    },
    assign: (dto, token) => {
      const t = token ? `?token=${token}` : ''
      return this.http.post(`/team/matchLineup${t}`, dto)
    }
  }

  tokens = {
    getAll: (id, token) => {
      const t = token ? `?token=${token}` : ''
      return this.http.get(`/team/${id}/tokens${t}`)
    },
    create: (id, token, dto) => {
      const t = token ? `?token=${token}` : ''
      return this.http.post(`/team/${id}/tokens${t}`, dto)
    },
    send: (id, token, dto) => {
      const t = token ? `?token=${token}` : ''
      return this.http.post(`/team/${id}/tokens/send${t}`, dto)
    }
  }

  tags = {
    post: (id, tags) => {
      return this.http.post('/team/tags', { id: id, tags: tags })
    }
  }
}
