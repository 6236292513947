<template>
  <v-card flat
    height="100%"
    class="outlined-card"
    :color="(hover || selected) ? selectColor.string : ''"
    :dark="(hover || selected) && selectColor.dark"
  >
    <v-card-text class="text-center">
      <div class="title" v-html="level.title"></div>
      <div class="subheading">{{level.subtitle}}</div>
    </v-card-text>
    <v-card-text class="text-center pt-0">
      <h1 v-if="level.price > 0">{{level.price | usDollars}}</h1>
      <h1 v-if="level.price < 0">Coming Soon</h1>
      <h1 v-if="level.price === 0 && !infoOnly">Free</h1>
      <v-btn
        v-if="!noAction && !redirect && !infoOnly"
        color="color3 color3Text--text"
        block
        large
        :dark="iDisabled && selected && selectColor.dark"
        @click.stop="$emit('selected', level)"
        :disabled="iDisabled"
        style="height: 60px"
        class="mt-2"
      >
        <h1>{{actionText}}</h1>
      </v-btn>
      <v-btn
        v-if="redirect"
        color="color3 color3Text--text"
        block
        large
        :dark="iDisabled && selected && selectColor.dark"
        :href="redirect"
        target="_blank" rel="noopener noreferrer"
        :disabled="iDisabled"
        style="height: 60px"
        class="mt-2"
      >
        <h1>{{actionText}}</h1>
      </v-btn>
      <v-divider class="mt-6 mx-12" :dark="selected ? selectColor.dark : false"></v-divider>
    </v-card-text>
    <v-card-text class="pt-0">
      <div v-html="level.description"></div>
      <v-expand-transition>
        <div v-html="level.moreInfo" v-if="more" class="pt-4"></div>
      </v-expand-transition>
      <div
        v-if="level.moreInfo"
        class="text-center"
      >
        <v-btn
          color="color3"
          text small
          @click.stop="more = !more"
        >{{moreText}}</v-btn>
      </div>
    </v-card-text>

  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['level', 'selected', 'disabled', 'actionText', 'hover', 'noAction'],
  data () {
    return {
      more: false
    }
  },
  computed: {
    ...mapGetters(['color1IsDark', 'color2IsDark']),
    iDisabled () {
      return this.disabled || this.level.disabled
    },
    selectColor () {
      return (this.color2IsDark || !this.color1IsDark) ? {
        color: this.$vuetify.theme.color2,
        string: 'color2 color2Text--text',
        text: 'color2Text--text',
        dark: this.color2IsDark
      } : {
        color: this.$vuetify.theme.color1,
        string: 'color1 color1Text--text',
        text: 'color1Text--text',
        dark: this.color1IsDark
      }
    },
    moreText () {
      if (this.level.moreInfoBtn) {
        const splt = this.level.moreInfoBtn.split('|')
        return this.more ? splt.length > 1 ? splt[1] : splt[0] : splt[0]
      }
      return this.more ? 'Less' : 'More'
    },
    jProps () {
      return this.level && this.level.jsonProps && JSON.parse(this.level.jsonProps)
    },
    redirect () {
      return this.jProps && this.jProps.redirect
    },
    infoOnly () {
      return this.jProps && this.jProps.infoOnly
    }
  },
  mounted () {
    this.more = this.level.moreInfoOpen
  }
}
</script>

<style scoped>
.outlined-card {
  border: 2px solid var(--v-color3-base) !important
}
</style>
