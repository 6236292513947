<template>
  <v-card
    height="100%"
    class="flexcard"
  >
    <div>
    <v-toolbar dense :style="`color: ${org.theme.color1Text}; background-color: ${org.theme.color1}`">
      <v-img
        v-if="org.theme.logoUrl && false"
        :src="org.theme.logoUrl"
        :style="`background-color: ${org.theme.color1}`"
        contain
        height="100%"
        position="left center"
      ></v-img>
      <v-toolbar-title v-else>{{org.name}}{{org.pointsOnly ? ' - Point System' : ''}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="org.contact"
        :href="`mailto:${org.contact}`"
        text
        icon
        small
        :color="`${org.theme.color3Text}`"
      >
        <v-icon :color="`${org.theme.color3 === org.theme.color1 ? org.theme.color3Text : org.theme.color3}`">far fa-envelope</v-icon>
      </v-btn>
    </v-toolbar>
    </div>
    <v-card-text class="grow pt-0" @click.stop="cardClick">
      <div class="text-caption">Id: {{org.id}}</div>
      <div class="hidden-sm-and-up text-center">{{org.name}}</div>
      <v-img
        v-if="org.theme.logoUrl"
        :src="org.theme.logoUrl"
        aspect-ratio="1"
        contain
        :style="`background-color: ${org.theme.color1}`"
      ></v-img>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions style="min-height: 48px">
      <v-row dense>
      <v-btn
        v-if="org.websiteUrl"
        :href="org.websiteUrl"
        target="_blank"
        fab
        small
        :color="`${org.theme.color3}`"
        class="social"
      >
        <v-icon :color="`${org.theme.color3Text}`" >fas fa-globe-americas</v-icon>
      </v-btn>

      <v-btn
        v-if="org.facebook"
        :href="`https://facebook.com/${org.facebook}`"
        target="_blank"
        fab
        small
        color="#3B5998"
        class="social"
      >
        <v-icon color="white">fab fa-facebook-f</v-icon>
      </v-btn>

      <v-btn
        v-if="org.instagram"
        :href="`https://instagram.com/${org.instagram}`"
        target="_blank"
        fab
        small
        class="social instagram"
      >
        <v-icon color="white">fab fa-instagram</v-icon>
      </v-btn>

      <v-btn
        v-if="org.twitter"
        :href="`https://twitter.com/${org.twitter}`"
        target="_blank"
        fab
        small
        color="#1DA1F2"
        class="social"
      >
        <v-icon color="white">fab fa-twitter</v-icon>
      </v-btn>

      <v-btn
        v-if="org.snapchat"
        :href="`https://snapchat.com/${org.snapchat}`"
        target="_blank"
        fab
        small
        color="#FFFC00"
        class="social"
      >
        <v-icon color="white">fab fa-snapchat-ghost</v-icon>
      </v-btn>

      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['organization', 'selector'],
  computed: {
    org () {
      return this.organization
    }
  },
  methods: {
    cardClick () {
      if (this.selector) {
        this.$emit('selected', this.org)
        return
      }
      this.$router.push({ name: 'organization-home', params: { username: this.org.username } })
    }
  }
}
</script>

<style scoped>
.flexcard {
  cursor: pointer;
}
.social {
  border-radius: 15px;
}
.social .v-icon {
  font-size: 30px !important;
}
.social .v-icon.fa-twitter,
.social .v-icon.fa-snapchat-ghost {
  font-size: 24px !important;
}
.instagram {
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
}
.fa-facebook-f:before {
  margin-left: 6px;
  margin-top: 6px;
}

</style>
