<template>
  <div v-if="tournament" ref="topOfPage">
    <!-- REG CLOSED -->
    <v-container fluid class="fill-height" v-if="!regOpen" >
      <v-row dense align="center">
        <v-col cols="8" offset="2">
          <v-row dense class="ma-12 text-center">
            <v-col cols="12">
              <h2>This tournament's registration is not currently open</h2>
            </v-col>
            <v-col cols="12">
              Please check back a bit later.
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="fill-height" v-else-if="secure" >
      <v-row dense align="center">
        <v-col cols="8" offset="2">
          <v-row dense class="mt-12 text-center">
            <v-col cols="12">
              <h2>This tournament's registration requires a password</h2>
              Please enter a password to continue
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="10" offset-sm="1" md="8" offset-md="2" lg="6" offset-lg="3">
              <v-text-field
                label="Password"
                type="password"
                v-model="pass"
                :error-messages="passFail ? ['The password you entered is incorrect'] : []"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="text-center" cols="12" >
              <v-btn
                color="color3 color3Text--text"
                :disabled="!pass"
                :loading="loading"
                @click.stop="checkPass"
              >Continue</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid style="min-height: 300px;" v-else-if="tournament.is('userRegOnly') && !user" class="d-flex justify-center align-center">
      <div class="text-center">
        <h2>This tournament's registration requires a signed in user</h2>
        <v-btn color="color3 color3Text--text" @click.stop="login">Please sign in to continue</v-btn>
      </div>
    </v-container>

    <v-container fluid class="pa-0 ma-0" v-else-if="!tournament.externalRegistrationUrl || view.admin">
      <!-- NEW -->
      <v-row dense v-if="newReg">
        <v-col cols="12">
          <v-window v-model="window" touchless>
            <v-window-item :key="0">
              <new-registration
                ref="newReg"
                :tournament="tournament"
                :registration="registration"
                :adminQuickAdd="adminQuickAdd"
                :edit="edit"
                :quickAddLoading="quickAddLoading"
                @step-change="onStepChange"
                @lead-me="leadMe"
                @complete-click="onCompleteClick"
                :active="inView"
                :leadId="leadId"
                :stepHistory="stepHistory"
              ></new-registration>
              <v-btn
                color="success"
                x-small
                v-if="tournament.addOns && user && user.vbl"
                @click.stop="window = 1"
              >skip to addon</v-btn>
            </v-window-item>
            <v-window-item :key="1" v-if="tournament.addOns" eager>
              <v-row dense v-if="tournament.addOns">
                <v-col cols="12">
                  <div class="title ml-3">{{ tournament.addOns.question }}</div>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" v-for="tag in tournament.addOns.productTags" :key="tag">
                  <product-display
                    :tag="tag"
                    @added-to-cart="cartDialog = true"
                    :addOn="true"
                    :pickupOnly="tournament.addOns.pickupOnly"
                    :linkId="lastRegLinkId"
                  ></product-display>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" class="text-center">
                  <v-btn color="error" @click.stop="cartDialog = true">No thanks</v-btn>
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>
        </v-col>
      </v-row>
      <!-- ADDED TO CART DIALOG -->
      <v-dialog v-model="cartDialog" max-width="500">
        <v-card>
          <v-card-title class="title justify-center">
            Your registration has been added to the cart!
          </v-card-title>
          <v-card-actions class="justify-center">
            <v-btn color="color3 color3Text--text" class="mr-2" :to="{name: 'checkout'}">Check Out Now</v-btn>
            <v-btn color="color3 color3Text--text" @click.stop="cartDialog=false; window=0">Add Another</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <!-- EXTERNAL REG -->
    <v-container fluid class="fill-height" v-else >
      <v-row dense align="center">
        <v-col cols="8" offset="2">
          <v-row dense class="ma-12 text-center">
            <v-col cols="12">
              <h2>This tournament's registration is on an external website</h2>
            </v-col>
            <v-col cols="12">
              <v-btn color="color3 color3Text--text" dark @click="exreg(tournament.externalRegistrationUrl)">
                Continue to registration
                <v-icon class="ml-4">fas fa-external-link</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import NewRegistration from './New/NewRegistration.vue'
import CartItem from '../../../classes/CartItem'
import * as actions from '../../../store/ActionTypes'
import { mapGetters } from 'vuex'
const ProductDisplay = () => import('@/components/Store/ProductDisplay.vue')

export default {
  props: ['tournament', 'registration', 'inView', 'adminQuickAdd', 'edit'],
  data () {
    return {
      currentStep: 1,
      cartDialog: false,
      quickAddLoading: false,
      oDto: null,
      leadId: 0,
      stepHistory: [],
      newReg: true,
      cleared: false,
      pass: null,
      passFail: false,
      loading: false,
      window: 0,
      lastRegLinkId: null
    }
  },
  computed: {
    ...mapGetters(['user', 'admin', 'view', 'liveUpdates', 'auth']),
    useP1440IndoorShowcase () {
      return this.tournament.props.includes('p1440IndoorShowcase')
    },
    allowAlternates () {
      return this.registration.division && this.registration.division.numOfPlayers !== this.registration.division.numAllowedOnRoster
    },
    inValidAlternate () {
      return this.allowAlternates && this.registration.alternates.filter(f => !f.valid).length > 0
    },
    me () {
      return this.$store.getters.user && this.$store.getters.user.id === 1 && !this.$store.getters.publicView
    },
    entryFee () {
      const w = this.registration.window
      return w && w.fee ? w.feeIsPerTeam ? w.fee : w.fee * this.registration.players.length : 0
    },
    regOpen () {
      return this.adminQuickAdd || ((this.tournament && this.tournament.divisions.find(d => d.regOpen)) || (this.user && this.user.id === 1))
    },
    cartItem () {
      console.log('here')
      const item = new CartItem()
      item.type = 'TR'
      item.organization = this.registration.organization
      item.name = this.bidDiscount ? 'Tournament Registration with Bid' : 'Tournament Registration'
      item.description = `${this.tournament.name} | ${this.registration._teamName} | ${this.registration.division?.divisionsString}${this.registration.window?.name ? ` | ${this.registration.window.name}` : ''}`
      item.amount = this.total
      item.registration = this.registration.dto
      item.leadId = this.leadId
      item.stepHistory = this.stepHistory
      item.division = this.registration.division?.divisionsString
      item.teamName = this.registration._teamName
      item.preAuth = this.registration.window?.canPayAtEvent
      return item
    },
    changed () {
      return this.oDto && this.oDto !== JSON.stringify(this.registration.dto)
    },
    bid () {
      return this.registration.bid
    },
    bidDiscount () {
      if (this.bid && (this.bid.discountAmount || this.bid.discountPercentage)) {
        return this.bid.discountAmount || this.entryFee * this.bid.discountPercentage
      }
      return 0
    },
    total () {
      var t = this.entryFee - this.bidDiscount
      return t > 0 ? t : 0
    },
    secure () {
      return this.tournament && !this.adminQuickAdd && this.tournament.hasPassword && !this.cleared
    },
    iFrame () {
      return window.location.href.includes('iframe=true')
    },
    isCoaches () {
      return this.registration && this.registration.isCoaches && false
    }
  },
  methods: {
    login () {
      this.auth.login()
    },
    onCompleteClick (cartItem) {
      if (this.isCoaches) {
        this.addToCart(cartItem)
        this.$router.push({ name: 'checkout' })
        return
      }
      this.adminQuickAdd ? this.quickAdd(cartItem) : this.edit ? this.saveEdit(cartItem) : this.addToCart(cartItem)
    },
    onStepChange (val) {
      this.stepHistory.push(val)
      this.leadMe()
    },
    addToCart (cartItem) {
      const item = (cartItem || this.cartItem)
      const items = Array.isArray(item) ? item : [item]
      this.stepHistory.push('add_to_cart')
      this.leadMe()
      item.stepHistory = this.stepHistory
      items.forEach(i => {
        this.$store.dispatch(actions.ADD_CART_ITEM, i)
      })
      this.lastRegLinkId = item.linkId
      this.reset()
      this.$emit('added-to-cart')
      if (!this.iFrame) {
        if (this.tournament.addOns) {
          this.window = 1
        } else {
          this.cartDialog = true
        }
      } else {
        this.$router.push({ name: 'checkout' })
      }
    },
    exreg (url) {
      window.open(url)
    },
    reset () {
      if (this.$refs.newReg) this.$refs.newReg.reset()
      if (this.$refs.indoorShow) this.$refs.indoorShow.reset()
      this.currentStep = 1
      this.stepHistory = []
      this.leadId = 0
      this.addOnComplete = false
      this.quickAddLoading = false
    },
    quickAdd (cartItem) {
      this.quickAddLoading = true
      this.$emit('complete-quick-add', (cartItem || this.cartItem))
    },
    saveEdit (cartItem) {
      this.$emit('save-click', (cartItem || this.cartItem))
      this.reset()
    },
    leadMe (cartItem) {
      if (!this.user && !this.registration.isLead) return
      if (this.admin) return
      const item = (cartItem || this.cartItem)

      this.$store.dispatch(actions.STORE_LEAD, item)
        .then(response => {
          this.leadId = response
        })
    },
    checkPass () {
      this.loading = true
      this.liveUpdates.invoke('CheckPass', { id: this.tournament.id, p: this.pass })
        .then(r => {
          this.cleared = r
          if (!r) {
            this.passFail = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  components: {
    NewRegistration,
    ProductDisplay
  },
  watch: {
    'registration.confirmed': function (val) {
      if (val) {
        this.oDto = JSON.stringify(this.registration.dto)
      } else {
        this.oDto = null
      }
    },
    currentStep: function (newVal, oldVal) {
      this.$ga.event('Registration', `Step ${newVal}`, this.$route.name, newVal)
    },
    inView: function (val) {
      if (val) {
        if (!this.registration.division) {
          this.reset()
        }
      }
    },
    changed: function (val) {
      if (val) {
        this.registration.confirmed = false
      }
    },
    pass: function (val) {
      this.passFail = false
    }
  }
}
</script>

<style>
.hideOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fa-sm {
  font-size: .875em !important;
}
</style>
