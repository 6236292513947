import SeriesHome from '@/Pages/Series/Index.vue'

export default [
  {
    path: '/series/:seriesTag',
    name: 'series-home',
    component: SeriesHome,
    props: true
  },
  {
    path: '/series/:seriesTag/admin',
    name: 'series-admin',
    component: SeriesHome,
    props: true
  }
]
