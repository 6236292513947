import uniq from 'lodash.uniq'
import firstBy from 'thenby'

export default class MatchDescriptor {
  constructor (option) {
    Object.assign(this, option)
    this.games = option ? option.games.map(m => new MatchGameDescriptor(m)) : []
  }

  get description () {
    if (this.games.length === 2 && this.games[0].to === 999) return '2 timed sets.'
    return `${this.heading} ${this.subheading}`
  }

  get heading () {
    return this.isMatch ? `Match Play (${this.matchStyle}).` : this.games.length === 1 ? '1 set' : `${this.games.length} sets.`
  }

  get subheading () {
    if (this.games.length === 1) {
      return this.games[0].description
    }
    if (this.games.length === 2) {
      const descriptions = uniq(this.games.sort(firstBy('number')).map(g => g.description))
      return descriptions.length === 1 ? `Both sets ${descriptions[0]}` : `Set 1 ${descriptions[0]} & set 2 ${descriptions[1]}`
    }
    if (this.games.length === 3) {
      const descriptions = uniq(this.games.sort(firstBy('number')).map(g => g.description))
      return descriptions.length === 1 ? `All sets ${descriptions[0]}` : `Sets 1 & 2 ${descriptions[0]} & set 3 ${descriptions[descriptions.length - 1]}`
    } else {
      const descriptions = uniq(this.games.sort(firstBy('number')).map(g => g.description))
      return descriptions.length === 1 ? `All sets ${descriptions[0]}` : `Sets 1 - ${descriptions.length - 1} ${descriptions[0]} & set ${descriptions.length} ${descriptions[descriptions.length - 1]}`
    }
  }

  get matchStyle () {
    return this.games.length === 5 ? 'best 3 out of 5' : 'best 2 out of 3'
  }
}

class MatchGameDescriptor {
  constructor (settings) {
    Object.assign(this, settings)
  }

  get description () {
    const cap = this.cap ? `with a ${this.cap} point cap` : 'with no cap'
    return `to ${this.to} ${cap}`
  }
}
