import * as actions from '../store/ActionTypes'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['accessToken']),
    liveUpdates () {
      return this.$store.getters.liveUpdates
    },
    liveUpdateStatus () {
      return this.$store.getters.liveUpdateStatus
    }
  },
  watch: {
    accessToken (newVal, oldVal) {
      this.$store.dispatch(actions.LIVE_CONNECT)
    }
  },
  methods: {
  }
}
