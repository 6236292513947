import moment from 'moment'

const gradYears = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => {
  return i + new Date().getFullYear()
})

const season = () => {
  const d = new Date()
  return d.getMonth() + 1 >= 9 ? d.getFullYear() + 1 : d.getFullYear()
}

const indoorSeason = () => {
  const d = new Date()
  return d.getMonth() + 1 >= 7 ? d.getFullYear() + 1 : d.getFullYear()
}

const seasonFromMoment = (d) => {
  if (moment.isMoment(d)) {
    return d.month() + 1 >= 9 ? d.year() + 1 : d.year()
  }
  return false
}

const indoorSeasonFromMoment = (d) => {
  if (moment.isMoment(d)) {
    return d.month() + 1 >= 7 ? d.year() + 1 : d.year()
  }
  return false
}

const ageDivisionAge = (_dob, _season) => {
  if (_dob) {
    const dob = moment(_dob)
    const s = moment(`${_season || season()}-08-31`)
    return s.diff(dob, 'years')
  }
  return null
}

const ratingAge = (dob, gradYear, _season) => {
  const s = _season || season()
  const age = ageDivisionAge(dob, s)
  if (!age) return 0
  if (age <= 12) return 12
  if (age <= 14) return 14
  if (age <= 16) return 16
  if (age < 18) return 18
  if (age === 18 && (gradYear || 0) >= s) return 18
  if (age <= 19 && (gradYear || 0) >= s) return 18
  return 99
}

const ageDivision = (dob, gradYear, _season) => {
  const s = _season || season()
  const age = ageDivisionAge(dob, s)
  if (!age) return ''
  if (age <= 10) return '10U'
  if (age < 18) return `${age}U`
  if (age === 18 && (gradYear || 0) >= s) return `${age}U`
  if (age <= 19 && (gradYear || 0) >= s) return '18U'
  if (age <= 25) return `U${age + 1}`
  return 'Adult'
}

const indoorAgeDivisionAge = (_dob, _season) => {
  if (_dob) {
    const dob = moment(_dob)
    const s = moment(`${_season || season()}-06-30`)
    return s.diff(dob, 'years')
  }
  return null
}

const aauAgeDivisionAge = (_dob, _season) => {
  if (_dob) {
    const dob = moment(_dob)
    const s = moment(`${_season || season()}-07-31`)
    return s.diff(dob, 'years')
  }
  return null
}

const indoorAgeDivision = (dob, gradYear, _season) => {
  const s = _season || indoorSeason()
  const age = indoorAgeDivisionAge(dob, s)
  if (!age) return ''
  if (age <= 10) return '10U'
  if (age < 18) return `${age}U`
  if (age === 18 && (gradYear || 0) >= s) return `${age}U`
  if (age <= 19 && (gradYear || 0) >= s) return '18U'
  if (age <= 25) return `U${age + 1}`
  return 'Adult'
}

const heightInInches = (height) => {
  if (!height) return 0
  if (height.includes('"')) {
    var h = height.replace('"', '').split('\' ')
    return (+h[0] * 12) + (+h[1])
  }
  return height.replace(/\D/g, '') * 12
}

const ageDayOf = (dob, day) => {
  dob = moment(dob)
  const start = day ? moment(day) : moment()
  return dob && start ? start.diff(dob, 'years') : 0
}

const collegeYearList = [
  { text: 'Freshman', value: 'Fr' },
  { text: 'Sophomore', value: 'So' },
  { text: 'Junior', value: 'Jr' },
  { text: 'Senior', value: 'Sr' },
  { text: 'Graduate Student', value: 'GS' },
  { text: 'Redshirt Freshman', value: 'R-Fr' },
  { text: 'Redshirt Sophomore', value: 'R-So' },
  { text: 'Redshirt Junior', value: 'R-Jr' },
  { text: 'Redshirt Senior', value: 'R-Sr' },
  { text: 'Graduated', value: 'Gr' },
  { text: 'Retired', value: 'Ret' }
]

export { gradYears, ageDivision, ageDivisionAge, indoorAgeDivisionAge, indoorAgeDivision, heightInInches, ageDayOf, seasonFromMoment, season, indoorSeason, indoorSeasonFromMoment, ratingAge, aauAgeDivisionAge, collegeYearList }
