<template>
  <v-card>
    <v-toolbar color="color2 color2Text--text">
      <v-toolbar-title>{{leaderboard.title}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="view.admin" color="color2Text" text icon @click.stop="onDownloadClick">
        <v-icon>fas fa-download</v-icon>
      </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="leaderboard.leaders"
      :options.sync="pager"
      :loading="loading"
      dense
      :mobile-breakpoint="0"
    >
      <v-progress-linear slot="progress" color="color3" indeterminate></v-progress-linear>
      <template v-slot:[`item.name`]="{item}">
        <PlayerLink v-if="item.players.length === 1" :player="{name: item.name, id: +item.id}"></PlayerLink>
        <div v-else>{{ item.name }}</div>
      </template>
      <template v-slot:[`item.points`]="{item}">
        {{item.points | fixed1}}
      </template>
      <template v-slot:[`item.actions`]="{item}">
        <v-btn
          color="color3 color3Text--text"
          small text icon
          @click="selectLeader(item)"
        >
          <v-icon>fas fa-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <!-- SELECTED RECORD DIALOG -->
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card color="grey lighten-3">
        <v-toolbar color="color1 color1Text--text">
          <v-toolbar-title>{{byClub ? selectedLeader.name : byTeam ? 'Team' : 'Player'}} Summary</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="color1Text" text icon @click.stop="dialog = false"><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>
        <v-card-text :class="{'pa-1': xs}">
            <v-row dense>
              <v-col cols="12">
                <v-card color="color1 color1Text--text">
                  <v-card-title primary-title>
                    <div>
                      <h3 class="headline mb-0">Currently Ranked {{selectedLeader.rank | ordinal}}</h3>
                      <div>with {{selectedLeader.points | fixed1}} points</div>
                    </div>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" :sm="selectedLeader.players.length > 1 ? 6 : 12" v-for="player in selectedLeader.players" :key="player.id">
                <v-card color="color2 color2Text--text" height="100%">
                  <v-container fluid class="pa-1">
                    <v-row dense>
                      <v-col class="shrink">
                          <v-img
                            :src="player.pic || getPic(player.male)"
                            :alt="player.name"
                            :aspect-ratio="1"
                            :width="60"
                          ></v-img>
                      </v-col>
                      <v-col class="grow">
                        <div class="d-flex justify-space-between">
                          <div :class="{title: selectedLeader.players.length === 1}">
                            {{player.name}}
                          </div>
                          <div>
                            <v-btn color="color2Text" text icon :to="{name: 'player-profile', params: {playerProfileId: player.id}}">
                              <v-icon small>fas fa-id-badge</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div :class="{title: selectedLeader.players.length === 1}">{{player.isJunior && player.gradYear ? player.gradYear : null}}</div>
                        <template v-if="!byClub">
                          <hr>
                          <div v-if="player.isJunior">
                            <div>{{player.committedSchool}}</div>
                            <div class="pt-1 sub">
                              <fit-text>{{player.club}}</fit-text>
                            </div>
                          </div>
                          <div v-else>
                            {{player.cityState}}
                          </div>
                        </template>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
            <v-data-table
              :headers="detailHeaders"
              :items="details"
              hide-default-footer
              class="elevation-1"
              :options.sync="detailsPager"
              dense
              :mobile-breakpoint="0"
            >
              <template v-slot:[`item.unix`]="{item}">
                {{item.date.format('l')}}
              </template>
              <template v-slot:[`item.tournament`]="{item}">
                <table class="nested">
                  <tr>
                    <td class="nameCol">
                      {{item.tournament}}
                    </td>
                  </tr>
                  <tr v-if="byClub">
                    <td class="nameCol">
                      {{item.name2}} - {{item.division}}
                    </td>
                  </tr>
                </table>
              </template>
              <template v-slot:[`item.finish`]="{item}">
                {{item.finish | ordinal}}
              </template>
              <template v-slot:[`item.points`]="{item}">
                <span :class="{'font-weight-bold': item.usedFor.includes(leaderboard.title)}">{{(item.points * item.multiplier) | fixed1}}{{item.multiplier !== 1 ? '*' : ''}}</span>
              </template>
            </v-data-table>
            <v-row dense>
              <v-col cols="12" class="caption">
                <strong>Bolded</strong> points are used in ranking total
              </v-col>
            </v-row>
            <v-row dense v-if="hasMultiplier">
              <v-col cols="12" class="caption">
                *Point multipliers only apply to the series points
              </v-col>
            </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from 'moment'
import { firstBy } from 'thenby'
import avatar from '@/classes/Avatar'
import PlayerLink from '@/components/Player/PlayerLink'
import Papa from 'papaparse'
import { mapGetters } from 'vuex'

export default {
  props: ['leaderboard', 'search', 'loading', 'byClub', 'byTeam', 'titleTag', 'view'],
  data () {
    return {
      dialog: false,
      selectedLead: null,
      headers: [
        { text: 'Rank', value: 'rank', align: 'center', sortable: 'false' },
        { text: 'Name', value: 'name', align: 'left', sortable: 'false' },
        { text: this.byClub ? 'Finishes' : 'Events', value: 'events', align: 'center', sortable: 'false' },
        { text: 'Points', value: 'points', align: 'center', sortable: 'false' },
        { value: 'actions', align: 'right', sortable: 'false' }
      ],
      dHeaders: [
        { text: 'Date', value: 'unix', align: 'left', sortable: 'false' },
        { text: 'Name', value: 'tournament', align: 'left', sortable: 'false', class: 'nameCol' },
        { text: 'Finish', value: 'finish', align: 'center', sortable: 'false' },
        { text: 'Points', value: 'points', align: 'center', sortable: 'false' }
      ],
      pager: { sortBy: ['rank'], sortDesc: [false], itemsPerPage: 10 },
      detailsPager: { sortBy: ['unix', 'points'], sortDesc: [true, true], itemsPerPage: -1 }
    }
  },
  computed: {
    ...mapGetters(['theme']),
    xs () {
      return this.$vuetify.breakpoint.xsOnly
    },
    detailHeaders () {
      return !this.$vuetify.breakpoint.xs ? this.dHeaders : this.dHeaders.filter(f => f.value !== 'tournament')
    },
    selectedLeader () {
      return this.selectedLead || {
        id: 0
      }
    },
    details () {
      if (this.selectedLeader.records) {
        return this.selectedLeader.records.map((m, i) => {
          const x = Object.assign({}, m)
          x.id += i
          x.date = moment.utc(x.date)
          x.unix = moment.utc(x.date).unix()
          return x
        }).sort(firstBy('unix'))
      }
      return []
    },
    hasMultiplier () {
      return this.details.filter(f => f.multiplier !== 1).length > 0
    },
    downloadData () {
      const subdomain = !window.location.host.startsWith(this.theme.username) ? '' : this.theme.username + '.'
      const base = `${window.location.protocol}//${subdomain}${window.location.host}`

      return this.leaderboard?.leaders?.sort((a, b) => a.rank - b.rank).map(l => {
        const a = {
          Rank: l.rank,
          Name: l.name,
          Points: l.points
        }

        if (this.byClub) {
          a.Finishes = l.events
        } else {
          a.Events = l.events
        }

        a['Player Id'] = l.id
        a['Profile Url'] = `${base}/player/${l.id}`

        return a
      })
    }
  },
  methods: {
    selectLeader (leader) {
      this.selectedLead = leader
      this.dialog = true
    },
    getPic (male) {
      return avatar(male)
    },
    onDownloadClick () {
      var fileName = `${this.titleTag}_${this.leaderboard ? this.leaderboard.title : ''}_Rankings_.csv`
      var data = this.downloadData
      this.createFileForDownload(data, fileName)
    },
    createFileForDownload (data, name) {
      var opt = { header: !this.avpaFormat }
      var csv = Papa.unparse(data, opt)
      var blob = new Blob([csv])
      var a = window.document.createElement('a')
      a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
      a.download = name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  },
  components: {
    PlayerLink
  },
  watch: {
    dialog: function (val) {
      if (!val) {
        setTimeout(() => {
          this.selectedLead = null
        }, 333)
      }
    }
  }
}
</script>

<style scoped>
.nameCol {
  max-width: 225px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nested td {
  border: none !important;
}
</style>
