export default class CollegeSDK {
  constructor (http) {
    this.http = http
  }

  names () {
    return this.http.get('college/all')
  }

  list () {
    return this.http.get('college/list')
  }

  details = {
    get: () => {
      return this.http.get('college/all/details')
    },
    patch: (dto) => {
      return this.http.post('college/all/details', dto)
    }
  }

  coach = {
    patch: (dto) => {
      return this.http.post('college/coach', dto)
    },
    invite: (id) => {
      return this.http.post(`college/coach/invite?id=${id}`)
    },
    inviteHistory: (id) => {
      return this.http.get(`college/coach/invite?id=${id}`)
    },
    getQrInvite: (id) => {
      return this.http.get(`college/coach/inviteLink?id=${id}`)
    }
  }
}
