const Club = () => import('@/Pages/Club/Index.vue')
const Home = () => import('@/Pages/Club/Home.vue')
const Create = () => import('@/Pages/Club/Create.vue')
const GeneralInfo = () => import('@/Pages/Club/GeneralInfo.vue')
const Teams = () => import('@/Pages/Club/Teams.vue')
const Events = () => import('@/Pages/Club/Events.vue')
const Membership = () => import('@/Pages/Club/Membership.vue')
const DataManager = () => import('@/Pages/Club/DataManager.vue')
// const Calendar = () => import('@/Pages/Club/Calendar.vue')
const OrganizationCalendarHome = () => import('@/New/OrganizationCalendarHome')

export default [
  {
    path: '/club',
    component: Club,
    props: true,
    children: [
      {
        path: '',
        name: 'club-home',
        component: Home,
        props: true
      },
      {
        path: 'create',
        name: 'club-create',
        component: Create,
        props: true
      },
      {
        path: 'general-info',
        name: 'club-general-info',
        component: GeneralInfo,
        props: true
      },
      {
        path: 'teams',
        name: 'club-teams',
        component: Teams,
        props: true
      },
      {
        path: 'events',
        name: 'club-events',
        component: Events,
        props: true
      },
      {
        path: 'membership',
        name: 'club-membership',
        component: Membership,
        props: true
      },
      {
        path: 'data-manager',
        name: 'club-data-manager',
        component: DataManager,
        props: true
      }
    ]
  },
  {
    path: '/calendar',
    name: 'organization-calendar-subdomain',
    component: OrganizationCalendarHome,
    props: true
  },
  {
    path: '/:username/calendar',
    name: 'organization-calendar',
    component: OrganizationCalendarHome,
    props: true
  }
]
