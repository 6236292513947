const BracketSeeding = () => import('@/Pages/Tournament/Division/Brackets/Teams/Index.vue')
const BracketDisplay = () => import('@/Pages/Tournament/Division/Brackets/Home/Index.vue')

export default [
  {
    path: '',
    name: 'league-bracket-home',
    component: BracketDisplay,
    props: (route) => { return { winners: true } },
    meta: { displayName: 'Bracket' }
  },
  {
    path: 'seeding',
    name: 'league-bracket-seeding',
    component: BracketSeeding,
    meta: { displayName: 'Bracket' }
  },
  {
    path: 'contenders',
    name: 'league-bracket-contenders',
    component: BracketDisplay,
    props: (route) => { return { winners: false } },
    meta: { displayName: 'Bracket' }
  }]
