import MatchOptions from './MatchOptions'
import DtoUpdate from './DtoUpdate'

export default class MatchSettings {
  id = 0
  poolsOf = 0
  gameSettings = []
  matchTypeId = null
  matchType = null
  assignTimes = true
  minutesPerMatch = 30
  startTime = null
  organizationTemplateId = null
  pdfMake = null

  constructor (dto, forceGames, forceMpm) {
    if (dto) {
      this.update(dto, forceGames, forceMpm)
    }
  }

  // methods
  update (dto, forceGames, forceMpm) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = ['matchTypeId']
    DtoUpdate(dto, this, exclude)
    if (this.startTime && !this.startTime.endsWith('Z')) {
      this.startTime += 'Z'
    }

    if (dto.matchTypeId) {
      this.setPlayTypeById(dto.matchTypeId, true && !forceGames, true && !forceMpm)
    }

    this.mark()
  }

  mark () {
    this._dto = JSON.stringify(this.dto)
  }

  restore () {
    this.update(this._dto)
  }

  setPlayTypeById (id, skipGames, skipMpm) {
    this.matchTypeId = id
    if (!this.matchType || this.matchType.id !== id) {
      const playType = MatchOptions.find(f => f.id === id)
      this.matchType = playType
      if (!skipGames) this.setGames()
      if (!skipMpm) this.setMpm()
    }
  }

  setGames () {
    this.gameSettings = JSON.parse(JSON.stringify(this.matchType.games))
  }

  setMpm () {
    this.minutesPerMatch = this.matchType.mpm
  }

  // props with getter and setter
  set _matchType (playType) {
    this.setPlayTypeById(playType.id)
  }

  get _matchType () {
    return this.matchType
  }

  get isAdvanced () {
    return !!this.matchType.games.find((f, i) => f.to !== this.gameSettings[i].to)
  }

  get dirty () {
    return JSON.stringify(this.dto) !== this._dto
  }

  // getters
  get dto () {
    return {
      id: this.id,
      matchTypeId: this.matchType.id,
      poolsOf: this.poolsOf,
      isMatch: this.matchType.isMatch,
      gameSettings: this.gameSettings.map(g => {
        return {
          to: +g.to,
          cap: +g.cap || null,
          number: g.number
        }
      }),
      assignTimes: this.assignTimes,
      minutesPerMatch: this.minutesPerMatch || 30,
      startTime: this.startTime,
      organizationTemplateId: this.organizationTemplateId
    }
  }

  get isTimed () {
    return !!this.gameSettings.find(f => f.to === 999)
  }

  set isTimed (v) {
    if (v) {
      this.gameSettings.forEach(g => { g.to = 999 })
    } else {
      this.gameSettings.forEach(g => { g.to = 21 })
    }
  }
}
