import DtoUpdate from './DtoUpdate'
import { firstBy } from 'thenby'
import PlayerRating from './PlayerRating'
import { ageDivision, ageDivisionAge, ratingAge } from './ProfileHelpers'

export default class PlayerRatings {
  constructor (dto) {
    this.id = 0
    this.name = null
    this.dob = null
    this.gradYear = null
    this.ratings = []
    this.update(dto)
  }

  // methods
  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = ['ratings']
    DtoUpdate(dto, this, exclude)
    if (dto.ratings) this.ratings = dto.ratings.map(m => new PlayerRating(m))
    if (this.ratings.length) {
      this.ratings.sort(firstBy('currentValue', -1).thenBy('unix', -1))
    }
  }

  // Getters
  get rating () {
    return this.ratings.length > 0 ? this.ratings[0] : { rating: 'Unrated' }
  }

  get ageDivision () {
    return ageDivision(this.dob, this.gradYear)
  }

  get age () {
    return ageDivisionAge(this.dob)
  }

  get ratingAge () {
    return ratingAge(this.dob, this.gradYear)
  }

  get currentAgeRatings () {
    return this.ratings.filter(f => f.age <= this.ratingAge).sort(firstBy('currentValue', -1).thenBy('unix', -1))
  }

  get currentAgeRating () {
    return this.currentAgeRatings.length > 0 ? this.currentAgeRatings[0] : { rating: 'Unrated' }
  }

  get displayRating () {
    const aaa = this.ratingsByAge.find(f => f.status === 'Future' && f.rating && f.rating.rating.includes('AAA'))
    if (aaa) {
      return aaa.rating
    }
    if (this.currentAgeRatings.length === 0) {
      return this.rating
    }
    return this.currentAgeRating
  }

  get ratingsByAge () {
    const r12 = this.historic(12)
    const r14 = this.historic(14)
    const r16 = this.historic(16)
    const r18 = this.historic(18)
    const r = this.ratingAge
    return [
      {
        age: '12U',
        rating: r12.length > 0 ? r12[0] : null,
        status: r === 12 ? 'Current' : r > 12 ? 'Past' : 'Future'
      },
      {
        age: '14U',
        rating: r14.length > 0 ? r14[0] : null,
        status: r === 14 ? 'Current' : r > 14 ? 'Past' : 'Future'
      },
      {
        age: '16U',
        rating: r16.length > 0 ? r16[0] : null,
        status: r === 16 ? 'Current' : r > 16 ? 'Past' : 'Future'
      },
      {
        age: '18U',
        rating: r18.length > 0 ? r18[0] : null,
        status: r === 18 ? 'Current' : r > 18 ? 'Past' : 'Future'
      }
    ]
  }

  historic (age) {
    return this.ratings.filter(f => f.age === age).sort(firstBy('ratingValue', -1).thenBy('unix', -1))
  }
}
