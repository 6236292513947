import { store } from '@/store'
import * as mutations from '@/store/MutationTypes'

const Members = () => import('@/Pages/Member/Index.vue')
const MembershipHome = () => import('@/Pages/Member/Home.vue')
const MemberLevels = () => import('@/Pages/Member/LevelsNew.vue')
const MemberSearch = () => import('@/Pages/Member/Search.vue')
const NewMember = () => import('@/Pages/Member/New.vue')

const PlayerProfile = () => import('@/Pages/Player/Profile')

export default [
  {
    path: '/membership',
    name: 'membership',
    redirect: { name: 'member-home' }
  },
  {
    path: '/members',
    component: Members,
    redirect: { name: 'member-levels' }
  },
  {
    path: '/join',
    component: Members,
    redirect: { name: 'member-levels' }
  },
  {
    path: '/member',
    component: Members,
    children: [
      {
        path: '',
        name: 'member-home',
        component: MembershipHome,
        props: true
      },
      {
        path: 'levels',
        name: 'member-levels',
        component: MemberLevels,
        props: true,
        beforeEnter: (to, from, next) => {
          console.log('beforeEnter')
          const user = store.getters.user
          const mRoute = user && user.memberRoute
          if (mRoute) {
            store.commit(mutations.LOADING_COMPLETE, to.name)
            mRoute.query = { levels: true }
            next(mRoute)
          } else {
            next()
          }
        }
      },
      {
        path: 'search',
        name: 'member-search',
        component: MemberSearch,
        props: true
      },
      {
        path: 'new',
        name: 'new-member',
        component: NewMember,
        props: true
      }
    ]
  },
  {
    path: '/member/:id',
    name: 'member-profile',
    component: PlayerProfile,
    props: true
  }
]
