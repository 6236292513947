import Vue from 'vue'
import * as mutations from '../MutationTypes'
import * as actions from '../ActionTypes'
import Tournament from '../../classes/Tournament'
// import { Promise } from 'es6-promise'

export default {
  state: {
    tournament: null,
    tournamentNav: false,
    regData: null,
    allowAdd: false,
    messageTeamIds: []
  },
  mutations: {
    [mutations.SET_TOURNAMENT] (state, payload) {
      state.tournament = payload
    },
    [mutations.SET_TOURNAMENT_REGDATA] (state, payload) {
      state.regData = payload
    },
    [mutations.SET_TOURNAMENT_ALLOWADD] (state, payload) {
      state.allowAdd = payload
    },
    [mutations.SET_TEAM_TO_MSG] (state, payload) {
      if (payload) {
        state.messageTeamIds.push(...payload)
        state.messageTeamIds = [...new Set(state.messageTeamIds)]
      } else {
        state.messageTeamIds = []
      }
    },
    // START UPDATES
    [mutations.UPDATE_TOURNAMENT] (state, payload) {
      console.log('Tournament Update!')
      if (typeof payload === 'string') payload = JSON.parse(payload)
      if (state.tournament && state.tournament.id === payload.id) {
        // Object.assign(state.tournament, payload)
        state.tournament.update(payload)
      }
    },
    [mutations.UPDATE_DIVISION] (state, payload) {
      if (!state.tournament) return
      console.log('Division Update!')
      // console.log(payload)
      const update = (d) => {
        const division = state.tournament.divisions.find(f => f.id === d.id)
        if (division) {
          division.update(d)
        } else if (state.allowAdd) {
          state.tournament.addDivision(d)
        }
      }
      if (Array.isArray(payload)) {
        payload.forEach(f => update(f))
      } else {
        update(payload)
      }
    },
    [mutations.UPDATE_TEAM] (state, payload) {
      console.log('Team Update!')
      if (state.tournament && state.tournament.id === payload.tournamentId) {
        const division = state.tournament.divisions.find(f => f.id === payload.division.id)
        if (division) {
          const update = (dto) => {
            console.log(`   TeamId: ${dto.id}`)
            const team = division.teams.find(f => f.id === dto.id)
            if (team) {
              team.update(dto)
            }
          }
          payload.division.teams.forEach(dto => update(dto))
        }
      }
    },
    [mutations.UPDATE_DAY] (state, payload) {
      console.log('Day Update!')
      if (state.tournament) {
        const update = (dto) => {
          const day = state.tournament.getDay(dto.id)
          if (day) {
            day.update(dto)
          }
        }
        if (Array.isArray(payload)) {
          payload.forEach(dto => update(dto))
        } else {
          update(payload)
        }
      }
    },
    [mutations.UPDATE_BRACKET] (state, payload) {
      console.log('Bracket Update!')
      if (state.tournament) {
        const update = (dto) => {
          const bracket = state.tournament.brackets.find(p => p.id === dto.id)
          if (bracket) {
            bracket.update(dto)
          }
        }
        if (Array.isArray(payload)) {
          payload.forEach(dto => update(dto))
        } else {
          update(payload)
        }
      }
    },
    [mutations.UPDATE_FLIGHT] (state, payload) {
      console.log('Flight Update!')
      if (state.tournament) {
        const update = (dto) => {
          const flight = state.tournament.flights.find(p => p.id === dto.id)
          if (flight) {
            flight.update(dto)
          }
        }
        if (Array.isArray(payload)) {
          payload.forEach(dto => update(dto))
        } else {
          update(payload)
        }
      }
    },
    [mutations.UPDATE_POOL] (state, payload) {
      console.log('Pool Update!')
      if (state.tournament) {
        const update = (dto) => {
          const pool = state.tournament.pools.find(p => p.id === dto.id)
          if (pool) {
            pool.update(dto)
          }
        }
        if (Array.isArray(payload)) {
          payload.forEach(dto => update(dto))
        } else {
          update(payload)
        }
      }
    },
    [mutations.UPDATE_MATCH] (state, payload) {
      console.log('Match Update!')
      if (state.tournament) {
        const update = (dto) => {
          const match = state.tournament.matches.find(m => (dto.id !== 0 && m.id === dto.id) ||
            (dto.poolId && dto.poolId === m.poolId && m.homeTeam.id === dto.homeTeam.id && m.awayTeam.id === dto.awayTeam.id && m.number === dto.number) ||
            (dto.bracketId && dto.bracketId === m.bracketId && m.number === dto.number)
          )
          if (match) {
            match.update(dto)
          } else if (dto.poolId) {
            const pool = state.tournament.pools.find(p => p.id === dto.poolId)
            if (pool) {
              pool.addMatch(dto)
            }
          }
        }

        if (Array.isArray(payload)) {
          payload.forEach(f => update(f))
        } else {
          update(payload)
        }
      }
    },
    [mutations.UPDATE_GAME] (state, payload) {
      console.log('Game Update!')
      if (state.tournament) {
        const update = (dto) => {
          const game = state.tournament.games.find(g => g.id === dto.id)
          if (game) {
            if (game.dtModified) {
              if (!dto.dtModified) return
              const a = +game.dtModified
              const b = +dto.dtModified
              if (a > b) {
                console.log('old update')
                return
              }
            }
            game.home = dto.home
            game.away = dto.away
            game.dtModified = dto.dtModified
          }
        }

        if (Array.isArray(payload)) {
          payload.forEach(f => update(f))
        } else {
          update(payload)
        }
      }
    },
    // START DELETES
    [mutations.DELETE_MATCH] (state, payload) {
      console.log('Delete Match!')
      if (state.tournament) {
        const p = state.tournament.pools.find(f => f.id === payload.poolId)
        const i = p.matches.findIndex(f => f.id === payload.matchId)
        p.matches.splice(i, 1)
      }
    },
    [mutations.DELETE_ROUND] (state, payload) {
      console.log('Delete Round!')
      if (state.tournament) {
        const update = (dayId) => {
          state.tournament.deleteRound(dayId)
        }
        if (Array.isArray(payload)) {
          payload.forEach(dto => update(dto))
        } else {
          update(payload)
        }
      }
    },
    [mutations.RESET_ROUND] (state, payload) {
      console.log('Reset Round!')
      if (state.tournament) {
        const update = (dayId) => {
          state.tournament.resetRound(dayId)
        }
        if (Array.isArray(payload)) {
          payload.forEach(dto => update(dto))
        } else {
          update(payload)
        }
      }
    },
    [mutations.DELETE_SELECTOR] (state, payload) {
      console.log('Delete Selector!')
      if (state.tournament) {
        const update = (id) => {
          state.tournament.deleteSelector(id)
        }
        if (Array.isArray(payload)) {
          payload.forEach(dto => update(dto))
        } else {
          update(payload)
        }
      }
    },
    [mutations.DELETE_TEAM] (state, payload) {
      console.log('Team Delete')
      if (state.tournament) {
        const update = (id) => {
          const t = state.tournament.teams.find(f => f.id === id)
          if (t) t.isDeleted = true
        }
        if (Array.isArray(payload)) {
          payload.forEach(dto => update(dto))
        } else {
          update(payload)
        }
      }
    },
    // START OTHERS
    [mutations.MOVE_TEAM] (state, payload) { // payload should be 2 ints teamId, newDivisionId
      if (state.tournament) {
        state.tournament.changeDivisions(...payload)
      }
    },
    [mutations.SET_DIVISION_JPROP] (state, payload) {
      if (!state.tournament) return

      if (!state.tournament.jProps) {
        state.tournament.jProps = {}
      }
      if (!state.tournament.jProps.divisions) {
        state.tournament.jProps.divisions = {}
      }
      if (!state.tournament.jProps.divisions[payload.id]) {
        state.tournament.jProps.divisions[payload.id] = {}
      }
      Object.assign(state.tournament.jProps.divisions[payload.id], payload.val)
    },
    [mutations.SET_ROUND_JPROP] (state, payload) {
      if (!state.tournament) return

      if (!state.tournament.jProps) {
        state.tournament.jProps = {}
      }
      if (!state.tournament.jProps.rounds) {
        state.tournament.jProps.rounds = {}
      }
      if (!state.tournament.jProps.rounds[payload.id]) {
        state.tournament.jProps.rounds[payload.id] = {}
      }
      Object.assign(state.tournament.jProps.rounds[payload.id], payload.val)
    }
  },
  actions: {
    [actions.LOAD_TOURNAMENT] ({ commit, getters, dispatch }, payload) {
      const tId = +payload.tournamentId
      const dId = +payload.divisionId || null
      const teamId = +payload.teamId || null
      return new Promise((resolve, reject) => {
        if (getters.tournament && getters.tournament.id === +tId) {
          if (dId || teamId) {
            const division = dId ? getters.getDivision(dId) : getters.tournament.getDivisionByTeam(teamId)
            if (division && !division.hydrated) {
              dispatch(actions.SUBSCRIBE_TO_TOURNAMENT, payload)
            }
          }
          return resolve()
        }

        commit(mutations.LOADING, actions.LOAD_TOURNAMENT)
        if (getters.tournament) {
          dispatch(actions.UNSUBSCRIBE_TO_TOURNAMENT, payload)
        }
        const sdk = Vue.prototype.$VBL
        sdk.tournament.getById(tId)
          .then(response => {
            commit(mutations.SET_TOURNAMENT, new Tournament(sdk, response.data))
            dispatch(actions.SUBSCRIBE_TO_TOURNAMENT, payload)
            resolve()
          })
          .catch(e => {
            console.log(e.response)
            reject(e)
          })
          .finally(() => {
            commit(mutations.LOADING_COMPLETE, actions.LOAD_TOURNAMENT)
          })
      })
    },
    [actions.LOAD_MY_TOURNAMENT_TEAMS] ({ commit }, payload) {
      const tId = +payload.tournamentId
      return new Promise((resolve, reject) => {
        const sdk = Vue.prototype.$VBL
        sdk.user.getMyTeams(tId)
          .then(r => {
            commit(mutations.SET_MY_TOURNAMENT_TEAMS, r.data)
            resolve()
          })
          .catch(e => {
            console.log(e.response)
            reject(e)
          })
      })
    },
    [actions.HYDRATE_DIVISION] ({ commit, getters }, divisionId) {
      return new Promise((resolve, reject) => {
        const division = getters.tournament.getDivision(divisionId)
        if (!division) return reject(new Error(`Can't find division with id: ${divisionId}`))
        const sdk = Vue.prototype.$VBL

        commit(mutations.LOADING, actions.HYDRATE_DIVISION)
        division.hydrate(sdk)
          .then(response => {
            return resolve()
          })
          .catch(err => {
            return reject(err)
          })
          .finally(() => {
            commit(mutations.LOADING_COMPLETE, actions.HYDRATE_DIVISION)
          })
      })
    }
  },
  getters: {
    tournament: (state) => {
      return state.tournament
    },
    myEvent: (state) => {
      const t = state.tournament
      return {
        loaded: !!t,
        isTournament: t && t.isTournament,
        isLeague: t && t.isLeague,
        isCamp: t && t.isCamp,
        isClinic: t && t.isClinic,
        isTryout: t && t.isTryout,
        isShowcase: t && t.isShowcase
      }
    },
    regData: (state) => {
      return state.regData
    },
    messageTeamIds: (state) => {
      return state.messageTeamIds
    },
    locked: (state) => {
      return state.tournament && state.tournament.locked
    },
    getDivision: (state) => (id) => {
      return state.tournament && state.tournament.divisions.find(d => d.id === id)
    },
    getRound: (state) => (id) => {
      return state.tournament && state.tournament.rounds.find(d => d.id === id)
    },
    getRoundIdByI: (state) => (i) => {
      return state.tournament && state.tournament.rounds[i] && state.tournament.rounds[i].id
    },
    getDivisionJProps: (state) => (id) => {
      return state.tournament && state.tournament.jProps && state.tournament.jProps.divisions && state.tournament.jProps.divisions[id]
    },
    getDivisionJProp: (state) => (id, prop) => {
      return state.tournament && state.tournament.jProps && state.tournament.jProps.divisions && state.tournament.jProps.divisions[id] && state.tournament.jProps.divisions[id][prop]
    },
    getRoundJProps: (state) => (id) => {
      return state.tournament && state.tournament.jProps && state.tournament.jProps.rounds && state.tournament.jProps.rounds[id]
    },
    getTeam: (state) => (id) => {
      return id ? state.tournament.teams.find(d => d.id === id) : null
    },
    getPoolTeamSummary: (state) => (teamId, poolId) => {
      const pool = state.tournament.pools.find(f => f.id === poolId)
      const team = pool.teams.find(t => t.teamId === teamId)
      return team && team.summary
    },
    getRepById: (state) => (id, field) => {
      const team = state.tournament.teams.find(d => d.id === id)
      if (!team) console.log(id)
      return team.getRep(field)
    },
    host: (state) => {
      return state.tournament && state.tournament.organization.username
    },
    teamColors: () => {
      return [
        'red--text text--darken-4',
        'purple--text text--darken-4',
        'blue--text text--darken-4',
        'cyan--text text--darken-4',
        'green--text text--darken-4',
        'yellow--text text--darken-4',
        'deep-orange--text text--darken-4',
        'blue-grey--text text--darken-4',
        'pink--text text--darken-4',
        'deep-purple--text text--darken-4',
        'indigo--text text--darken-4',
        'teal--text text--darken-4',
        'lime--text text--darken-4',
        'amber--text text--darken-4',
        'brown--text text--darken-4',
        'grey--text text--darken-4'
      ]
    },
    teamColorsInverse: () => {
      return [
        'red darken-4',
        'purple darken-4',
        'blue darken-4',
        'cyan darken-4',
        'green darken-4',
        'yellow darken-4',
        'deep-orange darken-4',
        'blue-grey darken-4',
        'pink darken-4',
        'deep-purple darken-4',
        'indigo darken-4',
        'teal darken-4',
        'lime darken-4',
        'amber darken-4',
        'brown darken-4',
        'grey darken-4'
      ]
    }
  }
}
