<template>
  <v-list-item v-if="noEmit">
    <v-list-item-avatar>
      <img :src="profilePic || (player.male ? 'https://vblimages.blob.core.windows.net/images/avatar-male.jpg' : 'https://vblimages.blob.core.windows.net/images/avatar-female.jpg')">
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="grey--text text--lighten-1 caption" v-if="showId">Player #{{player.id}}</v-list-item-title>
      <v-list-item-title>
        {{player.fullName}} {{ gradYear }}
        <span class="grey--text text--lighten-1 caption">
          {{player.email ? ` - ${player.email}` : ''}}
        </span>
      </v-list-item-title>
      <v-list-item-subtitle>{{subtitle | pipeArray}}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
  <v-list-item v-else @click.stop="$emit('click', player)">
    <v-list-item-avatar>
      <img :src="profilePic || (player.male ? 'https://vblimages.blob.core.windows.net/images/avatar-male.jpg' : 'https://vblimages.blob.core.windows.net/images/avatar-female.jpg')">
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title v-if="showId">{{player.id}}</v-list-item-title>
      <v-list-item-title>
        {{player.fullName}} {{ gradYear }}
        <span class="grey--text text--lighten-1 caption">
          {{player.email ? ` - ${player.email}` : ''}}
        </span>
      </v-list-item-title>
      <v-list-item-subtitle>{{subtitle | pipeArray}}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { season } from '@/classes/ProfileHelpers'
import { ageDivisionYouth } from '@/Filters'

export default {
  props: ['player', 'showId', 'noEmit'],
  computed: {
    subtitle () {
      const a = [this.player.cityState, this.age]
      return a.filter(f => !!f)
    },
    profilePic () {
      return this.player.profilePic
    },
    gradYear () {
      const g = this.player.gradYear
      return g && g >= season() ? g : null
    },
    age () {
      return ageDivisionYouth(this.player.ageGroup)
    }
  }
}
</script>

<style scoped>

</style>
