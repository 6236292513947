import AuthGuard from '@/router/methods/auth-guard'

const OrgAdmin = () => import('@/Pages/_OrgUsername/Index.vue')
const Settings = () => import('@/Pages/_OrgUsername/Settings.vue')
const Staff = () => import('@/Pages/_OrgUsername/Staff.vue')
const OrganizationHome = () => import('@/Pages/_OrgUsername/Home.vue')
const Theme = () => import('@/Pages/_OrgUsername/Theme.vue')
const Images = () => import('@/Pages/_OrgUsername/Images.vue')
const Players = () => import('@/Pages/_OrgUsername/Players.vue')
const RegistrationSearch = () => import('@/Pages/_OrgUsername/RegistrationSearch.vue')
const Emails = () => import('@/Pages/_OrgUsername/Emails.vue')
const Members = () => import('@/Pages/_OrgUsername/Members.vue')
const Templates = () => import('@/Pages/_OrgUsername/Templates.vue')
const Agreements = () => import('@/Pages/_OrgUsername/Agreements.vue')
const Affiliates = () => import('@/Pages/_OrgUsername/Affiliates.vue')
const Exports = () => import('@/Pages/_OrgUsername/Exports.vue')
const Points = () => import('@/Pages/_OrgUsername/Points.vue')

const Reports = () => import('@/Pages/_OrgUsername/Reports.vue')
// const ReportDetails = () => import('@/components/Reporting/CustomReportDisplay.vue')

const MemberList = () => import('@/components/Membership/Admin/MemberList.vue')
const MemberLevels = () => import('@/components/Membership/Admin/MembershipLevels.vue')
const TeamImporter = () => import('@/components/Tournament/Importer/TeamImporter.vue')
const OrganizationManager = () => import('@/components/PointSystem/OrganizationManager.vue')
// New Cart Manager
const CartManager = () => import('@/Pages/_OrgUsername/CartManager/Index.vue')
const CartHome = () => import('@/Pages/_OrgUsername/CartManager/Home.vue')
const Promos = () => import('@/Pages/_OrgUsername/CartManager/Promos.vue')
const RefundPolicies = () => import('@/Pages/_OrgUsername/CartManager/RefundPolicies.vue')
const Products = () => import('@/Pages/_OrgUsername/CartManager/Products.vue')
// Ratings
const Ratings = () => import('@/Pages/_OrgUsername/Ratings.vue')
const RatingsSettings = () => import('@/components/Ratings/Settings')
const RatingsLevels = () => import('@/components/Ratings/RatingLevels')
const RatingsResults = () => import('@/components/Ratings/TournamentResults')
const RatingsOrganizationManager = () => import('@/components/Ratings/OrganizationManager')
const RatingsPlayers = () => import('@/components/Ratings/Players')

export default [
  {
    path: '/:username',
    beforeEnter: AuthGuard,
    component: OrgAdmin,
    props: true,
    children: [
      {
        path: '',
        name: 'organization-home',
        component: OrganizationHome,
        beforeEnter: AuthGuard,
        props: true,
        meta: { requiresUser: true }
      },
      {
        path: 'staff',
        name: 'organization-staff',
        component: Staff,
        beforeEnter: AuthGuard,
        props: true,
        meta: { requiresUser: true }
      },
      {
        path: 'settings',
        name: 'organization-settings',
        component: Settings,
        beforeEnter: AuthGuard,
        props: true,
        meta: { requiresUser: true }
      },
      {
        path: 'theme',
        name: 'organization-theme',
        component: Theme,
        beforeEnter: AuthGuard,
        props: true,
        meta: { requiresUser: true }
      },
      {
        path: 'images',
        name: 'organization-images',
        component: Images,
        beforeEnter: AuthGuard,
        props: true,
        meta: { requiresUser: true }
      },
      {
        path: 'promos',
        name: 'organization-promos',
        component: Promos,
        beforeEnter: AuthGuard,
        props: true,
        meta: { requiresUser: true }
      },
      {
        path: 'players',
        name: 'organization-players',
        component: Players,
        beforeEnter: AuthGuard,
        props: true,
        meta: { requiresUser: true }
      },
      {
        path: 'reg-search',
        name: 'organization-reg-search',
        component: RegistrationSearch,
        beforeEnter: AuthGuard,
        props: true,
        meta: { requiresUser: true }
      },
      {
        path: 'emails',
        name: 'organization-emails',
        component: Emails,
        beforeEnter: AuthGuard,
        props: true,
        meta: { requiresUser: true }
      },
      {
        path: 'members',
        component: Members,
        beforeEnter: AuthGuard,
        props: true,
        meta: { requiresUser: true },
        children: [
          {
            path: '',
            name: 'organization-members',
            component: MemberList,
            beforeEnter: AuthGuard,
            props: true
          },
          {
            path: 'levels',
            name: 'organization-members-levels',
            component: MemberLevels,
            beforeEnter: AuthGuard,
            props: true
          }
        ]
      },
      {
        path: 'templates',
        name: 'organization-templates',
        component: Templates,
        beforeEnter: AuthGuard,
        props: true,
        meta: { requiresUser: true }
      },
      {
        path: 'import',
        name: 'organization-import',
        component: TeamImporter,
        beforeEnter: AuthGuard,
        props: true,
        meta: { requiresUser: true }
      },
      {
        path: 'agreements',
        name: 'organization-agreements',
        component: Agreements,
        beforeEnter: AuthGuard,
        props: true,
        meta: { requiresUser: true }
      },
      {
        path: 'agreement/:aId',
        name: 'organization-agreement',
        component: Agreements,
        beforeEnter: AuthGuard,
        props: true,
        meta: { requiresUser: true }
      },
      {
        path: 'agreement/:aId/players',
        name: 'organization-agreement-players',
        component: Agreements,
        beforeEnter: AuthGuard,
        props: true,
        meta: { requiresUser: true }
      },
      {
        path: 'affiliates',
        name: 'organization-affiliates',
        component: Affiliates,
        beforeEnter: AuthGuard,
        props: true,
        meta: { requiresUser: true }
      },
      {
        path: 'points',
        name: 'pointsystem-points',
        component: Points,
        beforeEnter: AuthGuard,
        props: true,
        meta: { requiresUser: true }
      },
      {
        path: 'MemberOrganizations',
        name: 'pointsystem-organizations',
        component: OrganizationManager,
        beforeEnter: AuthGuard,
        props: true,
        meta: { requiresUser: true }
      },
      {
        path: 'Export',
        name: 'organization-exports',
        component: Exports,
        beforeEnter: AuthGuard,
        props: true,
        meta: { requiresUser: true }
      },
      {
        path: 'ratings',
        component: Ratings,
        beforeEnter: AuthGuard,
        props: true,
        meta: { requiresUser: true },
        children: [
          {
            path: '',
            name: 'ratings-manager-settings',
            component: RatingsSettings,
            beforeEnter: AuthGuard,
            props: true,
            meta: { requiresUser: true }
          },
          {
            path: 'levels',
            name: 'ratings-manager-levels',
            component: RatingsLevels,
            beforeEnter: AuthGuard,
            props: true,
            meta: { requiresUser: true }
          },
          {
            path: 'results',
            name: 'ratings-manager-results',
            component: RatingsResults,
            beforeEnter: AuthGuard,
            props: true,
            meta: { requiresUser: true }
          },
          {
            path: 'organizations',
            name: 'ratings-manager-organizations',
            component: RatingsOrganizationManager,
            beforeEnter: AuthGuard,
            props: true,
            meta: { requiresUser: true }
          },
          {
            path: 'players',
            name: 'ratings-manager-players',
            component: RatingsPlayers,
            beforeEnter: AuthGuard,
            props: true,
            meta: { requiresUser: true }
          }
        ]
      },
      {
        path: 'CartManager',
        component: CartManager,
        beforeEnter: AuthGuard,
        props: true,
        meta: { requiresUser: true },
        children: [
          {
            path: '',
            name: 'cart-manager',
            component: CartHome,
            beforeEnter: AuthGuard,
            props: true,
            meta: { requiresUser: true }
          },
          {
            path: 'promos',
            name: 'cart-manager-promos',
            component: Promos,
            beforeEnter: AuthGuard,
            props: true,
            meta: { requiresUser: true }
          },
          {
            path: 'refund-policies',
            name: 'cart-manager-refund-policies',
            component: RefundPolicies,
            beforeEnter: AuthGuard,
            props: true,
            meta: { requiresUser: true }
          },
          {
            path: 'products',
            component: Products,
            beforeEnter: AuthGuard,
            props: true,
            meta: { requiresUser: true },
            children: [
              {
                path: '',
                name: 'cart-manager-products',
                component: Products,
                beforeEnter: AuthGuard,
                props: true,
                meta: { requiresUser: true }
              },
              {
                path: ':sku',
                name: 'cart-manager-product-details',
                component: Products,
                beforeEnter: AuthGuard,
                props: true,
                meta: { requiresUser: true }
              }
            ]
          }
        ]
      },
      {
        path: 'reports',
        component: Reports,
        beforeEnter: AuthGuard,
        props: true,
        meta: { requiresUser: true },
        children: [
          {
            path: '',
            name: 'organization-reports',
            component: Reports,
            beforeEnter: AuthGuard,
            props: true,
            meta: { requiresUser: true }
          },
          {
            path: ':id',
            name: 'report-details',
            component: Reports,
            beforeEnter: AuthGuard,
            props: true,
            meta: { requiresUser: true }
          }
        ]
      }
    ]
  }
]
