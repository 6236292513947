import EventHome from '@/Pages/Event/Index.vue'

export default [
  {
    path: '/event/:username/:eventTag',
    name: 'event-home',
    component: EventHome,
    props: true
  },
  {
    path: '/event/:username/:eventTag/admin',
    name: 'event-admin',
    component: EventHome,
    props: true
  }
]
