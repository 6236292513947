<template>
  <v-container fluid :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
    <!-- Publish Alert -->
        <v-alert
          type="warning"
          :value="view.admin && !vblEvent.isPublic"
          icon="far fa-eye-slash"
          prominent tile
          class="mb-0"
        >
          <v-row align="center">
            <v-col class="grow">
              <strong class="black--text">Only you can see this. This event is not yet published.</strong>
            </v-col>
            <v-col class="shrink">
              <v-btn
              :loading="loading"
              @click.stop="publish"
              >publish</v-btn>
            </v-col>
          </v-row>
        </v-alert>
    <v-row dense v-if="view.admin || vblEvent.isPublic">
      <v-col cols="12">
        <v-card tile>
          <v-toolbar
            absolute
            :floating="true"
            dense
            :class="{'ma-1': $vuetify.breakpoint.xs, 'ma-2': $vuetify.breakpoint.sm, 'ma-3': $vuetify.breakpoint.mdAndUp}"
            :style="`background-color: rgba(0, 0, 0, .7);${fontSize}`"
          >
            <v-toolbar-title class="white--text mr-4" :style="`white-space: inherit; ${fontSize}`">
              {{vblEvent.title}}
            </v-toolbar-title>
          </v-toolbar>
          <!-- Pic and Title -->
          <v-carousel
            v-if="vblEvent.coverPhotos.length > 7"
            :height="$vuetify.breakpoint.xs ? 250 : 360"
            hide-controls
          >
            <v-carousel-item
              v-for="image in vblEvent.coverPhotos"
              :key="image.id"
              :src="image.url"
            >
            </v-carousel-item>
          </v-carousel>
          <v-img
            v-else-if="vblEvent.coverPhotos.length"
            :src="vblEvent.coverPhotos[0].url"
            :cover="!vblEvent.coverPhotos[0].contain"
            :contain="vblEvent.coverPhotos[0].contain"
            :height="vblEvent.coverPhotos[0].contain ? null : $vuetify.breakpoint.xs ? 250 : 360"
            :max-height="vblEvent.coverPhotos[0].contain ? $vuetify.breakpoint.xs ? 250 : 360 : null"
            :position="vblEvent.coverPhotos[0].position"
          >
          </v-img>
          <!-- Date Bar -->
          <v-toolbar
            v-if="!$vuetify.breakpoint.xs"
            flat
            dense
            color="color1 color1Text--text"

          >
            <v-toolbar-title :style="fontSize">
              {{dateDisplay}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title :style="fontSize">
              {{vblEvent.locationNames | formatArraySorted}}
            </v-toolbar-title>
          </v-toolbar>
          <v-card v-else color="color1 color1Text--text">
            <v-card-title>
              {{dateDisplay}}
              <br>
              {{vblEvent.locationNames | formatArraySorted}}
            </v-card-title>
          </v-card>
          <!-- tabs -->
          <v-tabs
            fixed
            :icons-and-text="!$vuetify.breakpoint.xs"
            centered
            v-model="activeTab"
            background-color="color2"
            slider-color="color3"
          >
            <v-tab href="#information" ripple append v-if="vblEvent.brochureHTML">
              <span class="hidden-xs-only color2Text--text">Information</span>
              <v-icon color="color2Text">fas fa-info-circle</v-icon>
            </v-tab>
            <slot name="tabs"></slot>
            <v-tab href="#location" ripple append v-if="!!vblEvent.locations.length">
              <span class="hidden-xs-only color2Text--text">{{vblEvent.locations.length == 1 ? 'Location' : 'Locations'}}</span>
              <v-icon color="color2Text">fas fa-map-marker-alt</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items touchless v-model="activeTab">
            <!-- INFO -->
            <v-tab-item key="information" value="information" v-if="vblEvent.brochureHTML">
              <v-card flat>
                <v-card-text v-html="vblEvent.brochureHTML"></v-card-text>
                <v-card-text v-if="vblEvent.infoAlerts && vblEvent.infoAlerts.length > 0" class="pt-0">
                  <v-alert
                    v-for="alert in vblEvent.infoAlerts"
                    :key="alert.id"
                    :type="alert.type"
                    :value="true"
                    :dismissible="alert.dismissable"
                  >
                    {{alert.text}}
                  </v-alert>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <slot name="tab-items"></slot>
            <!-- LOCATION -->
            <v-tab-item key="location" value="location" v-if="!!vblEvent.locations.length">
              <v-card>
                <v-card-title v-if="vblEvent.locations.length === 1">
                  <iframe width="100%" height="450" frameborder="0" style="border:0" :src="vblEvent.locations[0].googleUrl" allowfullscreen></iframe>
                </v-card-title>
                <v-tabs
                  v-else
                  v-model="activeLocation"
                  color="color2"
                  slider-color="color2"
                  fixed
                  centered
                >
                  <v-tab v-for="location in vblEvent.locations" :key="location.id">
                    {{location.name}}
                  </v-tab>

                  <v-tab-item v-for="location in vblEvent.locations" :key="location.id">
                    <v-card>
                      <v-card-text>
                        <iframe width="100%" height="450" frameborder="0" style="border:0" :src="location.googleUrl" allowfullscreen></iframe>
                      </v-card-text>
                      <slot name="location-tab"></slot>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { mapGetters } from 'vuex'

export default {
  props: ['vblEvent', 'view', 'startTab'],
  data () {
    return {
      loading: false,
      publishing: false,
      activeTab: null,
      activeLocation: null
    }
  },
  computed: {
    fontSize () {
      return this.$vuetify.breakpoint.xs ? 'font-size: small;' : this.$vuetify.breakpoint.sm ? 'font-size: medium;' : ''
    },
    dateDisplay () {
      return this.$options.filters.readableDateRange(this.vblEvent.dtStart, this.vblEvent.dtEnd)
    },
    qtab () {
      return this.$route.query.tab
    }
  },
  methods: {
    publish () {
      const dto = {
        id: this.vblEvent.id,
        isPublic: true
      }
      this.loading = true
      this.$VBL.post.series(dto)
        .then(r => {
          this.vblEvent.isPublic = true
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    },
    saveState (tab) {
      const query = {}
      Object.assign(query, this.$route.query)
      if (tab !== (query.tab)) {
        query.tab = tab

        this.$router.push({
          query
        })
      }
    }
  },
  watch: {
    activeTab: function (n, o) {
      if (n && o && o === 'settings') {
        if (this.$refs && this.$refs.editor) {
          this.$refs.editor.cancel()
        }
      }
      this.saveState(n)
    },
    qtab: function (val) {
      if (val && this.activeTab !== val) this.activeTab = val
    }
  },
  mounted () {
    if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab
    } else if (this.startTab) {
      this.activeTab = this.startTab
    }
  }
}
</script>

<style>

</style>
