<template>
  <v-card :color="bgColor">
    <v-toolbar dense color="color2 color2Text--text">
      <v-toolbar-title>
        {{title || (i ? `Player ${i}` : 'Player')}}
      </v-toolbar-title>
    </v-toolbar>
    <v-container fluid>
      <v-row dense v-for="(field,i) in displayFields" :key="i">
        <v-col cols="12" v-if="$vuetify.breakpoint.xsOnly">
          <strong>{{field.label}}: </strong>{{field.value}}
        </v-col>
        <template v-else>
          <v-col class="text-right" cols="4" sm="3" md="2" >
            <strong>{{field.label}}:</strong>
          </v-col>
          <v-col cols="8" sm="9" md="10">
            {{field.value}}
          </v-col>
        </template>
      </v-row>
      <slot></slot>
    </v-container>
  </v-card>
</template>

<script>

export default {
  props: ['fields', 'requiredFields', 'player', 'sanctioningBodyId', 'i', 'bgColor', 'title', 'adds', 'registration', 'tournament'],
  data () {
    return {
    }
  },
  computed: {
    additionalFields () {
      const r = []
      if (this.registration && this.registration.division && this.tournament) {
        const fields = this.tournament.getCustomFields(this.registration.division.id)
        if (fields && fields.length > 0) {
          fields.filter(f => f.perPlayer).forEach(field => {
            const l = `${this.registration.division.personType} ${this.i} ${field.label}`
            r.push({ label: l, value: this.registration.getAddField(l) })
          })
        }
      }
      return r
    },

    _fields () {
      return this.fields.concat(this.requiredFields)
    },
    sanctioningBody () {
      return this.sanctioningBodyId ? this.sanctioningBodyId.toLowerCase().startsWith('avp') ? 'AVP' : this.sanctioningBodyId : ''
    },
    displayFields () {
      const list = [
        { label: 'Name', value: this.player.fullname }
      ]
      this._fields.includes('gender') && list.push({ label: 'Gender', value: this.player.male ? 'Male' : 'Female' })
      this.sanctioningBody.toLowerCase() === 'aau' && list.push({ label: 'AAU #', value: this.player.isOnFile('aauNumber') ? 'On File' : this.player.aauNumber })
      this.sanctioningBody.toLowerCase() === 'avp' && list.push({ label: 'AVP America #', value: this.player.isOnFile('avpNumber') ? 'On File' : this.player.avpNumber })
      this.sanctioningBody.toLowerCase() === 'p1440' && list.push({ label: 'p1440 #', value: this.player.isOnFile('p1440Number') ? 'On File' : this.player.p1440Number })
      this.sanctioningBody.toLowerCase() === 'cbva' && list.push({ label: 'CBVA #', value: this.player.isOnFile('cbvaNumber') ? 'On File' : this.player.cbvaNumber })
      this.sanctioningBody.toLowerCase() === 'usav' && list.push({ label: 'USAV #', value: this.player.isOnFile('usavNumber') ? 'On File' : this.player.usavNumber })
      this._fields.includes('phone') && list.push({ label: 'Mobile Phone', value: this.player.isOnFile('phone') ? 'On File' : this.player.phone })
      this._fields.includes('email') && list.push({ label: 'Email', value: this.player.isOnFile('email') ? 'On File' : this.player.email })
      this._fields.includes('fullAddress') && list.push({ label: 'Address', value: this.player.isOnFile('address') ? 'On File' : this.player.address })
      this._fields.includes('fullAddress') && this.player.address2 && list.push({ label: 'Address 2', value: this.player.isOnFile('address') ? 'On File' : this.player.address2 })
      if (this._fields.includes('fullAddress') || this._fields.includes('cityState')) list.push({ label: 'City', value: this.player.isOnFile('city') ? 'On File' : this.player.city })
      if (this._fields.includes('fullAddress') || this._fields.includes('cityState')) list.push({ label: 'State', value: this.player.isOnFile('state') ? 'On File' : this.player.state })
      this._fields.includes('fullAddress') && list.push({ label: 'Zip', value: this.player.isOnFile('zip') ? 'On File' : this.player.zip })
      this._fields.includes('country') && list.push({ label: 'Country', value: this.player.isOnFile('country') ? 'On File' : this.player.country })
      this._fields.includes('dob') && list.push({ label: 'Birthdate', value: this.player.dob ? this.player.dobF : this.player.isOnFile('dob') ? 'On File' : '' })
      this._fields.includes('gradyear') && list.push({ label: 'Grad Year', value: this.player.isOnFile('gradYear') ? 'On File' : this.player.gradYear })
      if (this._fields.includes('ibvl') || this._fields.includes('highSchool')) list.push({ label: 'High School', value: this.player.isOnFile('highSchool') ? 'On File' : this.player.highSchool })
      this._fields.includes('gpa') && list.push({ label: 'GPA', value: this.player.isOnFile('gpa') ? 'On File' : this.player.gpa })
      this._fields.includes('club') && list.push({ label: 'Club', value: this.player.isOnFile('club') ? 'On File' : this.player.club })
      this._fields.includes('shirtSize') && list.push({ label: 'T-Shirt Size', value: this.player.isOnFile('shirtSize') ? 'On File' : this.player.shirtSize })
      this._fields.includes('height') && list.push({ label: 'Height', value: this.player.isOnFile('height') ? 'On File' : this.player.height })
      this._fields.includes('playerPhone') && list.push({ label: 'Player\'s Phone', value: this.player.isOnFile('playerPhone') ? 'On File' : this.player.playerPhone })
      this._fields.includes('playerEmail') && list.push({ label: 'Player\'s Email', value: this.player.isOnFile('playerEmail') ? 'On File' : this.player.playerEmail })
      this._fields.includes('clubDirectorName') && list.push({ label: 'Club Director\'s Name', value: this.player.isOnFile('clubDirectorName') ? 'On File' : this.player.clubDirectorName })
      this._fields.includes('clubDirectorEmail') && list.push({ label: 'Club Director\'s Email', value: this.player.isOnFile('clubDirectorEmail') ? 'On File' : this.player.clubDirectorEmail })
      this._fields.includes('sandRecruitsNumber') && list.push({ label: 'Sand Recruits Number', value: this.player.isOnFile('sandRecruitsNumber') ? 'On File' : this.player.sandRecruitsNumber })
      this._fields.includes('committed') && list.push({ label: 'Committed', value: this.player.isOnFile('committed') ? 'On File' : this.player.committed === true ? 'Yes' : this.player.committed === false ? 'No' : '' })
      this._fields.includes('committed') && this.player.committed && list.push({ label: 'School Committed To', value: this.player.isOnFile('committedSchool') ? 'On File' : this.player.committedSchool })
      this._fields.includes('yearsExp') && list.push({ label: 'Beach Experience', value: this.player.isOnFile('yearsExp') ? 'On File' : this.player.yearsExp })
      this._fields.includes('hand') && list.push({ label: 'Dominant hand', value: this.player.isOnFile('hand') ? 'On File' : this.player.hand })
      this._fields.includes('side') && list.push({ label: 'Preferred Side', value: this.player.isOnFile('side') ? 'On File' : this.player.side })
      this._fields.includes('instagram') && list.push({ label: 'Instagram Handle', value: this.player.isOnFile('instagram') ? 'On File' : this.player.instagram })

      this._fields.includes('parent') && list.push({ label: 'Parent', value: this.player.isOnFile('parent') ? 'On File' : `${this.player.parent.firstName} ${this.player.parent.lastName}` })
      this._fields.includes('parent') && !this.player.isOnFile('parent') && list.push({ label: 'Parent Phone', value: this.player.parent.phone })
      this._fields.includes('parent') && !this.player.isOnFile('parent') && list.push({ label: 'Parent Email', value: this.player.parent.email })

      this._fields.includes('emergency') && list.push({ label: 'Emergency Contact', value: this.player.isOnFile('emergency') ? 'On File' : `${this.player.emergency.firstName} ${this.player.emergency.lastName}` })
      this._fields.includes('emergency') && !this.player.isOnFile('emergency') && list.push({ label: 'Emergency Contact Phone', value: this.player.emergency.phone })
      this._fields.includes('emergency') && !this.player.isOnFile('emergency') && list.push({ label: 'Emergency Contact Email', value: this.player.emergency.email })

      this.sanctioningBody.toLowerCase() !== 'aau' && this._fields.includes('aau') && list.push({ label: 'AAU #', value: this.player.isOnFile('aauNumber') ? 'On File' : this.player.aauNumber })
      this.sanctioningBody.toLowerCase() !== 'avp' && this._fields.includes('avp') && list.push({ label: 'AVP #', value: this.player.isOnFile('avpNumber') ? 'On File' : this.player.avpNumber })
      this.sanctioningBody.toLowerCase() !== 'cbva' && this._fields.includes('cbva') && list.push({ label: 'CBVA #', value: this.player.isOnFile('cbvaNumber') ? 'On File' : this.player.cbvaNumber })
      this.sanctioningBody.toLowerCase() !== 'usav' && this._fields.includes('usav') && list.push({ label: 'USAV #', value: this.player.isOnFile('usavNumber') ? 'On File' : this.player.usavNumber })

      if (this.additionalFields) {
        list.push(...this.additionalFields)
      }
      return this.adds ? [...list, ...this.adds] : list
    }
  }
}
</script>

<style>

</style>
