import * as mutations from '../MutationTypes'
// import Cart from '../../classes/Cart'
import * as actions from '../ActionTypes'
import sum from 'lodash.sum'
import max from 'lodash.max'
import flatten from '@/helpers/ArrayFlatten'
import Vue from 'vue'

export default {
  state: {
    carts: []
  },
  mutations: {
    [mutations.ADD_CART_ITEM] (state, newItem) {
      let i = state.carts.findIndex(f => f.organization.id === newItem.organization.id)
      if (i === -1) {
        state.carts.push({ organization: newItem.organization, items: [], status: true, stripeAccount: null })
        i = state.carts.length - 1
      }
      const cart = state.carts[i]
      let id = 1
      if (cart.items.length) {
        id = max(cart.items.map(item => item.id)) + 1
      }
      newItem.id = id
      cart.items.push(newItem)
    },
    [mutations.SET_CART_STATUS] (state, dto) {
      const cart = state.carts.find(f => f.organization.id === dto.organization.id)
      if (!cart) return

      Object.assign(cart, dto.organization)
      cart.status = dto.status
      cart.stripeAccount = dto.stripeAccount
      cart.paymentType = dto.organization.paymentType
      cart.allowPayLater = dto.organization.allowPayLater
    },
    [mutations.REMOVE_CART_ITEM] (state, item) {
      const i = state.carts.findIndex(f => f.organization.id === item.organization.id)
      if (i === -1) return

      const cart = state.carts[i]
      const x = cart.items.findIndex(f => f.id === item.id)
      if (x !== -1) {
        cart.items.splice(x, 1)
      }
    },
    [mutations.CLEAN_CARTS] (state) {
      for (let i = 0; i < state.carts.length; i++) {
        const cart = state.carts[i]
        if (!cart.items.length || cart.orderId || cart.organization === null) state.carts.splice(i, 1)
      }
    },
    [mutations.UPDATE_CART_ITEM] (state, item) {
      const i = state.carts.findIndex(f => f.organization.id === item.organization.id)
      if (i === -1) return

      const cart = state.carts[i]
      const cartItem = cart.items.find(f => f.id === item.id)
      cartItem && Object.assign(cartItem, item)
    },
    [mutations.CLEAR_CART] (state, organizationId) {
      const i = state.carts.findIndex(f => f.organization.id === organizationId)
      if (i === -1) return

      const cart = state.carts[i]
      cart.items = []
    },
    [mutations.SET_CART_ORDER_ID] (state, payload) {
      const cart = state.carts.find(f => f.organization.id === payload.organizationId)
      if (!cart) {
        console.log('Cart not found')
        return
      }
      cart.orderId = payload.orderId
      cart.emailReceipt = payload.emailReceipt
      cart.jot = [{ text: 'Your Order Id', value: payload.orderId }]
      cart.jot.push(...payload.jot)
    },
    [mutations.APPLY_PROMO] (state, payload) {
      const cart = state.carts.find(f => f.organization.id === payload.organizationId)
      if (!cart) {
        console.log('Cart not found')
        return
      }
      cart.promo = payload.code
    },
    [mutations.CLEAR_PROMO] (state, payload) {
      const cart = state.carts.find(f => f.organization.id === payload)
      if (!cart) {
        console.log('Cart not found')
        return
      }
      cart.items.forEach(i => { i.promo = null })
      cart.promo = null
    }
  },
  actions: {
    [actions.REMOVE_CART_ITEM] ({ commit }, payload) {
      commit(mutations.REMOVE_CART_ITEM, payload)
      commit(mutations.CLEAN_CARTS)
    },
    [actions.CHECK_PROMO] ({ commit, dispatch }, payload) { // payload = {organizationId, code, items }
      return new Promise((resolve, reject) => {
        const sdk = Vue.prototype.$VBL
        sdk.cart.applyPromo(payload)
          .then(r => {
            commit(mutations.CLEAR_PROMO, payload.organizationId)
            if (r.data.items) {
              r.data.items.forEach(i => {
                payload.items = r.data.items
                dispatch(actions.APPLY_PROMO, payload)
              })
              resolve()
            } else {
              resolve(r.data)
            }
          })
          .catch(e => reject(e))
      })
    },
    [actions.APPLY_PROMO] ({ commit, state }, payload) {
      const cart = state.carts.find(f => f.organization.id === payload.organizationId)
      if (!cart) {
        console.log('Cart not found')
        return
      }

      payload.items.forEach(i => {
        i.organization = cart.organization
        commit(mutations.UPDATE_CART_ITEM, i)
      })
      commit(mutations.APPLY_PROMO, payload)
    },
    [actions.CHECK_CART_STATUS] ({ commit, getters }, organizationId) {
      return new Promise((resolve, reject) => {
        const cart = getters.getCart(organizationId)
        if (!cart) return reject(new Error(`Can't find cart with id: ${organizationId}`))
        const sdk = Vue.prototype.$VBL
        sdk.cart.getStatus(organizationId)
          .then(r => {
            commit(mutations.SET_CART_STATUS, r.data)
            return resolve()
          })
          .catch(error => {
            console.log(error.response)
            return reject(error)
          })
      })
    },
    [actions.PURCHASE_COMPLETE] ({ commit }, payload) {
      commit(mutations.SET_CART_ORDER_ID, payload)
      commit(mutations.CLEAR_CART, payload.organizationId)
    },
    [actions.ADD_CART_ITEM] ({ commit, state, dispatch }, payload) {
      commit(mutations.CLEAN_CARTS)
      commit(mutations.ADD_CART_ITEM, payload)
      const cart = state.carts.find(f => f.organization.id === payload.organization.id)
      if (!cart) {
        console.log('Cart not found')
        return
      }
      if (cart.promo) {
        dispatch(actions.CHECK_PROMO, {
          organizationId: cart.organization.id,
          code: cart.promo,
          items: cart.items
        })
      }
    }
  },
  getters: {
    carts: (state) => {
      return state.carts
    },
    getCart: (state) => (organizationId) => {
      return organizationId ? state.carts.find(f => f.organization.id === organizationId) : state.carts[0]
    },
    cartCount: state => {
      return state.carts.length
    },
    cartsItemCount: state => {
      return sum(
        flatten(
          state.carts.map(m => m.items.map(f => f.quantity))
        )
      )
    }
  }
}
