import DtoUpdate from './DtoUpdate'
import moment from 'moment'
import { firstBy } from 'thenby'

export default class VblEvent {
  id = 0
  title = null
  subtitle = null
  brochureHTML = null
  _dtStart = null
  _dtEnd = null
  isPublic = false
  coverPhotos = []
  organizers = []
  locations = []
  type = null
  adminId = null
  _settings = null

  constructor (dto, type, exclude) {
    this.type = type
    if (dto) {
      this.update(dto.dto || dto, exclude || [])
    }
  }

  update (dto, exclude) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    // exclude.push()
    DtoUpdate(dto, this, exclude)
    this.coverPhotos.sort(firstBy('order'))
    if (!this.dtEnd) this.dtEnd = this.dtStart
  }

  // getters-setters
  set dtStart (val) {
    this._dtStart = val ? moment.utc(val).format('YYYY-MM-DD[T]HH:mm[Z]') : null
  }

  get dtStart () {
    return this._dtStart ? moment.utc(this._dtStart) : null
  }

  set dtEnd (val) {
    this._dtEnd = val ? moment.utc(val).format('YYYY-MM-DD[T]HH:mm[Z]') : null
  }

  get dtEnd () {
    return this._dtEnd ? moment.utc(this._dtEnd) : null
  }

  set settings (val) {
    if (typeof val === 'string') val = JSON.parse(val)
    this._settings = val
  }

  get settings () {
    return this._settings
  }

  get coverBackground () {
    return this.coverPhotos.find(f => f.order === 0)
  }

  get covers () {
    return this.coverPhotos.filter(f => f.order > 0)
  }

  // getters
  get dto () {
    return {
      id: this.id,
      title: this.title,
      titleTag: this.titleTag,
      subtitle: this.subtitle,
      brochureHTML: this.brochureHTML,
      dtStart: this._dtStart,
      dtEnd: this._dtEnd,
      isPublic: this.isPublic,
      coverPhotos: this.coverPhotos,
      organizers: this.organizers,
      type: this.type,
      settings: JSON.stringify(this._settings)
    }
  }
}
