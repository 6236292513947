// import VblAdmin from '@/router/methods/vbl-admin'
import VblData from '@/router/methods/vbl-data'

const ProfileMerge = () => import('@/Pages/Admin/Merge')
const Colleges = () => import('@/Pages/Admin/Colleges')
const Users = () => import('@/Pages/Admin/Users')

export default [
  {
    path: '/admin/merge',
    name: 'admin-profile-merge',
    component: ProfileMerge,
    beforeEnter: VblData
  },
  {
    path: '/admin/college/:id',
    name: 'admin-college',
    component: Colleges,
    beforeEnter: VblData,
    props: true
  },
  {
    path: '/admin/colleges',
    name: 'admin-college-list',
    component: Colleges,
    beforeEnter: VblData,
    props: true
  },
  {
    path: '/admin/users',
    name: 'user-search',
    component: Users,
    beforeEnter: VblData,
    props: true
  },
  {
    path: '/admin/user/:id',
    name: 'admin-user',
    component: Users,
    beforeEnter: VblData,
    props: true
  }
]
