import DtoUpdate from '../DtoUpdate'
import StandingGroup from './StandingsGroup'
import uniq from 'lodash.uniq'
import flatten from '@/helpers/ArrayFlatten'

export default class GroupedStandings {
  id = 0
  divisionId = 0
  title = 'Standings'
  groups = [new StandingGroup()]
  roundIds = []
  finishRoundIds = []
  _rounds = null
  tiebreaks = null

  constructor (dto, rounds) {
    this.update(dto)
    this._rounds = rounds
  }

  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = ['groups', 'published']
    DtoUpdate(dto, this, exclude)
    if (dto.groups) {
      this.groups = dto.groups.map(m => new StandingGroup(m))
    }
    if (!dto.tiebreaks) this.tiebreaks = null
  }

  addGroup () {
    this.groups.push(new StandingGroup())
  }

  get publishedGroups () {
    return this.groups && this.groups.filter(f => f.published)
  }

  get published () {
    return !!this.publishedGroups.length
  }

  get complete () {
    return this.rounds && this.rounds.filter(f => !f.complete).length === 0
  }

  get editable () {
    return this.groups.filter(f => f.locked).length === 0
  }

  get dto () {
    var a = {
      id: this.id,
      divisionId: this.divisionId,
      published: this.published,
      title: this.title,
      groups: this.groups.map(m => m.dto),
      roundIds: this.roundIds
    }
    if (this.finishRoundIds.length) {
      a.finishRoundIds = this.finishRoundIds
    }
    if (this.tiebreaks) {
      a.tiebreaks = this.tiebreaks
    }
    return a
  }

  get rounds () {
    return this._rounds && this._rounds.filter(f => this.roundIds.includes(f.id) || (this.roundIds.includes('*') && f.poolPlay))
  }

  get availableTeams () {
    return uniq(flatten(this.rounds.map(m => m.allTeams.map(t => t.teamId))))
  }

  get groupTeamsIds () {
    const result = []
    this.groups.forEach(g => {
      const usedTeams = flatten(result)
      const teamIds = g.type === 'all' ? this.availableTeams : g.type === 'spec' ? g.teamIds : this.availableTeams.filter(f => !usedTeams.includes(f))
      result.push(teamIds)
    })
    return uniq(result)
  }
}
