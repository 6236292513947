const PoolsHome = () => import('@/Pages/Tournament/Division/Pools/Home/Index.vue')
const PoolSheet = () => import('@/components/Tournament/Pools/PoolSheet/Index.vue')

export default [
  {
    path: '',
    name: 'league-pools-home',
    component: PoolsHome,
    props: true,
    meta: { displayName: 'Pools' }
  },
  {
    path: ':poolId',
    name: 'league-pool-sheet',
    component: PoolSheet,
    props: true,
    meta: { displayName: 'Pools' }
  }
]
