export default function (dto, _this, exclude) {
  exclude = exclude || []
  dto = dto || {}
  _this = _this || {}
  for (const prop in dto) {
    if (!exclude.includes(prop)) {
      _this[prop] = dto[prop]
      // if (typeof dto[prop] === 'boolean' || dto[prop]) {
      // }
    }
  }
}
