import DtoUpdate from './DtoUpdate'
import { feeAmount } from './HelperFunctions'
import { usDollars, fixed2OrInt } from '@/Filters'

export default class CartFee {
  label = null
  amount = null
  percentage = null
  divisionIds = ['*']
  isTax = false
  perPlayer = false
  promoable = true
  description = null
  nonMembers = []
  members = []

  constructor (dto) {
    if (dto) {
      this._update(dto)
    }
  }

  _update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = []
    DtoUpdate(dto, this, exclude)
  }

  get dto () {
    return {
      label: this.label,
      amount: +this.amount,
      percentage: +this.percentage,
      divisionIds: this.divisionIds,
      isTax: this.isTax,
      perPlayer: this.perPlayer,
      promoable: this.promoable,
      description: this.description,
      nonMembers: this.nonMembers,
      members: this.members
    }
  }

  get valid () {
    return this.label && (this.amount || this.percentage) && this.divisionIds.length > 0
  }

  feeAmount (itemAmount) {
    return feeAmount(this, itemAmount)
  }

  get feeDisplay () {
    return this.amount ? usDollars(this.amount) : `${fixed2OrInt(this.percentage * 100)}%`
  }

  applies (divisionId, player) {
    // division
    if (!this.divisionIds.includes('*') && !this.divisionIds.includes(divisionId)) return false
    if (this.perPlayer && !this.isTax) {
      const mem = player.memberships ? player.memberships : player._memberships ? player._memberships.split(',') : []
      if (this.nonMembers.length > 0) {
        const a = mem.filter(f => this.nonMembers.includes(f)).length === 0
        return a
      }
      if (this.members.length > 0) {
        const b = mem.filter(f => this.members.includes(f)).length > 0
        return b
      }
    }
    return true
  }

  get checkMembership () {
    console.log('cartFee Check')
    return this.nonMembers.length > 0 || this.members.length > 0
  }

  isEqual (fee) {
    return JSON.stringify(this.dto) === JSON.stringify(fee)
  }
}
