import * as mutations from '../MutationTypes'

export default {
  state: {
    working: []
  },
  mutations: {
    [mutations.LOADING] (state, payload) {
      const i = state.working.indexOf(payload)
      i === -1 && state.working.push(payload)
    },
    [mutations.LOADING_COMPLETE] (state, payload) {
      const i = state.working.indexOf(payload)
      if (i > -1) {
        state.working.splice(i, 1)
      }
    },
    [mutations.LOADING_CLEAR] (state) {
      state.working = []
    }
  },
  actions: {

  },
  getters: {
    isLoading (state) {
      return state.working.length > 0
    }
  }
}
