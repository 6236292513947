
import moment from 'moment'

export default class TournamentRegistrationWindow {
  constructor (sdk, dto) {
    this.sdk = sdk
    this.id = 0
    this.fee = 65
    this.feeIsPerTeam = true
    this.dtStart = moment().format('YYYY-MM-DD[T]HH:mm')
    this.dtEnd = null
    this.offset = null
    this.daylightOffset = null
    this.emit = true
    this.canPayAtEvent = false
    this.canProcessPayment = false
    this.isEarly = false // Allow TBD
    this.isLate = false // Allow partial payment
    this.minAmountDue = null
    this.minAmountLabel = null
    this.minAmountAgreement = null

    if (dto) {
      this.update(dto)
    }
  }

  setOffset (offset, daylight) {
    this.offset = offset
    this.daylightOffset = daylight
  }

  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    Object.assign(this, dto)
    if (!this.name && this.canPayAtEvent) {
      this.name = 'Pre-registration'
    }
  }

  updateFromTemplate (template) {
    this.fee = +template.fee
    this.feeIsPerTeam = template.feeIsPerTeam
    this.dtStart = template.dtStart
    this.dtEnd = template.dtEnd
    this.canPayAtEvent = template.canPayAtEvent
    this.canProcessPayment = template.canProcessPayment
    this.isEarly = template.isEarly
    this.isLate = template.isLate
    this.minAmountDue = template.minAmountDue
    this.minAmountLabel = template.minAmountLabel
    this.minAmountAgreement = template.minAmountAgreement
  }

  // getters-setters
  set dtStart (val) {
    this._dtStart = val ? moment.utc(val).format('YYYY-MM-DD[T]HH:mm[Z]') : null
  }

  get dtStart () {
    return this._dtStart ? moment.utc(this._dtStart) : null
  }

  set dtEnd (val) {
    this._dtEnd = val ? moment.utc(val).format('YYYY-MM-DD[T]HH:mm[Z]') : null
  }

  get dtEnd () {
    return this._dtEnd ? moment.utc(this._dtEnd) : null
  }

  // getters
  get dto () {
    return {
      id: this.id,
      fee: +this.fee,
      feeIsPerTeam: this.feeIsPerTeam,
      dtStart: this._dtStart,
      dtEnd: this._dtEnd,
      name: this.name,
      canPayAtEvent: this.canPayAtEvent,
      canProcessPayment: this.canProcessPayment,
      isEarly: !!this.isEarly,
      isLate: !!this.isLate,
      minAmountDue: this.minAmountDue,
      minAmountLabel: this.minAmountLabel,
      minAmountAgreement: this.minAmountAgreement
    }
  }

  get unix () {
    return moment(this.dtStart).format('X')
  }

  get neverOpen () {
    return this._dtStart === this._dtEnd
  }

  get compareString () {
    return JSON.stringify({
      fee: this.fee,
      feeIsPerTeam: this.feeIsPerTeam,
      dtStart: this.dtStart,
      dtEnd: this.dtEnd,
      name: this.name,
      canPayAtEvent: this.canPayAtEvent,
      canProcessPayment: this.canProcessPayment,
      isEarly: !!this.isEarly,
      isLate: !!this.isLate,
      minAmountDue: this.minAmountDue,
      minAmountLabel: this.minAmountLabel,
      minAmountAgreement: this.minAmountAgreement
    })
  }

  get feeString () {
    return `$${parseFloat(this.fee).toFixed(2)}${this.feeIsPerTeam ? '' : '/per player'}`
  }

  get startOffset () {
    return this.daylightOffset && moment(this._dtStart.replace('Z', '')).isDST() ? this.daylightOffset : this.offset
  }

  get endOffset () {
    const de = this._dtEnd || this._dtStart
    return this.daylightOffset && moment(de.replace('Z', '')).isDST() ? this.daylightOffset : this.offset
  }

  get isCurrent () {
    if (this.offset) {
      const start = moment(this._dtStart.replace('Z', this.startOffset))
      const de = this._dtEnd || this._dtStart
      const end = moment(de.replace('Z', this.endOffset))
      return moment().isBetween(start, end, 'minute', '[]')
    }
  }

  get closed () {
    if (this.offset) {
      const end = moment(this._dtEnd.replace('Z', this.endOffset))
      return moment().isAfter(end)
    }
  }

  get future () {
    if (this.offset) {
      const start = moment(this._dtStart.replace('Z', this.startOffset))
      return moment().isBefore(start)
    }
  }

  get deadline () {
    const x = moment(`${this.dtEnd}Z${this.endOffset}`)
    return this.endOffset && x
  }

  get startDate () {
    return moment(this.dtStart).format('YYYY-MM-DD')
  }

  get startTime () {
    return moment(this.dtStart).format('HH:mm')
  }

  get endDate () {
    return moment(this.dtEnd).format('YYYY-MM-DD')
  }

  get endTime () {
    return moment(this.dtEnd).format('HH:mm')
  }

  get startDisplay () {
    const start = moment(this._dtStart.replace('Z', this.startOffset))
    return start.calendar(null, {
      sameElse: function (now) {
        return this.format('LT') === '12:00 AM' ? 'l' : 'M/D/YYYY LT'
      }
    })
  }

  get endDisplay () {
    const end = moment(this._dtEnd.replace('Z', this.endOffset))
    return end.calendar(null, {
      sameElse: function (now) {
        return this.format('LT') === '12:00 AM' ? 'l' : 'M/D/YYYY LT'
      }
    })
  }

  get allowTbd () {
    return this.isEarly
  }

  get truePerPlayer () {
    return this.isLate
  }
}
