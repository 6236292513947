import moment from 'moment'
import sum from 'lodash.sum'
import flatten from '../helpers/ArrayFlatten'
import round from 'lodash.round'

const minuteDiff = (time1, time2) => {
  const a = moment(time1, 'h:mm A')
  const b = moment(time2, 'h:mm A')
  return Math.abs(a.diff(b, 'm'))
}

const sameName = (n1, n2) => {
  return n1.toUpperCase().replace(/[^0-9a-z]/gi, '') === n2.toUpperCase().replace(/[^0-9a-z]/gi, '')
}

// eslint-disable-next-line no-control-regex
const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i

const distance = (lat1, lon1, lat2, lon2, unit) => {
  if ((lat1 === lat2) && (lon1 === lon2)) {
    return 0
  } else {
    const radlat1 = Math.PI * lat1 / 180
    const radlat2 = Math.PI * lat2 / 180
    const theta = lon1 - lon2
    const radtheta = Math.PI * theta / 180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
    if (dist > 1) {
      dist = 1
    }
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515
    if (unit === 'K') { dist = dist * 1.609344 }
    if (unit === 'N') { dist = dist * 0.8684 }
    return dist
  }
}

const generateUniqueId = () => `_${Date.now().toString(36)}\
${Math.floor(Number.MAX_SAFE_INTEGER * Math.random()).toString(36)}`

const routeHelper = (t, d, r, m) => {
  const l = t.isLeague
  const i = d.days.findIndex(f => f.id === r.id)
  const p = m.type === 'pool'
  const text = p ? l ? `Week ${i + 1}` : 'Pool' : 'Bracket'

  const result = {
    text: `Jump to ${text}`,
    to: {
      name: p ? l ? 'league-play' : 'pool-sheet' : `${l ? 'league-' : ''}bracket-home`,
      params: {
        tournamentId: t.id,
        divisionId: d.id,
        dayId: r.id,
        poolId: m.poolId
      }
    }
  }

  if (p && l) {
    result.to.query = { round: (i + 1) }
  }

  return result
}

const groupFees = (fees, itemAmount) => {
  const labels = [...(new Set(fees.map(m => m.label)))]
  const r = labels.map(l => {
    const items = fees.filter(f => f.label === l)
    return {
      label: l,
      details: flatten(items.map(m => {
        return m.details || { amount: feeAmount(m, itemAmount), label: m.label }
      })),
      get amount () { return sum(this.details.map(m => m.amount)) }
    }
  })
  return r
}

const feeAmount = (fee, itemAmount) => {
  if (fee.amount > 0 || fee.amount < 0) return fee.amount
  if (fee.percentage > 0 || fee.percentage < 0) return round(itemAmount * fee.percentage, 2)
  return 0
}

const getAmountObj = (items) => {
  const allFees = flatten(items.filter(f => f.cartFees).map(m => m.cartFees.map(cf => {
    return {
      label: cf.label,
      description: cf.description,
      amount: feeAmount(cf, m.total),
      isTax: cf.isTax
    }
  })))
  const feeLabels = [...new Set(allFees.filter(f => !f.isTax).map(m => m.label))]
  const taxLabels = [...new Set(allFees.filter(f => f.isTax).map(m => m.label))]
  const fees = allFees.filter(f => f.amount > 0)
  const disc = allFees.filter(f => f.amount < 0)
  const q = items.findIndex(f => f.quantity > 1) > -1
  return {
    subtotal: sum(items.map(m => m.subtotal)),
    promoTotal: sum(items.map(m => m.promoTotal)),
    feeLabel: feeLabels.length === 1 ? feeLabels[0] : fees.length === allFees.length ? 'Fees' : disc.length === allFees.length ? 'Discounts' : 'Fees & Discounts',
    feeTotal: sum(items.map(m => m.feeTotal)),
    feeDetails: allFees.filter(f => !f.isTax).map(m => {
      return {
        label: m.description || m.label,
        amount: m.amount
      }
    }),
    taxLabel: taxLabels.length === 1 ? taxLabels[0] : 'Tax',
    taxTotal: sum(items.map(m => m.taxTotal)),
    get total () { return sum([this.subtotal, this.promoTotal, this.feeTotal, this.taxTotal]) },
    get showSub () { return this.subtotal !== this.total || q }
  }
}

const namesAreSame = (a, b) => {
  a = (a || '').split('#')[0]
  b = (b || '').split('#')[0]
  return removeWhitespace(a).toUpperCase() === removeWhitespace(b).toUpperCase()
}

const removeWhitespace = (str) => {
  return str ? str.split('').filter(e => e.trim().length).join('') : ''
}

const average = (array) => array.reduce((a, b) => a + b) / array.length

const getRandomInt = (max) => {
  return Math.floor(Math.random() * max)
}

const usePlayerNames = (team, division, isDuals, xs) => {
  const p = team.players || team.playerNames || []
  if (isDuals ||
      p.length === 0 ||
      p.length > division.numOfPlayers ||
      division.useTeamNames ||
      (division.numOfPlayers > 2 && !division.noTeamNames)
  ) return false

  return true
}

const timeOptions = () => {
  let i = 0
  const result = []
  const min = [':00', ':15', ':30', ':45']
  for (i; i < 24; i++) {
    const ap = i > 11 ? 'PM' : 'AM'
    const h = i === 0 ? 12 : i > 11 ? i - 12 : i
    min.forEach(m => {
      result.push({ text: `${h}${m} ${ap}`, value: `${i}${m}` })
    })
  }
  return result
}

export {
  minuteDiff, sameName, emailRegex, distance, generateUniqueId, routeHelper, groupFees, feeAmount, getAmountObj, namesAreSame, average, getRandomInt, usePlayerNames, timeOptions
}
