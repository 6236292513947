<template>
  <v-container fluid class="pa-1">
    <!-- DID YOU KNOW -->
    <v-row dense v-if="!user">
      <v-col cols="12">
        <v-card color="color2 color2Text--text">
          <v-card-title>
            <h2>Did you know?</h2>
          </v-card-title>
          <v-card-text class="text-left color2Text--text">
            If you sign in before you pay for any registrations, you will be able to easily
            access them in your order history. Don't have an account? You can create an account by clicking sign in at the top of the page.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- CART -->
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="color1 color1Text--text">
            <v-toolbar-title>
              <v-icon class="mr-2 color1Text--text">fas fa-shopping-cart</v-icon>Your Cart
            </v-toolbar-title>
          </v-toolbar>
          <v-container fluid class="pa-1">
            <v-row dense>
              <v-col cols="12">
                <checkout-view></checkout-view>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <!-- DIALOG -->
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
        <v-card>
          <v-card-title primary-title>
            <div>
              <h3 class="title mb-0">You have multiple carts</h3>
              <div class="subtitle-1">You have added registrations from multiple organizations and each organization is processed separately. You can switch between the carts at the top of the page.</div>
            </div>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="color3" @click.stop="dialog = false">Got it!</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </v-container>

</template>

<script>
import { mapGetters } from 'vuex'
import CheckoutView from '@/components/Cart/CheckoutView'

export default {
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapGetters(['user', 'carts'])
  },
  mounted () {
    if (this.carts.length > 1) this.dialog = true
  },
  components: {
    CheckoutView
  }
}
</script>

<style scoped>

</style>
