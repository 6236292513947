import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Touch } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  directives: {
    Touch
  }
})

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#152a69',
        secondary: '#446da3',
        accent: '#189ebb',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        color1: '#152a69',
        color2: '#446da3',
        color3: '#189ebb',
        avpYellow1: '#fcd538',
        avpYellow2: '#ffd400'
      }
    }
  },
  icons: {
    iconfont: 'fa'
  }
})
