import moment from 'moment'
import Person from './Person'
import DtoUpdate from './DtoUpdate'
import baseProps from './PlayerJsonProps'
import { api } from '@/classes/_URL'
import toProperCase from '@/helpers/properCase'
import deepAssign from 'assign-deep'
import { ageDivision, indoorAgeDivision, heightInInches } from '@/classes/ProfileHelpers'
import MetricsArray from '@/classes/MetricsArray'
import PlayerRatings from './PlayerRatings'
const avatars = ['https://vblimages.blob.core.windows.net/images/avatar-male.jpg', 'https://vblimages.blob.core.windows.net/images/avatar-female.jpg']

export default class PlayerProfile {
  constructor (sdk, dto) {
    this.sdk = sdk
    this.key = 0
    this.init()
    this.update(dto)
  }

  // methods
  update (dto) {
    if (!dto) return
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = ['parent', 'emergency', 'profilePic', 'metrics', 'ratings', 'dob']
    DtoUpdate(dto, this, exclude)
    if (dto.metrics) {
      Object.assign(this.metrics, dto.metrics)
    }
    dto.parent && this.parent.update(dto.parent)
    dto.emergency && this.emergency.update(dto.emergency)

    if (dto.dob) this._dob = moment(dto.dob.replace('Z', '')).format('YYYY-MM-DD')
    if (!this.jProps.programs) {
      this.jProps.programs = JSON.parse(JSON.stringify(baseProps.programs))
    }
    if (this.club && this.jProps && this.jProps.programs && !this.jProps.programs.beach.club.name) {
      this.jProps.programs.beach.club.name = this.club
      this.jProps.programs.beach.club.yesOrNo = true
    }
    if (dto.ratings) {
      dto.ratings.forEach(r => {
        this.updateRatings(new PlayerRatings(r))
      })
    }
  }

  clearAau () {
    this.aauNumber = null
    this.validAau = false
  }

  init () {
    this.id = 0
    this.vblId = null
    this.firstName = null
    this.lastName = null
    this.phone = null
    this.email = null
    this.maskedEmail = null
    this.male = false
    this.address = null
    this.address2 = null
    this.city = null
    this.state = null
    this.zip = null
    this.profileType = null
    this.dob = null
    this.highSchool = null
    this.gradYear = null
    this.club = null
    this.aauNumber = null
    this.avpNumber = null
    this.p1440Number = null
    this.bvcaNumber = null
    this.bvneNumber = null
    this.cbvaNumber = null
    this.usavNumber = null
    this.validAau = false
    this.manualAau = false
    this.valid = false
    this.shirtSize = null
    this.height = null
    this.clubDirectorName = null
    this.clubDirectorEmail = null
    this.sandRecruitsNumber = null
    this._committed = null
    this.committedSchool = null
    this.committedAs = null
    this.commitAbbr = null
    this._onFile = null
    this.playerProfileId = null
    this.avpaStatus = null
    this.p1440Status = null
    this.usavStatus = null
    this.manualAvp = false
    this.searchResult = null
    this.passport = null
    this.country = 'United States'
    this.password = null
    this.consents = []
    this.classification = null
    this.status = null
    this.facebook = null
    this.instagram = null
    this.twitter = null
    this.snapchat = null
    this.parent = new Person({ title: 'Parent/Guardian' })
    this.emergency = new Person({ title: 'Emergency Contact', subtitle: '(Other than Parent/Guardian)' })
    this.metrics = {
      beach: true,
      indoor: false,
      dominantHand: null,
      wingspan: null,
      reach: null,
      blockTouch: null,
      approachTouch: null,
      stardrill: null,
      side: null,
      sidePreferred: null,
      defense: null,
      defensePreferred: null,
      videos: [],
      primaryPosition: null,
      preferredPosition: null,
      positionsPlayed: null
    }
    this.metricsOG = {
      domHand: 'Right',
      wingspan: null,
      reach: null,
      blockTouch: null,
      approachTouch: null,
      stardrill: null,
      indoor: {
        position: {
          primary: null,
          preferred: null,
          experience: []
        },
        videos: [],
        blockTouch: null,
        approachTouch: null
      },
      beach: {
        side: null,
        blockOrD: null,
        videos: [],
        blockTouch: null,
        approachTouch: null
      }
    }
    this.tournaments = []
    this.jProps = JSON.parse(JSON.stringify(baseProps))
    this.imgI = 0
    this.pic = null
    this.yearsExp = null
    this.hand = null
    this.side = null
    this.playerEmail = null
    this.playerPhone = null
    this.gpa = null
    this.verifications = []
    this.ratings = []
    this._memberships = null
    this.tags = []
  }

  updateRatings (r) {
    if (this.dob && !r.dob) r.dob = this.dob
    if (this.gradYear && !r.gradYear) r.gradYear = this.gradYear
    const i = this.ratings.findIndex(f => f.systemId === r.systemId)
    if (i === -1) {
      this.ratings.push(r)
    } else {
      this.ratings.splice(i, 1, r)
    }
  }

  get vblRatings () {
    return this.ratings && this.ratings.find(f => f.systemId === 4)
  }

  get jsonProps () {
    return JSON.stringify(this.jProps)
  }

  set jsonProps (val) {
    if (val) {
      this.jProps = deepAssign(this.jProps, JSON.parse(val))
    }
  }

  get academics () {
    return this.jProps.academics
  }

  get clubs () {
    return this.jProps.clubs
  }

  get social () {
    return this.jProps.social
  }

  set social (val) {
    this.jProps.social = val
  }

  get committed () {
    return this._committed === 'Yes' ? true : this._committed === 'No' ? false : null
  }

  set committed (val) {
    this._committed = val === true ? 'Yes' : val === false ? 'No' : null
  }

  get onFile () {
    return this._onFile ? this._onFile.split(',') : []
  }

  set onFile (val) {
    this._onFile = val.join(',')
  }

  get memberships () {
    return this._memberships ? this._memberships.split(',') : []
  }

  set memberships (val) {
    this._memberships = val && val.join(',')
  }

  clearOnFile (val) {
    this.onFile = this.onFile.filter(f => f !== val)
  }

  addOnFile (val) {
    this.onFile = [...this.onFile, val]
  }

  get dob () {
    return this._dob
  }

  set dob (v) {
    this._dob = v
    this.avpaStatus = null
    this.p1440Status = null
    this.usavStatus = null
  }

  // getters
  get current () {
    return {
      school: this.schools.find(f => f.current),
      clubs: {
        indoor: this.clubs.indoor.find(f => f.current),
        beach: this.clubs.beach.find(f => f.current)
      }
    }
  }

  get fullname () {
    return `${this.firstName} ${this.lastName}`
  }

  get name () {
    return this.fullname
  }

  get dobF () {
    return this.dob ? moment(this.dob.replace('Z', '')).format('MM/DD/YYYY') : ''
  }

  get age () {
    return this.dob ? Math.trunc(moment().diff(moment(this.dob), 'years', true)) : 0
  }

  get ageDivision () {
    return ageDivision(this.dob, this.gradYear)
  }

  get indoorAgeDivision () {
    return indoorAgeDivision(this.dob, this.gradYear)
  }

  get cityState () {
    const a = []
    this.city && a.push(this.city)
    this.state && a.push(this.state)
    return a.join(', ')
  }

  get isJunior () {
    const ad = this.ageDivision
    return (ad && !['', 'U19', 'U20', 'U21', 'U22', 'U23', 'U24', 'U25', 'U26', 'Adult'].includes(ad)) || (this.age === 0 && (this.aauPoints !== 'NA' || this.bvnePoints !== 'NA' || this.p1440Points !== 'NA'))
  }

  get isAdult () {
    return (this.age && !this.isJunior)
  }

  get recruitFields () {
    const ad = this.ageDivision
    return (ad && !['', 'Adult'].includes(ad)) || (this.profileType && this.profileType.startsWith('recruit'))
  }

  get recruitDisplay () {
    return this.profileType.startsWith('recruit')
  }

  get cleanPhone () {
    return (this.phone || '').replace(/\D/g, '')
  }

  get dto () {
    return {
      id: this.id,
      aauNumber: this.aauNumber,
      address: this.address,
      address2: this.address2,
      avpNumber: this.avpNumber,
      p1440Number: this.p1440Number,
      city: this.city,
      usavNumber: this.usavNumber,
      club: this.club,
      country: this.country,
      dob: this.dob,
      email: this.email,
      firstName: this.firstName,
      gradYear: this.gradYear,
      height: this.height,
      highSchool: this.highSchool,
      lastName: this.lastName,
      manualAau: this.manualAau,
      manualAvp: this.manualAvp,
      phone: this.cleanPhone,
      shirtSize: this.shirtSize,
      state: this.state,
      zip: this.zip,
      clubDirectorEmail: this.clubDirectorEmail,
      clubDirectorName: this.clubDirectorName,
      sandRecruitsNumber: this.sandRecruitsNumber,
      committed: this.committed,
      committedSchool: this.committedSchool,
      playerProfileId: this.playerProfileId,
      _onFile: this._onFile,
      avpaStatus: this.avpaStatus,
      p1440Status: this.p1440Status,
      usavStatus: this.usavStatus,
      classification: this.classification,
      facebook: this.facebook,
      instagram: this.instagram,
      twitter: this.twitter,
      snapchat: this.snapchat,
      parent: this.parent,
      emergency: this.emergency,
      clubs: this.clubs,
      schools: this.schools,
      academics: this.academics,
      metrics: this.metrics,
      password: this.password,
      playerEmail: this.playerEmail,
      playerPhone: this.playerPhone
    }
  }

  get tournamentRegistrationDto () {
    return {
      id: this.id,
      vblId: this.vblId,
      aauNumber: this.aauNumber,
      address: this.address,
      address2: this.address2,
      avpNumber: this.avpNumber,
      p1440Number: this.p1440Number,
      city: this.city,
      usavNumber: this.usavNumber,
      club: this.club,
      country: this.country,
      dob: this.dob,
      email: this.email,
      firstName: this.firstName,
      gradYear: this.gradYear,
      height: this.height,
      highSchool: this.highSchool,
      lastName: this.lastName,
      manualAau: this.manualAau,
      manualAvp: this.manualAvp,
      phone: this.cleanPhone,
      shirtSize: this.shirtSize,
      state: this.state,
      zip: this.zip,
      clubDirectorEmail: this.clubDirectorEmail,
      clubDirectorName: this.clubDirectorName,
      sandRecruitsNumber: this.sandRecruitsNumber,
      committed: this.committed,
      committedSchool: this.committedSchool,
      commitAbbr: this.commitAbbr,
      playerProfileId: this.playerProfileId,
      _onFile: this._onFile,
      _memberships: this._memberships,
      avpaStatus: this.avpaStatus,
      p1440Status: this.p1440Status,
      usavStatus: this.usavStatus,
      classification: this.classification,
      yearsExp: this.yearsExp,
      hand: this.hand,
      playerEmail: this.playerEmail,
      playerPhone: this.playerPhone,
      side: this.side,
      instagram: this.instagram,
      parent: JSON.stringify(this.parent),
      emergency: JSON.stringify(this.emergency),
      gpa: this.gpa,
      male: this.male || false
    }
  }

  get basicInfoDto () {
    return {
      id: this.id,
      address: this.address,
      address2: this.address2,
      city: this.city,
      club: this.club,
      committed: this.committed,
      committedSchool: this.committedSchool,
      committedAs: this.committedAs,
      commitAbbr: this.commitAbbr,
      country: this.country,
      dob: this.dob,
      email: this.email,
      firstName: this.firstName,
      gradYear: this.gradYear,
      height: this.height,
      highSchool: this.highSchool,
      lastName: this.lastName,
      phone: this.cleanPhone,
      shirtSize: this.shirtSize,
      state: this.state,
      zip: this.zip,
      playerProfileId: this.playerProfileId,
      male: this.male,
      aauNumber: this.aauNumber,
      avpAmericaNumber: this.avpAmericaNumber,
      bvcaNumber: this.bvcaNumber,
      bvneNumber: this.bvneNumber,
      p1440Number: this.p1440Number,
      usavNumber: this.usavNumber,
      jProps: this.jProps,
      playerEmail: this.playerEmail,
      playerPhone: this.playerPhone
    }
  }

  get uploadDto () {
    return {
      id: this.id,
      aauNumber: this.aauNumber,
      address: this.address,
      address2: this.address2,
      avpNumber: this.avpNumber,
      p1440Number: this.p1440Number,
      city: this.city,
      usavNumber: this.usavNumber,
      club: this.club,
      country: this.country,
      dob: this.dob,
      email: this.email,
      firstName: this.firstName,
      gradYear: this.gradYear,
      lastName: this.lastName,
      phone: this.cleanPhone,
      state: this.state,
      zip: this.zip,
      playerProfileId: this.playerProfileId
    }
  }

  get academicInfoDto () {
    return {
      highSchool: this.highSchool,
      gradYear: this.gradYear,
      academics: this.academics
    }
  }

  get otherContacts () {
    return {
      parent: this.parent,
      emergency: this.emergency,
      other: this.jProps.contacts
    }
  }

  setProfile (val) {
    // val should be a search result
    this.init()
    this.firstName = val.firstName
    this.lastName = val.lastName
    if (val && val.id !== 0) {
      this.searchResult = val
      this.playerProfileId = val.id
      this.onFile = val.onFile
      this.validAau = this.isOnFile('aauNumber')
      this.firstName = val.firstName
      this.lastName = val.lastName
      this.gradYear = val.gradYear
      this.maskedEmail = val.email
      if (this.isOnFile('country')) this.country = null
      this.memberships = val.memberships
    }
    this.key++
  }

  checkMemberships () {
    this.sdk.player.membershipCheck(this.ppId)
      .then(r => {
        const m = r.data && r.data.find(f => f.id === this.ppId)
        if (m) this.memberships = m.memberships
      })
      .catch(e => console.log(e.response))
  }

  isOnFile (field) {
    return this.onFile.includes(field)
  }

  removeOnFile (field) {
    this.onFile = this.onFile.filter(f => f !== field)
  }

  isVerified (prop) {
    return this.verifications && !!this.verifications.find(f => f.selected.includes(prop))
  }

  get hasVerifications () {
    return this.verifications && this.verifications.length > 0
  }

  get profilePic () {
    return this.pic || `${api}/PlayerProfile/${this.id}/profilePic?i=${this.imgI}`
  }

  getPoints (id) {
    let useId = true
    if (typeof id === 'string') {
      useId = false
    }
    const p = this.tournaments.map(t => {
      const r = useId ? t.points.find(f => f.systemId === id && f.used) : t.points.find(f => f.short.toLowerCase() === id.toLowerCase() && f.used)
      return r ? r.total : 0
    }).reduce((a, b) => a + b, 0)
    return p
  }

  get aauPoints () {
    return this.getPoints(3) || 'NA'
  }

  get bvnePoints () {
    return this.getPoints(19) || 'NA'
  }

  get isBvne () {
    return this.memberships && this.memberships.includes('bvne')
  }

  get usavpPoints () {
    return this.getPoints('usavp') || 'NA'
  }

  get p1440Points () {
    return (this.getPoints(4) + this.getPoints(5)) || 'NA'
  }

  get avpaPoints () {
    return this.getPoints('usavp') || 'NA'
  }

  get avpaCPoints () {
    return this.getPoints(-1) || 'NA'
  }

  get movr () {
    return this.getPoints(-33) || 'NA'
  }

  get completeTournaments () {
    const base = this.tournaments.filter(f => !(f.partners.length > 2 && (f._Props2 && f._Props2.includes('ncaa')) && f.partners.length !== 2) && !(f._Props2 && f._Props2.includes('noResults')) && !(f._Props && f._Props.includes('noResults')))
    return base.filter(f => !moment(f.date).isAfter(moment().startOf('day')))
  }

  get upcomingTournaments () {
    let base = this.tournaments.filter(f => moment(f.date).isAfter(moment().startOf('day')))

    const ncaa = base.filter(f => f._Props2 && f._Props2.includes('ncaa'))
    base = base.filter(f => !(f._Props2 && f._Props2.includes('ncaa')))
    if (ncaa.length) {
      const ids = [...new Set(ncaa.map(m => m.id))]
      ids.forEach(id => {
        var t = ncaa.find(f => f.id === id)
        base.push(t)
      })
    }
    return base
  }

  get hasPic () {
    return this.pic && !avatars.includes(this.pic) ? 1 : 0
  }

  get iGradYear () {
    return this.gradYear || 9999999
  }

  get ppId () {
    return this.playerProfileId || this.id
  }

  clean (states) {
    this.firstName = toProperCase(this.firstName)
    this.lastName = toProperCase(this.lastName)
    if (this.phone) this.phone = this.phone.replace(/\D/g, '')
    if (this.email) {
      this.email = this.email.toLowerCase().replace('mailto:', '')
    }
    if (this.state) {
      const x = states.find(f => f.abbreviation.toLowerCase() === this.state.toLowerCase())
      const y = states.find(f => f.abbreviation.toLowerCase() === this.state.toLowerCase() || f.name.toLowerCase() === this.state.toLowerCase() || f.name.toLowerCase().startsWith(this.state.toLowerCase()))
      this.state = x ? x.abbreviation : y ? y.abbreviation : null
    }
  }

  get metricsArray () {
    var a = MetricsArray(this.metrics)
    this.height && a.unshift(this.height)
    return a
  }

  showMetrics (view, user) {
    const j = this.jProps.settings
    return view.admin || view.vbl ||
      (j && j.metrics && j.metrics.includes('public')) ||
      (user && user.isPlayer && (j && j.metrics && j.metrics.includes('players')))
  }

  getView (user, publicView, permitted) {
    return {
      vbl: user && user.vbl && !publicView,
      admin: this.userStatus(user, permitted) === 13 && !publicView,
      public: publicView,
      college: user && user.isCollegeCoach && !publicView
    }
  }

  get committedDisplay () {
    if (!this.committed) return 'Uncommitted'
    return `Committed to ${this.committedSchool || 'play'} ${this.committedAs === 'Both' ? 'Beach & Indoor' : this.committedAs || ''}`
  }

  get commitTag () {
    return this.committed ? 'Committed' : this.committed === false ? 'Uncommitted' : 'Commit Unknown'
  }

  userStatus (user, permitted) {
    if (!user) return 0 // 'No User'
    return permitted || (this.users && this.users.includes(user.id)) ? 13 : 0 // 'Permitted' : 'Nor Permitted'
  }

  get inches () {
    return heightInInches(this.height)
  }

  get gender () {
    return this.male === null ? null : this.male ? 'Male' : 'Female'
  }

  set gender (val) {
    this.male = val
  }

  reAskGradYear () {
    // const newOF = [...this.onFile.filter(f => f !== 'gradyear')]
    this.onFile = [...this.onFile.filter(f => f !== 'gradYear')]
  }
}
