export default class Person {
  constructor (dto) {
    this.init()
    this.update(dto)
  }

  // methods
  update (dto) {
    if (!dto) return
    if (typeof dto === 'string') dto = JSON.parse(dto)
    Object.assign(this, dto)
  }

  init () {
    this.firstName = null
    this.lastName = null
    this.phone = null
    this.email = null
    this.address = null
    this.address2 = null
    this.city = null
    this.state = null
    this.zip = null
    this.title = null
    this.subtitle = null
  }
}
