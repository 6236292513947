<template>
  <v-container class="fill-height">
    <v-row class="text-center"   align="center" justify="center" >
      <v-col cols="12" class="text-h3">
        You've been blocked!
      </v-col>
      <v-col cols="12" md="9" lg="6" xl="4">
        <v-img src="https://vblimages.blob.core.windows.net/images/Blocking2.gif" contain></v-img>
      </v-col>
    </v-row>
    <v-row class="text-center"   align="center" justify="center" >
      <v-col cols="12" md="9" lg="6" xl="4">
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>
