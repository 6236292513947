<template>
  <div>
    <event-editor
      :vblEvent="editable"
      :disabled="disabled"
      v-if="editable"
      @changed="onChange"
    >
      <template slot="cols">
        <v-col cols="12" class="py-0" order="2">
          <v-card flat tile>
            <v-toolbar dense flat :color="disabled ? 'grey lighten-3 grey--text' : 'color1 color1Text--text'">
              <v-toolbar-title>
                Points & Leaderboard Settings
              </v-toolbar-title>
            </v-toolbar>
            <v-container fluid>
              <!-- NAME & 1 day checkbox -->
              <v-row dense>
                <v-col cols="12" sm="6" lg="4">
                  <point-system-options
                    :disabled="disabled"
                    :model="editable.settings"
                  ></point-system-options>
                </v-col>
                <v-col cols="12" sm="6" lg="8">
                  <v-alert
                    type="warning"
                    :value="editable.settings.pointSystemId && editable.settings.pointSystemId !== -1"
                    prominent text
                    border="top"
                    transition="scale-transition"
                  >
                    All tournaments must add the chosen point system or they will be ignored
                  </v-alert>
                </v-col>
              </v-row>
              <v-expand-transition>
                <v-row dense v-if="editable.settings.pointSystemId === - 1">
                  <v-col cols="12" sm="6" lg="4">
                    <v-card>
                      <v-data-table
                        :headers="[
                          { text: 'Finish', value: 'finish'},
                          { text: 'Points', value: 'pv'},
                          { text: '', value: 'actions', align: 'end'}
                        ]"
                        :items="pointValues"
                        hide-default-footer
                        dense
                        :options="{ itemsPerPage: -1 }"
                      >
                        <template v-slot:[`item.finish`]="{item}">
                          {{item.finish === 9999 ? 'All Others' : item.finish | ordinal}}
                        </template>
                        <template v-slot:[`item.pv`]="{item}">
                          <v-edit-dialog
                            :disabled="disabled"
                            :return-value.sync="item.pv"
                          > {{item.pv}}
                            <template v-slot:input>
                              <v-text-field
                                v-model="item.pv"
                                type="number"
                                label="Points"
                                single-line
                                color="color3"
                                :disabled="disabled"
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </template>
                        <template v-slot:[`item.actions`]="{item}">
                          <v-btn color="error" text icon class="xsmall" @click="deletePV(item)" :disabled="disabled">
                            <v-icon>fas fa-trash</v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                      <div class="text-center">
                        <v-fab-transition>
                          <v-btn
                            v-if="!disabled && pMax < 9999"
                            color="color3 color3Text--text"
                            small fab
                            @click.stop="addPV"
                          >
                            <v-icon>fas fa-plus</v-icon>
                          </v-btn>
                        </v-fab-transition>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-expand-transition>
              <v-row dense>
                <v-col cols="12" sm="6" lg="4">
                  <v-select
                    label="How do you want to keep points?"
                    v-model="bySetting"
                    :disabled="disabled"
                    color="color3"
                    attach
                    :items="[
                      { text: 'By Player', value: 'player' },
                      { text: 'By Team (doubles)', value: 'team' },
                      { text: 'By Team (non-doubles)', value: 'teamName' },
                      { text: 'By Club', value: 'club' }
                    ]"
                    item-color="color3"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" lg="4">
                  <v-select
                    label="How do you display rankings?"
                    v-model="editable.settings.ranking"
                    :disabled="disabled"
                    color="color3"
                    attach multiple
                    :items="[
                      { text: 'Overall', value: 'overall' },
                      { text: 'By Division', value: 'divisional' },
                      { text: 'By Gender', value: 'gender' }
                    ]"
                    item-color="color3"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" lg="4">
                  <v-text-field
                    :label="`Use a ${editable.settings.byTeam ? 'team' : 'player'}'s Top N tournaments`"
                    v-model="editable.settings.topN"
                    type="number"
                    :disabled="disabled"
                    color="color3"
                    persistent-hint
                    hint="Enter a value to limit the number of events that will count towards rankings, otherwise leave blank"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="4">
                  <v-text-field
                    :label="`Only use a ${editable.settings.byTeam ? 'team' : 'player'}'s last N tournaments`"
                    v-model="editable.settings.lastN"
                    type="number"
                    :disabled="disabled"
                    color="color3"
                    persistent-hint
                    hint="Enter a value to limit the number of events that will count towards rankings, otherwise leave blank"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </template>
    </event-editor>
    <!-- BUTTONS -->
    <template>
      <v-tooltip top>
        <span>Save</span>
        <template v-slot:activator="{ on }">
          <v-fab-transition>
            <v-btn
              v-on="on"
              color="color3 color3Text--text"
              key="save"
              v-if="!viewOnly && dirty"
              fab
              bottom
              right
              fixed
              :style="`margin-right: ${(btnOffset || 0 )+ 60}px`"
              @click.stop="onSaveClick"
              :loading="saving"
            >
              <v-icon>fas fa-save</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
      </v-tooltip>
      <v-tooltip top>
        <span>{{activeFab.tooltip}}</span>
        <template v-slot:activator="{ on }">
          <v-fab-transition>
            <v-btn
              v-on="on"
              :color="activeFab.color"
              :key="activeFab.icon"
              v-model="fab"
              fab
              bottom
              right
              fixed
              :style="`margin-right: ${(btnOffset || 0)}px`"
              @click.stop="activeFab.onClick"
              :disabled="saving"
            >
              <v-icon>{{activeFab.icon}}</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
      </v-tooltip>
    </template>
  </div>

</template>

<script>
import EventEditor from '@/components/Events/EventEditor.vue'
import Series from '@/classes/Series'
import PointSystemOptions from './PointSystemOptions'
import { mapGetters } from 'vuex'
// import DtoPatchBuilder from '@/classes/DtoPatchBuilder'

export default {
  props: ['series', 'btnOffset'],
  data () {
    return {
      editable: null,
      saving: false,
      viewOnly: true,
      fab: null,
      limit: false
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
    activeFab () {
      return this.viewOnly ? {
        color: 'color3Text color3--text',
        icon: 'fas fa-pencil-alt',
        onClick: this.editMode,
        tooltip: 'Edit'
      } : {
        color: 'red white--text',
        icon: 'fas fa-times',
        onClick: this.cancel,
        tooltip: 'Cancel'
      }
    },
    dirty () {
      return this.series && this.editable && (this.series.id === 0 || JSON.stringify(this.series.dto) !== JSON.stringify(this.editable.dto))
    },
    disabled () {
      return this.viewOnly || this.saving
    },
    dto () {
      // return DtoPatchBuilder(this.series.dto, this.editable.dto)
      return this.editable.dto
    },
    s () {
      return this.editable && this.editable.settings
    },
    p () {
      return this.s && this.s.points
    },
    pointValues () {
      return this.p
    },
    pMax () {
      return this.p && this.p.length > 0 ? this.p[this.p.length - 1].finish : 0
    },
    bySetting: {
      get () {
        return this.s && this.s.byClub ? 'club' : this.s.byTeam ? 'team' : this.s.byTeamName ? 'teamName' : 'player'
      },
      set (val) {
        this.s.byClub = val === 'club'
        this.s.byTeam = val === 'team'
        this.s.byTeamName = val === 'teamName'
      }
    }
  },
  methods: {
    editPV (pv) {
      this.selectedPV = pv
    },
    deletePV (pv) {
      console.log('here')
      let a = JSON.parse(JSON.stringify(this.p))
      a = a.filter(f => f.finish !== pv.finish)
      this.editable.settings.points = a
    },
    addPV () {
      const a = JSON.parse(JSON.stringify(this.p))
      a.push({
        finish: (this.pMax + 1),
        pv: 0
      })
      this.editable.settings.points = a
    },
    editMode () {
      this.viewOnly = false
    },
    init () {
      this.editable = new Series(this.series.dto)
      if (this.series.id === 0) this.viewOnly = false
    },
    cancel () {
      if (this.series.id === 0) {
        this.$router.go(-1)
        return
      }
      this.$emit('cancel-click')
      this.init()
      this.viewOnly = true
    },
    onSaveClick () {
      this.saving = true
      this.$VBL.post.series(this.dto)
        .then(r => {
          this.viewOnly = true
          this.$router.replace({ query: null })
          this.$emit('changed', r.data)
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.saving = false
        })
    },
    onChange (dto) {
      this.$emit('changed', dto)
    }
  },
  mounted () {
    this.init()
  },
  components: {
    EventEditor,
    PointSystemOptions
  }
}
</script>

<style>

</style>
