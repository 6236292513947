export default [
  {
    teams: 3,
    matches: [
      {
        number: 1,
        home: 1,
        away: 3,
        ref: 2
      },
      {
        number: 2,
        home: 2,
        away: 3,
        ref: 1
      },
      {
        number: 3,
        home: 1,
        away: 2,
        ref: 3
      }
    ]
  },
  {
    teams: 4,
    matches: [
      {
        number: 1,
        home: 1,
        away: 4,
        ref: 2
      },
      {
        number: 2,
        home: 3,
        away: 2,
        ref: 1
      },
      {
        number: 3,
        home: 3,
        away: 1,
        ref: 4
      },
      {
        number: 4,
        home: 2,
        away: 4,
        ref: 1
      },
      {
        number: 5,
        home: 4,
        away: 3,
        ref: 2
      },
      {
        number: 6,
        home: 1,
        away: 2,
        ref: 3
      }
    ]
  },
  {
    teams: 5,
    matches: [
      {
        number: 1,
        home: 1,
        away: 5,
        ref: 3
      },
      {
        number: 2,
        home: 4,
        away: 2,
        ref: 3
      },
      {
        number: 3,
        home: 3,
        away: 1,
        ref: 2
      },
      {
        number: 4,
        home: 5,
        away: 4,
        ref: 2
      },
      {
        number: 5,
        home: 3,
        away: 2,
        ref: 5
      },
      {
        number: 6,
        home: 1,
        away: 4,
        ref: 5
      },
      {
        number: 7,
        home: 2,
        away: 5,
        ref: 1
      },
      {
        number: 8,
        home: 4,
        away: 3,
        ref: 1
      },
      {
        number: 9,
        home: 5,
        away: 3,
        ref: 4
      },
      {
        number: 10,
        home: 1,
        away: 2,
        ref: 4
      }
    ]
  },
  {
    teams: 6,
    matches: [
      {
        number: 1,
        home: 2,
        away: 3,
        ref: 1
      },
      {
        number: 2,
        home: 5,
        away: 6,
        ref: 4
      },
      {
        number: 3,
        home: 4,
        away: 2,
        ref: 5
      },
      {
        number: 4,
        home: 1,
        away: 6,
        ref: 3
      },
      {
        number: 5,
        home: 5,
        away: 4,
        ref: 2
      },
      {
        number: 6,
        home: 3,
        away: 1,
        ref: 6
      },
      {
        number: 7,
        home: 2,
        away: 5,
        ref: 3
      },
      {
        number: 8,
        home: 6,
        away: 4,
        ref: 1
      },
      {
        number: 9,
        home: 3,
        away: 5,
        ref: 2
      },
      {
        number: 10,
        home: 4,
        away: 1,
        ref: 6
      },
      {
        number: 11,
        home: 2,
        away: 6,
        ref: 1
      },
      {
        number: 12,
        home: 3,
        away: 4,
        ref: 5
      },
      {
        number: 13,
        home: 1,
        away: 5,
        ref: 4
      },
      {
        number: 14,
        home: 6,
        away: 3,
        ref: 2
      },
      {
        number: 15,
        home: 1,
        away: 2,
        ref: 3
      }
    ]
  }
]
