export default class RosterSDK {
  constructor (http) {
    this.http = http
  }

  teams (type, search) {
    const t = type ? `type=${type}` : null
    const s = search ? `search=${search}` : null
    const a = [t, s].filter(f => !!f)
    const q = a.length > 0 ? `?${a.join('&')}` : ''
    return this.http.get(`roster/teams${q}`)
  }

  teamsById (ids) {
    return this.http.post('roster/teams', ids)
  }

  submit (id, dto) {
    return this.http.post(`/roster/submit/${id}`, dto)
  }

  connect (id, parentId) {
    return this.http.get(`/roster/connect?tournamentTeamId=${id}&teamId=${parentId}&college=false`)
  }

  coaches (id) {
    return this.http.get(`/roster/team/coaches?id=${id}`)
  }

  team = {
    get: (id) => {
      return this.http.get(`/roster/team/${id}`)
    },
    post: (dto, image) => {
      if (image) {
        const form = image || new FormData()
        form.append('team', JSON.stringify(dto))
        return this.http.post('/roster/team/form', form)
      }
      return this.http.post('/roster/team', dto)
    },
    getCurrent: (ppid) => {
      return this.http.get(`/roster/player/${ppid}/teams`)
    }
  }

  contacts = {
    get: (id) => {
      return this.http.get(`/roster/team/contacts?id=${id}`)
    },
    post: (dto) => {
      return this.http.post('/roster/team', dto)
    }
  }

  lineups = {
    get: (dto, token) => {
      const t = token ? `?token=${token}` : ''
      return this.http.post(`/team/${dto.lineup.teamId}/lineups${t}`, dto)
    },
    post: (dto, token) => {
      const t = token ? `?token=${token}` : ''
      return this.http.post(`/team/lineup${t}`, dto)
    },
    delete: (dto, token) => {
      const t = token ? `?token=${token}` : ''
      return this.http.post(`/team/lineup/delete${t}`, dto)
    }
  }
}
