import uniq from 'lodash.uniq'
import firstBy from 'thenby'

export default class PoolPlayOptionDescriptor {
  constructor (option) {
    Object.assign(this, option)
    this.games = option.gameSettings.map(m => new GameDescriptor(m))
  }

  get description () {
    if (this.gameSettings.length === 2 && this.gameSettings[0].to === 999) return '2 timed sets.'
    return `${this.heading} ${this.subheading}`
  }

  get heading () {
    return this.matchType.isMatch ? `match play (${this.matchStyle}).` : this.gameSettings.length === 1 ? '1 set' : `${this.gameSettings.length} sets.`
  }

  get subheading () {
    if (this.games.length === 1) {
      return this.games[0].description
    }
    if (this.games.length === 2) {
      const descriptions = uniq(this.games.sort(firstBy('number')).map(g => g.description))
      return descriptions.length === 1 ? `Both sets ${descriptions[0]}` : `Set 1 ${descriptions[0]} & set 2 ${descriptions[1]}`
    }
    if (this.games.length === 3) {
      const descriptions = uniq(this.games.sort(firstBy('number')).map(g => g.description))
      return descriptions.length === 1 ? `All sets ${descriptions[0]}` : `Sets 1 & 2 ${descriptions[0]} & set 3 ${descriptions[descriptions.length - 1]}`
    } else {
      const a = this.games.sort(firstBy('number')).map(g => g.description)
      const b = uniq(a)
      // const descriptions = uniq(this.games.sort(firstBy('number')).map(g => g.description))
      return b.length === 1 ? `All sets ${b[0]}` : `Sets 1 - ${a.length - 1} ${b[0]} & set ${a.length} ${b[b.length - 1]}`
    }
  }

  get matchStyle () {
    return this.games.length === 5 ? 'best 3 out of 5' : 'best 2 out of 3'
  }
}

class GameDescriptor {
  constructor (settings) {
    Object.assign(this, settings)
  }

  get description () {
    const cap = this.cap ? `with a ${this.cap} point cap` : 'with no cap'
    return `to ${this.to} ${cap}`
  }
}
