const base = {
  settings: {
    upcoming: [],
    phone: [],
    email: [],
    metrics: ['public']
  },
  academics: {
    gpa: null,
    gpaScale: null,
    classRank: null,
    classSize: null,
    satMath: null,
    satEbrw: null,
    act: null,
    honors: null,
    majors: [],
    extracurricular: null,
    hobbies: null,
    service: null,
    selectivity: [],
    enrollment: [],
    regions: [],
    divisions: [],
    transcriptUrl: null,
    ncaaEligibilityNumber: null,
    hsCity: null,
    hsState: null
  },
  bio1: null,
  contacts: [],
  beachVindoor: null,
  programs: {
    beach: {
      club: {
        yesOrNo: false,
        name: null,
        team: null,
        contacts: []
      },
      hs: {
        yesOrNo: false,
        name: null,
        team: null,
        contacts: []
      }
    },
    indoor: {
      club: {
        yesOrNo: false,
        name: null,
        team: null,
        contacts: []
      },
      hs: {
        yesOrNo: false,
        name: null,
        team: null,
        contacts: []
      }
    }
  },
  social: {
    youtube: null,
    vimeo: null,
    facebook: null,
    instagram: null,
    twitter: null,
    snapchat: null,
    tiktok: null
  }
}

export default base
