<template>
  <v-list dense shaped class="pb-16">
    <!-- PUBLIC PAGES -->
      <template v-for="item in publicPages">
        <v-list-item
          v-if="item.to"
          :key="`p-${item.text}`"
          router
          :to="item.to"
          active-class="color3--text"
          :exact="item.exact"
        >
          <v-list-item-action>
            <v-avatar
              size="30"
              v-if="item.src"
            >
              <img :src="item.src" alt="alt">
            </v-avatar>
            <v-icon v-else>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else-if="item.click" :key="item.text" @click="item.click">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-for="team in collegeTeams">
        <v-list-item
          :key="`college-${team.id}`"
          router
          :to="{name: 'team-edit', params: {teamId: team.id}}"
          active-class="color3s--text"
          exact
        >
          <v-list-item-action>
            <v-img
              :src="team.logo"
              :alt="team.name"
              height="48"
              width="48"
              aspect-ratio="1"
              contain
            ></v-img>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ team.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-if="tournament && navableTournament">
        <!-- TOURNAMENT PAGES -->
        <template v-if="tournament.isTournament">
          <tournament-items></tournament-items>
        </template>
        <!-- LEAGUE PAGES -->
        <template v-else-if="tournament.isLeague">
          <league-items></league-items>
        </template>
        <!-- TRAINING SESSION PAGES -->
        <template v-else-if="tournament.isTrainingSession">
          <session-items></session-items>
        </template>
        <!-- CAMP PAGES -->
        <template v-else>
          <camp-items></camp-items>
        </template>
      </template>

    <!-- USER PAGES -->
      <template v-if="user">
        <!-- ADMIN PAGES -->
        <template v-if="userPages.length === 1">
          <template v-for="(page, i) in userPages">
            <v-subheader class="mt-4" :key="'head' + i">
              {{page.name}}
              <template v-if="user.vbl">
                <v-icon v-if="!page.pinned" small class="pb-4" @click.stop="togglePin(page.username)">fas fa-thumbtack fa-rotate-90</v-icon>
                <v-icon v-else small class="pl-2" @click.stop="togglePin(page.username)">fas fa-thumbtack</v-icon>
              </template>
            </v-subheader>
            <template v-for="tile in page.tiles">
              <v-list-item
                v-if="tile.to"
                :key="`${page.username}-${tile.text}`"
                router
                exact
                exact-active-class="color3--text"
                :to="tile.to">
                <v-list-item-action>
                  <v-icon>{{tile.icon}}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{tile.text}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-else-if="tile.click"
                :key="`${page.username}-${tile.text}`"
                router
                exact
                exact-active-class="color3--text"
                @click="tile.click"
              >
                <v-list-item-action>
                  <v-icon>{{tile.icon}}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{tile.text}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </template>
        </template>
        <template v-else>
          <v-list-group
            v-for="(page, i) in userPages"
            v-model="page.model"
            no-action
            :key="'page' + i"
          >
            <template v-slot:activator>
              <v-list-item-title>{{page.name}}</v-list-item-title>
                <template v-if="user.vbl">
                  <v-icon v-if="!page.pinned" small class="pb-4" @click.stop="togglePin(page.username)">fas fa-thumbtack fa-rotate-90</v-icon>
                  <v-icon v-else small class="pl-2" @click.stop="togglePin(page.username)">fas fa-thumbtack</v-icon>
                </template>
            </template>
            <template v-for="tile in page.tiles">
              <v-list-item
                v-if="tile.to"
                :key="`${page.username}-${tile.text}`"
                router
                exact
                exact-active-class="color3--text"
                :to="tile.to">
                <v-list-item-action>
                  <v-icon>{{tile.icon}}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{tile.text}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-else-if="tile.click"
                :key="`${page.username}-${tile.text}`"
                router
                exact
                exact-active-class="color3--text"
                @click="tile.click"
              >
                <v-list-item-action>
                  <v-icon>{{tile.icon}}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{tile.text}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
        <!-- USER NAME  -->
        <v-subheader class="mt-4">{{user.fullname}}</v-subheader>
        <!-- USER MENU  -->
        <template v-for="item in userMenu">
          <v-list-item
            v-if="item.to"
            :key="item.text"
            router
            :to="item.to"
            exact
            exact-active-class="color3--text"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-else-if="item.click"
            :key="item.text + '_click'"
            @click="item.click"
            exact
            exact-active-class="color3--text"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <!-- ADMIN  -->
        <template v-if="user.vbl || user.data">
          <v-subheader class="mt-4">Admin</v-subheader>
          <!-- ADMIN MENU  -->
          <template v-for="item in adminMenu">
            <v-list-item
              v-if="item.to"
              :key="item.text"
              router
              :to="item.to"
              exact
              exact-active-class="color3--text"
            >
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-else-if="item.click"
              :key="item.text"
              @click="item.click"
              exact
              exact-active-class="color3--text"
            >
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
      </template>

    <!-- PLAYER SEARCH DIALOG -->
      <player-edit-dialog :open="quickEdit" @closed="quickEdit = false"></player-edit-dialog>
    <!-- COLLEGE COACH DIALOG -->
      <college-welcome-dialog
        v-if="user && user.isCollegeCoachX"
        ref="ccWiz"
      ></college-welcome-dialog>
        <multi-tournament-team-search
          :username="theme.username"
          :open="findMe" @closed="findMe = false"
           v-if="theme.props.includes('multiTournamentTeamSearch')"
        >
        </multi-tournament-team-search>
      </v-list>
</template>

<script>
import { mapGetters } from 'vuex'
import uniqBy from 'lodash.uniqby'
const PlayerEditDialog = () => import('@/components/Forms/PlayerEditDialog.vue')
const TournamentItems = () => import('@/Nav/Drawers/TournamentItems.vue')
const CampItems = () => import('@/Nav/Drawers/CampItems.vue')
const LeagueItems = () => import('@/Nav/Drawers/LeagueItems.vue')
const SessionItems = () => import('@/Nav/Drawers/SessionItems.vue')
const CollegeWelcomeDialog = () => import('@/Nav/Drawers/CollegeWelcomeDialog')
const MultiTournamentTeamSearch = () => import('@/components/Team/MultiTournamentTeamSearch')

export default {
  data () {
    return {
      pins: [],
      quickEdit: false,
      findMe: false
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'theme',
      'tournament',
      'admin',
      'publicView',
      'subdomain',
      'view',
      'rankings',
      'myEvent',
      'eventsTab'
    ]),
    navableTournament () {
      return (
        (this.tournament && this.tournament.publicCanNav && !this.tournament.isCanceled) ||
        this.view.adminAndRefs
      ) ? this.tournament : null
    },
    soloPoints () {
      const a = this.theme.pointSystems
      const ps = a && a.length > 0 && a[0]
      const j = ps && ps.jsonProps && JSON.parse(ps.jsonProps)
      return j && j.solo ? ps : false
    },
    showStore () {
      return this.theme && this.theme.props.includes('store')
    },
    publicPages () {
      let pages = []
      if (this.rankings.length) {
        const orgRanks = this.rankings.filter(f => !f.sb)
        if (orgRanks.length === 1 || this.rankings.length === 1) {
          const r = orgRanks[0] || this.rankings[0]
          pages.push({ icon: 'fas fa-list-ol fa-fw', text: 'Rankings', to: r.route })
        } else {
          pages.push({ icon: 'fas fa-list-ol fa-fw', text: 'Rankings', to: { name: 'rankings-home' } })
        }
      }

      var to = { name: 'filtered-tournaments', params: { filter: this.eventsTab } }
      const jProps = this.theme.jsonProps ? JSON.parse(this.theme.jsonProps) : false
      if (jProps) {
        const btns = jProps.eventFilterBtns
        var defaultBtn = btns.find(f => f.default)
        if (defaultBtn && defaultBtn.query) to.query = defaultBtn.query
      }
      pages.push({ icon: 'far fa-calendar-alt fa-fw', text: 'Events', to: to })

      const p = this.theme.props
      if (this.theme.membership || (p && p.includes('member-grid'))) {
        pages.unshift({
          icon: 'fas fa-users fa-fw',
          text: this.theme.membership ? 'Members' : 'Players',
          to: { name: p && p.includes('member-grid') ? 'player-search' : 'member-search', params: { members: p && p.includes('member-grid') } }
        })
        if (!(this.user && this.user.member) && this.theme.membership) {
          pages.unshift({ icon: 'fas fa-users-medical fa-fw', text: 'Join', to: { name: 'member-levels' } })
        }
      } else if (this.user) {
        if (this.user.canSearchPlayers && !p.includes('no-player-list')) {
          pages.unshift({ icon: 'fas fa-users fa-fw', text: 'Players', to: { name: 'player-search' } })
        } else {
          pages.unshift({ icon: 'fas fa-user fa-fw', text: 'Players', click: () => { this.quickEdit = true } })
        }
      }
      if (!p.includes('no-claim')) {
        if (!this.user || (this.user && !this.user.profiles.length)) {
          pages.unshift({ icon: 'fas fa-certificate fa-fw', text: 'Your Player Photo!', to: { name: 'claim-my-profile' } })
        }
      }
      if (this.subdomain === 'avp' || this.subdomain === 'usav') {
        // pages.push(
        //   { icon: 'fas fa-trophy fa-fw', text: 'Point System', to: { name: 'avppoints' } }
        // )
      } else if (this.subdomain === 'aau') {
        pages.push(
          { icon: 'fas fa-trophy fa-fw', text: 'Point System', to: { name: 'aaupoints' } }
        )
      } else if (this.subdomain === 'bvne' || this.subdomain === 'gnbv') {
        pages.push(
          { icon: 'fas fa-trophy fa-fw', text: 'Point System', to: { name: 'bvnepoints' } }
        )
      } else if (this.subdomain === 'p1440') {
        pages.push(
          { icon: 'fas fa-trophy fa-fw', text: 'Point System', to: { name: 'p1440points' } }
        )
      } else if (this.soloPoints) {
        pages.push(
          { icon: 'fas fa-trophy fa-fw', text: 'Point System', to: { name: 'soloPoints', params: { pointSystemId: this.soloPoints.id } } }
        )
      } else {
        pages.push(
          { icon: 'fas fa-trophy fa-fw', text: 'Point Systems', to: { name: 'points' } }
        )
      }
      if (this.user && this.user.vbl) {
        pages.push(
          { icon: 'fas fa-volleyball-ball fa-fw', text: 'Organizations', to: { name: 'organizations' } }
        )
      }

      if (!this.user) {
        pages.push({ icon: 'fas fa-unlock-alt fa-fw', text: 'Sign In', click: () => { this.$emit('login-click') } })
      }
      if (p.includes('store')) {
        pages.push({ icon: 'fas fa-store fa-fw', text: 'Store', to: { name: 'store-front' } })
      }
      pages.push({ icon: 'fas fa-search fa-fw', text: 'Order Lookup', to: { name: 'order-lookup' } })

      if (this.theme.excludes) {
        const x = this.theme.excludes.split(',')
        if (x.includes('rankings')) {
          pages = pages.filter(f => f.text !== 'Rankings')
        }
        if (x.includes('tournaments')) {
          pages = pages.filter(f => f.text !== 'Tournaments')
        }
        if (x.includes('points')) {
          pages = pages.filter(f => !f.text.startsWith('Point System'))
        }
      }
      if (!p.includes('no-videos')) {
        pages.unshift({ icon: 'fas fa-video fa-fw', text: 'Helpful Videos', to: { name: 'video-library' } })
      }
      if (!p.includes('no-ratings')) {
        pages.unshift({ icon: 'fas fa-font fa-fw', text: 'VBL Ratings', to: { name: 'ratings-explained' }, srcX: 'https://vblimages.blob.core.windows.net/images/Badges/badge_green_AAA.png' })
      }
      if (!p.includes('no-help') && this.user && this.user.vbl) {
        pages.unshift({ icon: 'fas fa-question fa-fw', text: 'Helpful Links', to: { name: 'link-library' } })
      }
      if (p.includes('multiTournamentTeamSearch')) {
        pages.unshift({ icon: 'fas fa-users fa-fw', text: 'Find Your Team', click: () => { this.findMe = true } })
      }
      pages.unshift({ icon: 'fas fa-home fa-fw', text: 'Home', to: { name: 'home' }, exact: true })
      return pages
    },
    userMenu () {
      // const me = this.user.member ? { name: 'member-profile', params: this.user.member } : { name: 'me' }
      return [
        { icon: 'fas fa-user-circle fa-fw', text: 'My Account', to: { name: 'me' } },
        ...this.user.profiles.map(p => {
          return {
            icon: 'fas fa-id-card',
            text: p.relation === 'Self' ? 'My Player Profile' : `${p.profile.firstName}'s Player Profile`,
            to: { name: 'player-profile', params: { playerProfileId: p.profile.id } }
          }
        }),
        { icon: 'fas fa-history fa-fw', text: 'My Orders', to: { name: 'my-orders' } },
        { icon: 'fas fa-heart fa-fw', text: 'Favorites & Notes', to: { name: 'my-bookmarks' }, hide: !this.user },
        { icon: 'fas fa-sign-out-alt fa-fw', text: 'Sign Out', click: () => { this.$emit('logout-click') } }
      ].filter(f => !f.hide)
    },
    adminMenu () {
      return [
        { icon: 'fas fa-user-edit fa-fw', text: 'Quick Edit', click: () => { this.quickEdit = true }, hide: !this.user.vbl },
        { icon: 'fas fa-people-arrows fa-fw', text: 'Merge Profiles', to: { name: 'admin-profile-merge' } },
        { icon: 'fas fa-university fa-fw', text: 'College List', to: { name: 'admin-college-list' } },
        { icon: 'fas fa-search fa-fw', text: 'User Search', to: { name: 'user-search' } },
        { icon: 'fas fa-users-class fa-fw', text: 'Teams', to: '/teams', hide: !this.user.vbl }
      ].filter(f => !f.hide)
    },
    userPages () {
      return this.subdomain && this.subdomainPages.length
        ? this.subdomainPages
        : this.user.vbl ? this.vblAdminPages
          : this.allUserPages
    },
    subdomainPages () {
      return this.subdomain ? this.user && this.user.pages.filter(f => f.username.toLowerCase() === this.subdomain.toLowerCase()).map(m => this.createAdminMenu(m)) : []
    },
    allUserPages () {
      return this.user && this.user.pages.filter(f => f.username !== 'coachesbox').map(m => this.createAdminMenu(m))
    },
    currentPages () {
      return this.$route.params.username && !this.pins.includes(this.$route.params.username.toLowerCase()) ? this.user.pages.filter(f => f.username === this.$route.params.username.toLowerCase()).map(m => this.createAdminMenu(m)) : []
    },
    pinnedPages () {
      return this.user && this.user.pages.filter(f => this.pins.includes(f.username)).map(m => this.createAdminMenu(m))
    },
    vblAdminPages () {
      return uniqBy([...this.jonPage, ...this.pinnedPages, ...this.currentPages], 'username')
    },
    jonPage () {
      return (this.user && this.user.id === 1) ? this.user.pages.filter(f => f.username.toLowerCase() === 'demo').map(m => this.createAdminMenu(m)) : []
    },
    collegeTeams () {
      return this.user && this.user.collegeTeams
    }
  },
  methods: {
    createAdminMenu (page) {
      if (page.username === 'ntdp' && !this.user.vblx) {
        var y = {
          name: page.name,
          username: page.username,
          pinned: this.pins.includes(page.username),
          tiles: [
            { icon: 'fas fa-search fa-fw', text: 'Player Search', click: () => { this.quickEdit = true } },
            { icon: 'fas fa-file-chart-line fa-fw', text: 'Reports', to: { name: 'organization-reports', params: { username: page.username } }, show: true }
          ]
        }
        if (this.user.props.includes('ntdp-admin')) {
          y.tiles.push({ icon: 'fas fa-users fa-fw', text: 'Staff', to: { name: 'organization-staff', params: { username: page.username } }, show: true })
        }
        return y
      }
      var x = {
        name: page.name,
        username: page.username,
        pinned: this.pins.includes(page.username),
        tiles: [
          { icon: 'fas fa-home fa-fw', text: 'Account Home', to: { name: 'organization-home', params: { username: page.username } }, show: true },
          { icon: 'fas fa-calendar fa-fw', text: 'Calendar', to: { name: 'organization-calendar', params: { username: page.username } }, show: page.props.includes('calendar') },
          { icon: 'fas fa-cog fa-fw', text: 'Account Settings', to: { name: 'organization-settings', params: { username: page.username } }, show: true },
          { icon: 'fas fa-palette fa-fw', text: 'Theme', to: { name: 'organization-theme', params: { username: page.username } }, show: true },
          { icon: 'fas fa-images fa-fw', text: 'Images', to: { name: 'organization-images', params: { username: page.username } }, show: true },
          { icon: 'fas fa-money-bill-alt fa-fw', text: 'Promos', to: { name: 'organization-promos', params: { username: page.username } }, show: !page.pointsOnly && !page.ratingsOnly },
          { icon: 'fas fa-address-book fa-fw', text: 'Players', to: { name: 'organization-players', params: { username: page.username } }, show: !page.pointsOnly && !page.ratingsOnly },
          { icon: 'fas fa-search fa-fw', text: 'Reg Search', to: { name: 'organization-reg-search', params: { username: page.username } }, show: !page.pointsOnly && !page.ratingsOnly }
        ].filter(f => f.show)
      }
      if (this.theme.membership) {
        x.tiles.splice(2, 0, { icon: 'fas fa-users fa-fw', text: 'Members', to: { name: 'organization-members', params: { username: page.username } } })
      }

      return x
    },
    togglePin (page) {
      const i = this.pins.indexOf(page)
      if (i === -1) {
        this.pins.push(page)
      } else {
        this.pins.splice(i, 1)
      }
    }
  },
  components: {
    PlayerEditDialog,
    TournamentItems,
    CampItems,
    CollegeWelcomeDialog,
    LeagueItems,
    MultiTournamentTeamSearch,
    SessionItems
  }
}
</script>
<style scoped>
.sub-item > .v-list-item__action {
  margin-right: 4px;
}
</style>
