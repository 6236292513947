<template>
  <div v-if="event">
    <event-manager
      v-if="manage"
      :event="event"
      @changed="onChange"
      @tab-change="onTabChanged"
      :view="view"
    ></event-manager>
    <event-brochure
      v-else
      :event="event"
      :view="view"
      :startTab="event && event.iLeaderboard.leaders.length ? 'leaderboard' : null"
    ></event-brochure>
    <v-fab-transition>
      <v-tooltip top v-if="view.admin">
        <span v-if="manage" class="text-center">Event Brochure</span>
        <span v-else class="text-center">Event Admin</span>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            color="color3 color3Text--text"
            fab
            bottom
            right
            fixed
            @click.stop="toggleManage"
            :disabled="isNew"
          >
            <v-icon v-if="manage">fas fa-file-image</v-icon>
            <v-icon v-else>fas fa-user-shield</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </v-fab-transition>
    <div class="text-right pr-3" v-if="event && event.id > 0">
      <v-btn color="color3" text icon class="xsmall" @click="load">
        <v-icon>fas fa-redo-alt</v-icon>
      </v-btn>
    </div>
  </div>
  <div v-else>
    ERROR
  </div>
</template>

<script>
import EventBrochure from '@/components/Events/Reoccuring/EventBrochure.vue'
import EventManager from '@/components/Events/Reoccuring/EventManager.vue'
import ReoccuringEvent from '@/classes/ReoccuringEvent'
import { mapGetters } from 'vuex'
import * as mutations from '@/store/MutationTypes'
export default {
  props: ['eventTag'],
  data () {
    return {
      event: null,
      activeTab: 'edit'
    }
  },
  computed: {
    ...mapGetters(['user', 'publicView', 'getPageInfo']),
    view () {
      return {
        admin: this.event && this.user && (this.event.adminId === this.user.id || this.event.id === 0) && !this.publicView,
        public: !(this.event && this.user && (this.event.adminId === this.user.id || this.event.id === 0) && !this.publicView)
      }
    },
    manage () {
      return this.$route.name.startsWith('event-admin')
    },
    isNew () {
      return !!this.$route.query.new
    },
    username () {
      return this.$route.query.username
    },
    page () {
      return this.getPageInfo(this.username)
    }
  },
  methods: {
    onTabChanged (val) {
      this.activeTab = val
    },
    load () {
      if (this.isNew && this.user && this.user.pages.length) {
        this.createNew()
        return
      }
      this.$store.commit(mutations.LOADING, this.eventTag)
      this.$VBL.events.event.get(this.eventTag)
        .then(r => {
          if (!r.data) this.$router.push(`/signin?returnUrl=${this.$route.fullPath}`)
          this.event = new ReoccuringEvent(r.data)
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.$store.commit(mutations.LOADING_COMPLETE, this.eventTag)
        })
    },
    onChange (dto) {
      // this.event = new ReoccuringEvent(dto)
      this.load()
    },
    toggleManage () {
      const to = {
        name: this.$route.name === 'event-home' ? 'event-admin' : 'event-home',
        params: this.$route.params
      }
      this.$router.push(to)
    },
    createNew () {
      if (this.isNew && this.user && this.user.pages.length) {
        this.event = new ReoccuringEvent({
          adminId: this.user.id,
          titleTag: this.eventTag,
          title: this.eventTag.replace(/_/g, ' '),
          dtStart: this.$route.query.start,
          organizers: [{
            organization: this.page
          }]
        })
      }
    }
  },
  watch: {
    user: function (n, o) {
      this.load()
    }
  },
  mounted () {
    console.log('here')
    this.load()
  },
  components: {
    EventBrochure,
    EventManager
  }
}
</script>

<style>

</style>
