<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn color="color3" small text v-on="on">Need {{btnLabel}} Membership?</v-btn>
    </template>
    <v-card>
      <v-toolbar color="white" class="pl-0">
        <v-img
          height="40px"
          style="max-width: 95px"
          contain
          :src="logo.url"
        ></v-img>
        <v-spacer></v-spacer>
        <v-btn
          color="error white--text"
          small fab
          @click.stop="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <loading-bar :value="loading"></loading-bar>
      <v-card-text>
        <v-expand-transition>
          <div v-if="program">
            <v-window v-model="window">
              <v-window-item :key="0">
                <v-expand-transition>
                  <div v-if="player.avpNumber && !renew">
                    <div class="title">Are you renewing {{player.avpNumber}}?</div>
                    <div class="text-center">
                      <v-btn color="success" class="mr-3" @click.stop="renew = true">yes</v-btn>
                      <v-btn color="error" @click.stop="player.avpNumber = null">No</v-btn>
                    </div>
                  </div>
                </v-expand-transition>
                <v-expand-transition>
                  <div v-if="!(player.avpNumber && !renew)">
                    <div class="title">Select your membership level</div>
                    <v-list two-line>
                      <v-list-item-group
                        v-model="level"
                      >
                        <v-list-item v-for="l in program.levels" :key="l.id">
                          <template v-slot:default="{ active }">
                            <v-list-item-action>
                              <v-checkbox :input-value="active" color="success"></v-checkbox>
                            </v-list-item-action>

                            <v-list-item-content>
                              <v-list-item-title>{{l.name}}</v-list-item-title>
                              <v-list-item-subtitle>{{l.subtitle}}</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                              {{l.price | usDollars}}
                            </v-list-item-action>

                          </template>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </div>
                </v-expand-transition>

              </v-window-item>
              <v-window-item :key="1">
                <template v-if="selectedLevel">
                  <person-form
                    v-if="fields"
                    ref="p1"
                    :player="player"
                    @valid-change="onValidChange"
                    :fields="['name', 'gender', ...fields.top.ask, ...fields.bottom.ask]"
                    :requiredFields="[...fields.top.req, ...fields.bottom.req]"
                    :allowInternational="true"
                    :embed="true"
                  ></person-form>
                </template>
              </v-window-item>
            </v-window>
          </div>
        </v-expand-transition>
      </v-card-text>
      <v-card-actions class="justify-space-between">
        <v-fab-transition>
          <v-btn
            color="white success--text"
            small fab
            key="back"
            :disabled="window === 0"
            @click.stop="window = 0"
          >
            <v-icon>fas fa-caret-left</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-fab-transition>
          <v-btn
            color="success white--text"
            v-if="window === 1"
            small fab
            :disabled="!valid"
            @click.stop="addToCart"
          >
            <v-icon>fas fa-shopping-cart</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-fab-transition>
          <v-btn
            color="success white--text"
            small fab
            v-if="window === 0"
            :key="!selectedLevel"
            :disabled="!selectedLevel || window === 1"
            @click.stop="window = 1"
          >
            <v-icon>fas fa-caret-right</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Logos from '@/classes/LogoUrls'
import PersonForm from '@/components/Forms/PersonForm'
import CartItem from '@/classes/CartItem'
import * as actions from '@/store/ActionTypes'
import { firstBy } from 'thenby'

export default {
  props: ['subdomain', 'playerI', 'player', 'organization', 'expired'],
  data () {
    return {
      dialog: false,
      loading: false,
      program: null,
      level: null,
      window: 0,
      valid: false,
      renew: false
    }
  },
  computed: {
    btnLabel () {
      return `${this.expired ? ' to renew ' : ''}${this.anOrA} ${this.subdomain}`
    },
    anOrA () {
      return this.subdomain.toLowerCase().startsWith('avp') ? 'an' : 'a'
    },
    logo () {
      return this.subdomain.toLowerCase().startsWith('avp') ? Logos.avpamerica : Logos[this.subdomain.toLowerCase()]
    },
    selectedLevel () {
      return this.program && this.program.levels[this.level]
    },
    name () {
      return this.selectedLevel && `${this.selectedLevel.name}${this.selectedLevel.name.endsWith('Card') ? '' : ' Membership'}`
    },
    playerName () {
      return (this.player && !!this.player.firstName && this.player.name) || `Player ${this.playerI}`
    },
    jProps () {
      return this.selectedLevel && this.selectedLevel.jsonProps && JSON.parse(this.selectedLevel.jsonProps)
    },
    fields () {
      return this.jProps && this.jProps.fields
    },
    rightProgram () {
      return this.program && this.program.organization.username === this.subdomain
    }
  },
  methods: {
    loadProgram () {
      this.loading = true
      this.$VBL.get.membership(this.subdomain)
        .then(r => {
          this.program = r.data
          this.program.levels && this.program.levels.sort(firstBy('order'))
        })
        .catch(e => {
          console.log(e.response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    cartItem () {
      if (!(this.selectedLevel && this.valid)) return null
      const item = new CartItem()
      item.newMembership(this.program, this.selectedLevel, this.player, this.organization)
      return item
    },
    addToCart () {
      var dto = this.cartItem()
      if (dto) {
        this.player.avpaStatus = 'true'
        this.$store.dispatch(actions.ADD_CART_ITEM, dto)
        this.dialog = false
      }
    },
    onValidChange (v) {
      this.valid = v
    }
  },
  watch: {
    dialog: function (v) {
      this.window = 0
      this.level = null
      if (v && !this.rightProgram) this.loadProgram()
    },
    subdomain: function (n, o) {
      if (n & o & n !== o) {
        this.program = null
      }
    }
  },
  components: {
    PersonForm
  }
}

</script>
