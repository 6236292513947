import moment from 'moment'
import Flight from './Flight'
import flatten from '@/helpers/ArrayFlatten'
import ValidIsoDate from '@/helpers/ValidIsoDate'
import Bracket from './Bracket'
import DtoUpdate from './DtoUpdate'
import TeamSelector from './TeamSelector'
import { firstBy } from 'thenby'
import { formatArray } from '@/Filters'
import uniq from 'lodash.uniq'

export default class TournamentDay {
  constructor (sdk, dto) {
    this.sdk = sdk
    this.id = 0
    this.number = 1
    this.name = null
    this._date = null
    this.checkInTime = '08:00'
    this.playTime = '09:00'
    this.poolPlay = true
    this.bracketPlay = false
    this.bracketType = null
    this.teamSelectors = []
    this.flights = []
    this.brackets = []
    this.flightsCreated = false
    this.published = false
    this.hideTimes = false
    this.locked = false
    this.noFinish = false
    this.teamCount = null
    this.freePlay = false

    if (dto) {
      this.update(dto, true)
    }
  }

  reset () {
    this.number = 1
    this.name = null
    this.poolPlay = true
    this.bracketPlay = false
    this.bracketType = null
    this.teamSelectors = []
    this.flights = []
    this.brackets = []
    this.flightsCreated = false
    this.published = false
    this.hideTimes = false
    this.locked = false
    this.noFinish = false
    this._dto = null
    this.teamCount = null
    this.freePlay = false
    this.setName()
  }

  update (dto, isNew) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = ['flights', 'brackets', 'teamSelectors']
    if (!dto.teamCount) exclude.push('teamCount')
    DtoUpdate(dto, this, exclude)
    if (!this.checkInTime) {
      this.checkInTime = '08:00'
    }
    if (!this.playTime) {
      this.playTime = '09:00'
    }

    if (dto.teamSelectors) {
      this.teamSelectors = this.teamSelectors.filter(f => f.id)
      dto.teamSelectors.forEach(ts => {
        const x = this.teamSelectors.find(t => t.id === ts.id)
        if (x) {
          x.update(ts)
        } else {
          this.teamSelectors.push(new TeamSelector(this.sdk, ts))
        }
      })
    }

    if (dto.flights) {
      dto.flights.forEach(flight => {
        const i = this.flights.findIndex(f => f.id === flight.id)
        if (i === -1) {
          !flight.deleted && this.flights.push(new Flight(this.sdk, flight))
        } else {
          if (flight.deleted) {
            this.flights.splice(i, 1)
          } else {
            const y = this.flights[i]
            if (y) {
              y.update(flight)
            }
          }
        }
      })
    }
    if (dto.brackets) {
      dto.brackets.forEach(b => {
        const x = this.brackets.find(f => f.id === b.id)
        if (x) {
          x.update(b)
        } else {
          this.brackets.push(new Bracket(this.sdk, b))
        }
      })
      if (!isNew) {
        this.brackets = this.brackets.filter(f => f.id)
      }
    }
    if (this.teamsSummary) {
      this.teamsSummary.teams.sort(firstBy('in', -1).thenBy('seed'))
    }

    if (!this.name) {
      this.setName()
    }
    this._dto = null
  }

  edit () {
    this._dto = JSON.stringify(this.dto)
  }

  restore () {
    if (this._dto && this.isDirty()) this.update(this._dto)
    this._dto = null
  }

  isDirty () {
    return this._dto !== JSON.stringify(this.dto) || this.id === 0
  }

  updateFromTemplate (template) {
    this.date = template.date
    this.checkInTime = template.checkInTime
    this.playTime = template.playTime
  }

  formatTime (t) {
    return t ? (t.toLowerCase().includes('am') || t.toLowerCase().includes('pm'))
      ? moment(t, ['h:mmA']).format('h:mm A') : moment(t, ['HH:mm']).format('h:mm A') : ''
  }

  formatTimeReverse (t) {
    return t ? (t.toLowerCase().includes('am') || t.toLowerCase().includes('pm'))
      ? moment(t, ['h:mmA']).format('HH:mm') : moment(t, ['HH:mm']).format('HH:mm') : ''
  }

  formatDate (d) {
    return d ? moment(d).format('MM/DD/YYYY') : ''
  }

  patch (dto) {
    dto.id = this.id
    return this.sdk.patch.day(dto)
  }

  addBracket () {
    this.brackets.push(new Bracket(this.sdk, {}))
  }

  removeBracket (i) {
    this.brackets.splice(i, 1)
  }

  cleanBrackets () {
    this.brackets = this.brackets.filter(f => f.id !== 0)
  }

  toPoolPlay () {
    this.poolPlay = true
    this.bracketPlay = false
    this.setName()
    this.cleanBrackets()
  }

  toBracketPlay () {
    if (this.brackets.length === 0) this.addBracket()
    this.poolPlay = false
    this.bracketPlay = true
    this.setName()
  }

  setName () {
    const defaults = ['Pools', 'Brackets', 'Playoffs', 'Free Play']
    if (this.name && !defaults.includes(this.name)) return
    this.name = this.poolPlay ? this.freePlay ? 'Free Play' : 'Pools' : this.number === 1 ? 'Brackets' : 'Playoffs'
  }

  getTimeline (teamId, isLeague, offset, crossCheck) {
    const team = this.allTeams.find(f => f.teamId === teamId)
    if (!team && !crossCheck) return null
    const sum = {
      id: this.id,
      title: this.name,
      description: isLeague && this.poolPlay ? 'Regular Season' : this.description,
      hideTimes: this.hideTimes,
      dtStart: moment(`${this.dtStart}${offset}`),
      matches: []
    }
    this.pools.forEach(pool => {
      const matches = pool.getMatchSummaries(teamId)
      if (matches.length) {
        sum.matches.push(...matches.map(m => {
          m.round = `Pool ${pool.name}`
          m.poolId = pool.id
          return m
        }))
      }
    })
    this.brackets.forEach(bracket => {
      const matches = flatten(bracket.matches.sort(firstBy('roundN').thenBy('number')).map(m => {
        return m.timeLineItems.filter(f => f.teamId === teamId)
      }))
      if (matches.length) {
        sum.matches.push(...matches)
      }
    })
    if (!team) {
      sum.matches = sum.matches.filter(f => f.what.startsWith('Ref'))
    }
    if (team && team.poolId) {
      const pool = team.poolId ? this.pools.find(f => f.id === team.poolId) : null
      sum.subtitle = isLeague ? this.date.format('MMM Do, YYYY') : pool.courts ? `Pool ${pool.name} | Court: ${formatArray(pool.courts)}` : `Pool ${pool.name}`
      sum.poolId = team.poolId
      sum.to = isLeague ? { name: 'league-play', params: {}, query: { round: this.number } } : { name: 'pool-sheet', params: { poolId: pool.id, dayId: this.id } }
      const ss = ['Sit', 'Sat', 'TBD']
      if (isLeague || this.freePlay) {
        sum.matches = sum.matches.filter(f => !ss.includes(f.what))
      } else {
        sum.matches = sum.matches.filter(f => !(ss.includes(f.what) && f.poolId !== team.poolId))
        sum.matches = sum.matches.filter(f => {
          if (!ss.includes(f.what)) return true
          const o = sum.matches.filter(m => m.unix === f.unix && !ss.includes(m.what))
          return o.length === 0
        })
      }
    }
    if (team && team.bracketId) {
      sum.to = { name: 'bracket-home', params: { dayId: this.id } }
    }
    sum.matches.sort(firstBy('unix').thenBy('matchN'))
    return sum
  }

  getTimelineOG (teamId) {
    const team = this.allTeams.find(f => f.teamId === teamId)
    if (!team) return null
    const sum = {
      id: this.id,
      title: this.name,
      description: this.description,
      hideTimes: this.hideTimes
    }
    if (team.poolId) {
      const pool = team.poolId ? this.pools.find(f => f.id === team.poolId) : null
      sum.subtitle = `Pool ${pool.name}`
      sum.matches = pool.getMatchSummaries(teamId)
      sum.matches.sort(firstBy('unix').thenBy('matchN'))
      sum.to = { name: 'pool-sheet', params: { poolId: pool.id, dayId: this.id } }
    }
    if (team.bracketId) {
      const bracket = team.bracketId ? this.brackets.find(f => f.id === team.bracketId) : null
      sum.matches = flatten(bracket.matches.sort(firstBy('roundN').thenBy('number')).map(m => {
        return m.timeLineItems.filter(f => f.teamId === teamId)
      }))
      sum.to = { name: 'bracket-home', params: { dayId: this.id } }
    }
    return sum
  }

  updateMatchMeta (tournament, division) {
    this.pools.forEach(p => p.updateMatchMeta(tournament, division, this))
    this.bracket && this.bracket.updateMatchMeta(tournament, division, this)
  }

  getMatchMeta (tournament, division) {
    const a = []
    this.poolPlay && a.push(...flatten(this.pools.map(p => p.getMatchMeta(tournament, division, this))))
    this.bracketPlay && this.bracket && a.push(...flatten(this.bracket.getMatchMeta(tournament, division, this)))
    return a
  }

  // GETTER SETTERS
  set date (val) {
    if (!moment.isMoment(val) && ValidIsoDate(val)) {
      this._date = val
      return
    }
    this._date = val ? moment(val).format('YYYY-MM-DD') : null
  }

  get date () {
    return this._date ? moment(this._date) : null
  }

  set dtStart (val) {
    this._date = val ? moment(val).format('YYYY-MM-DD') : null
    this.playTime = val ? moment(val).format('HH:mm') : null
    this.flight && this.flight.setStart(val)
  }

  get dtStart () {
    return `${this._date}T${this.formatTimeReverse(this.playTime)}:00`
  }

  // GETTERS
  get dto () {
    return {
      id: this.id,
      number: this.number,
      name: this.name,
      date: this.date,
      checkInTime: this.checkInTime,
      playTime: this.playTime,
      poolPlay: this.poolPlay,
      bracketPlay: this.bracketPlay,
      published: this.published,
      hideTimes: this.hideTimes,
      locked: this.locked,
      noFinish: this.noFinish,
      teamSelectors: this.teamSelectors.map(t => t.dto),
      brackets: this.brackets.map(b => b.settingsDto),
      flights: this.flights.map(f => f.patchDto),
      freePlay: this.freePlay
    }
  }

  get dateF () {
    return this.formatDate(this.date)
  }

  get startDateDisplay () {
    return this.date && this.date.format('dddd, MMMM Do YYYY')
  }

  get checkInTimeF () {
    return this.formatTime(this.checkInTime)
  }

  get playTimeF () {
    return this.formatTime(this.playTime)
  }

  get isAdvanced () {
    return this.flights.length > 1 || this.waves.length > 1
  }

  get pools () {
    return flatten(
      this.flights.map(flight => {
        return flight.pools
      })
    )
  }

  get uncourtedPools () {
    return this.pools && this.pools.filter(f => !f.courts)
  }

  get poolTypes () {
    return this.pools.length > 0 && uniq(this.pools.map(m => m.teams.length)).sort().join('/')
  }

  get poolTeams () {
    return this.bracketPlay ? [] : flatten(
      this.flights.map(flight => {
        return flight.pools.map(pool => {
          return pool.teams
        })
      })
    )
  }

  get unlockedPoolTeams () {
    return this.bracketPlay ? [] : flatten(
      this.flights.map(flight => {
        return flight.pools.filter(f => !f.locked).map(pool => {
          return pool.teams
        })
      })
    )
  }

  get bracketTeams () {
    return this.poolPlay ? [] : flatten(
      this.brackets.map(b => b.teams)
    )
  }

  get bracketMatches () {
    return this.poolPlay ? [] : flatten(
      this.brackets.map(b => b.matches)
    )
  }

  get poolMatches () {
    return !this.poolPlay ? [] : flatten(
      this.flights.map(flight => {
        return flight.pools.map(pool => {
          return pool.matches
        })
      })
    )
  }

  get allMatches () {
    return [...this.poolMatches, ...this.bracketMatches]
  }

  get activeTeamIds () {
    const tIds = new Set(flatten(this.allMatches.map(m => m.activeTeamIds)))
    return [...tIds]
  }

  get hasMatches () {
    return this.poolMatches.length > 0 || this.bracketMatches.length > 0
  }

  get poollessTeamIds () {
    if (this.bracketPlay) return []

    const pooled = new Set(this.poolTeams.map(m => m.teamId))
    return this.teamsSummary.teams.map(t => t.teamId).filter(f => !pooled.has(f))
  }

  get teams () {
    return this._teams.length > 0 ? flatten(this.teamSelectors.map(t => {
      return t.getTeams(this._teams)
    })) : []
  }

  get unSeededTeams () {
    return this._teams && this._teams.filter(team => !team.isDeleted && !team.seed && !team.waitlist)
  }

  get seededTeams () {
    return this._teams && this._teams.filter(team => !team.isDeleted && team.seed && !team.waitlist)
  }

  get isFullySeeded () {
    return this.unSeededTeams && this.unSeededTeams.length === 0
  }

  get hasRegSelector () {
    return this.teamSelectors.filter(f => f.isFromReg).length > 0
  }

  get description () {
    return this.poolPlay ? 'Pool Play' : this.brackets.length ? this.brackets[0].type.toLowerCase().startsWith('single') ? 'Single Elim Bracket Play' : 'Double Elim Bracket Play' : 'Bracket Play'
  }

  get allTeams () {
    return [...this.poolTeams, ...this.bracketTeams]
  }

  get teamsIds () {
    return this.teamsSummary && this.teamsSummary.teams.filter(f => f.in && f.roundFinish >= 0).map(m => m.teamId)
  }

  get complete () {
    return (this.flight && this.flight.allLocked) || (this.brackets[0] && this.brackets[0].complete)
  }

  get lockReady () {
    if (!this.poolPlay) return false
    const lockReady = this.pools.filter(f => f.lockReady).length
    return lockReady > 0
  }

  get waitingOn () {
    if (!this.poolPlay) return false
    const unlocked = this.pools.filter(f => !f.locked).length
    return unlocked !== this.pools.length && unlocked
  }

  get bracket () {
    return this.brackets[0]
  }

  get flight () {
    return this.flights[0]
  }

  hasTeam (teamId) {
    return !!this.allTeams.find(f => f.teamId === teamId)
  }

  get dtCheckin () {
    return `${this._date}T${this.checkInTime}`
  }

  get endTime () {
    if (this.poolMatches.length) {
      const m = this.poolMatches.filter(f => f.unix).sort(firstBy('unix'))
      const lastMatch = m && m.length && m[m.length - 1]
      if (lastMatch) {
        const pool = this.pools.find(f => f.id === lastMatch.poolId)
        return pool && pool.setting && pool.setting.minutesPerMatch && moment(lastMatch.startTime).add(pool.setting.minutesPerMatch, 'm').format('HH:mm')
      }
    }
    if (this.bracketMatches.length) {
      const m = this.bracketMatches.filter(f => f.unix).sort(firstBy('unix'))
      const lastMatch = m && m.length && m[m.length - 1]
      if (lastMatch) {
        const b = this.bracket
        return b && b.winnersMatchSettings && b.winnersMatchSettings.minutesPerMatch && moment(lastMatch.startTime).add(b.winnersMatchSettings.minutesPerMatch, 'm').format('HH:mm')
      }
    }
    return null
  }

  get leaguePool () {
    return this.pools.length === 1 && this.pools[0]
  }

  get leagueLock () {
    return this.pools.filter(f => !f.locked).length === 0
  }
}
