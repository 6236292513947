<template>
  <div v-if="noCard" class="white">
    <quill-editor
      :value="value"
      @input="onInput"
      ref="myQuillEditor"
      :options="editorOption"
      :disabled="disabled"
      @ready="onEditorReady($event)"
      id="editor"
    ></quill-editor>
  </div>

  <v-card v-else :flat="!elevate">
    <div :class="{'block': disabled}"></div>
    <v-container class="pa-0" fluid>
      <v-row dense>
        <v-col cols="12">
          <quill-editor
            :value="value"
            @input="onInput"
            ref="myQuillEditor"
            :options="editorOption"
            :disabled="disabled"
            @ready="onEditorReady($event)"
            id="editor"
          ></quill-editor>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import Quill from 'quill'
import { quillEditor } from 'vue-quill-editor'
var Block = Quill.import('blots/block')
Block.tagName = 'div'
Quill.register(Block)
var Parchment = Quill.import('parchment')
var alignOptions = { scope: Parchment.Scope.BLOCK, whitelist: ['right', 'center'] }
var AlignClass = new Parchment.Attributor.Class('align', 'text', alignOptions)
Quill.register(AlignClass, true)

export default {
  props: ['value', 'disabled', 'noHeader', 'elevate', 'noCard', 'generic', 'placeholder'],
  data () {
    return {
    }
  },
  computed: {
    editorOption () {
      const t = this.generic ? [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }],
        [{ align: [false, 'center', 'right'] }],
        ['link', 'clean']
      ] : [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        [{ align: [false, 'center', 'right'] }],
        ['link', 'clean']
      ]

      if (this.noHeader) {
        t.splice(4, 1)
      }

      const r = {
        debug: 'error',
        modules: {
          toolbar: t
        }
      }

      if (this.placeholder) {
        r.placeholder = this.placeholder
      }
      return r
    }
  },
  methods: {
    onInput (val) {
      this.$emit('input', val)
    },
    onEditorReady (quill) {
      quill.options.modules = { toolbar: false }
    }
  },
  components: {
    quillEditor
  }
}
</script>

<style scoped>
#editor >>> .ql-container {
  min-height: 300px
}
</style>
