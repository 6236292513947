export default class Game {
  id = 0
  number = 1
  to = 21
  cap = null
  _home = 0
  _away = 0
  isFinal = false
  _winner = null
  possesion = null
  dtModified = null

  constructor (sdk, dto) {
    this.sdk = sdk
    if (dto) {
      this.update(dto)
    }
  }

  get home () {
    return this._winner ? 0 : this._home
  }

  set home (val) {
    this._home = val !== null ? +val : null
  }

  get away () {
    return this._winner ? 0 : this._away
  }

  set away (val) {
    this._away = val !== null ? +val : null
  }

  // Methods
  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    if (this.dtModified) {
      if (!dto.dtModified) return
      const a = +this.dtModified
      const b = +dto.dtModified
      if (a > b) {
        console.log('old update')
        return
      }
    }
    Object.assign(this, dto)
    if (this.home + this.away === 0) {
      this.home = null
      this.away = null
    }
  }

  // getters
  set winner (val) {
    this._winner = val
  }

  get winner () {
    if (this._winner) return this._winner
    if (this.isFinal && this.home > this.away) return 'home'
    if (this.home >= this.to && this.away < this.home - 1) return 'home'
    if (this.cap && this.home >= this.cap && this.away < this.home) return 'home'

    if (this.isFinal && this.away > this.home) return 'away'
    if (this.away >= this.to && this.home < this.away - 1) return 'away'
    if (this.cap && this.away >= this.cap && this.home < this.away) return 'away'

    return null
  }

  get dto () {
    return {
      id: +this.id,
      number: +this.number,
      to: +this.to,
      cap: +this.cap,
      home: this.home ? +this.home : null,
      away: this.away ? +this.away : null,
      isFinal: this.isFinal,
      _winner: this._winner
    }
  }

  get status () {
    return this.winner ? 'Complete' : (this.home || this.away) ? 'Started' : null
  }

  get complete () {
    return !!this.winner
  }
}
