import { firstBy } from 'thenby'
import flatten from '../helpers/ArrayFlatten'
// import min from 'lodash.min'

export default class Dual {
  constructor (n, matches, displayN) {
    this.n = n
    this.displayN = Math.abs(displayN || n)
    this.matches = matches
    this.matches.sort((a, b) => a.dualMatchN - b.dualMatchN)
  }

  get number () {
    return this.n
  }

  get dualOf () {
    return this.matches ? this.matches.length > 4 ? 5 : 3 : 0
  }

  get pointMatches () {
    const n = this.matches.length > 4 ? 5 : 3
    return this.matches ? this.matches.filter(f => f.dualMatchN <= n) : []
  }

  get exhibitMatches () {
    const n = this.matches.length > 4 ? 5 : 3
    return this.matches ? this.matches.filter(f => f.dualMatchN > n) : []
  }

  get courts () {
    return this.matches && this.matches.map(m => m.court).filter(f => !!f)
  }

  get court () {
    return this.courts && this.courts.join(',')
  }

  get startTime () {
    if (!this.matches) return
    const m = [...this.matches]
    return m && m.sort(firstBy('unix'))[0].startTime
  }

  get unix () {
    return this.startTime && this.startTime.unix()
  }

  get slotGap () {
    if (!this.homeTeam || !this.awayTeam) return 0
    return Math.abs(this.homeTeam.slot - this.awayTeam.slot)
  }

  get homeScore () {
    // console.log('here')
    // const a = this.pointMatches
    // console.log(a)
    return this.pointMatches.filter(f => f.winner === 'home').length
  }

  get awayScore () {
    return this.pointMatches.filter(f => f.winner === 'away').length
  }

  get matchesNeededForWin () {
    // const adj = this.matches.length % 2 === 0 ? 1 : 0
    // return Math.ceil(this.matches.length / 2) // + adj
    return this.matches.length > 4 ? 3 : 2
  }

  get winner () {
    return this.homeScore >= this.matchesNeededForWin ? 'home' : this.awayScore >= this.matchesNeededForWin ? 'away' : null
  }

  get homeId () {
    return this.matches.length > 0 && this.matches[0].homeTeam && this.matches[0].homeTeam.id
  }

  get homeTeam () {
    return this.matches.length > 0 && this.matches[0].homeTeam
  }

  get awayId () {
    return this.matches.length > 0 && this.matches[0].awayTeam && this.matches[0].awayTeam.id
  }

  get awayTeam () {
    return this.matches.length > 0 && this.matches[0].awayTeam
  }

  get winningId () {
    return this.winner === 'home' ? this.homeId : this.winner === 'away' ? this.awayId : null
  }

  get losingId () {
    return this.winner === 'home' ? this.awayId : this.winner === 'away' ? this.homeId : null
  }

  get winningTeam () {
    return this.winner === 'home' ? this.homeTeam : this.winner === 'away' ? this.awayTeam : null
  }

  get losingTeam () {
    return this.winner === 'home' ? this.awayTeam : this.winner === 'away' ? this.homeTeam : null
  }

  get winningScore () {
    return this.winner === 'home' ? this.homeScore : this.winner === 'away' ? this.awayScore : null
  }

  get losingScore () {
    return this.winner === 'home' ? this.awayScore : this.winner === 'away' ? this.homeScore : null
  }

  get status () {
    return this.allComplete ? 'Complete' : this.matches.filter(g => g.status).length > 0 ? 'Started' : null
  }

  get completeMatches () {
    return this.matches.filter(f => f.complete)
  }

  get allComplete () {
    return this.matches.length === this.completeMatches.length
  }

  get complete () {
    return !!this.winner
  }

  get teamIds () {
    return [(this.homeTeam && this.homeTeam.teamId), (this.awayTeam && this.awayTeam.teamId)]
  }

  get matchIds () {
    return this.matches.map(m => m.id)
  }

  get myDays () {
    return [...new Set(this.matches.map(m => m.day))]
  }

  getStatus (teams) {
    if (this.completeMatches.length === 0 || !teams) {
      return false
    }
    const h = teams.find(f => f.id === this.homeTeam.teamId)
    const a = teams.find(f => f.id === this.awayTeam.teamId)
    if (this.complete) {
      const w = this.winner === 'home' ? h : a
      return `${w.name} won ${this.winningScore}-${this.losingScore}`
    }
    if (this.homeScore > this.awayScore) {
      return `${h.name} leads ${this.homeScore}-${this.awayScore}`
    }
    if (this.homeScore < this.awayScore) {
      return `${a.name} leads ${this.awayScore}-${this.homeScore}`
    }
    return `Tied ${this.awayScore}-${this.homeScore}`
  }

  get activeTeamIds () {
    const ids = []
    this.homeTeam && ids.push(this.homeTeam.teamId)
    this.awayTeam && ids.push(this.awayTeam.teamId)
    return ids
  }

  get poolId () {
    return this.matches.length > 0 && this.matches[0].poolId
  }

  get bracketId () {
    return this.matches.length > 0 && this.matches[0].bracketId
  }

  get timeLineItems () {
    const items = []
    if (this.isBye) return items
    const hIds = []
    this.homeTeam && hIds.push(this.homeTeam.teamId)
    const aIds = []
    this.awayTeam && aIds.push(this.awayTeam.teamId)
    if (hIds.length) {
      items.push(...hIds.map(hId => {
        return {
          teamId: hId,
          what: this.complete ? this.winner === 'home' ? 'Beat' : this.winner === 'away' ? 'Lost to' : 'Split with' : 'Play',
          matchN: this.displayN,
          roundN: this.type === 'bracket' ? this.round + 1 : null,
          status: this.status,
          when: this.startTime,
          unix: this.startTime ? this.startTime.unix() : null,
          who: aIds,
          matches: flatten(this.matches.map(m => m.timeLineItems)),
          complete: this.complete
        }
      }))
    }
    if (aIds.length) {
      items.push(...aIds.map(aId => {
        return {
          teamId: aId,
          what: this.complete ? this.winner === 'away' ? 'Beat' : this.winner === 'home' ? 'Lost to' : 'Split with' : 'Play',
          matchN: this.displayN,
          roundN: this.type === 'bracket' ? this.round + 1 : null,
          status: this.status,
          when: this.startTime,
          unix: this.startTime ? this.startTime.unix() : null,
          who: hIds,
          matches: flatten(this.matches.map(m => m.timeLineItems)),
          complete: this.complete
        }
      }))
    }
    const refIds = [...new Set(this.matches.map(m => m.manualRefId || (m.refTeam && m.refTeam.teamId ? m.refTeam.teamId : m.refTeamId || m.manualRefId)))]
    refIds.forEach(rId => {
      items.push({
        teamId: rId,
        what: this.complete ? 'Reffed' : 'Ref',
        matchN: this.displayN,
        roundN: this.type === 'bracket' ? this.round + 1 : null,
        status: this.status,
        when: this.startTime,
        unix: this.startTime ? this.startTime.unix() : null,
        who: [...hIds, ...aIds],
        matches: flatten(this.matches.map(m => m.timeLineItems)),
        complete: this.complete
      })
    })
    return items
  }

  get map () {
    return {
      homeMap: this.homeTeam ? this.homeTeam.map : this.matches[0].homeMap,
      awayMap: this.awayTeam ? this.awayTeam.map : this.matches[0].awayMap
    }
  }

  get homeMap () {
    return this.homeTeam ? this.homeTeam.map : this.matches[0].homeMap
  }

  get awayMap () {
    return this.awayTeam ? this.awayTeam.map : this.matches[0].awayMap
  }

  get ref () {
    let refs = this.matches && this.matches.map(m => {
      if (m.manualRefTeam) return m.manualRefTeam.name
      if (m.refTeam) return m.refTeam.name
      return null
    }).filter(f => !!f)

    refs = [...new Set(refs)]
    if (refs.length === 1) return refs[0]
    if (refs.length > 1) return 'Split refs'
    return ''
  }

  get refMap () {
    console.log('here')
    let refs = this.matches && this.matches.map(m => m.refMap === 'Loser match 0' ? 'Previous Loser' : m.refMap).filter(f => !!f)

    refs = [...new Set(refs)]
    if (refs.length === 1) return refs[0]
    if (refs.length > 1) return 'Split refs'
    return ''
  }
}
