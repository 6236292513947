// import Vue from 'vue'
import * as mutations from '../MutationTypes'
// import * as actions from '../ActionTypes'

export default {
  state: {
    order: null
  },
  mutations: {
    [mutations.SET_ORDER] (state, payload) {
      state.order = payload
    }
  },
  actions: {

  },
  getters: {
    order (state) {
      return state.order
    }
  }
}
