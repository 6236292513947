// import endpoints from './endpoints'

export default class AgreementsSDK {
  constructor (http) {
    this.http = http
  }

  organization = {
    getAll: (username) => {
      return this.http.get(`/organization/${username}/agreements`)
    },
    post: (form) => {
      return this.http.post('/agreement', form)
    },
    getPlayers: (agreementId) => {
      return this.http.get(`/agreement/${agreementId}/players`)
    }
  }

  player = {
    get: (id) => {
      return this.http.get(`/agreement/player/${id}`)
    },
    post: (id, dto) => {
      return this.http.post(`/agreement/player/${id}`, dto)
    }
  }

  tournament = {
    getStatus: (id) => {
      return this.http.get(`/agreement/tournament/${id}`)
    },
    send: (dto) => {
      return this.http.post('/agreement/send', dto)
    },
    sendSms: (number, aid, pid) => {
      return this.http.post(`/agreement/send-sms?number=${number}&aId=${aid}&pId=${pid}`)
    },
    player: (aid, ppid) => {
      return this.http.get(`/agreement/${aid}/player/${ppid}`)
    },
    postRequirements: (dto, tournamentId) => {
      return this.http.post(`/agreement/${tournamentId}/atr`, dto)
    }
  }
}
