import Vue from 'vue'
import Vuex from 'vuex'

import Shared from './Shared'
import Loading from './Loading'
import User from './User'
import Cart from './Cart'
import Order from './Order'
import Theme from './Theme'
import Tournament from './Tournament'
import Live from './Live'
import Organization from './Organization'
import LiveScoring from './LiveScoring'
import OIDC from './OIDC'

import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    Tournament,
    Shared,
    Loading,
    User,
    Cart,
    Order,
    Theme,
    Live,
    Organization,
    LiveScoring,
    OIDC
  },
  plugins: [createPersistedState({
    paths: ['User', 'Cart', 'LiveScoring']
  })]
})
