<template>
  <v-card>
    <v-toolbar color="color2 color2Text--text">
      <v-toolbar-title>
        {{cart.organization.name}}
      </v-toolbar-title>
    </v-toolbar>
    <loading-bar :value="checking"></loading-bar>

    <v-expand-transition>
      <!-- CONFIRMATION -->
      <v-container class="fill-height pa-0" v-if="cart.orderId" fluid>
        <v-row dense align="center">
          <v-col cols="12">
            <v-row dense class="text-center">
              <v-col cols="12">
                <div class="headline">Thank you!</div>
                <div class="title">Your purchase is complete!</div>
              </v-col>
              <v-col cols="12">
                Please jot down the following for your records
              </v-col>
            </v-row>
            <v-row v-for="(j) in cart.jot" :key="j.value" dense class="font-weight-bold">
              <v-col cols="6" class="text-right">
                {{j.text}}:
              </v-col>
              <v-col cols="6" class="d-flex align-end">
                {{j.value}}
              </v-col>
            </v-row>
            <v-row dense class="text-center">
              <v-col cols="12">
                <div>You should be receiving a receipt at {{cart.emailReceipt}} shortly.</div>
                <div v-if="cart.hasRegistrations">We have also sent tournament registration emails to the players</div>
              </v-col>
            </v-row>
            <v-row dense class="text-center">
              <v-col cols="12">
                Be sure to save this order id, in case you need to make any changes!
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- ADS -->
        <v-row v-if="bidStream" dense>
          <v-col cols="12" class="pt-4 d-flex justify-center">
            <v-card width="310">
              <div v-if="$vuetify.breakpoint.smAndDown" id="smad">
                <div data-ad-tagid="48ocrXJf9ddMdc6lK799" data-zid="1241" data-name="volleyballlife.com MOB 300x250"></div>
              </div>
              <div v-else id="not-smad">
                <div data-ad-tagid="n8XeCFZ0NcwsP3Kg03ER" data-zid="1238" data-name="volleyballlife.com 300x600"></div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <!-- Checking -->
      <v-container class="fill-height pa-0" v-else-if="checking && checks < 1 && false" fluid>
        <v-row dense>
          <v-col cols="12" class="text-center">
            <v-progress-circular indeterminate color="color3" size="64"></v-progress-circular>
            <div>Checking your items...</div>
          </v-col>
        </v-row>
      </v-container>
      <!-- CART -->
      <v-container fluid v-else>
        <!-- Items -->
        <v-row dense v-for="(item,i) in cartItems" :key="i">
          <v-col cols="8" sm="10" xl="11">
            <div class="title">{{item.name}}</div>
            <div class="subheading clickable" @click.stop="goto(item)">{{item.description}}</div>
            <div class="subheading error--text" v-if="item.error">
              {{item.error}}
            </div>
            <div class="subheading error--text" v-if="item.status === 'Full' ">
              This division is currently only taking waitlist teams.
            </div>
            <div class="subheading error--text" v-if="item.status === 'Closed' ">
              Registration for this division is no longer available. <b>Please click edit to check for available divisions or remove it from your cart.</b>
            </div>
            <div class="subheading error--text" v-if="item.preAuth ">
              *Authorization Only - not charged today
            </div>
            <div d-flex align-center>
              <v-btn
                v-if="['TR', 'ER'].includes(item.type) && !item.registration.teamId"
                color="color3"
                text
                class="xsmall ma-0 pa-0 mr-3"
                small
                @click.stop="editClick(item)"
              >
                <v-icon class="mr-1">fas fa-pencil-alt</v-icon>
                {{$vuetify.breakpoint.xsOnly ? '' : 'Edit'}}
              </v-btn>
              <div style="width: 120px; display: inline-block;" v-if="item.type === 'PROD' && item.availability > 1">
                <v-select
                  :items="quantityOptions(item)"
                  v-model="item.quantity"
                  label="Quantity"
                  class="mr-3 shrink"
                  color="color3"
                  item-color="color3"
                  @change="onQuantityChange($event, item)"
                  hide-details solo dense
                >
                  <template v-slot:selection="{ item }">
                    Qty: {{ item }}
                  </template>
                </v-select>
              </div>
              <v-btn
                color="color3"
                text
                class="xsmall ma-0 pa-0 mr-3"
                @click.stop="deleteClick(item)"
                v-if="!noDelete(item)"
              >
                <v-icon class="mr-1">fas fa-trash</v-icon>
                {{$vuetify.breakpoint.xsOnly ? '' : 'Delete'}}
              </v-btn>
              <v-btn
                v-if="item.productObj && item.productObj.details"
                color="color3"
                text
                class="xsmall ma-0 pa-0"
                @click.stop="showDetails(item)"
              >
                <v-icon class="mr-1">fas fa-info-circle</v-icon>
                {{$vuetify.breakpoint.xsOnly ? '' : 'Details'}}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="4" sm="2" xl="1" class="title d-flex justify-center text-right" style="flex-direction: column;">
            {{(item.status === 'Full' && !item.amount ? 'Wailist' : item.amount) | usDollars}}
          </v-col>
        </v-row>
        <!-- Total row && Promo -->
        <template v-if="cartItems.length">
          <v-divider></v-divider>
          <!-- Subtotal row -->
          <v-row dense class="mt-1 grey--text" v-if="amountObj.showSub">
            <v-col cols="8" sm="10" xl="11" class="title text-right py-0">
              Subtotal:
            </v-col>
            <v-col cols="4" sm="2" xl="1" class="title text-right py-0">
              {{amountObj.subtotal | usDollars}}
            </v-col>
          </v-row>
          <!-- APPLIED PROMO -->
          <v-expand-transition>
              <!-- Promo row -->
              <v-row dense class="mt-1" v-if="hasPromo">
                  <v-col cols="8" sm="10" xl="11" class="title text-right py-0">
                    {{cart.promos | formatArray}}:
                  </v-col>
                  <v-col cols="4" sm="2" xl="1" class="title text-right py-0">
                    {{cart.promoAmount | usDollars}}
                  </v-col>
              </v-row>
          </v-expand-transition>
          <!-- Fees row -->
          <v-row dense v-if="amountObj.feeDetails.length" class="mt-1 grey--text">
            <v-col cols="8" sm="10" xl="11" class="title text-right py-0">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-on="on"
                    v-bind="attrs"
                  >
                    fas fa-info-circle
                  </v-icon>
                </template>
                <span>
                  <div v-for="(d, i) in amountObj.feeDetails" :key="i">{{d.label}}: {{d.amount | usDollars}}</div>
                </span>
              </v-tooltip>
              {{amountObj.feeLabel}}:
            </v-col>
            <v-col cols="4" sm="2" xl="1" class="title text-right py-0">
              {{amountObj.feeTotal | usDollars}}
            </v-col>
          </v-row>
          <!-- Tax row -->
          <v-row dense v-if="amountObj.taxTotal" class="mt-1 grey--text">
            <v-col cols="8" sm="10" xl="11" class="title text-right py-0">
              {{amountObj.taxLabel}}:
            </v-col>
            <v-col cols="4" sm="2" xl="1" class="title text-right py-0">
              {{amountObj.taxTotal | usDollars}}
            </v-col>
          </v-row>
          <!-- Total row -->
          <v-row dense :class="{ 'mt-1': !amountObj.showSub, 'mt-1': amountObj.showSub}">
            <v-col cols="8" sm="10" xl="11" class="title text-right py-0">
              Total:
            </v-col>
            <v-col cols="4" sm="2" xl="1" class="title text-right py-0">
              {{amountObj.total | usDollars}}
            </v-col>
          </v-row>
          <!-- preAuth Rows -->
          <v-expand-transition>
            <div v-if="cart.preAuthAmount">
              <v-row dense class="mt-3">
                <v-col cols="8" sm="10" xl="11" class="title text-right py-0">
                  Due Today:
                </v-col>
                <v-col cols="4" sm="2" xl="1" class="title text-right py-0">
                  {{amountObj.today.total | usDollars}}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="8" sm="10" xl="11" class="title text-right py-0">
                  Due Later:
                </v-col>
                <v-col cols="4" sm="2" xl="1" class="title text-right py-0">
                  {{amountObj.later.total | usDollars}}
                </v-col>
              </v-row>
            </div>
          </v-expand-transition>
          <!-- Promo Button -->
          <v-row dense>
            <v-col cols="12">
              <v-btn
                v-if="!hasPromo"
                color="color3"
                text
                @click.stop="promo = !promo"
                class="ma-0"
              >got a promo code?</v-btn>
              <v-btn
                v-else
                color="color3"
                text
                @click.stop="clearPromo"
                class="ma-0"
              >remove promo</v-btn>
            </v-col>
          </v-row>
          <!-- Promo form -->
          <v-expand-transition>
            <div v-if="promo">
              <v-row dense>
                <v-col cols="9" sm="6" md="4" class="pl-4">
                  <v-text-field
                    name="promoCode"
                    label="Promo Code"
                    v-model="promoCode"
                    :loading="promoing"
                    color="color3"
                    :error-messages="promoResponse && promoResponse.error ? [promoResponse.error] : []"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" md="2">
                  <v-btn
                    color="color3 color3Text--text"
                    :disabled="!promoApplyable"
                    @click.stop="applyPromo"
                    :loading="promoing"
                  >Apply</v-btn>
                </v-col>
              </v-row>
            </div>
          </v-expand-transition>
          <!-- Organizattion Payment not set up -->
          <v-expand-transition>
            <div v-if="!cart.status" class="text-center">
              <v-row dense>
                <v-col  cols="12" md="6" offset-md="3">
                  <v-alert type="error" :value="true" text prominent>
                    It appears {{cart.organization.name}}'s payment processing is down
                  </v-alert>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" md="6" offset-md="3" class="headline">
                  If the problem persists please contact <a :href="`mailto:${cart.organization.support}`">{{cart.organization.support}}</a>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" md="6" offset-md="3" class="headline">
                  <v-btn
                    color="color3 color3Text--text"
                    :loading="checking"
                    :disabled="checking"
                    @click.stop="getCartStatus"
                  >Check Again</v-btn>
                </v-col>
              </v-row>
            </div>
          </v-expand-transition>
        </template>
        <!-- Empty row -->
        <v-row dense v-else>
          <v-col cols="12" class="title text-center">
            There are no items in your cart
          </v-col>
        </v-row>
        <!-- ERRORS -->
        <v-expand-transition>
          <div v-if="hasErrors">
            <v-row dense>
              <v-col cols="12">
                <v-alert type="error" :value="true" text prominent dismissible>
                  <b>{{hasErrors}} of your items have an error.</b> Please check the items.
                </v-alert>
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
      </v-container>
    </v-expand-transition>

    <!-- DELETE DIALOG -->
      <v-dialog max-width="300px" v-if="selectedItem" v-model="deleteDialog">
        <v-card>
          <v-card-title class="headline">Are you sure?</v-card-title>
          <v-card-text>Are you sure you want to remove this {{selectedItem.name}} from the cart</v-card-text>
          <v-card-actions class="justify-center">
            <v-btn text @click.stop="deleteSelected" class="mr-2">remove</v-btn>
            <v-btn text @click.stop="deleteDialog = false">cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!-- REG EDIT DIALOG -->
      <registration-edit-dialog
        :cartItem="selectedItem ? selectedItem : null"
        :dialog="editDialog"
        ref="editor"
        @save-click="onSaveClick"
        @cancel-click="onCancelClick"
      ></registration-edit-dialog>
    <!-- WAITLIST CHOOSE DIALOG -->
      <v-dialog
        v-model="chooseDialog"
        scrollable
        persistent
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <v-toolbar color="color1 color1Text--text">
            <v-toolbar-title>Oops</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn text icon color="color1Text" @click.stop="chooseDialog = false"><v-icon>fas fa-times</v-icon></v-btn>
          </v-toolbar>
          <v-container>
            <v-row dense>
              <v-col cols="12" class="subheading">
                It looks like we don't have enough room for all of your registrations.
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    <!-- DETAILS DIALOG -->
      <v-dialog
        v-model="detailsDialog"
        scrollable
        max-width="500px"
        transition="dialog-transition"
        v-if="selectedItem && selectedItem.productObj && selectedItem.productObj.details"
      >
        <v-card>
          <v-toolbar color="color2 color2Text--text">
            <v-toolbar-title>{{selectedItem.name}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="error white--text" @click.stop="detailsDialog = false" fab small>
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="pa-3" v-if="selectedItem.type === 'MEM'">
            <level-card
              :level="selectedItem.productObj.details"
              :actionText="'Join Now'"
              :noAction="true"
              :selected="true"
            ></level-card>
          </v-card-text>
          <v-card-text v-else v-html="selectedItem.productObj.details">
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="showWork"
        :timeout="-1"
      >
        <v-progress-circular indeterminate color="white" :class="{ 'my-9': checks === 0 }"></v-progress-circular>
        {{working[0]}}
      </v-snackbar>
  </v-card>

</template>

<script>
import * as mutations from '@/store/MutationTypes'
import * as actions from '@/store/ActionTypes'
import { mapGetters } from 'vuex'
import RegistrationEditDialog from '@/components/Tournament/Registration/RegistrationEditDialog.vue'
import LevelCard from '@/components/Membership/LevelCard'
import flatten from '@/helpers/ArrayFlatten'

export default {
  props: ['cart', 'loading', 'ads', 'processing'],
  data () {
    return {
      promo: false,
      promoCode: null,
      promoing: false,
      promoResponse: null,
      chooseDialog: false,
      deleteDialog: false,
      editDialog: false,
      selectedItem: null,
      timeout: null,
      checking: false,
      itemStatus: null,
      appliedStatuses: [],
      statusChoices: [],
      cartStatus: true,
      detailsDialog: false,
      checks: 0,
      working: []
    }
  },
  computed: {
    ...mapGetters(['bidStream']),
    showWork () {
      return this.working.length > 0
    },
    amountObj () {
      return this.cart.amounts
    },
    taxLabel () {
      return 'Fix Me'
    },
    cartItems () {
      return this.cart ? this.cart.items : []
    },
    promoApplyable () {
      return this.promoCode && !this.promoResponse
    },
    total () {
      return this.cart && this.amountObj.total
    },
    choices () {
      return this.statusChoices.map(m => {
        const result = Object.assign({}, m)
        result.items = this.cartItems.filter(f => f.statusApplies(m))
        return result
      })
    },
    hasErrors () {
      return this.cartItems.filter(f => f.error).length
    },
    hasPromo () {
      return this.cartItems.filter(f => f.promo).length
    },
    hasPreAuthItems () {
      return this.cartItems.filter(f => f.preAuth).length
    },
    noPayment () {
      return this.total <= 0
    },
    promos () {
      return this.cart.promos
    },
    work () {
      const a = [...this.working]
      return a
    },
    playerLinkIds () {
      return flatten(this.cartItems.filter(f => f.registration && f.registration.players.length > 0).map(m => m.registration.players.map(p => p.vblId))).filter(f => !!f)
    }
  },
  methods: {
    goto (item) {
      if (item.registration && item.registration.tournamentId) {
        this.$router.push({ name: 'tournament-brochure', params: { tournamentId: item.registration.tournamentId } })
      }
    },
    deleteClick (item) {
      this.selectedItem = item
      this.deleteDialog = true
    },
    deleteSelected () {
      if (this.selectedItem) {
        this.$store.commit(mutations.REMOVE_CART_ITEM, this.selectedItem)
      }
      this.deleteDialog = false
    },
    editClick (item) {
      this.selectedItem = item
      this.editDialog = true
    },
    showDetails (item) {
      this.selectedItem = item
      this.detailsDialog = true
    },
    applyPromo () {
      var dto = {
        organizationId: this.cart.organization.id,
        code: this.promoCode || this.cart.promo,
        items: this.cart.items
      }
      if (!dto.code) return
      this.working.push('Verifying promos')
      this.promoing = true
      this.$store.dispatch(actions.CHECK_PROMO, dto)
        .then(r => {
          if (r) {
            this.promoResponse = r
          } else {
            this.promoCode = null
            this.promo = false
          }
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.working = this.working.filter(f => f !== 'Verifying promos')
          this.promoing = false
        })
    },
    clearPromo () {
      this.$store.commit(mutations.CLEAR_PROMO, this.cart.organization.id)
    },
    onSaveClick (dto) {
      dto.id = this.selectedItem.id
      this.$store.commit(mutations.UPDATE_CART_ITEM, dto)
      this.onCancelClick()
      this.checkItemsStatus()
    },
    onCancelClick () {
      this.editDialog = false
      this.selectedItem = null
    },
    getCartStatus () {
      this.checking = true
      this.working.push('Verifying payment processor')
      this.$store.dispatch(actions.CHECK_CART_STATUS, this.cart.organization.id)
        .finally(() => {
          this.working = this.working.filter(f => f !== 'Verifying payment processor')
          this.checking = false
        })
    },
    checkItemsStatus () {
      console.log('checking')
      clearTimeout(this.timeout)
      if (this.cartItems.length) {
        if (this.processing) {
          this.timeout = setTimeout(this.checkItemsStatus, 30000)
          return
        }
        this.checking = true
        this.working.push('Verifying registration availability')
        this.$VBL.cart.checkItemsStatus(this.cartItems, this.cart.organization.id, !this.cart.promo)
          .then(r => {
            const s = r.data.filter(f => !f.isPromoResponse)
            this.processStatuses(s)
            const p = r.data.find(f => f.isPromoResponse)
            if (p) {
              this.promoCode = p.code
            }
          })
          .catch(e => console.log(e.reposne || e))
          .finally(() => {
            this.working = this.working.filter(f => f !== 'Verifying registration availability')
            this.checking = false
            this.checks++
            if (this.checks < 6) {
              this.timeout = setTimeout(this.checkItemsStatus, 30000)
            }
            this.applyPromo()
          })
      }
    },
    processStatuses (statuses) {
      statuses.forEach(s => {
        // const x = this.appliedStatuses.find(f => f.id === s.id && f.idField === s.idField)
        // if (!x || JSON.stringify(x) !== JSON.stringify(s)) {
        //   this.applyStatus(s)
        // }
        this.applyStatus(s)
      })
    },
    applyStatus (status) {
      const s = Object.assign({}, status)
      const choice = false
      this.cartItems.forEach(item => {
        item.statusUpdate(s)
        // const applied = item.statusUpdate(s)
        // if (applied && s.status === 'Open') {
        //   --s.availability
        //   if (!s.availability) {
        //     s.status = 'Full'
        //     choice = true
        //   }
        // }
        this.$store.commit(mutations.UPDATE_CART_ITEM, item)
      })
      this.saveStatus(status, choice)
    },
    saveStatus (status, choice) {
      let i = this.appliedStatuses.findIndex(f => f.id === status.id && f.idField === status.idField)
      i === -1 ? this.appliedStatuses.push(status) : this.appliedStatuses.splice(i, 1, status)
      if (choice) {
        i = this.statusChoices.findIndex(f => f.id === status.id && f.idField === status.idField)
        i === -1 ? this.statusChoices.splice(i, 1, status) : this.statusChoices.push(status)
        this.chooseDialog = true
      }
    },
    noDelete (item) {
      return item && item.type === 'MEM' && this.playerLinkIds.includes(item.linkId)
    },
    quantityOptions (item) {
      return [...Array(item.availability).keys()].map(x => ++x)
    },
    onQuantityChange (n, item) {
      console.log(n)
      console.log(item)
      this.$store.commit(mutations.UPDATE_CART_ITEM, item)
    }
  },
  watch: {
    promoCode: function (val) {
      this.promoResponse = null
    },
    'cart.items.length': 'checkItemsStatus',
    checks: function (v) {
      this.$emit('check-update', v)
    }
  },
  mounted () {
    if (this.cart && this.cart.organization) {
      this.getCartStatus()
      this.checkItemsStatus()
    }
  },
  components: {
    RegistrationEditDialog,
    LevelCard
  },
  destroyed () {
    clearTimeout(this.timeout)
  }
}
</script>

<style>

</style>
