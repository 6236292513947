export default class ReportsSDK {
  constructor (http) {
    this.http = http
  }

  get (username, id) {
    return this.http.get(`/reports/${username}/${id}`)
  }

  getList (username) {
    return this.http.get(`/reports/list/${username}`)
  }
}
