<template>
  <router-link
    v-if="!emitter"
    class="link color3--text"
    :to="{name: 'player-profile', params: {playerProfileId: playerId, sbid: sbid}}"
  >{{player.name}}</router-link>
  <span
    v-else
    class="link color3--text"
    @click.stop="$emit('link-click')"
  >
    {{player.name}}
    <i class="fas fa-heart ml-2" v-if="booked"></i>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['player', 'sbid', 'emitter'],
  data () {
    return {
      open: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    booked () {
      return this.user && this.user.bookmarks && this.user.bookmarks.findIndex(f => f.id === this.playerId) > -1
    },
    playerId () {
      return this.player.playerProfileId || this.player.id
    }
  },
  components: {
  }
}
</script>

<style scoped >
.link {
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
}
</style>
