import Player from './PlayerProfile'
import DtoUpdate from './DtoUpdate'
import Bid from './Bid'

export default class TournamentRegistration {
  constructor (sdk, dto) {
    this.sdk = sdk
    this.tournamentId = 0
    this.tournamentDivisionId = 0
    this.organization = null
    this.division = null
    this.players = []
    this.teamName = null
    this.teamId = null
    this.confirmed = false
    this.finish = null
    this.token = null
    this.bid = null
    this.extras = false
    this.country = null
    this.isfFederation = null
    this.partner = null
    this.teams = []
    this.hod = {
      name: null,
      phone: null,
      email: null,
      fee: false
    }
    this.coach = {
      name: null,
      phone: null,
      email: null,
      fee: false
    }
    this.club = {
      name: null,
      yesOrNo: true,
      contacts: [],
      valid: false
    }
    this.teamInfo = {
      id: null,
      name: null,
      yesOrNo: true,
      contacts: [],
      valid: false,
      teams: []
    }
    if (dto) {
      this.update(dto)
    }
    this.window = null
    this.fieldPref = []
    this.addFields = []
    this.tags = null
    this.minAmount = false
  }

  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = ['players', 'tournamentId', 'tournamentDivisionId', 'organization', 'division', 'extraFields', 'windowId']
    DtoUpdate(dto, this, exclude)
    if (this.division && dto.windowId) {
      this.setWindow(dto.windowId)
    }

    if (dto.players) {
      this.players = []
      dto.players.forEach(p => {
        this.players.push(new Player(this.sdk, p))
      })
    }
    if (dto.extraFields) {
      this.extraFields = dto.extraFields
    }
  }

  setBid (bid, tournament) {
    if (bid) {
      this.bid = new Bid(bid)
      const divs = this.bid.getAllowedDivisions(tournament)
      if (divs.length === 1) {
        this.setDivision(divs[0])
        return
      } else if (this.division) {
        if (divs.filter(f => f.id === this.division.id).length) {
          this.initPlayers()
          return
        }
      }
    }
    this.division = null
  }

  setDivision (division) {
    this.division = division
    this.initPlayers()
    if (division) {
      const window = division.currentRegistrationWindow || division.lastRegistrationWindow
      if (window) {
        this.window = window
      }
    }
  }

  setWindow (id) {
    if (!this.division) return
    this.window = this.division.registrationWindows.find(f => f.id === id)
  }

  initPlayers () {
    if (!this.division) return
    for (; this.players.length < this.division.numOfPlayers;) {
      this.players.push(new Player(this.sdk))
    }
    for (; this.players.length > this.division.numAllowedOnRoster;) {
      this.players.pop()
    }
    if (this.bid && this.bid.players && this.bid.players.length) {
      this.bidKeep(this.bid.players)
    }
  }

  addPlayer () {
    this.players.push(new Player(this.sdk))
  }

  dropPlayer (i) {
    this.players.splice(i, 1)
  }

  bidKeep (keep) {
    this.players.forEach((p, i) => {
      let k = keep[i]
      if (typeof k === 'number') {
        k = this.bid.players.find(f => f.id === k)
      }
      if (k) {
        p.setProfile(k)
      } else {
        p.init()
      }
    })
  }

  get playersOnBid () {
    return this.bid ? this.bid.players.map(m => m.id) : []
  }

  get bidPlayers () {
    return this.bid ? this.players.map(p => p.playerProfileId).filter(f => this.playersOnBid.includes(f)) : []
  }

  get bidPlayerObjs () {
    return this.bid ? this.players.filter(f => this.playersOnBid.includes(f.playerProfileId)) : []
  }

  get notReplaceable () {
    if (this.bid && this.bid.subPolicy === 0) return []
    return this.bidPlayers.length <= this.bid.subPolicy ? this.bidPlayers : []
  }

  get isLead () {
    return this.players.filter(player => player.email || player.lastName || player.phone || player.aauNumber || player.avpNumber).length
  }

  get starters () {
    return this.division ? this.players.slice(0, this.division.numOfPlayers) : this.players
  }

  get alternates () {
    return this.division ? this.players.slice(this.division.numOfPlayers) : []
  }

  get rosterFull () {
    return this.division && this.players.length === this.division.numAllowedOnRoster
  }

  get hasAlternates () {
    return this.division && this.players.length > this.division.numOfPlayers
  }

  get valid () {
    return !!this.division && this.players.every(player => player.valid) && this.confirmed
  }

  get addOnQty () {
    return this.division && this.division.addOn ? this.division.addOn.qty : 0
  }

  get _teamName () {
    return this.teamName ? this.teamName : this.completePlayers.map((p) => {
      return p.fullname
    }).join('/')
  }

  get completePlayers () {
    return this.players.filter(f => !!f.firstName)
  }

  get extraFields () {
    const obj = {}

    if (this.country) obj.Country = this.country
    if (this.isfFederation) obj['ISF Federation'] = this.isfFederation
    if (this.hod.name) obj['Head of Delegation'] = this.hod.name
    if (this.hod.email) obj['Head of Delegation Email'] = this.hod.email
    if (this.hod.phone) obj['Head of Delegation Phone'] = this.hod.phone
    if (this.hod.fee) obj['Head of Delegation Room and Board'] = this.hod.fee
    if (this.coach.name) obj.Coach = this.coach.name
    if (this.coach.email) obj['Coach Email'] = this.coach.email
    if (this.coach.phone) obj['Coach Phone'] = this.coach.phone
    if (this.coach.fee) obj['Coach Room and Board'] = this.coach.fee
    if (this.partner) obj.Partner = this.partner
    if (this.teams && this.teams.length) obj.Teams = this.teams
    this.players.forEach((p, i) => {
      if (p.passport) {
        obj[`Player ${i + 1} Passport Number`] = p.passport || 'NA'
      }
    })
    if (this.isClubVClub) {
      obj.Club = this.club.name
      if (this.club.contacts.length) {
        obj['Club Director Name'] = this.club.contacts[0].name
        obj['Club Director Email'] = this.club.contacts[0].email
        obj['Club Director Phone'] = this.club.contacts[0].phone
      }
    }
    if (this.teamReg) {
      obj.Team = this.teamInfo.name
      if (this.teamInfo.contacts.length) {
        obj['Team Admin Name'] = this.teamInfo.contacts[0].name
        if (this.teamInfo.contacts[0].id) {
          obj['Team Admin Id'] = this.teamInfo.contacts[0].id
        } else {
          obj['Team Admin Email'] = this.teamInfo.contacts[0].email
          obj['Team Admin Phone'] = this.teamInfo.contacts[0].phone
        }
      }
    }
    if (this.fieldPref.length > 0) obj['Field Preference'] = this.fieldPref.join(', ')
    if (this.addFields.length > 0) {
      this.addFields.forEach(f => {
        obj[`~${f.label}`] = f.value
      })
    }
    return JSON.stringify(obj) === JSON.stringify({}) ? null : obj
  }

  set extraFields (val) {
    if (!val) return
    this.country = val.Country
    this.isfFederation = val['ISF Federation']
    this.hod.name = val['Head of Delegation']
    this.hod.email = val['Head of Delegation Email']
    this.hod.phone = val['Head of Delegation Phone']
    this.hod.fee = val['Head of Delegation Room and Board']
    this.coach.name = val.Coach
    this.coach.email = val['Coach Email']
    this.coach.phone = val['Coach Phone']
    this.coach.fee = val['Coach Room and Board']
    if (this.players.length) this.players[0].passport = val['Player 1 Passport Number']
    if (this.players[1]) this.players[1].passport = val['Player 2 Passport Number']
    this.partner = val.Partner
    this.teams = val.Teams
    this.club.name = val.Club
    if (val['Club Director Name']) {
      this.club.contacts = [{
        title: 'Club Director',
        name: val['Club Director Name'],
        email: val['Club Director Email'],
        phone: val['Club Director Phone']
      }]
    }
    this.teamInfo.name = val.Team
    if (val['Team Admin Name']) {
      this.teamInfo.contacts = [{
        title: 'Team Admin',
        name: val['Team Admin Name'],
        email: val['Team Admin Email'],
        phone: val['Team Admin Phone']
      }]
    }
    if (val['Field Preference']) {
      this.fieldPref = val['Field Preference'].split(', ')
    }
    for (const key in val) {
      if (key.startsWith('~')) {
        this.setAddField(key.substring(1), val[key])
      }
    }
  }

  setAddField (key, val) {
    // if (!key.startsWith('~')) key = '~' + key
    const f = this.addFields.find(f => f.label === key)
    if (f) {
      f.value = val
    } else {
      this.addFields.push({ label: key, value: val })
    }
  }

  getAddField (key) {
    const f = this.addFields.find(f => f.label === key)
    return f ? f.value || null : null
  }

  get dto () {
    return {
      id: 0,
      bid: this.bid,
      tournamentId: this.tournamentId,
      tournamentDivisionId: this.division ? this.division.id : null,
      windowId: this.window ? this.window.id : null,
      players: this.players.map(p => p.tournamentRegistrationDto),
      teamName: this.teamName,
      teamId: this.teamId,
      confirmed: this.confirmed,
      finish: this.finish,
      extraFields: this.extraFields,
      minAmount: this.minAmount
    }
  }

  get uploadDto () {
    return {
      id: 0,
      bid: this.bid,
      tournamentId: this.tournamentId,
      tournamentDivisionId: this.division.id,
      windowId: this.window ? this.window.id : null,
      players: this.players.map(p => p.uploadDto), // .filter(f => f.firstName && f.lastName),
      teamName: this.teamName,
      teamId: this.teamId,
      finish: this.finish,
      dBracket: this.dBracket,
      extraFields: this.extraFields,
      tags: this.tags
    }
  }

  get isISF () {
    return this.division && this.division.registrationFields && this.division.registrationFields.requiredFields.includes('isf')
  }

  get baseFee () {
    return this.division && this.division.currentEntryFee
  }

  get entryFee () {
    const fee = this.division && this.division.currentEntryFee
    const subtotal = fee ? fee.perTeam ? fee.amount : fee.amount * this.players.length : 0
    return subtotal
  }

  get amount () {
    let subtotal = this.entryFee
    if (this.coach.fee) subtotal += 580
    if (this.hod.fee) subtotal += 580
    return subtotal
  }

  get player1 () {
    return this.players.length ? this.players[0] : null
  }

  get isCoaches () {
    return this.division && this.division.isCoaches
  }

  get isClubVClub () {
    return this.division && this.division.isClubVClub
  }

  get teamReg () {
    return this.division && this.division.teamReg
  }

  get ibvl () {
    return this.division && this.division.ibvl
  }

  get isNcaa () {
    return this.division && this.division.isNcaa
  }

  get isPairs () {
    return this.division && this.division.numOfPlayers === 2
  }

  get isSquad () {
    return this.division && this.division.isDuals
  }
}
