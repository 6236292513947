<template>
  <v-card flat>
    <loading-bar :value="loading"></loading-bar>
    <v-card-text fluid>
      <v-row dense v-if="isSingleRow">
        <v-slide-group
          show-arrows
          center-active
          v-model="activeImg"
        >
          <v-slide-item
            v-for="(image, imgN) in displayImages"
            :key="`${image.id}-${imgN}`"
            v-slot:default="{ active, toggle }"
            show-arrows
            center-active
          >
            <v-card
              v-if="image.uploader"
              class="grey lighten-2 mb-8"
              :class="{holder: !isSingleRow, 'mr-8': !!unsavedImage}"
              :height="height || '100%'"
              :width="width">
              <v-row class="fill-height ma-0 pa-0"
                align="center"
                justify="center"
                v-if="!unsavedImage"
              >
                <image-input v-model="unsavedImage" ref="uploader">
                  <div class="fill-height justify-center d-flex align-center">
                    <v-btn fab color="grey">
                      <v-icon color="grey lighten-3">fas fa-plus</v-icon>
                    </v-btn>
                  </div>
                </image-input>
              </v-row>
              <v-img
                  v-else
                  :src="unsavedImage.imageURL"
                  :aspect-ratio="ratio"
                  :width="width"
                  :height="height"
                  class="grey lighten-2 elevation-1"
                  :contain="!cover"
                  :cover="cover"
                  style="z-index: 3"
              >
              </v-img>
              <v-fab-transition>
                <v-btn
                  v-if="unsavedImage && !hasNoUpload"
                  color="color3 color3Text--text"
                  fab
                  small
                  right
                  bottom
                  absolute
                  @click.stop="uploadImage"
                  :loading="uploading"
                >
                  <v-icon>fas fa-cloud-upload-alt</v-icon>
                </v-btn>
              </v-fab-transition>
              <v-fab-transition>
                <v-btn
                  v-if="unsavedImage && !uploading"
                  color="white error--text"
                  fab
                  small
                  right
                  bottom
                  absolute
                  @click.stop="unsavedImage = null"
                  :style="hasNoUpload ? '' : 'margin-right: 44px'"
                >
                  <v-icon>fas fa-times</v-icon>
                </v-btn>
              </v-fab-transition>
            </v-card>
            <v-img
              v-else
              @click="toggle"
              :elevation="active ? 5 : 1"
              :src="image.url"
              :aspect-ratio="ratio"
              :width="width"
              :height="height"
              :class="`${active ? 'white' : 'grey'} lighten-2 elevation-${active ? 5 : 1} ml-2 ma-1`"
              :contain="!cover"
              :cover="cover"
              style="z-index: 3"
            ></v-img>
          </v-slide-item>
        </v-slide-group>
      </v-row>
      <v-data-iterator
        v-else
        :items="displayImages"
        :items-per-page.sync="itemsPerPage"
        :footer-props="{itemsPerPageOptions: ippOptions}"
      >
        <template v-slot:default="props">
          <v-row dense>
            <v-col
              v-for="(image, imgN) in props.items"
              :key="image.id"
              :cols="isVertical && !isSingleRow ? 12 : !isVertical && !isSingleRow ? 6 : null"
              :sm="!isVertical && !isSingleRow ? 4 : null"
              :md="!isVertical && !isSingleRow ? 3 : null"
              :xl="!isVertical && !isSingleRow ? 2 : null"
              @click.stop="!image.uploader && previewImage(image, imgN)"
            >
              <v-card
                v-if="image.uploader"
                class="grey lighten-2"
                :class="{holder: !isSingleRow}"
                :height="height || '100%'"
                :width="width"
              >
                <v-row class="fill-height ma-0"
                  align="center"
                  justify="center"
                  v-if="!unsavedImage"
                >
                  <image-input v-model="unsavedImage" ref="uploader">
                    <div class="fill-height justify-center d-flex align-center">
                      <v-btn fab color="grey">
                        <v-icon color="grey lighten-3">fas fa-plus</v-icon>
                      </v-btn>
                    </div>
                  </image-input>
                </v-row>
                <v-img
                    v-else
                    :src="unsavedImage.imageURL"
                    :aspect-ratio="ratio"
                    :width="width"
                    :height="height"
                    class="grey lighten-2 elevation-1"
                    :contain="!cover"
                    :cover="cover"
                    style="z-index: 3"
                >
                </v-img>
                <v-fab-transition>
                  <v-btn
                    v-if="unsavedImage && !hasNoUpload"
                    color="color3 color3Text--text"
                    fab
                    small
                    right
                    bottom
                    absolute
                    @click.stop="uploadImage"
                    :loading="uploading"
                  >
                    <v-icon>fas fa-cloud-upload-alt</v-icon>
                  </v-btn>
                </v-fab-transition>
                <v-fab-transition>
                  <v-btn
                    v-if="unsavedImage && !uploading"
                    color="white error--text"
                    fab
                    small
                    right
                    bottom
                    absolute
                    @click.stop="unsavedImage = null"
                    :style="hasNoUpload ? '' : 'margin-right: 44px'"
                  >
                    <v-icon>fas fa-times</v-icon>
                  </v-btn>
                </v-fab-transition>
              </v-card>

              <v-img
                v-else
                :src="image.url"
                :aspect-ratio="ratio"
                :width="width"
                :height="height"
                class="grey lighten-2 elevation-1"
                :contain="!cover"
                :cover="cover"
                style="z-index: 3"
              >
                <v-icon
                  color="rgba(255,255,255,0.54)"
                  class="ml-2 mt-2"
                  large
                  v-if="profile && image.url === profilePic"
                >fas fa-user-circle</v-icon>
                <v-row class="fill-height ma-0"
                  slot="placeholder"

                  align="center"
                  justify="center"

                >
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </v-img>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-card-text>

    <!-- PREVIEW DIALOG -->
    <v-dialog
      v-model="dialog"
      :persistent="!showButtons"
      content-class="imageDialog"
      :width="diaWidth"
    >
      <v-card style="min-width: 205px">
        <v-card-text v-if="dialog" class="pa-1 py-0">
          <v-row dense justify="center">
            <v-col>
              <v-img
                :src="selected.url"
                contain
                class="dialogImage"
                @dblclick="copyUrl"
              >
              <div class="text-right mb-n8" width="100%">
                <v-btn
                  color="grey"
                  text x-small
                  class="px-0"
                  @click.stop="dialog = false"
                >
                  <v-icon x-small>fas fa-times</v-icon>
                </v-btn>
              </div>
                <v-expand-transition>
                  <v-container
                    v-if="confirm"
                    class="d-flex transition-fast-in-fast-out title v-card--reveal"
                    style="height: 100%;"
                  >
                    <v-card style="max-width: 300px">
                      <v-card-title>Delete this image?</v-card-title>
                      <v-card-actions class="d-flex justify-center">
                        <v-btn color="success" :loading="deleteing" @click.stop="deleteImage">Yes</v-btn>
                        <v-btn color="error" @click.stop="confirm=false" :disabled="deleteing">No</v-btn>
                      </v-card-actions>

                    </v-card>
                  </v-container>
                </v-expand-transition>
                <v-expand-transition>
                  <v-container
                    v-if="profileAsk"
                    class="d-flex transition-fast-in-fast-out title v-card--reveal"
                    style="height: 100%;"
                  >
                    <v-card style="max-width: 300px">
                      <v-card-title>Make this your profile pic?</v-card-title>
                      <v-card-actions class="d-flex justify-center">
                        <v-btn color="success" :loading="uploading" @click.stop="makeProfile">Yes</v-btn>
                        <v-btn color="error" @click.stop="profileAsk=false" :disabled="uploading">No</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-container>
                </v-expand-transition>
              </v-img>
            </v-col>
          </v-row>
          <v-expand-transition>
            <div v-if="showUrl" class="text-center">
              {{selected.url}}
            </div>
          </v-expand-transition>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions py-0 style="min-height: 56px" class="justify-center">
            <v-fab-transition>
              <v-btn
                v-if="showButtons && profile"
                fab small left absolute
                color="color3 color3Text--text"
                @click.stop="profileAsk = true"
                :disabled="selected && selected.url === profilePic"
              >
                <v-icon>fas fa-user-circle</v-icon>
              </v-btn>
            </v-fab-transition>
            <template>
              <v-fab-transition>
                <v-btn v-if="showButtons && images.length > 1" fab small color="color3Text color3--text" @click.stop="imageStep(true)" class="mr-1">
                  <v-icon>fas fa-caret-left</v-icon>
                </v-btn>
              </v-fab-transition>
              <v-fab-transition>
                <v-btn v-if="showButtons" color="color3" x-small text icon @click="showUrl = !showUrl">
                  <v-icon>fas fa-link</v-icon>
                </v-btn>
              </v-fab-transition>
              <v-fab-transition>
                <v-btn v-if="showButtons && images.length > 1" fab small color="color3Text color3--text" @click.stop="imageStep()">
                  <v-icon>fas fa-caret-right</v-icon>
                </v-btn>
              </v-fab-transition>
            </template>
            <v-fab-transition>
              <v-btn v-if="showButtons && !viewOnly" fab small right absolute color="white error--text" @click.stop="confirm = true">
                <v-icon>fas fa-trash</v-icon>
              </v-btn>
            </v-fab-transition>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import imageInput from '@/components/Utils/ImageUploaderNew.vue'

export default {
  props: [
    'username',
    'oId',
    'vertical',
    'noPreview',
    'singleRow',
    'imgHeight',
    'imgWidth',
    'disabled',
    'cover',
    'noUpload',
    'center',
    'logo',
    'uploadOnly',
    'exclude',
    'images',
    'onUploadImage',
    'onDeleteImage',
    'loading',
    'deleteing',
    'uploading',
    'profilePic',
    'profile',
    'viewOnly'
  ],
  data () {
    return {
      unsavedImage: null,
      dialog: false,
      selected: null,
      confirm: false,
      profileAsk: false,
      showUrl: false,
      vw: 0,
      vh: 0,
      itemsPerPage: 8,
      ippOptions: [8, 16, 32, -1],
      activeImg: 0
    }
  },
  computed: {
    ...mapGetters(['user']),
    showButtons () {
      return (!this.confirm && !this.profileAsk) || (this.user && this.user.vbl)
    },
    isVertical () {
      return this.vertical !== undefined && this.vertical !== false
    },
    isLogo () {
      return this.logo !== undefined && this.logo !== false
    },
    hasNoPreview () {
      return this.noPreview !== undefined && this.noPreview !== false
    },
    hasNoUpload () {
      return this.noUpload !== undefined && this.noUpload !== false
    },
    isSingleRow () {
      return this.singleRow !== undefined && this.singleRow !== false
    },
    isCentered () {
      return this.center !== undefined && this.center !== false
    },
    isUploadOnly () {
      return this.uploadOnly !== undefined && this.uploadOnly !== false
    },
    ratio () {
      return this.width && this.height ? null : 1
    },
    width () {
      return this.imgWidth ? this.imgWidth : this.isSingleRow ? 192 : null
    },
    height () {
      return this.imgHeight ? this.imgHeight : this.isSingleRow ? 192 : null
    },
    displayImages () {
      const base = [...(this.images || [])]
      !this.hasNoUpload && base.unshift({ uploader: true })
      if (!this.exclude) return base
      const x = typeof this.exclude === 'string' ? [this.exclude] : this.exclude
      return base.filter(f => !x.includes(f.url))
    },
    dimensions () {
      if (!this.selected) return 0
      const calcH = Math.min(this.selected.h, this.cvh)
      const ratio = calcH / this.selected.h
      return { h: calcH, r: ratio, w: this.selected.w * ratio }
    },
    diaWidth () {
      return Math.max((this.dimensions.w + 8), 255)
    },
    cvh () {
      return (this.vh * 0.9) - 87
    },
    cvw () {
      return (this.vw * 0.9) - 8
    }
  },
  methods: {
    imageStep (back) {
      var i = this.images.findIndex(f => f.id === this.selected.id)
      i = back ? i - 1 : i + 1
      if (i < 0) i = this.images.length - 1
      if (i >= this.images.length) i = 0
      this.previewImage(this.images[i], i, true)
    },
    loadImages () {
      if (this.onLoadImages) {
        this.onLoadImages()
        return
      }
      this.$emit('load-images')
    },
    uploadImage () {
      if (this.onUploadImage) {
        this.onUploadImage()
        return
      }
      this.$emit('upload-image', this.unsavedImage)
    },
    deleteImage () {
      if (this.onDeleteImage) {
        this.onDeleteImage()
        return
      }
      this.$emit('delete-image', this.selected.id)
    },
    manualPreview (image, imgN) {
      this.previewImage(image, imgN, true)
    },
    previewImage (image, imgN, force) {
      if (this.disabled) return
      const i = new Image()
      i.src = image.url

      image.w = i.width
      image.h = i.height
      image.n = imgN
      if (!force) {
        this.$emit('preview-click', image)
        if (this.hasNoPreview) return
      }

      this.selected = image
      this.dialog = true
    },
    onUploadClick () {
      this.$refs.uploader.launchFilePicker()
    },
    uploaded () {
      this.unsavedImage = null
    },
    makeProfile () {
      this.$emit('make-profile', this.selected.id)
    },
    setViewport () {
      this.vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
      this.vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    },
    resizeMe () {
      const bp = this.$vuetify.breakpoint.name
      switch (bp) {
        case 'xs':
          this.itemsPerPage = 4
          this.ippOptions = [4, 8, 16, -1]
          break
        case 'sm':
          this.itemsPerPage = 6
          this.ippOptions = [6, 12, 24, -1]
          break
        case 'md':
          this.itemsPerPage = 8
          this.ippOptions = [8, 16, 32, -1]
          break
        case 'lg':
          this.itemsPerPage = 8
          this.ippOptions = [8, 16, 32, -1]
          break
        case 'xl':
          this.itemsPerPage = 12
          this.ippOptions = [6, 12, 24, -1]
          break
      }
    },
    copyUrl () {
      var copyText = document.createElement('input')
      copyText.value = this.selected.url
      document.body.appendChild(copyText)
      copyText.focus()
      copyText.select()
      copyText.setSelectionRange(0, 99999)
      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'
        console.log('Copying text command was ' + msg)
      } catch (err) {
        console.log('Oops, unable to copy')
      }

      document.body.removeChild(copyText)
    }
  },
  watch: {
    username: function (val) {
      if (val) {
        this.loadImages()
      }
    },
    dialog: function (val) {
      this.confirm = this.profileAsk = false
      if (!val) {
        this.selected = null
        this.showUrl = false
      }
    },
    '$vuetify.breakpoint.name': 'resizeMe',
    unsavedImage: function (val) {
      this.$emit('has-unsaved-image', !!val)
    },
    activeImg: function (i) {
      if (i !== undefined) {
        const a = this.displayImages[i]
        this.previewImage(a, i)
      }
    }
  },
  mounted () {
    this.loadImages()
    this.resizeMe()
    this.setViewport()
    this.$nextTick(() => {
      window.addEventListener('resize', this.setViewport)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.setViewport)
  },
  components: {
    imageInput
  }
}
</script>

<style>
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    width: 100%;
  }
  .v-card--reveal .v-card {
    opacity: 1;
  }
  .diaCont {
    width: auto;
    min-width: 300px;
    max-height: calc(90vh - 64px) !important;
    max-width: 90vw !important;
  }
  .diaCont img {
    /* max-width: 75vw; */
    max-height: 75vh;
    border: solid #ECEFF1 1px;
  }
  .logo {
    border: none !important;
    vertical-align: middle;
  }
  .dialogImage {
    max-height: calc(90vh - 87px) !important;
    max-width: calc(90vw - 8px) !important;
  }
  .imageDialog {
    width: auto
  }
  .holder:before {
    content: "";
    display: block;
    padding-top: 100%;
    float: left;
  }
  .single-row {
    overflow-x: auto;
  }
  .single-row .row {
    padding-bottom: 10px
  }
  .single-row .flex {
    flex-grow: 0
  }
</style>
