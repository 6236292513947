import DtoUpdate from './DtoUpdate'

export default class TeamSelector {
  id = 0
  type = 'ALL'
  seeds = null
  startSeed = 1
  endSeed = 9999
  seedStyle = 'ORIGINAL_SEED'
  fromRoundId = null
  fromStandings = null
  n = 3
  map = null
  result = null
  advancedN = null
  teamIds = null
  exclude = false

  constructor (sdk, dto) {
    this.sdk = sdk
    if (dto) {
      this.update(dto)
    }
  }

  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    DtoUpdate(dto, this)

    this._dto = null
  }

  edit () {
    this._dto = JSON.stringify(this.dto)
  }

  restore () {
    if (this.isDirty()) this.update(this._dto)
    this._dto = null
  }

  isDirty () {
    return this._dto !== JSON.stringify(this.dto) || this.id === 0
  }

  getTeams (teams) {
    if (this.type === 'ALL') return teams

    if (this.type === 'RANGE') {
      switch (this.seedStyle) {
        case 'ORIGINAL_SEED':
          return teams.filter(f => f.seed >= this.startSeed && f.seed <= this.endSeed)
      }
    }
    if (this.type === 'SELECT') {
      const seeds = JSON.parse(`[${this.seeds}]`)
      switch (this.seedStyle) {
        case 'ORIGINAL_SEED':
          return teams.filter(f => seeds.includes(f.seed))
      }
    }
  }

  get valid () {
    if (this.type === 'MAPPED') {
      return !!this.map
    }
    return true
  }

  get dto () {
    return {
      id: this.id,
      type: this.type,
      seeds: this.seeds,
      startSeed: this.startSeed,
      endSeed: this.endSeed,
      seedStyle: this.seedStyle,
      fromRoundId: this.fromRoundId,
      n: this.n,
      map: this.map,
      advancedN: this.advancedN,
      teamIds: this.teamIds,
      exclude: this.exclude,
      fromStandings: this.fromStandings
    }
  }

  get isFromStandings () {
    return this.fromStandings !== null
  }

  get isFromReg () {
    return !this.fromRoundId && !this.isFromStandings
  }
}
