import loadTournament from '@/router/methods/loadTournament'
import BracketRoutes from './league-bracket-routes'
import PoolRoutes from './league-pool-routes'

const DivisionHome = () => import('@/Pages/Tournament/Division/Home.vue')
const LeagueRounds = () => import('@/Pages/Tournament/Division/Rounds/League.vue')
const DivisionSettings = () => import('@/Pages/Tournament/Division/Settings/Index.vue')
const TeamList = () => import('@/Pages/Tournament/Division/Teams/Home.vue')
const DivisionStandings = () => import('@/Pages/Tournament/Division/Standings/Index.vue')
const RegularSeason = () => import('@/components/Leagues/RegularSeason.vue')
const PoolsHome = () => import('@/Pages/Tournament/Division/Pools/Index.vue')
const BracketHome = () => import('@/Pages/Tournament/Division/Brackets/Index.vue')
// import DayHome from '../Pages/Tournament/Division/Days/Index.vue'

export default [
  {
    path: '/league/:tournamentId/division/:divisionId',
    component: DivisionHome,
    beforeEnter: loadTournament,
    props: true,
    children: [
      {
        path: '',
        name: 'league-division-home',
        component: DivisionSettings,
        props: true,
        meta: { displayName: 'Settings' }
      },
      {
        path: 'schedule',
        name: 'league-rounds',
        component: LeagueRounds,
        props: true,
        meta: { displayName: 'Schedule' }
      },
      {
        path: 'teams',
        name: 'league-division-teams',
        component: TeamList,
        props: true,
        meta: { displayName: 'Teams' }
      },
      {
        path: 'waitlist',
        name: 'league-division-waitlist',
        component: TeamList,
        props: (route) => { return { waitlist: true } },
        meta: { displayName: 'Waitlist' }
      },
      {
        path: 'standings/:standingsI',
        name: 'league-division-standings',
        component: DivisionStandings,
        props: true,
        meta: { displayName: 'Standings' }
      },
      {
        path: 'regular_season',
        name: 'league-play',
        component: RegularSeason,
        props: true,
        meta: { displayName: 'Regular Season' }
      },
      {
        path: 'round/:dayId/pools',
        component: PoolsHome,
        props: true,
        meta: { displayName: 'Pools' },
        children: [...PoolRoutes]
      },
      {
        path: 'round/:dayId/brackets',
        component: BracketHome,
        props: true,
        meta: { displayName: 'Brackets' },
        children: [...BracketRoutes]
      }
    ]
  }
]
