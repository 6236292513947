import LiveUpdates from '@/SignalR'
import * as actions from '@/store/ActionTypes'
// import * as mutations from '../../store/MutationTypes'

// const ConnectionState = [
//   'Connecting',
//   'Connected',
//   'Disconnected'
// ]

export default {
  state: {
    updates: new LiveUpdates()
  },
  mutations: {
  },
  actions: {
    [actions.LIVE_CONNECT] ({ commit, state, getters, rootGetters }) {
      const token = rootGetters.accessToken
      return state.updates.connect(token)
    },
    [actions.SUBSCRIBE_TO_TOURNAMENT] ({ state, getters, commit }, payload) {
      const tId = +payload.tournamentId
      let dId = +payload.divisionId || null
      const teamId = +payload.teamId || null
      if (!dId && teamId) {
        const d = getters.tournament.getDivisionByTeam(teamId)
        if (d) dId = d.id
      }
      const o = payload.add ? null : getters.tournament.loadOrder || null
      const subscription = {
        methodName: 'SubscribeToTournament',
        args: [
          tId, dId, o
        ]
      }
      state.updates.subscribe(subscription)
    },
    [actions.UNSUBSCRIBE_TO_TOURNAMENT] ({ state, getters, commit }, payload) {
      const tId = +payload.tournamentId
      const subscription = {
        methodName: 'SubscribeToTournament',
        args: [
          tId
        ]
      }
      state.updates.unsubscribe(subscription)
    },
    [actions.STORE_LEAD] ({ state }, payload) {
      return state.updates.connection.invoke('Lead', payload)
    },
    [actions.PLAYER_SEARCH] ({ state }, payload) {
      return state.updates.connection.invoke('PlayerSearch', payload)
    },
    [actions.AD_CLICK] ({ state }, payload) {
      return state.updates.connection.invoke('AdClick', payload)
    }
  },
  getters: {
    liveUpdates: state => state.updates.connection,

    liveUpdateStatus: state => state.updates.connection.connection.connectionState,

    connectionId: state => state.updates.connectionId,

    subscriptions: state => state.updates.subscriptions,

    getTournamentSubscription: (state) => (id) => {
      const subscription = {
        methodName: 'SubscribeToTournament',
        args: [
          id
        ]
      }
      return state.updates.subscriptions.find(f => JSON.stringify(f) === JSON.stringify(subscription))
    }
  }
}
