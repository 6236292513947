import { store } from '@/store'
import * as actions from '@/store/ActionTypes'

export default (to, from, next) => {
  if (store.getters.accessToken) {
    next()
  } else {
    store.dispatch(actions.UNLOAD_USER).then(() => {
      next('/signin?returnUrl=' + to.fullPath)
    })
  }
}
// Make private and public only files
