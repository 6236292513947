import endpoints from './endpoints'

export default class DivisionSDK {
  constructor (http) {
    this.http = http
  }

  hydrate (divisionId) {
    return this.http.get(endpoints.division.hydrate(divisionId))
  }

  patch (dto) {
    return this.http.patch(endpoints.division.base, dto)
  }

  getTeams (divisionId) {
    return this.http.get(endpoints.division.teams(divisionId))
  }

  patchTeams (divisionId, dto) {
    return this.http.patch(endpoints.division.teams(divisionId), dto)
  }

  seedTeams (divisionId, dto) {
    return this.http.patch(`/division/${divisionId}/teams/seed`, dto)
  }

  getTeamsWithLookup (divisionId) {
    return this.http.get(endpoints.division.teams(divisionId) + '/WithLookup')
  }

  saveFlights (dayId, dto) {
    return this.http.post(endpoints.day.flights(dayId), dto)
  }

  finish (id) {
    return this.http.post(`/division/${id}/finish`)
  }

  interweavePools (dto) {
    return this.http.post('/pools/interweave', dto)
  }

  pullPoints (divisionId) {
    return this.http.get(`/division/${divisionId}/teams/WithLookup`)
  }
}
