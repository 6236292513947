<template>
  <v-card :color="bgColor">
    <v-toolbar dense color="color2 color2Text--text">
      <v-toolbar-title>
        {{title}}
      </v-toolbar-title>
    </v-toolbar>
    <loading-bar :value="loading"></loading-bar>
    <v-container fluid>
      <template v-for="(field, i) in fields">
        <v-row dense :key="`row-${i}`">
          <template v-if="field.header">
            <v-col cols="12" sm="3" md="2" class="text-h6 text-sm-end grey--text">
              {{field.label}}
              <v-divider></v-divider>
            </v-col>
          </template>

          <template v-else>
            <v-col cols="12" v-if="$vuetify.breakpoint.xsOnly">
              <strong>{{field.label}}: </strong>{{field.value}}
            </v-col>
            <template v-else>
              <v-col class="text-right" cols="4" sm="3" md="2" >
                <strong>{{field.label}}:</strong>
              </v-col>
              <v-col cols="8" sm="9" md="10">
                {{field.value}}
              </v-col>
            </template>
          </template>

        </v-row>
        <v-divider v-if="field.divide" :key="`div-${i}`" :inset="!$vuetify.breakpoint.xsOnly"></v-divider>

      </template>
      <slot></slot>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: ['title', 'fields', 'bgColor', 'loading']
}
</script>

<style>

</style>
