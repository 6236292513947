<template>
  <div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler () {
        this.$emit('change', this.data)
      }
    }
  }
}
</script>
