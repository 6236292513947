var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.editable)?_c('event-editor',{attrs:{"vblEvent":_vm.editable,"disabled":_vm.disabled},on:{"changed":_vm.onChange}},[_c('template',{slot:"cols"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","order":"2"}},[_c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-toolbar',{attrs:{"dense":"","flat":"","color":_vm.disabled ? 'grey lighten-3 grey--text' : 'color1 color1Text--text'}},[_c('v-toolbar-title',[_vm._v(" Points & Leaderboard Settings ")])],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('point-system-options',{attrs:{"disabled":_vm.disabled,"model":_vm.editable.settings}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"8"}},[_c('v-alert',{attrs:{"type":"warning","value":_vm.editable.settings.pointSystemId && _vm.editable.settings.pointSystemId !== -1,"prominent":"","text":"","border":"top","transition":"scale-transition"}},[_vm._v(" All tournaments must add the chosen point system or they will be ignored ")])],1)],1),_c('v-expand-transition',[(_vm.editable.settings.pointSystemId === - 1)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-card',[_c('v-data-table',{attrs:{"headers":[
                        { text: 'Finish', value: 'finish'},
                        { text: 'Points', value: 'pv'},
                        { text: '', value: 'actions', align: 'end'}
                      ],"items":_vm.pointValues,"hide-default-footer":"","dense":"","options":{ itemsPerPage: -1 }},scopedSlots:_vm._u([{key:"item.finish",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("ordinal")(item.finish === 9999 ? 'All Others' : item.finish))+" ")]}},{key:"item.pv",fn:function(ref){
                      var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"disabled":_vm.disabled,"return-value":item.pv},on:{"update:returnValue":function($event){return _vm.$set(item, "pv", $event)},"update:return-value":function($event){return _vm.$set(item, "pv", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","label":"Points","single-line":"","color":"color3","disabled":_vm.disabled},model:{value:(item.pv),callback:function ($$v) {_vm.$set(item, "pv", $$v)},expression:"item.pv"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.pv)+" ")])]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [_c('v-btn',{staticClass:"xsmall",attrs:{"color":"error","text":"","icon":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.deletePV(item)}}},[_c('v-icon',[_vm._v("fas fa-trash")])],1)]}}],null,true)}),_c('div',{staticClass:"text-center"},[_c('v-fab-transition',[(!_vm.disabled && _vm.pMax < 9999)?_c('v-btn',{attrs:{"color":"color3 color3Text--text","small":"","fab":""},on:{"click":function($event){$event.stopPropagation();return _vm.addPV($event)}}},[_c('v-icon',[_vm._v("fas fa-plus")])],1):_vm._e()],1)],1)],1)],1)],1):_vm._e()],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-select',{attrs:{"label":"How do you want to keep points?","disabled":_vm.disabled,"color":"color3","attach":"","items":[
                    { text: 'By Player', value: 'player' },
                    { text: 'By Team (doubles)', value: 'team' },
                    { text: 'By Team (non-doubles)', value: 'teamName' },
                    { text: 'By Club', value: 'club' }
                  ],"item-color":"color3"},model:{value:(_vm.bySetting),callback:function ($$v) {_vm.bySetting=$$v},expression:"bySetting"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-select',{attrs:{"label":"How do you display rankings?","disabled":_vm.disabled,"color":"color3","attach":"","multiple":"","items":[
                    { text: 'Overall', value: 'overall' },
                    { text: 'By Division', value: 'divisional' },
                    { text: 'By Gender', value: 'gender' }
                  ],"item-color":"color3"},model:{value:(_vm.editable.settings.ranking),callback:function ($$v) {_vm.$set(_vm.editable.settings, "ranking", $$v)},expression:"editable.settings.ranking"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-text-field',{attrs:{"label":("Use a " + (_vm.editable.settings.byTeam ? 'team' : 'player') + "'s Top N tournaments"),"type":"number","disabled":_vm.disabled,"color":"color3","persistent-hint":"","hint":"Enter a value to limit the number of events that will count towards rankings, otherwise leave blank"},model:{value:(_vm.editable.settings.topN),callback:function ($$v) {_vm.$set(_vm.editable.settings, "topN", $$v)},expression:"editable.settings.topN"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-text-field',{attrs:{"label":("Only use a " + (_vm.editable.settings.byTeam ? 'team' : 'player') + "'s last N tournaments"),"type":"number","disabled":_vm.disabled,"color":"color3","persistent-hint":"","hint":"Enter a value to limit the number of events that will count towards rankings, otherwise leave blank"},model:{value:(_vm.editable.settings.lastN),callback:function ($$v) {_vm.$set(_vm.editable.settings, "lastN", $$v)},expression:"editable.settings.lastN"}})],1)],1)],1)],1)],1)],1)],2):_vm._e(),[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-fab-transition',[(!_vm.viewOnly && _vm.dirty)?_c('v-btn',_vm._g({key:"save",style:(("margin-right: " + ((_vm.btnOffset || 0 )+ 60) + "px")),attrs:{"color":"color3 color3Text--text","fab":"","bottom":"","right":"","fixed":"","loading":_vm.saving},on:{"click":function($event){$event.stopPropagation();return _vm.onSaveClick($event)}}},on),[_c('v-icon',[_vm._v("fas fa-save")])],1):_vm._e()],1)]}}])},[_c('span',[_vm._v("Save")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-fab-transition',[_c('v-btn',_vm._g({key:_vm.activeFab.icon,style:(("margin-right: " + ((_vm.btnOffset || 0)) + "px")),attrs:{"color":_vm.activeFab.color,"fab":"","bottom":"","right":"","fixed":"","disabled":_vm.saving},on:{"click":function($event){$event.stopPropagation();return _vm.activeFab.onClick($event)}},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.activeFab.icon))])],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.activeFab.tooltip))])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }