<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="color3"
        text
        class="pl-0 ml-0"
        v-on="on"
      >Don't have a {{partner}} yet?</v-btn>
    </template>
    <v-card>
      <v-card-title class="headline color2 color2Text--text">
          TBD Teammates
      </v-card-title>
      <v-card-text class="mt-3">
        {{tournament.organization.name}} is allowing {{partictipant}}s to sign up without a {{partner}}.
      </v-card-text>
      <v-card-text class="mt-3 font-weight-black">
        Upon checkout you will recieve an order id.
        You will need to log in to the team manager with that order id and {{partictipant === 'player' ? 'add your partner' : 'complete your roster'}}
        before the tournament starts.
      </v-card-text>
      <v-card-text class="mt-3">
        To sign up with out a {{partner}}, click continue below.
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="align-center">
        <v-spacer></v-spacer>
        <v-btn color="error" @click.stop="dialog=false">cancel</v-btn>
        <v-btn color="success" @click.stop="go">continue</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['tournament', 'registration'],
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    partictipant () {
      return this.registration.division.isDoubles ? 'player' : 'team'
    },
    partner () {
      return this.partictipant === 'player' ? 'partner' : 'full roster'
    }
  },
  methods: {
    go () {
      this.$emit('tbd-click')
      this.dialog = false
    }
  }
}
</script>
