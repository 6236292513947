export default class PointsSDK {
  constructor (http) {
    this.http = http
  }

  get (username) {
    return this.http.get(`/RatingSystem/${username}`)
  }

  add (organizationId) {
    return this.http.post(`/RatingSystem/add?organizationId=${organizationId}`)
  }

  display (ppId, ratingId) {
    return this.http.post(`/ratings/display?ppId=${ppId}&rId=${ratingId}`)
  }

  post (dto) {
    return this.http.post('/RatingSystem', dto)
  }

  postRating (dto, id) {
    return this.http.post(`/RatingSystem/rating?systemId=${id}`, dto)
  }

  postRule (ratingId, dto) {
    return this.http.post(`/RatingSystem/rating/${ratingId}/rule`, dto)
  }

  getResults (tournamentId, ratingSystemId) {
    return this.http.get(`/RatingSystem/tournament/results?tid=${tournamentId}&rid=${ratingSystemId}`)
  }

  getPlayers (ratingSystemId, playerIds) {
    return this.http.post(`/RatingSystem/players?rid=${ratingSystemId}`, playerIds)
  }

  getPlayerList (ratingSystemId) {
    return this.http.get(`/Ratings?systemId=${ratingSystemId}`)
  }

  getPlayer (id) {
    return this.http.post(`/Ratings/Player?id=${id}`)
  }

  getOptions (username) {
    return this.http.get(`/organization/${username}/RatingSystems/options`)
  }

  getDivisionRatings (tournamentId, divisionId, recalc) {
    let q = `tid=${tournamentId}`
    if (divisionId) q = `${q}&&did=${divisionId}`
    if (recalc) q = `${q}&&recalc=${recalc}`
    return this.http.get(`/RatingSystem/tournament/disvision?${q}`)
  }

  systemOrganizations = {
    get: (id) => {
      return this.http.get(`/RatingSystem/${id}/MemberOrganizations`)
    },
    post: (id, organizationId) => {
      return this.http.post(`/RatingSystem/${id}/MemberOrganizations?organizationId=${organizationId}`)
    },
    tournaments: {
      get: (id, username) => {
        if (username === 'ALL') {
          return this.http.get(`/RatingSystem/${id}/tournaments`)
        }
        return this.http.get(`/RatingSystem/${id}/${username}/tournaments`)
      },
      post: (id, username, dto) => {
        return this.http.post(`/RatingSystem/${id}/${username}/tournaments`, dto)
      }
    }
  }

  rerun (id, ratingSystemId) {
    return this.http.post(`/RatingSystem/tournament/${id}/rerun?rid=${ratingSystemId}`)
  }
}
