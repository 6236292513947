export default class EventSDK {
  constructor (http) {
    this.http = http
  }

  series = {
    get: (tag) => {
      return this.http.get(`Series/${tag}`)
    },
    organizers: {
      get: (id) => {
        return this.http.get(`/Series/${id}/MemberOrganizations`)
      },
      post: (id, organizationId) => {
        return this.http.post(`/Series/${id}/MemberOrganizations?organizationId=${organizationId}`)
      },
      tournaments: {
        get: (id, username) => {
          return this.http.get(`/Series/${id}/${username}/tournaments`)
        },
        post: (id, username, dto) => {
          return this.http.post(`/Series/${id}/${username}/tournaments`, dto)
        }
      }
    }

  }
}
