import CartFee from './CartFee'

export default function (reg, cartFees) {
  const fees = cartFees.map(f => new CartFee(f))
  const result = []
  const tdId = reg ? reg.division ? reg.division.id : reg.tournamentDivisionId : 0
  if (fees && fees.length > 0) {
    fees.forEach(f => {
      if (f.perPlayer && !f.isTax) {
        reg.players.forEach((p, i) => {
          if (f.applies(tdId, p)) {
            const a = JSON.parse(JSON.stringify(f))
            const pName = p.fullName ? p.fullName : p.firstName ? `${p.firstName} ${p.lastName}` : `Player ${i + 1}`
            if (a.description) a.description = a.description.replace('{name}', pName)
            result.push(a)
          }
        })
      } else {
        if (f.applies(tdId)) {
          result.push(f)
        }
      }
    })
  }
  return result
}
