import AuthService from '@/Oidc/AuthService'
import * as actions from '../ActionTypes'
import * as mutations from '../MutationTypes'
import { store } from '@/store'

const auth = new AuthService()
auth.userManager.events.addUserSignedIn(() => {
  // console.log(`addUserSignedIn - ${new Date()}`)
  store.dispatch(actions.USER_CHECK)
})
auth.userManager.events.addUserLoaded(() => {
  // console.log(`addUserLoaded - ${new Date()}`)
  store.dispatch(actions.USER_CHECK)
})
auth.userManager.events.addUserUnloaded(() => {
  // console.log(`addUserUnloaded - ${new Date()}`)
  store.dispatch(actions.USER_CHECK)
})
auth.userManager.events.addUserSignedOut(() => {
  // console.log(`addUserUnloaded - ${new Date()}`)
  store.dispatch(actions.USER_CHECK)
})

export default {
  state: {
    accessToken: null
  },
  mutations: {
    [mutations.SET_TOKEN] (state, payload) {
      state.accessToken = payload
    }
  },
  actions: {
    async [actions.USER_CHECK] ({ dispatch, getters, commit }, auto) {
      // console.log(`${actions.USER_CHECK} - ${new Date()}`)
      auth.userManager.clearStaleState()
      var access = await auth.getAccessToken()
      commit(mutations.SET_TOKEN, access)
      if (access) {
        dispatch(actions.LOAD_USER)
      }
      if (!access && auto) {
        await auth.userManager.signinSilent()
          .catch(e => {
            console.log('No user')
          })
      }
      if (!access && getters.user) {
        // auth.logout()
        dispatch(actions.UNLOAD_USER)
      }
    },
    async [actions.USER_CHECK2] ({ dispatch, getters, commit }, auto) {
      // console.log(`${actions.USER_CHECK2} - ${new Date()}`)
      const good = await auth.isLoggedIn(getters.user)
      // console.log(good)
      !good && dispatch(actions.USER_CHECK, true)
    }
  },
  getters: {
    auth () {
      return auth
    },
    accessToken (state) {
      return state.accessToken
    }
  }
}
