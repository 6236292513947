const STRIPE_CHECKOUT_URL = 'https://js.stripe.com'

export const loadStripeCheckout = (pk, version = 'v3', callback) => {
  if (window.Stripe) {
    callback()
  } else {
    const e = document.createElement('script')
    e.src = `${STRIPE_CHECKOUT_URL}/${version}`
    e.type = 'text/javascript'
    document.getElementsByTagName('head')[0].appendChild(e)
    e.addEventListener('load', callback)
  }
}
