<template>
  <v-progress-linear :indeterminate="value" :color="value ? 'color3' : ''" class="ma-0"></v-progress-linear>
</template>

<script>
export default {
  props: ['value']
}
</script>

<style>

</style>
