<template>
  <div>
    <v-btn
      v-if="status !== 'true'"
      color="color3 color3Text--text"
      :disabled="disabled"
      :loading="loading"
      @click.stop="verify"
      small
    >{{label}}</v-btn>
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar color="color1 color1Text--text">
          <v-toolbar-title>🤔 Hmmm!</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row dense>
            <v-col cols="12" class="title">
              We are a bit confused!
              <div class="subheading mt-1">According to our records, the number you entered belongs to a different player.</div>
            </v-col>
          </v-row>
          <v-list>

            <v-list-item>
              <v-subheader class="pa-0">You are registering</v-subheader>
            </v-list-item>

            <v-list-item>
              <v-list-item-action v-if="!$vuetify.breakpoint.xsOnly"></v-list-item-action>
              <v-list-item-content v-if="theOg">
                <v-list-item-title>
                  {{theOg.fullName}} {{theOg.ageGroup && theOg.ageGroup > 18 ? '' : `${theOg.ageGroup}U`}}
                  <span class="grey--text text--lighten-1 caption">
                    {{theOg.email ? ` - ${theOg.email}` : ''}}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle>{{theOg.cityState}}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content v-else>
                <v-list-item-title>
                  {{player.fullname}}
                  <span class="grey--text text--lighten-1 caption">
                    {{player.email ? ` - ${player.email}` : ''}}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-subheader class="pa-0">The number seems to belong to</v-subheader>
            </v-list-item>

            <v-list-item>
              <v-list-item-action v-if="!$vuetify.breakpoint.xsOnly"></v-list-item-action>
              <v-list-item-content v-if="theNoob">
                <v-list-item-title>
                  {{theNoob.fullName}} {{!theNoob.ageGroup ? '' : theNoob.ageGroup > 18 ? '' : `${theNoob.ageGroup}U`}}
                  <span class="grey--text text--lighten-1 caption">
                    {{theNoob.email ? ` - ${theNoob.email}` : ''}}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle>{{theNoob.cityState}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

          </v-list>
          <v-row dense justify="center">
            <v-col cols="12" class="title">
              What would you like to do?
            </v-col>
            <v-col cols="12">
              <v-btn
                color="color3 color3Text--text"
                @click.stop="cancelNumber"
                block
                class="blockBtn"
              >Enter a new number</v-btn>
            </v-col>
            <v-col cols="12" v-if="theNoob">
              <v-btn
                color="color3 color3Text--text"
                @click.stop="switchToNoob"
                block
                class="blockBtn"
              >Register with {{theNoob.fullName}}</v-btn>
            </v-col>
            <v-col cols="12" v-if="false">
              <v-btn
                color="color3Text color3--text"
                @click.stop="dialog = false"
                block
              >They are the same person</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapGetters } from 'vuex'
import { namesAreSame } from '@/classes/HelperFunctions'

export default {
  props: ['player', 'required'],
  data () {
    return {
      loading: false,
      history: {},
      lastResult: null,
      dialog: false
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    label () {
      return this.history[this.number] ? 'Try Again' : 'Verify'
    },
    number () {
      return this.player && this.player.avpNumber
    },
    status () {
      return this.player && this.player.avpaStatus
    },
    disabled () {
      return !this.player || !this.number || !this.valid
    },
    isAvpa () {
      return this.number && this.number.startsWith('1') && this.number.length === 7 && Number.isInteger(+this.number)
    },
    isAvp () {
      return this.number && this.number.startsWith('5') && this.number.length === 9 && Number.isInteger(+this.number)
    },
    valid () {
      return this.number && this.number.length >= 5 && Number.isInteger(+this.number)
    },
    nameMatchWarn () {
      return this.player && this.lastResult && this.player.firstName && this.lastResult.firstName && this.player.lastName && this.lastResult.lastName && !(namesAreSame(this.lastResult.firstName, this.player.firstName) && namesAreSame(this.lastResult.lastName, this.player.lastName))
    },
    nameMatchWarnObj () {
      return {
        p: this.player,
        l: this.lastResult,
        a: this.lastResult.firstName === this.player.firstName,
        a2: this.lastResult.firstName,
        a3: this.player.firstName,
        b: this.lastResult.lastName === this.player.lastName,
        b2: this.lastResult.lastName,
        b3: this.player.lastName
      }
    },
    warn () {
      return this.player && this.player.playerProfileId && this.lastResult && this.lastResult.profile && this.lastResult.profile.id !== this.player.playerProfileId
    },
    theOg () {
      return this.player && this.player.searchResult
    },
    theNoob () {
      return this.lastResult && (this.lastResult.profile || {
        id: 0,
        firstName: this.lastResult.firstName,
        lastName: this.lastResult.lastName,
        fullName: `${this.lastResult.firstName} ${this.lastResult.lastName}`
      })
    },
    strict () {
      return this.tournament && this.tournament.strict
    },
    asOf () {
      return this.tournament && this.tournament.strict ? this.tournament.startDate.format('MM-DD-YYYY') : null
    }
  },
  methods: {
    callVerify: debounce(function () {
      this.verify()
    }, 333),
    verify () {
      if (!this.valid) return
      this.loading = true
      this.$VBL.verifyAVPA(this.number, this.asOf)
        .then(r => { this.setResult(r.data) })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    setResult (dto) {
      this.lastResult = dto
      this.$set(this.history, this.number, dto.status)
      this.player.avpaStatus = dto.status
      if (this.player.avpaStatus !== 'false') {
        if (this.warn || this.nameMatchWarn) {
          this.dialog = true
        } else {
          if (!this.player.playerProfileId) {
            if (dto.firstName) this.player.firstName = dto.firstName
            if (dto.lastName) this.player.lastName = dto.lastName
          }
        }
      }
    },
    cancelNumber () {
      delete this.history[this.number]
      this.player.avpNumber = this.player.avpaStatus = null
      this.dialog = false
    },
    switchToNoob () {
      const n = this.number
      this.player.setProfile(this.theNoob)
      if (!this.theNoob.profile) {
        this.player.avpNumber = n
        this.player.avpaStatus = this.history[n]
        this.$emit('show-manual')
      }
      this.dialog = false
    }
  },
  watch: {
    'player.avpNumber': function (n, o) {
      if (n) {
        this.player.avpaStatus = this.history[this.number]
      } else {
        this.player.avpaStatus = null
      }
    }
  }
}
</script>

<style scoped>
.blockBtn {
  height: 100px;
}
.blockBtn >>> .v-btn__content {
  white-space: normal;
}
</style>
