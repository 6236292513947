<template>
  <v-container fluid :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
    <!-- MAIN CONTENT -->
    <template v-if="view.admin">
      <v-row dense>
        <v-col cols="12">
          <v-card height="100%">
            <v-toolbar tabs color="color1 color1Text--text">
              <v-toolbar-title>Series Manager - {{series.title}}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-tabs background-color="color2" slider-color="color3" v-model="currentTab" touchless dark show-arrows>
              <v-tab href="#edit" ripple class="color2Text--text">Brochure</v-tab>
              <v-tab href="#orgs" ripple class="color2Text--text">Organizers</v-tab>

              <v-tab-item value="edit" key="edit">
                <series-editor
                  v-if="currentTab === 'edit'"
                  :series="series"
                  :btnOffset="60"
                  @changed="onChange"
                ></series-editor>
              </v-tab-item>

              <v-tab-item value="orgs" key="orgs">
                <organization-manager
                  v-if="currentTab === 'orgs'"
                  :series="series"
                  :btnOffset="60"
                  @changed="onChange"
                ></organization-manager>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <got-blocked v-else>
      <span class="title">Sorry, you don't have permissions for this page.</span>
    </got-blocked>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import SeriesEditor from './SeriesEditor'
import OrganizationManager from './OrganizationManager'

export default {
  props: ['series', 'view'],
  data () {
    return {
      currentTab: 'edit'
    }
  },
  computed: {
    ...mapGetters(['user', 'publicView'])
  },
  methods: {
    onChange (dto) {
      this.$emit('changed', dto)
    }
  },
  watch: {
    currentTab: function (val) {
      this.$emit('tab-change', val)
    }
  },
  mounted () {
    this.$emit('tab-change', this.currentTab)
  },
  components: {
    SeriesEditor,
    OrganizationManager
  }
}
</script>
