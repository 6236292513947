<template>
  <v-dialog
    v-model="ask"
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-text class="text-center">
        <div class="title">{{question || 'Are you sure?'}}</div>
        <div v-if="subText">{{subText}}</div>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="success" @click.stop="$emit('confirm')" :loading="loading">yes</v-btn>
        <v-btn color="error" @click.stop="$emit('deny')" :disabled="loading">No</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['ask', 'question', 'loading', 'subText']
}
</script>
