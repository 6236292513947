import AuthGuard from '@/router/methods/auth-guard'
const Profile = () => import('@/Pages/Me')
const MyOrders = () => import('@/Pages/My/OrderHistory')
const MyBookmarks = () => import('@/Pages/My/Bookmarks')
const MyNotifications = () => import('@/Pages/My/Notifications')
const VerifyUser = () => import('@/components/User/VerifyUser')
const ProfileCheck = () => import('@/components/User/ProfileCheck')

export default [
  {
    path: '/me',
    name: 'me',
    component: Profile,
    beforeEnter: AuthGuard,
    meta: { requiresUser: true }
  },
  {
    path: '/my/account',
    name: 'my-account',
    component: Profile,
    beforeEnter: AuthGuard,
    meta: { requiresUser: true }
  },
  {
    path: '/my/account/verify',
    name: 'verify-me',
    component: VerifyUser,
    beforeEnter: AuthGuard,
    meta: { requiresUser: true }
  },
  {
    path: '/my/player/check',
    name: 'profile-check',
    component: ProfileCheck,
    beforeEnter: AuthGuard,
    meta: { requiresUser: true }
  },
  {
    path: '/my/orders',
    name: 'my-orders',
    component: MyOrders,
    beforeEnter: AuthGuard,
    meta: { requiresUser: true }
  },
  {
    path: '/my/bookmarks',
    name: 'my-bookmarks',
    component: MyBookmarks,
    beforeEnter: AuthGuard,
    meta: { requiresUser: true }
  },
  {
    path: '/my/notifications',
    name: 'my-notifications',
    component: MyNotifications,
    beforeEnter: AuthGuard,
    meta: { requiresUser: true }
  }
]
