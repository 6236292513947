<template>
  <div :key="user ? user.id : 0" v-if="true">
    <v-navigation-drawer
      fixed
      clipped
      app
      v-model="drawer"
      :dark="theme.dark"
    >
      <main-drawer
        @login-click="login"
        @logout-click="logout"
      ></main-drawer>
    </v-navigation-drawer>
    <p1440-toolbar
      v-if="subdomain === 'p1440'"
      @login-click="login"
      @logout-click="logout"
      @toggle-drawer="drawer = !drawer"
    ></p1440-toolbar>
    <tvt-toolbar
      v-else-if="subdomain === 'texasbeachvolleyballx'"
      @login-click="login"
      @logout-click="logout"
      @toggle-drawer="drawer = !drawer"
    ></tvt-toolbar>
    <usav-toolbar
      v-else-if="subdomain === 'usav'"
      @login-click="login"
      @logout-click="logout"
      @toggle-drawer="drawer = !drawer"
    ></usav-toolbar>
    <vbl-toolbar
      v-else-if="!subdomain || theme.username === 'vbl'"
      @login-click="login"
      @logout-click="logout"
      @toggle-drawer="drawer = !drawer"
    ></vbl-toolbar>
    <subdomain-toolbar
      v-else
      @login-click="login"
      @logout-click="logout"
      @toggle-drawer="drawer = !drawer"
    ></subdomain-toolbar>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="500px"
      transition="dialog-transition"
    >
      <template v-slot:activator="{on}" v-if="false">
        <v-btn color="success" fab fixed bottom right v-on="on">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </template>
      <set-password @close="dialog = false"></set-password>
    </v-dialog>
    <bookmark-notify v-if="user && tournament && tournament.activePlayers"></bookmark-notify>
  </div>
</template>

<script>
import MainDrawer from './Drawers/Main.vue'
import VblToolbar from './Toolbars/VBL.vue'
import SubdomainToolbar from './Toolbars/Index.vue'
import { mapGetters } from 'vuex'
const p1440Toolbar = () => import('./Toolbars/p1440.vue')
const UsavToolbar = () => import('./Toolbars/USAV.vue')
const TvtToolbar = () => import('./Toolbars/Tvt.vue')
const SetPassword = () => import('@/components/Account/SetPassword.vue')
const BookmarkNotify = () => import('@/components/Tournament/BookmarkNotify.vue')

export default {
  data () {
    return {
      drawer: null,
      tournamentNav: false,
      dialog: false,
      adBarShowing: false,
      adBarClosed: false
    }
  },
  computed: {
    ...mapGetters(['subdomain', 'user', 'admin', 'publicView', 'tournament', 'full', 'auth', 'theme', 'bidStream']),
    title () {
      switch (this.window) {
        case 0:
          return 'Sign In'
        case 1:
          return 'Sign Up'
        case 2:
          return 'Forgot Password?'
        case 3:
          return 'Are you sure?'
      }
      return null
    },
    canSetPW () {
      return this.user && this.user.canSetPassword
    },
    showAds () {
      const a = this.theme && this.theme.props.includes('noAds')
      const b = this.user && this.user.props.includes('noAds')

      if (a || b) return false

      return true
    }
  },
  methods: {
    login () {
      this.auth.login()
      // this.signInDialog = true
    },
    logout () {
      this.auth.logout(true)
    },
    setNav () {
      if (this.subdomain === 'p1440') this.drawer = false
      if (this.tournament) {
        this.tournament.active = true
      }
      if (this.showAds) {
        this.loadBidStream()
      }
    },
    loadBidStream () {
      const id = 'BSTREAM'
      if (!document.getElementById(id)) {
        const head = document.getElementsByTagName('head')[0]
        const s = document.createElement('script')
        s.type = 'text/javascript'
        s.src = 'https://vblimages.blob.core.windows.net/files/e9840c96-79bd-481a-aa4c-4e57a9663def.js'
        s.id = id
        head.appendChild(s)
      }
    }
  },
  components: {
    MainDrawer,
    VblToolbar,
    p1440Toolbar,
    SubdomainToolbar,
    SetPassword,
    TvtToolbar,
    UsavToolbar,
    BookmarkNotify
  },
  watch: {
    tournament: function (val) {
      this.setNav()
    },
    'user.canSetPassword': function (val) {
      if (val) {
        this.dialog = true
      }
    },
    'theme.drawer': function (val) {
      this.drawer = val
    },
    showAds: function (val) {
      if (val) this.loadBidStream()
    }
  },
  created () {
    this.setNav()
  }
}
</script>

<style scoped>
</style>
