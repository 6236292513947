export default class ClubSDK {
  constructor (http) {
    this.http = http
  }

  getAll () {
    return this.http.get('club/list')
  }

  getBids (tId, userId) {
    const b = userId ? '&byUser=true' : ''
    return this.http.get(`club/bids?tid=${tId}${b}`)
  }

  postBid (tId, dto) {
    return this.http.post(`club/bids?tid=${tId}`, dto)
  }
}
