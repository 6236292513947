import Vue from 'vue'
import * as mutations from '../MutationTypes'
import * as actions from '../ActionTypes'
// import { Promise } from 'es6-promise'
import Organization from '@/classes/Organization'

export default {
  state: {
    organization: new Organization()
  },
  mutations: {
    [mutations.SET_ORGANIZATION] (state, payload) {
      state.organization = new Organization(payload)
    },
    [mutations.UPDATE_ORGANIZATION] (state, payload) {
      console.log('Organization Update!')
      if (state.organization && state.organization.id === payload.id) {
        state.organization.update(payload)
      }
    }
  },
  actions: {
    [actions.LOAD_ORGANIZATION] ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (state.organization && state.organization.username === payload) return resolve()

        commit(mutations.LOADING, actions.LOAD_ORGANIZATION)
        const sdk = Vue.prototype.$VBL
        sdk.organization.get(payload)
          .then((response) => {
            response.data.agree = true
            commit(mutations.SET_ORGANIZATION, response.data)
          })
          .catch(err => {
            console.log(err.response)
          })
          .finally(() => {
            commit(mutations.LOADING_COMPLETE, actions.LOAD_ORGANIZATION)
          })
      })
    }
  },
  getters: {
    organization: (state) => {
      return state.organization
    }
  }
}
