<template>
  <v-container fluid :class="{'px-0' : noPad}">
    <!-- LIST VIEW -->
    <v-data-table
      v-if="listView"
      :headers="headers"
      :items="rows"
      :options.sync="dataOptions"
      :search="search"
      :footer-props="footerProps"
      :hide-default-footer="rows.length <= rowsPerPageItems[0]"
      @update:options="onPageChange"
      disable-sort
    >
      <v-progress-linear slot="progress" color="color3" indeterminate></v-progress-linear>
      <template v-slot:item="{ item }">
        <v-fade-transition hide-on-leave>
          <tr v-if="loading">
            <td width="10%">
              <v-skeleton-loader
                type="image"
                height="40"
                width="95"
              ></v-skeleton-loader>
            </td>
            <td width="10%">
                <v-skeleton-loader
                  type="list-item-two-line"
                ></v-skeleton-loader>
            </td>
            <td width="50%">
                <v-skeleton-loader
                  type="list-item-two-line"
                ></v-skeleton-loader>
            </td>
            <td width="10%">
                <v-skeleton-loader
                  type="list-item"
                ></v-skeleton-loader>
            </td>
            <td width="10%">
              <div class="d-flex justify-end">
                <v-skeleton-loader
                  type="avatar"
                ></v-skeleton-loader>
              </div>
            </td>
          </tr>
          <tr
            v-else-if="!item.isAd"
            style="cursor: pointer"
            :title="user && user.vbl ? item.id : item.name"
            :key="item.id"
            :class="{'canceled-faded': item.tournament.statusId > 100}"
          >
            <td class="text-center" @click="gotoDetails(item.tournament)">
              <div style="width:95px">
                <v-carousel
                  :show-arrows="false"
                  hide-delimiters
                  height="40"
                  max="95"
                  class="elevation-0"
                  :cycle="item.tournament.statusId < 101"
                  continuous
                  interval="3000"
                >
                  <v-carousel-item
                    height="40px"
                    v-for="(logo, i) in item.logos"
                    :key="`${logo.url}_${i}`"
                    :src="logo.url"
                    :class="logo.class"
                    :style="logo.style"
                    contain
                  ></v-carousel-item>
                </v-carousel>
              </div>
            </td>
            <td @click="gotoDetails(item.tournament)" :class="{strike: item.tournament.statusId > 100}">
              <div>{{ item.dayDisplay }}</div>
              <div>{{ item.dateDisplay }}{{item.year ? ` (${item.year})` : ''}}</div>
            </td>
            <td @click="gotoDetails(item.tournament)">
              <div class="font-weight-bold">
                {{ item.name }}
                <span v-if="item.tournament.statusId === 101" class="error--text"> - Canceled</span>
                <span v-if="item.tournament.statusId === 102" class="error--text"> - Postponed</span>
              </div>
              <div class="font-weight-thin caption" :class="{strike: item.tournament.statusId > 100}">
                {{ item.divisions | formatArray }}
              </div>
            </td>
            <td @click="gotoDetails(item.tournament, 'location')" :class="{strike: item.tournament.statusId > 100}">
              {{ item.locations }}
            </td>
            <td class="text-right">
              <v-tooltip left v-if="!item.public">
                <span>Only you can see this</span>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">fal fa-low-vision</v-icon>
                </template>
              </v-tooltip>
              <v-tooltip left>
                <span>View Tournament</span>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    small
                    fab
                    color="color3 color3Text--text"
                    @contextmenu.native="gotoDetails(item.tournament, 'register', 'right')"
                    @click="gotoDetails(item.tournament, 'register')">
                    <v-icon>fas fa-caret-right</v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </td>
          </tr>
          <tr
            v-else
            style="cursor: pointer"
            :key="item.id"
            @click="adClick(item)"
            :style="item.rowStyle"
          >
            <td
              colspan="4"
            >
              <div
              :class="item.rowTextClass"
              :style="item.rowTextStyle"
              >
                {{item.tagline}}
                <div style="width:175px">
                  <v-img
                    max-height="48"
                    contain
                    v-if="item.rowImages && item.rowImages.length"
                    :src="item.rowImages[0]">
                  </v-img>
                </div>
              </div>
            </td>
            <td>
              <v-btn
                small
                fab
                color="color3 color3Text--text"
                class="float-right"
                @click="adClick(item)">
                <v-icon>fas fa-caret-right</v-icon>
              </v-btn>
            </td>
          </tr>
        </v-fade-transition>
      </template>
    </v-data-table>
    <!-- CARD VIEW -->
    <template v-else>
      <v-data-iterator
        :items="rows"
        :options.sync="dataOptions"
        disable-sort
        :search="search"
        :footer-props="footerProps"
        :hide-default-footer="rows.length <= rowsPerPageItems[0]"
        @update:options="onPageChange"
      >
        <template v-slot:default="props">
          <v-row dense>
            <v-col
              v-for="item in props.items"
              :key="`${item.isAd ? 'a' : 't'}-${item.id}`"
              cols="12"
              sm="6"
              md="4"
              xl="3"
              :title="user && user.vbl ? item.id : item.name"
            >
              <v-scale-transition hide-on-leave>
                <v-card v-if="loading">
                  <v-card-text>
                    <v-row justify="space-between" dense>
                      <v-col cols="4">
                        <v-skeleton-loader
                          type="text"
                        ></v-skeleton-loader>
                        <v-skeleton-loader
                          width="75%"
                          type="text"
                        ></v-skeleton-loader>
                      </v-col>
                      <v-col cols="6">
                        <v-skeleton-loader
                          type="text"
                        ></v-skeleton-loader>
                        <v-skeleton-loader
                          type="text"
                        ></v-skeleton-loader>
                        <v-skeleton-loader
                          type="text"
                        ></v-skeleton-loader>
                      </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col cols="5" class="pl-3">
                        <v-skeleton-loader
                          height="50"
                          type="image"
                        ></v-skeleton-loader>
                      </v-col>
                      <v-col cols="7">
                        <v-skeleton-loader
                          type="text"
                        ></v-skeleton-loader>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <v-card v-else-if="item.isAd" class="fill-height">
                  <ad-card :ad="item"></ad-card>
                </v-card>
                <tournament-card
                  v-else-if="item.tournament"
                  :event="item.tournament"
                  :toolbar="theme.username === 'vbl'"
                  :sponsored="item.sponosored"
                  :image="item.image"
                  @blocked="onBlocked"
                  :emitter="emitter"
                  @event-clicked="onEventClicked"
                  :showCounts="showCounts"
                ></tournament-card>
              </v-scale-transition>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </template>
    <!-- Log in Dialog -->
    <got-blocked-dialog ref="blocked">
      <v-alert type="error" :value="true" text>
        {{blockedMsg}}
      </v-alert>
    </got-blocked-dialog>

  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import TournamentCard from '@/components/Tournament/TournamentCard.vue'
import LogoUrls from '@/classes/LogoUrls'
import AdCard from '@/components/Ads/AdCard.vue'
import moment from 'moment'
import GotBlockedDialog from '@/components/Utils/GotBlockedDialog'
import { firstBy } from 'thenby'

export default {
  props: [
    'tournaments', 'loading', 'search', 'listView', 'filterItems',
    'ads', 'sortDesc', 'filter', 'itemCountStart', 'noPad', 'lastPage',
    'emitter',
    'showCounts'
  ],
  data () {
    return {
      agefilter: '',
      genderFilter: '',
      divisionFilter: '',
      locationFilter: '',
      dataOptions: { sortBy: ['unix'], itemsPerPage: this.itemCountStart || 25, sortDesc: [false] },
      blockedMsg: null,
      baseFilters: ['Tournament', 'Camp', 'Clinic', 'Series', 'League'],
      isMounted: false
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'publicView',
      'ageTypeOptions',
      'genderOptions',
      'divisionOptions',
      'locationOptions',
      'theme',
      'inIFrame'
    ]),
    rowsPerPageItems () {
      const i = this.itemCountStart
      return i ? [i, i * 2, i * 3] : this.listView ? [25, 50, 100] : [12, 24, 48]
    },
    footerProps () {
      return {
        itemsPerPageOptions: this.rowsPerPageItems,
        prevIcon: `fas fa-chevron-left${this.$vuetify.breakpoint.smAndDown ? ' fa-sm' : ''}`,
        nextIcon: `fas fa-chevron-right${this.$vuetify.breakpoint.smAndDown ? ' fa-sm' : ''}`
      }
    },
    pageName () {
      return this.$route.params.username
    },
    admin (tournament) {
      return this.user && this.user.isTournamentAdmin(tournament)
    },
    rows () {
      if (this.loading) {
        const n = this.dataOptions.itemsPerPage
        return [...Array(n).keys()].map(m => {
          return { id: m }
        })
      }
      if (!this.tournaments) return []
      var base = this.tournaments
      if (this.filterItems && this.filterItems.length) {
        this.filterItems.filter(f => !this.baseFilters.includes(f)).forEach(tag => {
          base = base.filter(f => f.tags && f.tags.includes(tag))
        })
      }
      var result = base.map((t) => {
        const y = t.startDate.year()
        return {
          id: t.id,
          distance: t.distance(this.latLong),
          startDate: t.timestamp,
          dateDisplay: t.startDate.isSame(t.endDate) ? t.startDate.format('MMM Do') : `${t.startDate.format('MMM Do')} - ${t.endDate.format('MMM Do')}`,
          dayDisplay: t.startDate.isSame(t.endDate) ? t.startDate.format('dddd') : `${t.startDate.format('ddd')} - ${t.endDate.format('ddd')}`,
          year: y && moment().year() === y ? false : y,
          name: t.name,
          locations: t.locationsString,
          public: t.isPublic,
          regOpen: t.regOpen,
          sanctionedBy: t.sanctionedBy || '',
          admin: this.user && this.user.isTournamentAdmin(t),
          ibvl: t.ibvl,
          logos: t.logos.filter(f => f && f.url) || [],
          divisions: (t.divisionNames || []).sort(),
          tournament: t,
          tags: t.tags,
          unix: t.unix,
          sponosored: t.id === 227,
          image: t.id === 227 ? 'https://vblimages.blob.core.windows.net/images/8eeff516-c11c-41c2-aeac-73c3f3233a99.jpg' : null
        }
      })
      if (!result.length) result = []
      const sd = Array.isArray(this.sortDesc) ? this.sortDesc[0] : this.sortDesc
      result = result.sort(firstBy('unix', sd ? -1 : 1))

      // if (this.$route.params.filter && this.$route.params.filter === 'upcoming') {
      //   const stickyI = result.findIndex(f => f.id === 81)
      //   if (stickyI > -1) {
      //     const sticky = result.splice(stickyI, 1)
      //     result.unshift(...sticky)
      //   }
      // }
      const ads = this.ads || []
      ads.forEach(a => {
        if (a.spot > result.length) {
          result.push(a)
        } else {
          result.splice(a.spot, 0, a)
        }
      })

      return result
    },
    headers () {
      return [
        { value: 'sanctionedBy', align: 'left' },
        { text: 'Date', value: 'startDate', align: 'left' },
        { text: 'Name', value: 'name', align: 'left' },
        { text: 'Location', value: 'locations', align: 'left' },
        { value: 'tags', align: 'left', sortable: 'false' }
      ]
    },
    logos () {
      return LogoUrls
    }
  },
  methods: {
    adClick (ad) {
      window.open(ad.actionUrl, '_blank')
      const dto = {
        id: this.ad.id,
        page: this.$route.fullPath
      }
      this.liveUpdates.invoke('AdClick', dto)
    },
    onEventClicked (eventId) {
      this.$emit('event-clicked', eventId)
    },
    onBlocked (msg) {
      this.blockedMsg = msg
      this.$refs && this.$refs.blocked && this.$refs.blocked.open()
    },
    gotoDetails (event, tab, click) {
      if (this.emitter) {
        this.onEventClicked(event.id)
        return
      }
      const route = event.isSeries ? {
        name: 'series-home',
        params: { seriesTag: event.urlTag }
      } : event.isLeague ? {
        name: 'league-brochure',
        params: { tournamentId: event.id }
      } : {
        name: 'tournament-brochure',
        params: { tournamentId: event.id }
      }
      if (tab) {
        route.params.mode = tab
      }
      if (this.inIFrame || click === 'right') {
        const routeData = this.$router.resolve(route)
        window.open(routeData.href, '_blank')
        return
      }
      this.$router.push(route)
    },
    manage (id) {
      this.$router.push({ name: 'tournament-manager', params: { tournamentId: id } })
    },
    customSort (items, sortBy, sortDesc) {
      const isDesc = sortDesc[0]
      const key = sortBy[0]
      items.sort((a, b) => {
        if (!isDesc) {
          return a[key] < b[key] ? -1 : 1
        } else {
          return b[key] < a[key] ? -1 : 1
        }
      })

      if (this.$route.params.filter && this.$route.params.filter === 'upcoming') {
        const stickyI = items.findIndex(f => f.id === 227)
        if (stickyI > -1) {
          const sticky = items.splice(stickyI, 1)
          items.unshift(...sticky)
        }
      }
      const ads = this.ads || []
      ads.forEach(a => {
        if (a.spot > items.length) {
          items.push(a)
        } else {
          items.splice(a.spot, 0, a)
        }
      })
      return items
    },
    onPageChange () {
      if (!this.isMounted) return
      this.$emit('page-update', {
        p: this.dataOptions.page,
        ipp: this.dataOptions.itemsPerPage
      })
      // this.saveState()
    },
    saveState () {
      if (!this.isMounted) return
      const query = {}
      const p = this.dataOptions.page
      const ipp = this.dataOptions.itemsPerPage
      Object.assign(query, this.$route.query)
      if (p !== (+query.p)) {
        query.p = p
      }
      if (ipp !== (+query.ipp)) {
        query.ipp = ipp
      }
      if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
        this.$router.push({
          path: this.$route.path,
          query
        })
      }
    },
    setState (q) {
      this.dataOptions.page = q && q.p ? +q.p : 1
      if (q && q.ipp) {
        this.dataOptions.itemsPerPage = +q.ipp
      } else {
        this.setOptions()
      }
    },
    setOptions () {
      this.dataOptions.itemsPerPage = this.itemCountStart > 0 ? this.itemCountStart : this.listView ? 25 : 12
    },
    checkState () {
      const p = this.dataOptions.page
      const q = this.$route && this.$route.query && +this.$route.query.p
      if (p && q && p !== q) this.setState(this.$route.query)
    }
  },
  watch: {
    listView: 'setOptions',
    loading: 'checkState'
  },
  beforeRouteUpdate (to, from, next) {
    this.setState(to.query)
    next()
  },
  mounted () {
    this.dataOptions.sortDesc = Array.isArray(this.sortDesc) ? this.sortDesc : [this.sortDesc]
    const q = Object.assign({}, this.$route.query)
    this.setOptions()
    this.setState(q)
    this.isMounted = true
  },
  components: {
    TournamentCard,
    AdCard,
    GotBlockedDialog
  }
}
</script>

<style scoped>
  .strike {
    text-decoration: line-through;
  }
  .canceled-faded {
    opacity: 0.37;
  }
</style>
