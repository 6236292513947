var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_vm._t("default"),(_vm.question)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{staticClass:"align-start",attrs:{"row":"","hide-details":"","disabled":_vm.disable},model:{value:(_vm.model.yesOrNo),callback:function ($$v) {_vm.$set(_vm.model, "yesOrNo", $$v)},expression:"model.yesOrNo"}},[_c('template',{staticClass:"mr-4",slot:"label"},[_vm._v(" "+_vm._s(_vm.question)+" ")]),_c('v-radio',{staticClass:"ml-4",attrs:{"color":"color3","label":"Yes","value":true}}),_c('v-radio',{attrs:{"color":"color3","label":"No","value":false}})],2)],1)],1):_vm._e(),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.model.yesOrNo),expression:"model.yesOrNo"}]},[_c('v-row',{attrs:{"dense":""}},[(!!_vm.program)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.ncaa)?_c('college-selector',{attrs:{"disabled":_vm.disable,"errors":_vm.$v.model.name.$dirty && !_vm.$v.model.name.required ? ['A college is required'] : []},on:{"blur":function($event){return _vm.$v.model.name.$touch()}},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}):(_vm.ibvl)?_c('v-autocomplete',{attrs:{"items":_vm.ibvlSchools.girls,"label":(_vm.program + " Name*"),"color":"color3","disabled":_vm.disable,"hide-details":!!_vm.disable,"rules":_vm.$v.model.name.$dirty ? [
              function () { return _vm.$v.model.name.required || ("A " + (_vm.program.toLowerCase()) + " name is required"); }
            ] : []},on:{"blur":function($event){return _vm.$v.model.name.$touch()}},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}):_c('v-text-field',{attrs:{"label":(_vm.program + " Name*"),"color":"color3","disabled":_vm.disable,"hide-details":!!_vm.disable,"rules":_vm.$v.model.name.$dirty ? [
              function () { return _vm.$v.model.name.required || ("A " + (_vm.program.toLowerCase()) + " name is required"); }
            ] : []},on:{"blur":function($event){return _vm.$v.model.name.$touch()}},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1):_vm._e()],1),_vm._l((_vm.model.contacts),function(c,i){return [_c('v-row',{key:("c" + i + "_row1"),attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{ref:("tf" + i),refInFor:true,attrs:{"label":((c.title) + " Name" + (_vm.isRequired(i, 'name') ? '*' : '')),"color":"color3","disabled":_vm.disable,"hide-details":!!_vm.disable,"hint":"Please enter their full name","persistent-hint":"","rules":_vm.isRequired(i, 'name') && _vm.$v.model.contacts.$each[i].name.$dirty ? [
                function () { return _vm.$v.model.contacts.$each[i].name.required || "A name is required"; }
              ] : []},on:{"blur":function($event){return _vm.onBlur(i, 'name')}},model:{value:(_vm.model.contacts[i].name),callback:function ($$v) {_vm.$set(_vm.model.contacts[i], "name", $$v)},expression:"model.contacts[i].name"}})],1)],1),_c('v-row',{key:("c" + i + "_row2"),attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(###) ###-####'),expression:"'(###) ###-####'"}],attrs:{"label":((c.title) + " Phone" + (_vm.isRequired(i, 'phone') ? '*' : '')),"color":"color3","disabled":_vm.disable,"hide-details":!!_vm.disable,"rules":_vm.isRequired(i, 'phone') && _vm.$v.model.contacts.$each[i].phone.$dirty ? [
                function () { return _vm.$v.model.contacts.$each[i].phone.required || "A phone is required"; },
                function () { return _vm.$v.model.contacts.$each[i].phone.ValidUSPhone || "A valid phone is required"; }
              ] : []},on:{"blur":function($event){return _vm.onBlur(i, 'phone')}},model:{value:(_vm.model.contacts[i].phone),callback:function ($$v) {_vm.$set(_vm.model.contacts[i], "phone", $$v)},expression:"model.contacts[i].phone"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":((c.title) + " Email" + (_vm.isRequired(i, 'email') ? '*' : '')),"color":"color3","disabled":_vm.disable,"hide-details":!!_vm.disable,"rules":_vm.isRequired(i, 'email') && _vm.$v.model.contacts.$each[i].email.$dirty ? [
                function () { return _vm.$v.model.contacts.$each[i].email.required || "An email is required"; },
                function () { return _vm.$v.model.contacts.$each[i].email.email || 'A valid email is required'; }
              ] : []},on:{"blur":function($event){return _vm.onBlur(i, 'email')}},model:{value:(_vm.model.contacts[i].email),callback:function ($$v) {_vm.$set(_vm.model.contacts[i], "email", $$v)},expression:"model.contacts[i].email"}})],1)],1)]})],2)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }