import * as endpoints from '@/classes/_URL'

export default {
  aau: {
    verify: '/AAU/Verify2'
  },
  avpa: {
    verify: (number) => `/AVPA/Verify/${number}`
  },
  baseURL: endpoints.api,
  bids: {
    base: '/bid',
    list: (dto) => `/bid/${dto.programId}/${dto.tournamentId}/${dto.divisionId}`,
    program: '/bid/program',
    upload: (id) => `/bid/upload/${id}`,
    emailCount: (id) => `/bid/emailCount/${id}`
  },
  brackets: {
    base: '/brackets',
    matches: (bracketId) => `/brackets/${bracketId}/matches`
  },
  data: {
    registrationFields: (tournamentId) => `/Data/${tournamentId}/RegistrationFields`
  },
  day: {
    base: '/Day',
    brackets: (dayId) => `/Day/${dayId}/brackets`,
    flights: (dayId) => `/Day/${dayId}/flights`,
    hydrate: (dayId) => `/Day/${dayId}/hydrate`,
    teams: (dayId) => `/Round/${dayId}/teams`
  },
  cart: {
    process: 'cart/process',
    cartStatus: (organizationId) => `/cart/Status/${organizationId}`,
    cartStatus2: '/cart/Status2',
    registrationPayemtnStatus: (teamId) => `/cart/RegistrationStatus/${teamId}`,
    refund: 'cart/refund',
    getBag: 'cart/locate',
    getBagByItemId: (id) => `cart/LocateByItem/${id}`,
    checkItemStatus: 'cart/itemCheck',
    promo: 'cart/promo',
    validateBag: 'cart/validateBag'
  },
  country: {
    all: 'country/all'
  },
  division: {
    base: '/division',
    results: (divisionId) => `/division/${divisionId}/results`,
    seeds: (divisionId) => `/division/${divisionId}/seeds`,
    teams: (divisionId) => `/division/${divisionId}/teams`,
    hydrate: (divisionId) => `/division/${divisionId}/hydrate`,
    rounds: (divisionId) => `/division/${divisionId}/rounds`
  },
  email: {
    confirm: (emailId) => `email/confirm/${emailId}`,
    resend: (emailId) => `email/${emailId}/resend`,
    delete: (emailId) => `email/${emailId}`
  },
  facebook: {
    login: '/facebook/login',
    create: '/facebook/create',
    link: '/facebook/link',
    unlink: (id) => `/facebook/unlink/${id}`
  },
  feedback: '/Feedback',
  flight: {
    teams: (flightId) => `/flight/${flightId}/teams`
  },
  flights: {
    base: '/flights'
  },
  game: {
    base: '/game'
  },
  images: (type, id, imageId) => `/Images/${type}/${id}${imageId ? `/${imageId}` : ''}`,
  locations: '/locations',
  logo: (imageId, organizationId) => `/Images/Logo?imageId=${imageId}&organizationId=${organizationId}`,
  magicLink: {
    send: '/MagicLink/send',
    login: (id) => `/MagicLink/login/${id}`
  },
  matches: {
    base: '/matches',
    unplay: (matchId, type) => `/matches/unplay/${type}/${matchId}`
  },
  membership: {
    base: (username) => `/Membership/${username}`,
    form: (username) => `/Membership/${username}/form`,
    search: (username, term) => `/Membership/${username}/search/${term}`,
    list: (username, term) => `/Membership/${username}/list/${term}`,
    permit: (username) => `/Membership/${username}/permit`,
    access: (username) => `/Membership/${username}/Code`,
    invite: '/Membership/Invite',
    invites: (username) => `/Membership/${username}/invites`
  },
  notifications: {
    base: '/notification',
    tournament: '/notification/tournament'
  },
  organization: {
    base: (username) => `/organization/${username}`,
    permit: (username) => `/organization/${username}/permit`,
    stripe: (username) => `/organization/${username}/stripe`,
    promos: (username) => `/organization/${username}/promos`,
    admins: (username) => `/organization/${username}/admins`,
    templates: (username) => `/organization/${username}/templates`,
    getConnectURLById: (id) => `/stripe/connect/${id}`,
    getUsernameAvailability: (username) => `/organization/${username}/available`,
    vblAdmin: (username) => `/organization/${username}/vbladmin`
  },
  organizations: '/organizations',
  p1440: {
    verify: (number) => `/p1440/Verify/${number}`,
    unlimited: (number) => `/p1440/unlimited/${number}`
  },
  players: { base: 'players' },
  pool: {
    base: '/pool'
  },
  pools: {
    base: '/pools',
    matches: (poolId) => `/pools/${poolId}/matches`
  },
  poolTeams: {
    base: '/poolteams'
  },
  profile: {
    base: (id) => `/playerprofile/${id}`,
    search: (searchTerm) => `/playerprofile/search/${searchTerm}`,
    finishes: (playerProfileId) => `/playerprofile/${playerProfileId}/finishes`
  },
  rankings: {
    all: 'Ranking',
    byId: (sbid) => `Ranking/${sbid}`,
    pointScale: (sbid) => `Ranking/PointScale/${sbid}`,
    teamMultiplier: (sbid) => `Ranking/TeamMultiplier/${sbid}`
  },
  round: {
    byId: (roundId) => `/round/${roundId}`,
    teams: (dayId) => `/Round/${dayId}/teams`
  },
  series: {
    base: 'series',
    byTag: (tag) => `series/${tag}`
  },
  team: {
    changeDivisions: (teamId, newDivisionId) => `/Team/ChangeDivision/${teamId}/${newDivisionId}`,
    saveResults: '/Team/Results',
    unregister: (id) => `/Team/Unregister/${id}`,
    reregister: (id) => `/Team/Reregister/${id}`,
    changePartner: '/Team/ChangePartner',
    change: '/Team/Change',
    notes: (id) => `/Team/Notes/${id}`,
    regData: (id) => `/Team/RegistrationData/${id}`,
    partnerData: (id) => `/Team/PartnerData/${id}`,
    getManageable: (id) => `/Team/Manageable/${id}`,
    withdrawAndRefund: '/Team/Withdraw',
    putChangeRequest: '/Team/ChangeRequest',
    checkIn: (id, _in) => `/Team/${id}/CheckIn/${_in}`,
    updateName: (id) => `/Team/${id}/UpdateName`,
    onlineCheckin: 'Team/OnlineCheckin',
    tokenCheck: 'Team/Token',
    forfeit: 'Team/Forfeit',
    merge: 'Team/merge',
    newProfile: (id) => `/Team/NewProfile/${id}`,
    savePlayerField: (rpId) => `/Team/UpdateRegistration/${rpId}`
  },
  theme: {
    base: '/Theme'
  },
  tournament: {
    base: '/tournament',
    options: '/Tournament/Selects',
    getSelectOptions: (organizationId) => organizationId ? `/Tournament/Selects/${organizationId}` : '/Tournament/Selects',
    getById: (id) => `/tournament/${id}`,
    getCopyById: (id) => `/tournament/${id}/copy`,
    getRawById: (id) => `/tournament/${id}/raw`,
    put: '/tournament',
    getAll: '/tournament/list',
    getAllSummaries: (query) => `/tournament/summaries?${query}`,
    getByOrganizationId: (organizationId) => `/tournament/list/${organizationId}`,
    getByOrganizationUserName: (username) => `/tournament/${username}/list`,
    quickAdd: '/tournament/quickAdd',
    quickAdd2: '/tournament/quickAdd2',
    bulkRegister: (overwrite) => `/tournament/register/upload/${overwrite}`,
    publish: (id, val) => `/tournament/${id}/publish/${val}`,
    getSeededTeams: (id) => `/tournament/${id}/seededDivisions`,
    getRegistrationData: (id) => `/tournament/${id}/registrations`,
    getRawRegistrationData: (id) => `/tournament/${id}/rawRegistrations`,
    lockResults: (id) => `/tournament/${id}/LockResults`,
    getLeads: (id) => `/tournament/${id}/Leads`
  },
  user: {
    email: '/email/me',
    getMe: '/Me',
    getSignIns: '/Me/SignIns',
    login: '/account/Login',
    register: '/account/register',
    phone: '/phone/me',
    changePassword: '/Account/ChangePassword',
    orderHistory: '/Cart/History'
  },
  waitlist: '/waitlist'
}
