import endpoints from './endpoints'

export default class DeleteSDK {
  constructor (http) {
    this.http = http
  }

  round (id) {
    return this.http.delete(endpoints.round.byId(id))
  }

  tournament (id) {
    return this.http.delete(endpoints.tournament.getById(id))
  }

  image (type, id, imageId) {
    return this.http.delete(endpoints.images(type, id, imageId))
  }

  bid (id) {
    return this.http.delete(`${endpoints.bids.base}/${id}`)
  }

  match (matchId) {
    return this.http.delete(`matches?Id=${matchId}`)
  }
}
