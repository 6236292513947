import endpoints from './endpoints'

export default class PatchSDK {
  constructor (http) {
    this.http = http
  }

  tournament (dto) {
    return this.http.patch(endpoints.tournament.base, dto)
  }

  division (dto) {
    return this.http.patch(endpoints.division.base, dto)
  }

  day (dto) {
    return this.http.patch(endpoints.day.base, dto)
  }

  flights (dto) {
    return this.http.patch(endpoints.flights.base, dto)
  }

  pool (dto) {
    return this.http.patch(endpoints.pool.base, dto)
  }

  pools (dto) {
    return this.http.patch(endpoints.pools.base, dto)
  }

  poolTeams (dto) {
    return this.http.patch(endpoints.poolTeams.base, dto)
  }

  match (dto) {
    return this.http.patch(endpoints.match.base, dto)
  }

  game (dto) {
    return this.http.patch(endpoints.game.base, dto)
  }

  organization (username, dto) {
    return this.http.post(endpoints.organization.base(username), dto)
  }

  player (dto) {
    return this.http.patch(endpoints.players.base, dto)
  }
}
