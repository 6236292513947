<template>
  <v-app-bar
    color="color1 color1Text--text"
    app
    clipped-left
    fixed
    :dense="minibar"
  >
    <v-app-bar-nav-icon>
      <v-btn color="color1Text" icon @click.stop="$emit('toggle-drawer')">
        <v-icon>far fa-bars</v-icon>
      </v-btn>
    </v-app-bar-nav-icon>
    <v-toolbar-title class="pa-0 logo" :class="{shown: true}">
      <v-img
        src="https://vblimages.blob.core.windows.net/images/b792272a-0b6a-4f5f-9145-19f8b004a07b.png"
        width="220px"
        contain
      >
      </v-img>
    </v-toolbar-title>
    <template v-slot:img="{ props }" v-if="!minibar && false">
      <v-img
        v-bind="props"
        src="https://vblimages.blob.core.windows.net/images/e2984d84-c7dd-48ae-940b-2890e73fe4b4.jpg"
        height="100%"
        position="center 80%"
      >
        <scoped-slot-watcher :data="props.height" @change="onPropChange"></scoped-slot-watcher>
        <div class="d-flex align-center justify-center fill-height" style="text-shadow: #000 1px 0 3px;">
          <v-img
            src="https://vblimages.blob.core.windows.net/images/b792272a-0b6a-4f5f-9145-19f8b004a07b.png"
            height="90%"
            max-width="80%"
            contain
          >
          </v-img>
        </div>
      </v-img>
    </template>

    <v-spacer></v-spacer>
    <!-- CART FAB  -->
    <cart-fab btn-class="mr-2"></cart-fab>
    <!-- TOURNAMENT QUICK SETTINGS  -->
    <v-fab-transition>
      <quick-settings-dialog v-if="view.admin && tournament && tRoute"></quick-settings-dialog>
    </v-fab-transition>
    <v-fab-transition>
      <bid-assign-dialog v-if="user && user.bidPrograms && user.bidPrograms.length && tournament && tRoute"></bid-assign-dialog>
    </v-fab-transition>
    <!-- USER -->
    <template v-if="user">
      <!-- NOTIFICATIONS -->
      <v-fab-transition>
        <notifications v-if="notifications && notifications.length"/>
      </v-fab-transition>
      <!-- USER NAME  -->
      <user-menu></user-menu>
    </template>
    <template v-else>
      <v-btn text color=color1Text @click.stop="$emit('login-click')">
        <v-icon class="mr-2">fas fa-unlock-alt</v-icon>
        Sign In
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script>
import ScopedSlotWatcher from '@/components/Utils/ScopedSlotWatcher'
import { mapGetters } from 'vuex'
import * as actions from '@/store/ActionTypes'
const QuickSettingsDialog = () => import('@/components/Tournament/QuickSettings/Dialog')
const BidAssignDialog = () => import('@/components/Tournament/Bids/BidAssignDialog')
const CartFab = () => import('@/components/Utils/CartFab')
const UserMenu = () => import('@/Nav/Toolbars/UserMenu')
const Notifications = () => import('@/Nav/Toolbars/Notifications')

export default {
  data () {
    return {
      drawer: null,
      signInDialog: false,
      showLogo: false
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'organizationName',
      'logoUrl',
      'tournament',
      'view',
      'notifications'
    ]),
    minibar () {
      return (['tournament-players'].includes(this.$route.name) && this.$vuetify.breakpoint.xsOnly) || ['tournament-courts-advanced', 'holiday-ads'].includes(this.$route.name)
    },
    hasNotifications () {
      return true
    },
    tRoute () {
      return this.$route.path.startsWith('/tournament/') || this.$route.path.startsWith('/league/')
    }
  },
  methods: {
    goToCart () {
      this.$router.push({ name: 'checkout' })
    },
    onPropChange (val) {
      var x = val && val && parseInt(val)
      if (x) {
        this.showLogo = x < 61
      }
    },
    checkMyTeams () {
      if (this.user && this.tournament && this.tournament.checkUserTeams) {
        this.$store.dispatch(actions.LOAD_MY_TOURNAMENT_TEAMS, { tournamentId: this.tournament.id })
      }
    }
  },
  watch: {
    user: 'checkMyTeams',
    tournament: 'checkMyTeams',
    'tournament.teams.length': 'checkMyTeams'
  },
  mounted () {
    this.checkMyTeams()
  },
  components: {
    CartFab,
    QuickSettingsDialog,
    BidAssignDialog,
    ScopedSlotWatcher,
    UserMenu,
    Notifications
  }
}
</script>
<style scoped>
.noW {
  padding: 0;
  min-width: 0;
}
.logo {
  display: none !important;
  height: 48px;
}
.logo.shown {
  display: flex !important;
}
</style>
