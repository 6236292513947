<template>
  <v-card>
    <v-card-title class="justify-center">
      <slot></slot>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '300px'
    }
  }
}
</script>

<style>

</style>
