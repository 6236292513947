<template>
  <v-dialog v-model="dialog" persistent max-width="400px">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="!noBtn"
        @click="validateClick"
        :color="color"
        :rounded="round"
        :text="flat"
        :disabled="!player.aauNumber"
        :loading="validating"
        :class="{hideMe: player.validAau || player.manualAau}"
        v-on="on"
      >Verify</v-btn>
    </template>
    <v-card>
      <v-toolbar color="color2 color2Text--text">
        <v-toolbar-title>AAU Number Verification</v-toolbar-title>
      </v-toolbar>
      <loading-bar :value="validating"></loading-bar>
      <v-container class="pt-0">
        <v-row dense justify="center">
          <v-btn
            class="ma-0"
            small
            :color="mode === 'lastname' ? 'color3' : ''"
            text
            @click="mode = 'lastname'"
            :disabled="validating"
          >by name</v-btn>
          <v-btn
            :color="mode === 'dob' ? 'color3' : ''"
            class="ma-0"
            small
            active-class="color3"
            text
            @click="mode = 'dob'"
            :disabled="validating"
          >by dob</v-btn>
          <v-btn
            class="ma-0"
            small
            :color="mode === 'zip' ? 'color3' : ''"
            active-class="color3"
            text
            @click="mode = 'zip'"
            :disabled="validating"
          >by zip</v-btn>
        </v-row>
        <!-- START NUMBER -->
        <v-row dense>
          <v-col>
            <v-text-field
              label="AAU Number"
              v-model="player.aauNumber"
              required
              :disabled="validating"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- END NUMBER -->
        <!-- START NAME -->
        <v-row dense v-if="mode === 'lastname'">
          <v-col>
            <v-text-field
              label="Last Name"
              v-model="player.lastName"
              :disabled="validating || !!player.playerProfileId"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- END NAME -->
        <!-- START ZIP -->
        <v-row dense v-if="mode === 'zip'">
          <v-col>
            <v-text-field
              label="Zip Code"
              v-model="player.zip"
              :disabled="validating"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- END ZIP -->
        <!-- START DOB -->
        <v-row dense v-if="mode === 'dob'">
          <v-col>
            <v-dialog
              ref="dobPicker"
              v-model="dobPicker"
              width="290px"
              :return-value.sync="player.dob"
              :disabled="validating"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  label="Birthdate"
                  readonly
                  v-model="player.dobF"
                  :disabled="validating"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="dobPickerPicker"
                v-model="player.dob"
                @change="$refs.dobPicker.save(player.dob)"
              ></v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <!-- END DOB -->
        <!-- START RESULTS -->
        <v-row dense v-if="displayResults">
          <v-col cols="12" class="color1--text text-center" v-if="validating">
            <v-btn icon color="color1 white--text" :loading="true">
              <v-icon>fas fa-check-circle</v-icon>
            </v-btn>
            Verifying AAU Number
          </v-col>
          <v-col cols="12" class="green--text text-center" v-else-if="player.validAau">
            <v-btn icon color="green white--text">
              <v-icon>fas fa-check-circle</v-icon>
            </v-btn>
            AAU Number Confirmed
          </v-col>
          <v-col cols="12" class="red--text text-center" v-else-if="error">
            <v-alert type="error" :value="true">
              Oops we ran into the following error:<br>
              <strong>{{error}}</strong><br>
              Please try again.
            </v-alert>
          </v-col>
          <v-col cols="12" class="red--text text-center" v-else-if="isExpired">
            <v-btn icon color="red white--text">
              <v-icon>fas fa-times-circle</v-icon>
            </v-btn>
            {{dto.id}} Is Expired
          </v-col>
          <v-col cols="12" class="red--text text-center" v-else>
            <v-btn icon color="red white--text">
              <v-icon>fas fa-times-circle</v-icon>
            </v-btn>
            Invalid AAU Number
            <v-alert text type="info" :value="mode === 'lastname' && history.length < 3">
              Try verifying by <span class="link" @click="mode = 'dob'">Birthdate</span> or <span class="link" @click="mode = 'zip'">Zip Code</span>
            </v-alert>
            <v-alert text type="info" :value="mode === 'dob' && history.length < 3">
              Try verifying by <span class="link" @click="mode = 'lastname'">Last Name</span> or <span class="link" @click="mode = 'zip'">Zip Code</span>
            </v-alert>
            <v-alert text type="info" :value="mode === 'zip' && history.length < 3">
              Try verifying by <span class="link" @click="mode = 'lastname'">Last Name</span> or <span class="link" @click="mode = 'dob'">Birthdate</span>
            </v-alert>
          </v-col>
          <v-col cols="12" v-if="history.length == 3">
            <v-alert text color="color2" type="info" :value="true">
              <strong>All 3 verifications have failed.</strong><br>
              If you believe the number you have provide is correct
              and the verification is worng please check the box below.
            </v-alert>
            <v-checkbox
              color="color3"
              v-if="true"
              label="I will bring my card to the tournamnet"
              v-model="player.manualAau"
            ></v-checkbox>
          </v-col>
        </v-row>
        <!-- END RESULTS -->
        <!-- START BUTTONS -->
        <v-row dense justify="center" v-if="!validating">
          <v-btn
            v-if="!player.validAau && !player.manualAau"
            color="color3 white--text"
            class="mr-2"
            :disabled="!formValid"
            @click="doValidation"
          >Validate</v-btn>
          <v-btn
            v-if="!player.validAau && !player.manualAau"
            @click="dialog = false"
            class="mr-2"
            :disabled="validating"
          >Cancel</v-btn>
          <v-btn
            v-if="player.validAau || player.manualAau"
            color="color3 white--text"
            @click="dialog = false"
            :disabled="validating"
          >Continue</v-btn>
        </v-row>
        <!-- END BUTTONS -->
        <v-row dense class="mt-4">
          <v-col class="text-center" cols="12" >
            <v-btn color="color3" text class="xsmall ma-0" href="https://find.aausports.org/ArticalView/ArtMID/10914/ArticleID/398" target="_blank">
              AAU Membership Look Up
              <v-icon class="ml-4" small>fas fa-external-link-alt</v-icon>
            </v-btn>
            <v-btn color="color3" text class="xsmall ma-0" href="http://play.aausports.org/" target="_blank">
              Get a new AAU Membership
              <v-icon class="ml-4" small>fas fa-external-link-alt</v-icon>
            </v-btn>
            <v-btn
              color="color3"
              text class="xsmall ma-0"
              v-if="user && user.vbl"
              @click.stop="override"
            >override</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['player', 'color', 'round', 'flat', 'onfile', 'noBtn'],
  data () {
    return {
      dialog: false,
      dobPicker: false,
      validating: false,
      mode: 'lastname',
      error: false,
      validDto: null,
      history: [],
      attempts: [],
      expired: []
    }
  },
  computed: {
    ...mapGetters(['user']),
    valid () {
      return this.player.validAau || this.player.manualAau
    },
    formValid () {
      if (this.player.aauNumber) {
        switch (this.mode) {
          case 'lastname':
            return this.player.lastName
          case 'zip':
            return this.player.zip
          case 'dob':
            return this.player.dobF
        }
      }
      return false
    },
    dto () {
      return {
        id: this.player.aauNumber,
        lastname: this.player.lastName,
        zipcode: this.player.zip,
        dob: this.player.dob,
        by: this.mode
      }
    },
    attempt () {
      const dto = this.dto
      switch (dto.by) {
        case 'lastname':
          return `${dto.id}-${dto.lastname}`
        case 'zip':
          return `${dto.id}-${dto.zipcode}`
        case 'dob':
          return `${dto.id}-${dto.dob}`
      }
      return null
    },
    displayResults () {
      return !this.validating && this.attempts.includes(this.attempt)
    },
    isExpired () {
      return this.expired.includes(this.dto.id)
    },
    vbl () {
      return this.user && this.user.vbl
    }
  },
  methods: {
    open () {
      this.dialog = true
    },
    validateClick () {
      this.open()
      if (this.formValid && !this.vbl) {
        this.doValidation()
      }
    },
    doValidation () {
      this.validating = true
      this.player.validAau = false
      this.error = false
      // this.displayResults = true
      this.callApi()
    },
    callApi () {
      const dto = this.dto
      this.attempts.push(this.attempt)
      return this.$VBL.verifyAAU(this.dto)
        .then((response) => {
          if (!this.history.includes(dto.by)) this.history.push(dto.by)
          if (response.data === 'true' || response.data === true) {
            this.player.validAau = true
            this.validDto = dto
          } else if (response.data === 'expired') {
            if (!this.expired.includes(this.dto.id)) this.expired.push(this.dto.id)
          }
        })
        .catch((error) => {
          this.error = (error.response && error.response.data) ? error.response.data : error.message
        })
        .finally(() => { this.validating = false })
    },
    override () {
      this.player.validAau = true
      this.validDto = 'override'
    },
    setValid () {
      this.player.manualAau = false
      this.player.validAau = this.checkValidity()
    },
    checkValidity () {
      if (!this.validDto) return false
      if (this.onfile) return true

      switch (this.validDto.by) {
        case 'lastname':
          return this.validDto.lastname === this.player.lastName && this.validDto.id === this.player.aauNumber
        case 'dob':
          return this.validDto.dob === this.player.dob && this.validDto.id === this.player.aauNumber
        case 'zip':
          return this.validDto.zipcode === this.player.zip && this.validDto.id === this.player.aauNumber
      }
    }
  },
  watch: {
    mode: function (val) {
      if (this.history.length < 3) {
        // this.displayResults = false
      }
    },
    dobPicker: function (val) {
      val && this.$nextTick(() => (this.$refs.dobPickerPicker.activePicker = 'YEAR'))
    },
    'player.aauNumber': function (val) {
      this.history = []
      this.setValid()
    },
    'player.lastName': function (val) {
      this.setValid()
    },
    'player.dob': function (val) {
      this.setValid()
    },
    'player.zip': function (val) {
      this.setValid()
    },
    'player.validAau': function (val) {
      if (val) {
        this.dialog = false
      }
    }
  }
}
</script>

<style scoped>
.link {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}
.hideMe {
  display: none
}
</style>
