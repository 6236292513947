export const ACTIVE = 0
export const COMPLETE = 100
export const CANCELED = 101
export const POSTPONED = 102
export const LOCKED_INCOMPLETE = 103
export const DELETED = 999

export const UPCOMING = 'UPCOMING'
export const INPROCESS = 'INPROCESS'
export const ONGOING = 'ONGOING'
export const PAST = 'PAST'

export const StatusSelect = [
  { text: 'It\'s on', value: ACTIVE },
  { text: 'Canceled', value: CANCELED },
  { text: 'Postponed', value: POSTPONED },
  { text: 'Complete', value: COMPLETE },
  { text: 'Auto-Locked Incomplete', value: LOCKED_INCOMPLETE }
]
