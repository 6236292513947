<template>
  <v-text-field
    :label="label"
    :error-messages="error"
    :placeholder="label"
    ref="tf"
    solo
    :prepend-inner-icon="icon"
    single-line
    :hide-details="error.length < 1"
  ></v-text-field>
</template>

<script>
export default {
  props: ['divId', 'label', 'icon'],
  data () {
    return {
      focused: false,
      error: []
    }
  },
  computed: {
    l () {
      return this.focused && this.label
    }
  },
  methods: {
    setError (e) {
      this.error = e ? [e] : []
    },
    focus () {
      this.focused = true
      const el = this.$refs.tf.$el
      el.classList.add('v-input--is-focused')
      el.classList.add('color3--text')
      const label = el.querySelector('.v-label')
      if (label) {
        label.classList.add('v-label--active')
        label.classList.add('color3--text')
      }
    },
    blur () {
      this.focused = false
      const el = this.$refs.tf.$el
      el.classList.remove('v-input--is-focused')
      el.classList.remove('color3--text')
      const label = el.querySelector('.v-label')
      if (label) label.classList.remove('color3--text')
    },
    resetLabel () {
      const el = this.$refs.tf.$el
      const label = el.querySelector('.v-label')
      label && label.classList.remove('v-label--active')
    }
  },
  mounted () {
    const el = this.$refs.tf.$el
    const input = el.querySelector('input')
    const container = input.parentElement
    const div = document.createElement('div')
    div.id = this.divId
    input.style.display = 'none'
    container.appendChild(div)
  }
}
</script>
