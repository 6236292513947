import { UserManager, WebStorageStateStore } from 'oidc-client'
// import Vue from 'vue'

// const STS_DOMAIN = 'https://localhost:5443'
// const STS_DOMAIN = window.location.origin.includes('localhost:5443') ? 'https://localhost:5443' : 'https://volleyballlife.com'
const STS_DOMAIN = 'https://volleyballlife.com'

const MY_DOMAIN = window.location.origin
const tokenExp = (token) => {
  if (token) {
    const parsed = parseJwt(token)
    return parsed.exp ? parsed.exp * 1000 : null
  }
  return null
}

const tokenIsExpired = (token) => {
  const tokenExpiryTime = tokenExp(token)
  if (tokenExpiryTime) {
    return tokenExpiryTime < new Date().getTime()
  }
  return false
}
const parseJwt = (token) => {
  try {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))
  } catch (error) {
    return {}
  }
}
export default class AuthService {
  userManager = null
  settings = {
    authority: STS_DOMAIN,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    client_id: 'js',
    redirect_uri: `${MY_DOMAIN}/oidc-callback`,
    popup_redirect_uri: `${MY_DOMAIN}/static/popup-callback.html`,
    post_logout_redirect_uri: `${MY_DOMAIN}/static/signout-popup-callback.html`,
    response_type: 'code',
    scope: 'openid profile api1',
    automaticSilentRenew: true,
    silent_redirect_uri: `${MY_DOMAIN}/static/silent-renew.html`,
    accessTokenExpiringNotificationTime: 10
  }

  constructor () {
    this.userManager = new UserManager(this.settings)
  }

  getUser () {
    return this.userManager.getUser()
  }

  async isLoggedIn (storeUser) {
    console.log('auth')
    // const session = await this.userManager.querySessionStatus().catch(() => null)
    // await this.userManager.signinSilent().catch(() => null)
    const user = await this.userManager.getUser()
    if (!user) {
      console.log('No User')
      return false
    }
    console.log(user.expires_in)
    if (storeUser && `${storeUser.id}` !== user.profile.sub) {
      console.log(`FAIL 2 = User: ${user.profile.sub} - storeUser: ${storeUser.id}`)
      return false
    }
    return !user.expired
  }

  login () {
    console.log(MY_DOMAIN)
    const opt = this.popOptions()
    return this.userManager.signinPopup({ popupWindowFeatures: opt })
  }

  logout (click) {
    if (click) {
      this.userManager.signoutPopup()
    }
  }

  getAccessToken () {
    return this.userManager.getUser().then(data => {
      return (!data || tokenIsExpired(data.access_token)) ? null : data.access_token
    })
  }

  popOptions () {
    const w = 550
    const h = 685
    const left = (screen.width - w) / 2
    const top = (screen.height - h) / 4
    return `location=no,toolbar=no,width=${w},height=${h},left=${left},top=${top}`
  }
}
