import AuthGuard from '@/router/methods/auth-guard'

const TeamEditor = () => import('@/components/Team/Rostered/TeamEditor')
const TeamList = () => import('@/components/Team/Rostered/TeamList')

export default [
  {
    beforeEnter: AuthGuard,
    path: '/teams',
    name: 'team-list',
    component: TeamList,
    props: true
  },
  {
    beforeEnter: AuthGuard,
    path: '/team/:teamId',
    name: 'team-edit',
    component: TeamEditor,
    props: true
  }
]
