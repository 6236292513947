<template>
  <v-container fluid class="pa-0">
    <slot></slot>
    <v-row dense v-if="question">
      <v-col cols="12">
        <v-radio-group
          v-model="model.yesOrNo"
          row
          class="align-start"
          hide-details
          :disabled="disable"
        >
          <template slot="label" class="mr-4">
            {{question}}
          </template>
          <v-radio
            class="ml-4"
            color="color3"
            label="Yes"
            :value="true"
          ></v-radio>
          <v-radio
            color="color3"
            label="No"
            :value="false"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-expand-transition>
      <div v-show="model.yesOrNo">
        <v-row dense>
          <v-col cols="12" md="6" v-if="!!program">
            <college-selector
              v-if="ncaa"
              v-model="model.name"
              :disabled="disable"
              @blur="$v.model.name.$touch()"
              :errors="$v.model.name.$dirty && !$v.model.name.required ? ['A college is required'] : []"
            ></college-selector>
            <v-autocomplete
              v-else-if="ibvl"
              :items="ibvlSchools.girls"
              :label="`${program} Name*`"
              v-model="model.name"
              color="color3"
              :disabled="disable"
              :hide-details="!!disable"
              @blur="$v.model.name.$touch()"
              :rules="$v.model.name.$dirty ? [
                () => $v.model.name.required || `A ${program.toLowerCase()} name is required`
              ] : []"
            >
            </v-autocomplete>
            <v-text-field
              v-else
              :label="`${program} Name*`"
              v-model="model.name"
              color="color3"
              :disabled="disable"
              :hide-details="!!disable"
              @blur="$v.model.name.$touch()"
              :rules="$v.model.name.$dirty ? [
                () => $v.model.name.required || `A ${program.toLowerCase()} name is required`
              ] : []"
            ></v-text-field>
          </v-col>
        </v-row>
        <template v-for="(c, i) in model.contacts">
          <v-row dense :key="`c${i}_row1`">
            <v-col cols="12" md="6">
              <v-text-field
                :ref="`tf${i}`"
                :label="`${c.title} Name${isRequired(i, 'name') ? '*' : ''}`"
                v-model="model.contacts[i].name"
                color="color3"
                :disabled="disable"
                :hide-details="!!disable"
                hint="Please enter their full name"
                persistent-hint
                @blur="onBlur(i, 'name')"
                :rules="isRequired(i, 'name') && $v.model.contacts.$each[i].name.$dirty ? [
                  () => $v.model.contacts.$each[i].name.required || `A name is required`
                ] : []"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense :key="`c${i}_row2`">
            <v-col cols="12" md="6">
              <v-text-field
                :label="`${c.title} Phone${isRequired(i, 'phone') ? '*' : ''}`"
                v-model="model.contacts[i].phone"
                color="color3"
                :disabled="disable"
                :hide-details="!!disable"
                v-mask="'(###) ###-####'"
                @blur="onBlur(i, 'phone')"
               :rules="isRequired(i, 'phone') && $v.model.contacts.$each[i].phone.$dirty ? [
                  () => $v.model.contacts.$each[i].phone.required || `A phone is required`,
                  () => $v.model.contacts.$each[i].phone.ValidUSPhone || `A valid phone is required`
                ] : []"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :label="`${c.title} Email${isRequired(i, 'email') ? '*' : ''}`"
                v-model="model.contacts[i].email"
                color="color3"
                :disabled="disable"
                :hide-details="!!disable"
                @blur="onBlur(i, 'email')"
                :rules="isRequired(i, 'email') && $v.model.contacts.$each[i].email.$dirty ? [
                  () => $v.model.contacts.$each[i].email.required || `An email is required`,
                  () => $v.model.contacts.$each[i].email.email || 'A valid email is required'
                ] : []"
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
      </div>
    </v-expand-transition>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import ValidUSPhone from '@/helpers/ValidUSPhone'
import IBVL from '@/classes/IBVL'
const CollegeSelector = () => import('@/components/Colleges/CollegeSelector.vue')

export default {
  directives: { mask },
  mixins: [validationMixin],
  props: ['question', 'model', 'program', 'disable', 'contacts', 'requiredFields', 'ibvl', 'adminQuickAdd', 'ncaa'],
  data () {
    return {
    }
  },
  validations () {
    return {
      model: {
        name: this.adminQuickAdd ? {} : { required },
        contacts: {
          $each: {
            name: this.adminQuickAdd ? {} : { required },
            email: this.adminQuickAdd ? {} : { required, email },
            phone: this.adminQuickAdd ? {} : { required, ValidUSPhone }
          }
        }
      }
    }
  },
  computed: {
    valid () {
      return !this.$v.$invalid
    },
    ibvlSchools () {
      return IBVL
    }
  },
  methods: {
    addContact (title) {
      this.model.contacts.push({
        title: title,
        name: null,
        phone: null,
        email: null
      })
    },
    isRequired (i, field) {
      return this.requiredFields && this.requiredFields.length > i && this.requiredFields[i] && this.requiredFields[i].includes(field)
    },
    onBlur (i, field) {
      this.isRequired(i, field) && this.$v.model.contacts.$each[i].$touch()
    },
    checkContacts () {
      this.contacts.forEach(c => {
        const a = this.model.contacts.find(f => f.title === c)
        !a && this.addContact(c)
      })
    }
  },
  watch: {
    'model.yesOrNo': function (v) {
      v && this.checkContacts()
      this.$emit('yes-or-no-change', v)
    },
    valid: function (val) {
      this.model.valid = val
    },
    'model.name': function (v) {
      this.$emit('name-change', v)
      if (this.ncaa) {
        const a = this.$refs.tf0[0]
        if (a) {
          this.$nextTick(() => {
            a.focus()
          })
        }
      }
    }
  },
  mounted () {
    if (!this.model.contacts || !Array.isArray(this.model.contacts)) {
      this.$set(this.model, 'contacts', [])
    }
    this.checkContacts()
    if (this.valid) this.model.valid = true
  },
  components: {
    CollegeSelector
  }
}
</script>

<style>

</style>
