import Vue from 'vue'
import * as mutations from '../MutationTypes'
import * as actions from '../ActionTypes'

export default {
  state: {
    matchProps: {
      n: null,
      id: null,
      path: null,
      key: null
    }
  },
  mutations: {
    [mutations.SET_MATCH_PROPS] (state, payload) {
      state.matchProps.n = payload ? payload.n : null
      state.matchProps.id = payload ? payload.id : null
      state.matchProps.path = payload ? payload.path : null
      state.matchProps.key = payload ? payload.key : null
    },
    [mutations.CLEAR_MATCH_PROPS] (state) {
      state.matchProps.n = null
      state.matchProps.id = null
      state.matchProps.path = null
      state.matchProps.key = null
    }
  },
  actions: {
    [actions.KEY_CHECK] ({ commit, state }) {
      return new Promise((resolve, reject) => {
        const sdk = Vue.prototype.$VBL
        commit(mutations.LOADING, actions.KEY_CHECK)
        sdk.liveScoring.keycheck(state.matchProps.key, state.matchProps.id)
          .then(r => {
            if (!r.data) commit(mutations.CLEAR_MATCH_PROPS)
            resolve(r.data)
          })
          .catch(err => {
            commit(mutations.CLEAR_MATCH_PROPS)
            return reject(err)
          })
          .finally(() => {
            commit(mutations.LOADING_COMPLETE, actions.KEY_CHECK)
          })
      })
    }
  },
  getters: {
    matchProps: (state) => {
      return state.matchProps
    },
    isLiveScoring: (state) => {
      return state.matchProps && !!state.matchProps.path
    }
  }
}
