import moment from 'moment'

export default class PlayerRating {
  constructor (dto) {
    this.update(dto)
  }

  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    Object.assign(this, dto)
  }

  getMoment (dt) {
    const splt = dt.split('T')
    return moment(splt[0])
  }

  get dtEarned () {
    return this._dtEarned
  }

  set dtEarned (val) {
    this._dtEarned = this.getMoment(val)
  }

  get unix () {
    return this.dtEarned.unix()
  }

  getNext (i) {
    if (!this.values || ((this.values.length - 1) < (i + 1))) return 'Unrated'
    return this.values[i + 1].rating
  }

  get age () {
    const r = this.rating.replace(/\D/g, '')
    return r ? +r : 999
  }
}
