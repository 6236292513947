export default [
  {
    id: 1,
    text: 'Single Set',
    hint: '',
    isMatch: false,
    games: [
      {
        number: 1,
        to: 28,
        cap: null
      }
    ],
    mpm: 30
  },
  {
    id: 2,
    text: 'Match play',
    hint: 'Set 1 & 2 played to 21. Set 3 (if needed) to 15',
    isMatch: true,
    games: [
      {
        number: 1,
        to: 21,
        cap: null
      },
      {
        number: 2,
        to: 21,
        cap: null
      },
      {
        number: 3,
        to: 15,
        cap: null
      }
    ],
    mpm: 60
  },
  {
    id: 3,
    text: 'Mini Match',
    hint: 'Set 1 & 2 played to 15, cap of 17. Set 3 (if needed) to 15 no cap',
    isMatch: true,
    games: [
      {
        number: 1,
        to: 15,
        cap: 17
      },
      {
        number: 2,
        to: 15,
        cap: 17
      },
      {
        number: 3,
        to: 15,
        cap: null
      }
    ],
    mpm: 45
  },
  {
    id: 4,
    text: '2 Sets',
    hint: '',
    isMatch: false,
    games: [
      {
        number: 1,
        to: 21,
        cap: null
      },
      {
        number: 2,
        to: 21,
        cap: null
      }
    ],
    mpm: 40
  },
  {
    id: 5,
    text: '3 Sets',
    hint: '',
    isMatch: false,
    games: [
      {
        number: 1,
        to: 21,
        cap: null
      },
      {
        number: 2,
        to: 21,
        cap: null
      },
      {
        number: 3,
        to: 21,
        cap: null
      }
    ],
    mpm: 40
  },
  {
    id: 6,
    text: 'Indoor Match Best of 3',
    hint: 'Set 1 & 2 played to 25. Set 3 (if needed) to 15',
    isMatch: true,
    games: [
      {
        number: 1,
        to: 25,
        cap: null
      },
      {
        number: 2,
        to: 25,
        cap: null
      },
      {
        number: 3,
        to: 15,
        cap: null
      }
    ],
    mpm: 60
  },
  {
    id: 7,
    text: 'Indoor Match Best of 5',
    hint: 'Set 1 - 2 played to 25. Set 5 (if needed) to 15',
    isMatch: true,
    games: [
      {
        number: 1,
        to: 25,
        cap: null
      },
      {
        number: 2,
        to: 25,
        cap: null
      },
      {
        number: 3,
        to: 25,
        cap: null
      },
      {
        number: 4,
        to: 25,
        cap: null
      },
      {
        number: 5,
        to: 15,
        cap: null
      }
    ],
    mpm: 60
  }
]
