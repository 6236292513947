// User
export const ONLOGIN = 'ONLOGIN'
export const LOGOUT = 'LOGOUT'
export const LOAD_USER = 'LOAD_USER'
export const UNLOAD_USER = 'UNLOAD_USER'
export const LOAD_USER_IMAGES = 'LOAD_USER_IMAGES'
export const USER_CHECK = 'USER_CHECK'
export const USER_CHECK2 = 'USER_CHECK2'
export const LOAD_USER_BIDPROGRAMS = 'LOAD_USER_BIDPROGRAMS'
export const LOAD_USER_BOOKMARKS = 'LOAD_USER_BOOKMARKS'

// Shared
export const LOAD_SELECT_OPTIONS = 'LOAD_SELECT_OPTIONS'
export const LOAD_COUNTRIES = 'LOAD_COUNTRIES'
export const LOAD_COLLEGES = 'LOAD_COLLEGES'
export const LOAD_COLLEGE_LIST = 'LOAD_COLLEGE_LIST'
export const LOAD_THEME = 'LOAD_THEME'
export const UPDATE_THEME = 'UPDATE_THEME'

// Tournament
export const LOAD_TOURNAMENT = 'LOAD_TOURNAMENT'
export const HYDRATE_DIVISION = 'HYDRATE_DIVISION'
export const LOAD_MY_TOURNAMENT_TEAMS = 'LOAD_MY_TOURNAMENT_TEAMS'

// SignalR
export const LIVE_CONNECT = 'LIVE_CONNECT'
export const SUBSCRIBE_TO_TOURNAMENT = 'SUBSCRIBE_TO_TOURNAMENT'
export const UNSUBSCRIBE_TO_TOURNAMENT = 'UNSUBSCRIBE_TO_TOURNAMENT'
export const LIVE_SUBSCRIBE = 'LIVE_SUBSCRIBE'
export const STORE_LEAD = 'STORE_LEAD'
export const PLAYER_SEARCH = 'PLAYER_SEARCH'
export const AD_CLICK = 'AD_CLICK'

// Organization
export const LOAD_ORGANIZATION = 'LOAD_ORGANIZATION'

// Cart
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM'
export const CHECK_CART_STATUS = 'CHECK_CART_STATUS'
export const PURCHASE_COMPLETE = 'PURCHASE_COMPLETE'
export const ADD_CART_ITEM = 'ADD_CART_ITEM'
export const APPLY_PROMO = 'APPLY_PROMO'
export const CHECK_PROMO = 'CHECK_PROMO'

// LiveScoring
export const KEY_CHECK = 'KEY_CHECK'
