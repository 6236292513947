import DtoUpdate from './DtoUpdate'
import Pool from './Pool'
import SeedingStyles from './SeedingStyles'
import MatchSettings from './MatchSettings'
import flatten from '../helpers/ArrayFlatten'
import min from 'lodash.min'
import firstBy from 'thenby'

export default class Flight {
  id = 0
  published = false
  settings = []
  pools = []
  _teams = []
  deleted = false
  fivb = false
  aau3 = false
  courts = null
  namingConvention = null
  powerPools = null
  tiebreaks = null
  props = []

  constructor (sdk, dto) {
    this.sdk = sdk
    this.update(dto)
  }

  // methods
  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = ['pools', 'settings']
    DtoUpdate(dto, this, exclude)

    if (dto.settings) {
      this.settings = dto.settings.map(s => new MatchSettings(s))
    }
    if (dto.pools) {
      this.pools = dto.pools.map(p => {
        p.fivb = this.fivb
        p.tiebreaks = this.tiebreaks
        var pool = new Pool(this.sdk, p, this.settings)
        return pool
      })
    }
    this.pools.sort(firstBy('number'))
    this.settings.sort(firstBy('poolsOf'))
    if (!this.props) this.props = []

    this._dto = null
  }

  edit () {
    this._dto = JSON.stringify(this.dto)
    this.pools.forEach(p => p.edit())
  }

  restore () {
    if (this.isDirty()) this.update(this._dto)
    this.pools.forEach(p => p.restore())
    this._dto = null
  }

  isDirty () {
    return this._dto !== JSON.stringify(this.dto) || this.id === 0
  }

  patch () {
    return this.sdk.patch.flights([this.patchDto])
  }

  addPool () {
    let minId = min(this.pools.map(p => p.id))
    if (minId > 0) minId = 0
    this.pools.sort(firstBy('number'))
    const lastPool = this.pools[this.pools.length - 1]
    this.pools.push(new Pool(this.sdk, {
      id: minId - 1,
      name: this.pools.length + 1,
      assignTimes: lastPool.assignTimes,
      minutesPerMatch: lastPool.minutesPerMatch,
      startTime: lastPool.startTime,
      holder: true
    }, this.settings))
  }

  setStart (val) {
    this.settings.forEach(s => {
      s.startTime = val
    })
  }

  // getters
  get dirty () {
    return this._dto !== JSON.stringify(this.dto)
  }

  get dirtyPools () {
    return this.pools.filter(f => f.dirty)
  }

  get dirtyTeams () {
    return this.poolTeams.filter(f => f.dirty)
  }

  get dto () {
    return {
      id: this.id,
      published: this.published,
      settings: this.settings.map(setting => setting.dto),
      pools: this.pools.map(pool => pool.dto),
      fivb: this.fivb,
      aau3: this.aau3,
      courts: this.courts,
      namingConvention: this.namingConvention,
      powerPools: this.powerPools,
      tiebreaks: this.tiebreaks,
      props: this.props && this.props.length ? this.props : null
    }
  }

  get seedingStyle () {
    return SeedingStyles.find(f => f.style === this._seedingStyle || 'PRE')
  }

  set seedingStyle (val) {
    if (!val || typeof val === 'string') {
      this._seedingStyle = val
    } else {
      this._seedingStyle = val.style
    }
  }

  get patchDto () {
    return {
      id: this.id,
      published: this.published,
      settings: this.settings.map(setting => setting.dto),
      fivb: this.fivb,
      aau3: this.aau3,
      tiebreaks: this.tiebreaks,
      props: this.props && this.props.length ? this.props : null
    }
  }

  get completePools () {
    return this.pools.filter(pool => pool.complete)
  }

  get complete () {
    return this.completePools.length === this.pools.length
  }

  get allLocked () {
    return this.pools.filter(pool => !pool.locked).length === 0
  }

  get started () {
    return this.pools.filter(f => f.status).length
  }

  get teams () {
    return this._teams.filter(f => !f.isDeleted)
  }

  get poolTeams () {
    return flatten(this.pools.map(p => p.teams))
  }

  get rankedTeams () {

  }

  get poolLessTeams () {
    const pooled = new Set(flatten(this.pools.map(p => {
      return p.teamIds
    })))

    return this.teams.filter(t => {
      return !pooled.has(t.id)
    })
  }

  get isDuals () {
    return this.props.filter(f => f.startsWith('duals-')).length > 0
  }

  get isKob () {
    return this.props.includes('kob')
  }

  getPoolTitle (n, leagueSettings) {
    let p = this.props.find(f => f.startsWith(`pool-${n}`))
    if (p) {
      p = p.replace(`pool-${n}-`, '')
      const splt = p.split('~')
      let c = splt.find(f => f.startsWith('court'))
      if (c) c = c.replace('court-', '')
      let s = splt.find(f => f.startsWith('slot'))
      if (s) s = +s.replace('slot-', '')
      var courtNames = c.includes('&') ? c.replace('&', ' & ') : leagueSettings.courtNames[+c]
      return `${leagueSettings.slots[s]} on Court ${courtNames}`
    }
  }
}
