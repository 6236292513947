import endpoints from './endpoints'

export default class GetSDK {
  constructor (http) {
    this.http = http
  }

  images (type, id, sb) {
    const p = sb ? `?sb=${sb}` : ''
    const url = `${endpoints.images(type, id)}${p}`
    return this.http.get(url)
  }

  organizations () {
    return this.http.get(endpoints.organizations)
  }

  players (organizationId) {
    const url = organizationId ? `${endpoints.players.base}/organization/${organizationId}` : endpoints.players.base
    return this.http.get(url)
  }

  playerList (q, filter) {
    return this.http.post(`/players/list${q || ''}`, filter || {})
  }

  player (id) {
    return this.http.get(`${endpoints.players.base}/${id}`)
  }

  registrationFields (tournamentId) {
    return this.http.get(endpoints.data.registrationFields(tournamentId))
  }

  roundTeams (roundId) {
    return this.http.get(endpoints.round.teams(roundId))
  }

  selectOptions () {
    return this.http.get(endpoints.tournament.options)
  }

  notification (id) {
    return this.http.get(endpoints.notifications.tournament + `/${id}`)
  }

  bids (dto) {
    return this.http.get(endpoints.bids.list(dto))
  }

  bidEmailCount (id) {
    return this.http.get(endpoints.bids.emailCount(id))
  }

  countries () {
    return this.http.get(endpoints.country.all)
  }

  members (username, term) {
    return this.http.get(endpoints.membership.search(username, term))
  }

  memberList (username, term) {
    return this.http.get(endpoints.membership.list(username, term))
  }

  membership (username, add) {
    let url = endpoints.membership.base(username)
    if (add) url += `?add=${add}`
    return this.http.get(url)
  }

  admins (username) {
    return this.http.get(endpoints.organization.admins(username))
  }
}
