<template>
  <v-select
    :items="myOptions"
    item-value="id"
    item-text="name"
    v-model="model.pointSystemId"
    label="Point System"
    :loading="loading"
    attach chips deletable-chips
    :disabled="disabled"
    color="color3"
    item-color="color3"
  >
  </v-select>
</template>

<script>
import { firstBy } from 'thenby'
import { mapGetters } from 'vuex'

export default {
  props: ['disabled', 'model'],
  data () {
    return {
      pointOptions: [],
      loading: false,
      sbOptions: [
        { id: 3, name: 'AAU' },
        { id: 4, name: 'p1440' },
        { id: 6, name: 'AVPA 2019' },
        { id: 8, name: 'AVPA AVPNext 5K -14999 / U26  ' },
        { id: 9, name: 'AVPA AVPNext GOLD 15K-25K  ' },
        { id: 10, name: 'AVPA AVPNext GOLD 25K+  ' },
        { id: 11, name: 'AVPA 5 Star ' },
        { id: 12, name: 'AVPA 4 Star' },
        { id: 13, name: 'AVPA 3 Star' },
        { id: 14, name: 'AVPA 2 Star' },
        { id: 15, name: 'AVPA 1 Star' },
        { id: 16, name: 'AVPA AVPA Adults' }
      ]
    }
  },
  computed: {
    ...mapGetters(['user']),
    sbOption () {
      return this.model.pointSystemId && this.myOptions.find(f => f.id === this.model.pointSystemId)
    },
    myOptions () {
      var a = [
        ...this.pointOptions,
        { id: 3, name: 'AAU' },
        { id: 4, name: 'p1440' },
        { id: -2, name: 'AVP' }
      ]
      // this.sbOption && a.push(this.sbOption)
      a.sort(firstBy('name'))
      a.push({ id: -1, name: 'Custom' })
      return a
    },
    usernames () {
      return this.user && this.user.pages && this.user.pages.map(m => m.username)
    }
  },
  methods: {
    checkForPoints () {
      if (this.usernames) {
        this.loading = true
        Promise.all(this.usernames.map(u => {
          this.$VBL.organization.pointSystems.getOptions(u)
            .then(r => {
              r.data && this.pointOptions.push(...r.data)
            })
            .catch(e => {
              console.log(e.response)
            })
        })).then(() => {
          this.loading = false
        })
      }
    }
  },
  mounted () {
    this.checkForPoints()
  }
}
</script>
