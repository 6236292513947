import Vue from 'vue'
import * as mutations from '../MutationTypes'
import * as actions from '../ActionTypes'
import vbl from '../../VBL/endpoints'
import selectOptions from './SelectOptions.json'

export default {
  state: {
    error: null,
    nav: 'public',
    ageTypeOptions: selectOptions.ageTypeOptions,
    genderOptions: selectOptions.genderOptions,
    divisionOptions: selectOptions.divisionOptions,
    locationOptions: selectOptions.locationOptions,
    optionsLoaded: false,
    publicView: false,
    printPools: false,
    printable: null,
    inIFrame: false,
    countries: [],
    countryStatus: null,
    colleges: [],
    collegesStatus: null,
    collegeList: [],
    collegeListStatus: null,
    transition: null
  },
  mutations: {
    [mutations.SET_TRANSITION] (state, payload) {
      state.transition = payload
    },
    [mutations.SET_ERROR] (state, payload) {
      state.error = payload
    },
    [mutations.SET_NAV] (state, payload) {
      state.nav = payload
    },
    [mutations.SET_AGETYPEOPTIONS] (state, payload) {
      state.ageTypeOptions = payload
    },
    [mutations.SET_GENDEROPTIONS] (state, payload) {
      state.genderOptions = payload
    },
    [mutations.SET_DIVISIONOPTIONS] (state, payload) {
      state.divisionOptions = payload
    },
    [mutations.SET_LOCATIONOPTIONS] (state, payload) {
      state.locationOptions = payload
    },
    [mutations.SET_OPTIONS_LOADED] (state, payload) {
      state.optionsLoaded = payload
    },
    [mutations.TOGGLE_VIEW] (state) {
      state.publicView = !state.publicView
    },
    [mutations.PRINT_POOLS] (state) {
      state.printPools = true
    },
    [mutations.SET_PRINTABLE] (state, payload) {
      state.printable = payload
    },
    [mutations.SET_IFRAME] (state, payload) {
      state.inIFrame = payload
    },
    [mutations.SET_COUNTRIES] (state, payload) {
      state.countries = payload
    },
    [mutations.SET_COUNTRIES_STATUS] (state, payload) {
      state.countryStatus = payload
    },
    [mutations.SET_COLLEGES] (state, payload) {
      state.colleges = payload
    },
    [mutations.SET_COLLEGES_STATUS] (state, payload) {
      state.collegesStatus = payload
    },
    [mutations.SET_COLLEGE_LIST] (state, payload) {
      state.collegeList = payload
    },
    [mutations.SET_COLLEGE_LIST_STATUS] (state, payload) {
      state.collegeListStatus = payload
    }
  },
  actions: {
    [actions.LOAD_SELECT_OPTIONS] ({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.axios.get(vbl.tournament.getSelectOptions())
          .then((response) => {
            commit(mutations.SET_AGETYPEOPTIONS, response.data.ageTypeOptions)
            commit(mutations.SET_GENDEROPTIONS, response.data.genderOptions)
            commit(mutations.SET_DIVISIONOPTIONS, response.data.divisionOptions)
            commit(mutations.SET_LOCATIONOPTIONS, response.data.locationOptions)
            commit(mutations.SET_OPTIONS_LOADED, true)
            resolve()
          })
          .catch(e => {
          // Error
            console.log('Error loading select options')
            console.log(e.response)
            reject(e)
          })
      })
    },
    [actions.LOAD_COUNTRIES] ({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        if (state.countries.length || state.countryStatus === 'loading') return resolve()
        commit(mutations.SET_COUNTRIES_STATUS, 'loading')
        Vue.prototype.$VBL.get.countries()
          .then(r => {
            commit(mutations.SET_COUNTRIES, r.data)
            commit(mutations.SET_COUNTRIES_STATUS, 'loaded')
            resolve()
          })
          .catch(e => {
          // Error
            console.log('Error loading select options')
            console.log(e.response)
            reject(e)
          })
      })
    },
    [actions.LOAD_COLLEGES] ({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        if (state.colleges.length || state.collegesStatus === 'loading') return resolve()
        commit(mutations.SET_COLLEGES_STATUS, 'loading')
        Vue.prototype.$VBL.college.names()
          .then(r => {
            commit(mutations.SET_COLLEGES, r.data)
            commit(mutations.SET_COLLEGES_STATUS, 'loaded')
            resolve()
          })
          .catch(e => {
            // Error
            console.log('Error loading select options')
            console.log(e.response)
            reject(e)
          })
      })
    },
    [actions.LOAD_COLLEGE_LIST] ({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        if (state.collegeList.length || state.collegeListStatus === 'loading') return resolve()
        commit(mutations.SET_COLLEGE_LIST_STATUS, 'loading')
        Vue.prototype.$VBL.college.list()
          .then(r => {
            commit(mutations.SET_COLLEGE_LIST, r.data)
            commit(mutations.SET_COLLEGE_LIST_STATUS, 'loaded')
            resolve()
          })
          .catch(e => {
            // Error
            console.log('Error loading select options')
            console.log(e.response)
            reject(e)
          })
      })
    }
  },
  getters: {
    mainTransition (state) {
      return state.transition
    },
    countries (state) {
      return state.countries
    },
    colleges (state) {
      return state.colleges
    },
    collegeList (state) {
      return state.collegeList
    },
    error (state) {
      return state.error
    },
    nav (state) {
      return state.nav
    },
    ageTypeOptions (state) {
      return state.ageTypeOptions
    },
    genderOptions (state) {
      return state.genderOptions
    },
    divisionOptions (state) {
      return state.divisionOptions
    },
    locationOptions (state) {
      return state.locationOptions
    },
    subdomain (state) {
      if (window.location.host.includes('p1440.')) return 'p1440'
      if (window.location.host.includes('teqsports.')) return 'teqball'
      const i = window.location.host.includes('localhost') ? -1 : -2
      const subdomain = window.location.hostname.split('.').slice(0, i).join('.')
      // console.log('***' + subdomain)
      return subdomain
    },
    publicView (state) {
      return state.publicView
    },
    printPools (state) {
      return state.printPools
    },
    view (state, rootGetters) {
      return {
        public: state.publicView || (!rootGetters.admin && !rootGetters.isRef),
        admin: rootGetters.admin && !state.publicView,
        adminAndRefs: (rootGetters.admin || rootGetters.isRef) && !state.publicView,
        vbl: rootGetters.user && rootGetters.user.vbl && !state.publicView
      }
    },
    printable (state) {
      return state.printable
    },
    inIFrame (state) {
      return state.inIFrame
    }
  }
}
