import moment from 'moment'

export default class Checklist {
  id = 0
  tournamentId = null
  name = null
  note = null
  teamIds = null
  askWho = false
  createdBy = null
  dtCreated = null
  edited = null
  editedBy = null
  items = []

  constructor (dto) {
    if (dto) {
      this.update(dto)
    }
  }

  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    Object.assign(this, dto)
    if (dto.edited && typeof dto.edited === 'string') this.edited = moment(dto.edited.replace('Z', ''))
    if (dto.dtCreated && typeof dto.dtCreated === 'string') this.dtCreated = moment(dto.dtCreated.replace('Z', ''))
  }

  get dto () {
    return {
      id: this.id,
      tournamentId: this.tournamentId,
      name: this.name,
      note: this.note,
      teamIds: this.teamIds,
      askWho: this.askWho
    }
  }
}
